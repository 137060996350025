import React from 'react';
import packageJson from "../../../package.json";
import AuthActions from "../auth/AuthActions";
import {NavLink} from "react-router-dom";
import {Sidebar} from "primereact/sidebar";
import './Footer.css';
import {Trans, withTranslation} from "react-i18next";

const oldState = {
    cookiesBar: true
};

class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cookiesBar: sessionStorage.getItem('cookie-bar') === 'hide' ? false : true
        };
        this.state = oldState;
    }

    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();
        return (
            <footer className="footer">
                <img alt='' src={process.env.PUBLIC_URL + '/logotyp1.png'} style={{width: '100%', maxWidth: 1140}}/>
                <div style={{marginTop: '24px'}}>
                    <p style={{fontSize: ".8em"}}>{this.props.t('cofinance-desc')}</p>
                    <a href='https://www.kpodr.pl/' style={{color: 'var(--secondary)'}}>https://www.kpodr.pl/</a>
                    <br/>
                    <span>PTM Soft 2019-{new Date().getFullYear()} {externalUserLogged && <span> - {packageJson.version}</span>}</span>
                    <br/>
                    <NavLink to="/cookie" style={{color: 'var(--secondary)'}}>{this.props.t('cookies-policy')}</NavLink>
                    <br/>
                    <NavLink to="/rodo" style={{color: 'var(--secondary)'}}>{this.props.t('personal-data')}</NavLink>
                </div>
                <Sidebar visible={this.state.cookiesBar} position={this.props.mobile ? 'top' : 'bottom'} modal={false}
                         style={this.state.cookiesBar ? {width: '100%'} : {display: 'none'}}
                         className="ui-sidebar-sm cookies-bar"
                         onHide={e => {
                             sessionStorage.setItem('cookie-bar', 'hide');
                             this.setState({cookiesBar: false});
                         }}>
                    <Trans style={{width: '95%'}}
                           i18nKey="common:cookies-shortdesc"
                           components={[<NavLink to="/cookie">tutaj</NavLink>]}/>
                </Sidebar>
            </footer>
        )
    }

}

export default withTranslation('common')(Footer);