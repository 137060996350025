import React from 'react';
import {Button, Col, FormFeedback, FormGroup, Input, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './FieldDefinition.css';
import {Chips} from "primereact/chips";
import {Checkbox} from "primereact/checkbox";

export default class FieldDefinition extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            name: this.props.field.name,
            validName: true,
            position: this.props.field.position,
            description: this.props.field.description,
            type: this.props.field.type,
            required: this.props.field.required,
            items: this.props.field.items,
        };
        this.buildFieldDTO = this.buildFieldDTO.bind(this);
    }

    buildFieldDTO() {
        return {
            id: this.props.field.id,
            position: this.props.field.position,
            name: this.state.name,
            description: this.state.description,
            type: this.state.type.toUpperCase(),
            required: this.state.required,
            items: this.state.items
        }
    }

    render() {
        const fieldId = this.props.field.id;
        const nameFieldId = 'field_' + fieldId + '_name';
        const typeFieldId = 'field_' + fieldId + '_type';
        const dscFieldId = 'field_' + fieldId + '_dsc';
        const itemsFieldId = 'field_' + fieldId + '_items';
        const reqFieldId = 'field_' + fieldId + '_req';
        const showItemsRow = this.state.type === 'RADIO' || this.state.type === 'SELECT' || this.state.type === 'MULTIPLE';
        return (
            <div className='field-definition'>
                <Row form>
                    <Col md={6}>
                        <div style={{display: 'inline-block'}}>
                            <Button type='button' color='primary'
                                    onClick={() => this.props.onMoveUp(fieldId)}>
                                <FontAwesomeIcon icon='chevron-up'/>
                            </Button><br/>
                            <Button type='button' color='primary' className='mt-2'
                                    onClick={() => this.props.onMoveDown(fieldId)}>
                                <FontAwesomeIcon icon='chevron-down'/>
                            </Button>
                        </div>
                        <FormGroup style={{display: 'inline-block', width: 'calc(100% - 54px)', marginTop: '-16px',verticalAlign: 'bottom'}} className='ml-2'>
                            <Label for={nameFieldId}>Nazwa pola: </Label>
                            <Input name={nameFieldId}
                                   value={this.state.name}
                                   className={this.state.validName ? '' : 'is-invalid'}
                                   onChange={e => this.setState({
                                       name: e.target.value,
                                       validName: this.props.validateName(e.target.value)
                                   })}
                                   required/>
                            <FormFeedback>Wymagana unikalna nazwa!</FormFeedback>
                        </FormGroup>
                    </Col>
                    <Col md={3}>
                        <FormGroup>
                            <Label for={typeFieldId}>Typ pola: </Label>
                            <Input type="select"
                                   name={typeFieldId}
                                   value={this.state.type}
                                   onChange={e => this.setState({type: e.target.value})}>
                                <option value='TEXT'>Tekst</option>
                                <option value='NUMBER'>Numer</option>
                                <option value='CHECKBOX'>Flaga</option>
                                <option value='RADIO'>Pole wyboru</option>
                                <option value='SELECT'>Lista</option>
                                <option value='MULTIPLE'>Lista wielokrotnego wyboru</option>
                            </Input>
                        </FormGroup>
                    </Col>
                    <Col md={2}>
                        <FormGroup>
                            <label htmlFor={reqFieldId} className="p-checkbox-label">Wymagane:</label><br/>
                            <Checkbox inputId={reqFieldId} onChange={e => this.setState({required: e.checked})}
                                      checked={this.state.required}/>

                        </FormGroup>
                    </Col>
                    <Col md={1} className='delete-col'>
                        <Button type='button' color='danger'
                                onClick={() => this.props.onDelete(this.buildFieldDTO())}><FontAwesomeIcon
                            icon='trash-alt'/></Button>
                    </Col>
                </Row>
                <Row form>
                    <Col md={11}>
                        <FormGroup>
                            <Label for={dscFieldId}>Opis pola: </Label>
                            <Input name={dscFieldId}
                                   value={this.state.description}
                                   onChange={e => this.setState({description: e.target.value})}
                                   required/>
                        </FormGroup>
                    </Col>
                </Row>
                {showItemsRow && <Row form>
                    <Col md={11}>
                        <FormGroup>
                            <Label for={itemsFieldId}>Możliwe wartości: </Label>
                            <Chips name={itemsFieldId} value={this.state.items}
                                   onChange={(e) => this.setState({items: e.value})}/>
                            <span style={{fontSize: '85%'}}>Wciśnij ENTER aby dodać wartość do listy.</span>
                        </FormGroup>
                    </Col>
                </Row>}

            </div>
        )
    }

}
