import React from 'react';
import {withTranslation} from 'react-i18next';
import GrainDiseaseService from "../GrainDiseaseService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button, Form, FormGroup, Label} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dropdown} from "primereact/dropdown";
import Container from "reactstrap/es/Container";
import {Growl} from "primereact/growl";
import {Link} from "react-router-dom";
import './GrainDiseaseList.css';

class GrainDiseaseList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            grainDiseases: [],
            selected: null,
            disease: {},
            displayDialog: false
        };
        this.newDisease = true;
        this.grainDiseaseService = new GrainDiseaseService();
        this.addNew = this.addNew.bind(this);
        this.updateProperty = this.updateProperty.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.onSelect = this.onSelect.bind(this);
        this.updateUrl = this.updateUrl.bind(this);
        this.actionColumn = this.actionColumn.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.grainDiseaseService.getAll()
            .then(res => {
                this.setState({grainDiseases: res});
            })
    }

    addNew() {
        this.newDisease = true;
        this.setState({
            disease: {uuid: '', name: '', url: '', calcType: null},
            displayDialog: true
        });
    }

    onSelect(e) {
        this.newDisease = false;
        this.setState({
            displayDialog: true,
            disease: Object.assign({}, e.data)
        });
    }

    updateProperty(property, value) {
        let disease = this.state.disease;
        disease[property] = value;
        this.setState({disease: disease});
    }

    updateUrl() {
        if (this.state.disease.url === '') {
            let disease = this.state.disease;
            disease.url = disease.name.toLowerCase().replace(' ', '')
                .replace(/[ą]/g, "a")
                .replace(/[ę]/g, "e")
                .replace(/[ó]/g, "o")
                .replace(/[ś]/g, "s")
                .replace(/[ł]/g, "l")
                .replace(/[ż]/g, "z")
                .replace(/[ź]/g, "z")
                .replace(/[ć]/g, "c")
                .replace(/[ń]/g, "n");
            this.setState({disease: disease});
        }
    }

    save() {
        if (this.newDisease) {
            this.grainDiseaseService.create(this.state.disease)
                .then(res => {
                    this.setState(state => {
                        let diseases = [...state.grainDiseases];
                        diseases.push(res);
                        this.growl.current.show({severity: 'success', summary: 'Zapisano chorobę.'});
                        return {grainDiseases: diseases, selected: null, disease: null, displayDialog: false};
                    })
                })
                .catch(err => {
                    console.error(err);
                    this.growl.current.show({severity: 'error', summary: 'Nie udało się zapisać choroby.'});
                });
        } else {
            this.grainDiseaseService.update(this.state.disease)
                .then(res => {
                    this.setState(state => {
                        let diseases = state.grainDiseases.filter(d => d.uuid !== state.disease.uuid);
                        diseases.push(this.state.disease);
                        this.growl.current.show({severity: 'success', summary: 'Zapisano chorobę.'});
                        return {grainDiseases: diseases, selected: null, disease: null, displayDialog: false};
                    })
                })
                .catch(err => {
                    console.error(err);
                    this.growl.current.show({severity: 'error', summary: 'Nie udało się zapisać choroby.'});
                });
        }
    }

    delete() {
        this.grainDiseaseService.delete(this.state.disease.uuid)
            .then(res => {
                this.setState(state => {
                    return {
                        grainDiseases: this.state.grainDiseases.filter(d => d.uuid !== state.disease.uuid),
                        selected: null,
                        disease: null,
                        displayDeleteDialog: false,
                        displayDialog: false,
                    }
                });
            })
            .catch(err => {
                console.error(err);
                this.growl.current.show({severity: 'error', summary: 'Nie udało się usunąć choroby.'});
            });
    }

    typeTemplate(rowData, column) {
        return <span>{rowData.calcType === 'SEPTORIA' ? 'Septorioza' : rowData.calcType === 'EYESPOT' ? 'Łamliwość' : ''}</span>;
    }

    actionColumn(rowData, column) {
        return <div>
            <Link to={'/admin/chorobyzboz/historia/' + rowData.uuid} className='mr-3'>Historia</Link>
            <Link to={'/admin/chorobyzboz/edytor/pl/' + rowData.uuid} className='mr-3'>Treść pl</Link>
            <Link to={'/admin/chorobyzboz/edytor/en/' + rowData.uuid} className='mr-3'>Treść en</Link>
        </div>
    }

    render() {
        let footer = <div className="p-clearfix" style={{width: '100%'}}>
            <Button color='primary' onClick={this.addNew}><FontAwesomeIcon icon='plus'/> Dodaj</Button>
        </div>;
        const types = [
            {label: 'brak', value: null},
            {label: 'Septorioza', value: 'SEPTORIA'},
            {label: 'Łamliwość', value: 'EYESPOT'}
        ];
        const dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.state.disease && this.state.disease.uuid &&
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: true})}>
                <FontAwesomeIcon icon='trash-alt'/> Usuń
            </Button>
            }
            <Button color='primary' onClick={this.save}>
                <FontAwesomeIcon icon='check'/> Zapisz
            </Button>
            <Button color='primary' onClick={() => this.setState({displayDialog: false})}>
                <FontAwesomeIcon icon='times'/> Anuluj
            </Button>
        </div>;

        const deleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.state.disease && this.state.disease.uuid &&
            <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> Usuń
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: false})}>
                <FontAwesomeIcon icon='times'/> Anuluj
            </Button>
        </div>;

        return (
            <Container>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.grainDiseases} paginator={true} rows={15} footer={footer}
                           selectionMode="single" selection={this.state.selected}
                           onRowSelect={this.onSelect}
                           onSelectionChange={e => this.setState({selected: e.value})}>
                    <Column field="name" header="Nazwa" sortable={true}/>
                    <Column field="url" header="Adres podstrony" sortable={true}/>
                    <Column field="calcType" header="Kalkulator" sortable={true} body={this.typeTemplate}/>
                    <Column body={this.actionColumn}/>
                </DataTable>

                <Dialog visible={this.state.displayDeleteDialog}
                        header="Potwierdzenie usunięcia" footer={deleteFooter} modal={true}
                        onHide={() => this.setState({displayDeleteDialog: false})}>
                    {this.state.disease &&
                    <span>Czy na pewno chcesz usunąć podstronę choroby {this.state.disease.name}?</span>}
                </Dialog>

                <Dialog visible={this.state.displayDialog} className='grain-disease-form' header="Choroba zboża"
                        modal={true}
                        footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                    {
                        this.state.disease &&
                        <Form>
                            <FormGroup>
                                <Label for='name'>Nazwa</Label><br/>
                                <InputText id="name" onChange={(e) => {
                                    this.updateProperty('name', e.target.value)
                                }} value={this.state.disease.name}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for='url'>Adres podstrony</Label><br/>
                                <span style={{fontSize: '85%'}}>nazwa bez polskich i białych znaków</span><br/>
                                <InputText id="url" onFocus={() => this.updateUrl()}
                                           onChange={(e) => {
                                               this.updateProperty('url', e.target.value)
                                           }} value={this.state.disease.url}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for='calcType'>Kalkulator</Label><br/>
                                <Dropdown id='calcType' value={this.state.disease.calcType} options={types}
                                          onChange={e => this.updateProperty('calcType', e.value)}/>
                            </FormGroup>
                        </Form>
                    }
                </Dialog>
            </Container>
        )
    }

}

export default withTranslation('ns')(GrainDiseaseList);
