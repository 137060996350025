import ApiService from "../../../service/common/ApiService";

export default class PotatoVarietyService {
    getAll() {
        return ApiService.get('/negfry/varieties');
    }

    create(data) {
        return ApiService.post('/negfry/varieties', data);
    }

    update(id, data) {
        return ApiService.put('/negfry/varieties/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/negfry/varieties/' + id);
    }
}