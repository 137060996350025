import React from 'react';
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {getOrder, getPrecision, getUnit} from "../../../util/MeteoUtil";
import './DataStats.css';
import moment from "moment";

class DataStats extends React.Component {

    formatMeasurement(type, rawValue) {
        if (rawValue === undefined || rawValue === null || rawValue === Infinity || rawValue === -Infinity) {
            return '';
        }
        return rawValue.toFixed(Number(getPrecision(type)));
    }

    render() {
        const data = this.props.data;
        const rawData = this.props.rawData;
        data.sort((a, b) => getOrder(a.measurement) - getOrder(b.measurement));
        const dataArrays = Object.values(rawData);
        const tmp = [];
        for (let i = 0; i < data.length; i++) {
            let mt = data[i].measurement;
            const dataSet = [];
            for (let i = 0; i < dataArrays.length; i++) {
                let datum = dataArrays[i].data;
                dataSet.push(datum[mt]);
            }
            tmp[mt] = dataSet;
        }
        const cells = [];
        for (let i = 0; i < data.length; i++) {
            const minMax = data[i];
            const unit = getUnit(minMax.measurement).name;
            if (minMax.measurement === 'precipitation1' || minMax.measurement === 'precipitation2') {
                const sum = tmp[minMax.measurement].reduce((a, b) => a + b, 0);
                cells.push(
                    <p key={i}>
                        <span className='stats-key'>{this.props.t(minMax.measurement)} - {this.props.t('sum')}:</span>
                        <span
                            className='stats-val'> {this.formatMeasurement(minMax.measurement, sum)}{unit && unit}</span>
                    </p>);
            } else {
                cells.push(
                    <p key={i}>
                        <span className='stats-key'>{this.props.t(minMax.measurement)}:</span>
                        <span
                            className='stats-val'> {this.formatMeasurement(minMax.measurement, minMax.min)}{unit && unit} / {this.formatMeasurement(minMax.measurement, minMax.max)}{unit && unit}</span>
                    </p>);
            }
        }

        let dateFrom = moment(this.props.from);
        const from = dateFrom.isValid() ? dateFrom.format('YYYY-MM-DD HH:mm') : '';
        let dateTo = moment(this.props.to);
        const to = dateTo.isValid() ? dateTo.format('YYYY-MM-DD HH:mm') : '';
        return (
            <div>
                <span style={{fontSize: '95%'}}>{this.props.t('data-stats-dsc')} ({from} - {to}):</span>
                <div className='data-stats'>
                    <br/>
                    {cells}
                </div>
            </div>
        )
    }

}

DataStats.propTypes = {
    data: PropTypes.array.isRequired,
    rawData: PropTypes.object.isRequired
};


export default withTranslation('meteo')(DataStats);