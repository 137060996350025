import React, {Component} from 'react';
import {withTranslation} from "react-i18next";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import MyFieldService from "./MyFieldService";
import {Dialog} from "primereact/dialog";
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Col, Container, FormGroup, Label, Row} from "reactstrap";
import Button from "reactstrap/es/Button";
import MeteoService from "../map/MeteoService";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Commons from "../../common/Commons";
import Card from "reactstrap/es/Card";
import CardBody from "reactstrap/es/CardBody";
import CardText from "reactstrap/es/CardText";

class MyFieldList extends Component {

    constructor(props) {
        super(props);
        this.myFieldService = new MyFieldService();
        this.meteoService = new MeteoService();
        this.defaultStations = JSON.parse(localStorage.getItem('default_stations'));
        this.state = {
            field: null,
            fields: [],
            stations: [],
            selectedField: null
        };
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.onFieldSelect = this.onFieldSelect.bind(this);
        this.addNew = this.addNew.bind(this);
        console.log(this.props);
    }

    componentDidMount() {
        this.myFieldService.getAll()
            .then(data => this.setState({fields: data}));
        this.meteoService.getAllStations().then(data => this.setState({stations: data}));
    }

    onValidSubmit = (event, values) => {
        this.save();
    };

    findSelectedFieldIndex() {
        return this.state.fields.indexOf(this.state.selectedField);
    }

    save() {
        let fields = [...this.state.fields];
        if (this.newField) {
            this.myFieldService.create(this.state.field)
                .then(response => {
                    fields.push(response);
                    this.setState({fields: fields, selectedField: null, field: null, displayDialog: false});
                });
        } else {
            this.myFieldService.update(this.state.field.uuid, this.state.field);
            this.state.field.meteoStation = this.findMeteoStationdById(this.state.field.meteoStationId);
            fields[this.findSelectedFieldIndex()] = this.state.field;
            this.setState({fields: fields, selectedField: null, field: null, displayDialog: false});
        }
    }

    findMeteoStationdById(meteoStationId) {
        return this.state.stations.map(station => {
            if (station.uuid === meteoStationId) {
                return station.city;
            }
        })
    }

    delete() {
        this.myFieldService.delete(this.state.selectedField.uuid)
            .then(data => {
                let index = this.findSelectedFieldIndex();
                this.setState({
                    fields: this.state.fields.filter((val, i) => i !== index),
                    selectedField: null,
                    field: null,
                    displayDialog: false
                });
            });

    }

    addNew() {
        this.newField = true;
        this.setState({
            field: {
                uuid: '',
                name: '',
                description: '',
                fieldSize: '',
                city: '',
                county: '',
                x: '',
                y: '',
                meteoStationId: this.defaultStations.length === 1 ? this.defaultStations[0] : null,
                grainCropsDate: new Date()
            },
            displayDialog: true
        });
    }

    updateProperty(property, value) {
        let field = this.state.field;
        field[property] = value;
        this.setState({field: field});
    }

    onFieldSelect(e) {
        this.newField = false;
        this.setState({
            displayDialog: true,
            field: Object.assign({}, e.data)
        });
    }

    actionTemplate(rowData) {
        return <div>
            <Link to={'/pole/' + rowData.uuid}>
                <Button color='primary' type="button" className="btn-block">
                    {this.props.t('common:details')}
                </Button>
            </Link>

        </div>;
    }

    render() {
        const stationOptions = Commons.buildStationOptions(this.state.stations, this.defaultStations, (a, b) => this.props.t(a, b));
        const dataTableFooter = <div>
            <Button color='primary' onClick={e => this.addNew()}><FontAwesomeIcon
                icon='plus'/> {this.props.t('field:add-new-field')}</Button>
        </div>;
        return (
            <Container>
                <div>
                    <DataTable value={this.state.fields} paginator={true} rows={15}
                               responsive={true}
                               footer={dataTableFooter}
                               selectionMode="single" selection={this.state.selectedField}
                               onSelectionChange={e => this.setState({selectedField: e.value})}
                               onRowSelect={this.onFieldSelect}>
                        <Column field="name" header={this.props.t('common:name')}/>
                        <Column field="description" header={this.props.t('common:description')}/>
                        <Column field="fieldSize" header={this.props.t('field:field-size')}/>
                        <Column field="city" header={this.props.t('field:city')}/>
                        <Column field="county" header={this.props.t('common:county')}/>
                        <Column field="meteoStation" header={this.props.t('station:station')}/>
                        <Column body={this.actionTemplate.bind(this)}/>
                    </DataTable>

                    <Dialog
                        header={this.props.t('field:field-data')}
                        visible={this.state.displayDialog}
                        width='350px'
                        modal={true}
                        onHide={() => this.setState({displayDialog: false})}
                        maximizable={true}>
                        {this.state.field &&
                        <AvForm onValidSubmit={this.onValidSubmit}>
                            <Row form>
                                <Col>
                                    <AvGroup>
                                        <AvField id="meteoStationDD" type="select" name="select"
                                                 label={this.props.t('station:station')}
                                                 value={this.state.field.meteoStationId}
                                                 onChange={(e) => {
                                                     this.updateProperty('meteoStationId', e.target.value)
                                                 }} required>
                                            <option value="">---</option>
                                            {stationOptions}
                                        </AvField>
                                        <AvFeedback>{this.props.t('station:station-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <AvGroup>
                                        <Label for="name">{this.props.t('common:name')}</Label>
                                        <AvInput id="name" name="name" value={this.state.field.name}
                                                 onChange={(e) => {
                                                     this.updateProperty('name', e.target.value)
                                                 }} required/>
                                        <AvFeedback>{this.props.t('common:name-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="description">{this.props.t('common:description')}</Label>
                                        <AvInput id="description" name="description"
                                                 value={this.state.field.description}
                                                 onChange={(e) => {
                                                     this.updateProperty('description', e.target.value)
                                                 }}/>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <AvGroup>
                                        <Label for="city">{this.props.t('field:city')}</Label>
                                        <AvInput id="city" name="city" value={this.state.field.city}
                                                 onChange={(e) => {
                                                     this.updateProperty('city', e.target.value)
                                                 }} required/>
                                        <AvFeedback>{this.props.t('field:city-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <FormGroup>
                                        <Label for="county">{this.props.t('common:county')}</Label>
                                        <AvInput id="county" name="county" value={this.state.field.county}
                                                 onChange={(e) => {
                                                     this.updateProperty('county', e.target.value)
                                                 }}/>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row form>
                                <Col>
                                    <AvGroup>
                                        <Label for="fieldSize">{this.props.t('field:field-size')}</Label>
                                        <AvInput id="fieldSize" name="fieldSize" value={this.state.field.fieldSize}
                                                 type='number'
                                                 onChange={(e) => {
                                                     this.updateProperty('fieldSize', e.target.value)
                                                 }} required/>
                                        <AvFeedback>{this.props.t('field:field-size-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col></Col>
                            </Row>
                            <div>
                                <Button color='primary' className='mr-2' type='submit'><FontAwesomeIcon
                                    icon='check'/> {this.props.t('common:action-save')}</Button>
                                <Button color='primary' className='mr-2' type='button'
                                        onClick={e => this.delete()}><FontAwesomeIcon
                                    icon='trash-alt'/> {this.props.t('common:action-delete')}</Button>
                                <Button color='primary' type='button'
                                        label={this.props.t('common:action-cancel')}
                                        icon='pi pi-times'
                                        onClick={e => this.setState({displayDialog: false})}>
                                    <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
                                </Button>
                            </div>
                        </AvForm>
                        }
                    </Dialog>
                </div>
            </Container>
        );
    }
}

export default withTranslation(['meteo', 'station', 'common'])(MyFieldList);
