import React from 'react';
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {Table} from "reactstrap";
import moment from "moment";
import {values} from "lodash";
import {getOrder, getPrecision, getUnit} from "../../../util/MeteoUtil";
import './DataTable.css'

class DataTable extends React.Component {

    formatTime(date) {
        return moment(date).format("HH:mm");
    }

    formatDate(date) {
        return moment(date).format("YYYY-MM-DD");
    }

    formatMeasurement(type, rawValue) {
        if (rawValue === undefined || rawValue === null) {
            return '';
        }
        return rawValue.toFixed(Number(getPrecision(type)));
    }

    render() {
        const dates = Object.keys(this.props.data);
        if (dates.length === 0) {
            return <div>Brak danych</div>
        }
        dates.sort((a, b) => {
            return new Date(a) - new Date(b)
        });
        const measurementTypes = Object.keys(Object.values(this.props.data)[0].data);
        measurementTypes.sort((a, b) => getOrder(a) - getOrder(b));
        const headDates = [];
        const head = [];
        head.push(<th key='0' className='meteo-data-table-type-col'
                      style={{padding: '24px'}}>{this.props.t('data-table-measurement-type')}</th>);
        headDates.push(<th className='meteo-data-table-type-col' key={-1}></th>);

        let lastDay = '';
        let days = 0;
        for (let i = 0; i < dates.length; i++) {
            const date = dates[i];
            let day = this.formatDate(date);
            if (i === dates.length - 1 || (lastDay !== '' && day !== lastDay)) {
                if (i === dates.length - 1) {
                    ++days;
                }
                headDates.push(<th key={i} colSpan={days}>{lastDay}</th>);
                days = 1;
            } else {
                ++days;
            }
            lastDay = day;
            head.push(<th key={i}>{this.formatTime(date)}</th>);
        }
        if (head.length === 1) {
            head.push(<th key={1}></th>);
            headDates.push(<th key={1}></th>);
        }
        const rows = [];
        measurementTypes.forEach((mt, i) => {
            const rowData = dates
                .map(date => this.formatMeasurement(mt, this.props.data[date].data[mt]))
                .map((val, idx) => <td key={idx}>{val}</td>);
            if (rowData.length === 0) {
                rowData.push(<td key={0}></td>);
            }
            let unit = getUnit(mt).name.trim();
            const minMaxObj = this.props.dataMinMax.find(item => item.measurement === mt)
            let titleStr;
            if (mt === 'precipitation1' || mt === 'precipitation2') {
                const sum = values(this.props.data).map(item => item.data[mt]).reduce((a, b) => a + b, 0);
                titleStr = this.props.t('sum') + ': ' + this.formatMeasurement(minMaxObj.measurement, sum) + unit;
            } else {
                titleStr = this.formatMeasurement(minMaxObj.measurement, minMaxObj.min) + unit
                    + ' / ' + this.formatMeasurement(minMaxObj.measurement, minMaxObj.max) + unit;
            }
            rows.push(<tr key={i}>
                <td className='meteo-data-table-type-col' title={titleStr}>{this.props.t(mt)} {unit && <span>[{unit}]</span>}</td>
                {rowData}
            </tr>);
        });
        return (
            <div className='meteo-data-table-parent'>
                <Table className='meteo-data-table' responsive bordered striped hover size="sm">
                    <thead>
                    <tr>
                        {headDates}
                    </tr>
                    <tr>
                        {head}
                    </tr>
                    </thead>
                    <tbody>
                    {rows}
                    </tbody>
                </Table>
            </div>
        )
    }
}

DataTable.propTypes = {
    data: PropTypes.object.isRequired
};

export default withTranslation('meteo')(DataTable);