import React from 'react';
import '../components/layout/MainLayoutWrapper.css'
import AuthActions from "../components/auth/AuthActions";
import {Container, Row, Col, Alert} from "reactstrap";
import {Redirect} from "react-router";
import {withTranslation} from "react-i18next";
import PropTypes from 'prop-types';

function SecuredComponent(props) {
    const externalUserLogged = AuthActions.isExternalUserLogged();

    if (!externalUserLogged) {
        return (
            <Container className="p-4">
                <Row>
                    <Col>
                        <Alert color="info">
                            {props.t('login-required-site-secured')}
                        </Alert>
                    </Col>
                </Row>
            </Container>
        )
    } else {
        if (props.onlyAdmin && !AuthActions.isAdminLogged()) {
            return (
                <Redirect to="/dostepzabroniony"/>
            )
        }
    }
    return (
        <>
            {props.children}
        </>
    );
}

SecuredComponent.propTypes = {
    onlyAdmin: PropTypes.bool,
    children: PropTypes.element.isRequired
};

SecuredComponent.defaultProps = {
    onlyAdmin: false
};

export default withTranslation('common')(SecuredComponent);
