import ApiService from "../../../../service/common/ApiService";

export default class UserFormService {
    getAll() {
        return ApiService.get('/userform/fields');
    }

    update(fields) {
        return ApiService.post('/userform/fields', fields);
    }

}