import React, {Component} from 'react';
import {Button, Col, Row, Container, Form, FormFeedback, FormGroup, Label, Nav, NavItem} from "reactstrap";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import Commons from "../../../common/Commons"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog} from "primereact/dialog";
import {InputText} from "primereact/inputtext";
import {Calendar} from "primereact/calendar";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import {withTranslation} from "react-i18next";
import IrrigationService from "./IrrigationService";
import moment from "moment";
import MyFieldService from "../MyFieldService";
import TreatmentService from "./TreatmentService";
import {SelectButton} from "primereact/selectbutton";
import {Checkbox} from "primereact/checkbox";
import {NavLink} from "react-router-dom";
import iconFields from "../../menu/icon-fields.png";

class MyFieldDetails extends Component {

    constructor(props) {
        super(props);
        this.state = {
            field: null,
            irrigations: [],
            treatments: [],
            loadingDetails: true,
            selectedIrrigation: null,
            selectedTreatment: null,
            displayEditDialog: false,
            displayTreatmentEditDialog: false,
            newIrrigation: false,
            newTreatment: false,
            displayDeleteDialog: false,
            displayTreatmentDeleteDialog: false,
            editErrors: false,
            validateDate: true,
            selectedAppliesToPotatoesFilter: null
        };
        this.irrigationService = new IrrigationService();
        this.treatmentService = new TreatmentService();
        this.fieldService = new MyFieldService();
        this.dt = React.createRef();
    }

    componentDidMount() {
        this.fieldService.getById(this.props.match.params.fieldId)
            .then(data => {
                this.setState({
                    field: data,
                    irrigations: data.irrigations,
                    treatments: data.treatments
                })
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {
                this.setState(
                    {
                        loadingDetails: false
                    }
                )
            })
    }

    myFieldDetails = () => {
        if (this.state.field === null) {
            return <div></div>
        } else {
            let fieldName = this.state.field.name;
            let fieldDescr = this.state.field.description;
            let county = this.state.field.county;
            let city = this.state.field.city;
            let fieldSize = this.state.field.fieldSize;
            let meteoStation = this.state.field.meteoStation;
            return <div>
                <Row>
                    <Col>
                        {this.props.t('common:name')}
                    </Col>
                    <Col>
                        <b>{fieldName}</b>
                    </Col>
                    <Col>
                        {this.props.t('common:description')}
                    </Col>
                    <Col>
                        <b>{fieldDescr}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.t('field:city')}
                    </Col>
                    <Col>
                        <b>{city}</b>
                    </Col>
                    <Col>
                        {this.props.t('common:county')}
                    </Col>
                    <Col>
                        <b>{county}</b>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.t('station:station')}
                    </Col>
                    <Col>
                        <b>{meteoStation}</b>
                    </Col>
                    <Col>
                        {this.props.t('field:field-size')}
                    </Col>
                    <Col>
                        <b>{fieldSize}</b>
                    </Col>
                </Row>
            </div>
        }
    };

    irrigationActionTemplate = (rowData) => {
        return <div>
            <Button type="button"
                    color='primary'
                    onClick={() => {
                        this.setState({
                            formIrigation: {
                                uuid: rowData.uuid,
                                amount: rowData.amount,
                                date: moment(rowData.date).toDate(),
                                description: rowData.description,
                                fieldId: this.props.match.params.fieldId
                            },
                            newIrrigation: false,
                            selectedIrrigation: rowData,
                            displayEditDialog: true
                        });
                    }}
                    style={{marginRight: '5px'}}>
                <FontAwesomeIcon icon='edit'/>
            </Button>
            <Button type="button"
                    color='primary'
                    onClick={() => this.setState({selectedIrrigation: rowData, displayDeleteDialog: true})}>
                <FontAwesomeIcon icon='trash-alt'/>
            </Button>
        </div>;
    };

    addNew() {
        this.setState({
            newIrrigation: true,
            displayEditDialog: true,
            formIrigation: {
                uuid: null,
                date: new Date(),
                amount: 0,
                description: '',
                fieldId: this.props.match.params.fieldId
            }
        })
    }

    save = () => {
        const data = Object.assign({}, this.state);
        data.editErrors = false;
        if (data.formIrigation.date === null) {
            data.validateDate = false;
            data.editErrors = true;
        } else {
            data.validateDate = true;
        }
        if (data.editErrors) {
            this.setState({
                    editErrors: data.editErrors,
                    validateDate: data.validateDate,
                }
            );
            return;
        }
        if (this.state.newIrrigation) {
            this.irrigationService.create(this.createDto(this.state.formIrigation))
                .then((data) => {
                    this.setState(prevState => {
                        const irrigation = {
                            uuid: data.uuid,
                            amount: data.amount,
                            date: moment(data.date).toDate(),
                            description: data.description
                        };
                        prevState.irrigations.push(irrigation);
                        return ({
                                irrigations: prevState.irrigations,
                                formIrigation: null,
                                displayEditDialog: false,
                            }
                        );
                    })
                });
        } else {
            this.irrigationService.update(this.state.formIrigation.uuid, this.createDto(this.state.formIrigation))
                .then(() => {
                    this.setState(prevState => {
                        prevState.selectedIrrigation.amount = prevState.formIrigation.amount;
                        prevState.selectedIrrigation.date = prevState.formIrigation.date;
                        prevState.selectedIrrigation.description = prevState.formIrigation.description;
                        return ({
                                irrigations: prevState.irrigations,
                                formIrigation: null,
                                displayEditDialog: false,
                            }
                        );
                    });
                });
        }
    };

    createDto = () => {
        const formIrigation = this.state.formIrigation;
        return {
            uuid: formIrigation.uuid,
            amount: formIrigation.amount,
            date: Commons.dateFormat(formIrigation.date),
            description: formIrigation.description,
            fieldId: formIrigation.fieldId
        }
    };

    delete = () => {
        this.irrigationService.delete(this.state.selectedIrrigation.uuid)
            .then(() => {
                    this.setState(prevState => {
                            const idx = this.findIndexOfSelected();
                            return (
                                {
                                    irrigations: prevState.irrigations.filter((val, i) => i !== idx),
                                    selectedIrrigation: null,
                                    displayDeleteDialog: false
                                }
                            );
                        }
                    );
                }
            );
    };

    findIndexOfSelected() {
        return this.state.irrigations.indexOf(this.state.selectedIrrigation);
    }

    treatmentActionTemplate = (rowData) => {
        return <div>
            <Button type="button"
                    color='primary'
                    onClick={() => {
                        this.setState({
                            formTreatment: {
                                uuid: rowData.uuid,
                                date: moment(rowData.date).toDate(),
                                appliesToPotatoes: rowData.appliesToPotatoes,
                                description: rowData.description,
                                fieldId: this.props.match.params.fieldId
                            },
                            newTreatment: false,
                            selectedTreatment: rowData,
                            displayTreatmentEditDialog: true
                        });
                    }}
                    style={{marginRight: '5px'}}>
                <FontAwesomeIcon icon='edit'/>
            </Button>
            <Button type="button"
                    color='primary'
                    onClick={() => this.setState({selectedTreatment: rowData, displayTreatmentDeleteDialog: true})}>
                <FontAwesomeIcon icon='trash-alt'/>
            </Button>
        </div>;
    };

    addNewTreatment() {
        this.setState({
            newTreatment: true,
            displayTreatmentEditDialog: true,
            formTreatment: {
                uuid: null,
                date: new Date(),
                appliesToPotatoes: true,
                description: '',
                fieldId: this.props.match.params.fieldId
            }
        })
    }

    saveTreatment = () => {
        const data = Object.assign({}, this.state);
        data.editErrors = false;
        if (data.formTreatment.date === null) {
            data.validateDate = false;
            data.editErrors = true;
        } else {
            data.validateDate = true;
        }
        if (data.editErrors) {
            this.setState({
                    editErrors: data.editErrors,
                    validateDate: data.validateDate,
                }
            );
            return;
        }
        if (this.state.newTreatment) {
            this.treatmentService.create(this.createTreatmentDto(this.state.formTreatment))
                .then((data) => {
                    this.setState(prevState => {
                        const treatment = {
                            uuid: data.uuid,
                            date: moment(data.date).toDate(),
                            appliesToPotatoes: data.appliesToPotatoes,
                            description: data.description
                        };
                        prevState.treatments.push(treatment);
                        return ({
                            treatments: prevState.treatments,
                            formTreatment: null,
                            displayTreatmentEditDialog: false,
                        });
                    })
                });
        } else {
            this.treatmentService.update(this.state.formTreatment.uuid, this.createTreatmentDto(this.state.formTreatment))
                .then(() => {
                    this.setState(prevState => {
                        prevState.selectedTreatment.date = prevState.formTreatment.date;
                        prevState.selectedTreatment.description = prevState.formTreatment.description;
                        prevState.selectedTreatment.appliesToPotatoes = prevState.formTreatment.appliesToPotatoes;
                        return ({
                                treatments: prevState.treatments,
                                formTreatment: null,
                                displayTreatmentEditDialog: false,
                            }
                        );
                    });
                });
        }
    };

    createTreatmentDto = () => {
        const formTreatment = this.state.formTreatment;
        return {
            uuid: formTreatment.uuid,
            date: Commons.dateFormat(formTreatment.date),
            appliesToPotatoes: formTreatment.appliesToPotatoes,
            description: formTreatment.description,
            fieldId: formTreatment.fieldId
        }
    };

    deleteTreatment = () => {
        this.treatmentService.delete(this.state.selectedTreatment.uuid)
            .then(() => {
                    this.setState(prevState => {
                            const idx = this.findIndexOfSelectedTreatment();
                            return (
                                {
                                    treatments: prevState.treatments.filter((val, i) => i !== idx),
                                    selectedTreatment: null,
                                    displayTreatmentDeleteDialog: false
                                }
                            );
                        }
                    );
                }
            );
    };

    findIndexOfSelectedTreatment() {
        return this.state.treatments.indexOf(this.state.selectedTreatment);
    }

    appliesToPotatoesTemplate = (rowData, column) => {
        return <span>{rowData.appliesToPotatoes === true ? this.props.t('common:yes') : this.props.t('common:no')}</span>;
    };

    handleAppliesToPotatoesFilterChange = (event) => {
        this.dt.filter(event.value, 'appliesToPotatoes', 'equals');
        this.setState({selectedAppliesToPotatoesFilter: event.value});
    };

    render() {
        const appliesToPotatoesOptions = [
            {label: this.props.t('common:yes'), value: true},
            {label: this.props.t('common:no'), value: false}
        ];
        let appliesToPotatoesFilter = <SelectButton style={{width: '100%'}}
                                                    value={this.state.selectedAppliesToPotatoesFilter}
                                                    options={appliesToPotatoesOptions}
                                                    onChange={this.handleAppliesToPotatoesFilterChange}/>;

        let irrigationsFooter = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={e => this.addNew()}>
                <FontAwesomeIcon icon='plus'/> {this.props.t('common:action-add')}
            </Button>
        </div>;
        let treatmentsFooter = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={e => this.addNewTreatment()}>
                <FontAwesomeIcon icon='plus'/> {this.props.t('common:action-add')}
            </Button>
        </div>;

        return (
            <Container className='p-2'>
                <Nav>
                    <NavItem>
                        <NavLink to="/pole" activeClassName='active-link'>
                            <img alt='' width={28} src={iconFields}/><span>{this.props.t('return-to-my-fields')}</span>
                        </NavLink>
                    </NavItem>
                </Nav>
                <h2>{this.props.t('field:my-field-details')}</h2>
                {this.myFieldDetails()}
                <h2>{this.props.t('field:irrigation-ops')}</h2>
                <DataTable value={this.state.irrigations}
                           loading={this.state.loadingDetails}
                           responsive={true}
                           footer={irrigationsFooter}
                           paginator={true} rows={20} rowsPerPageOptions={[10, 20, 30, 40, 50]}
                           selectionMode="single" selection={this.state.selectedIrrigation}
                           onRowSelect={e => {
                               this.setState({
                                   formIrigation: {
                                       uuid: e.data.uuid,
                                       amount: e.data.amount,
                                       date: moment(e.data.date).toDate(),
                                       description: e.data.description,
                                       fieldId: this.props.match.params.fieldId
                                   },
                                   newIrrigation: false,
                                   selectedIrrigation: e.data,
                                   displayEditDialog: true
                               });
                           }}
                           onSelectionChange={e => this.setState({selectedIrrigation: e.value})}>
                    <Column field="date" body={Commons.dateColumnTemplate} header={this.props.t('common:date')} sortable={true} filter={true}
                            filterMatchMode='contains'/>
                    <Column field="amount" header={this.props.t('field:amount-of-water')} sortable={true} filter={true}
                            filterMatchMode='contains'/>
                    <Column field="description" header={this.props.t('common:description')} sortable={true} filter={true} filterMatchMode='contains'/>
                    <Column body={this.irrigationActionTemplate} style={{width: '122px'}}/>
                </DataTable>

                <Dialog visible={this.state.displayEditDialog} width="300px" header={this.props.t('field:irrigation-form')} modal={true}
                        onHide={() => this.setState({displayEditDialog: false})}>
                    {
                        this.state.formIrigation &&
                        <Form>
                            <FormGroup row>
                                <Label for="date" sm={4}>{this.props.t('common:date')}*</Label>
                                <Col sm={8}>
                                    <Calendar id='date' value={this.state.formIrigation.date}
                                              readOnlyInput={true}
                                              maxDate={new Date()}
                                              onChange={(e) => this.setState(prevState => {
                                                  prevState.formIrigation.date = e.value;
                                                  return prevState;
                                              })}
                                              dateFormat='yy-mm-dd' showIcon={true}
                                              className={this.state.validateDate ? '' : 'is-invalid'}
                                              locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                                    <FormFeedback>{this.props.t('common:date-required')}</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="amount" sm={4}>{this.props.t('field:amount-of-water')}</Label>
                                <Col sm={8}>
                                    <InputText name='amount'
                                               keyfilter='pnum'
                                               value={this.state.formIrigation.amount}
                                               onChange={e => {
                                                   let value = e.target.value;
                                                   this.setState((prevState) => {
                                                       prevState.formIrigation.amount = value;
                                                       return (
                                                           prevState
                                                       )
                                                   })
                                               }}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="desc" sm={4}>{this.props.t('common:description')}</Label>
                                <Col sm={8}>
                                    <InputText name='desc'
                                               value={this.state.formIrigation.description}
                                               onChange={e => {
                                                   let value = e.target.value;
                                                   this.setState((prevState) => {
                                                       prevState.formIrigation.description = value;
                                                       return (
                                                           prevState
                                                       )
                                                   })
                                               }}/>
                                </Col>
                            </FormGroup>
                            <Button color='primary' style={{marginRight: '5px'}} onClick={this.save}>
                                <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
                            </Button>
                            <Button color='primary' onClick={() => this.setState({displayEditDialog: false})}
                                    className="p-button-secondary">
                                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
                            </Button>
                        </Form>
                    }
                </Dialog>

                <Dialog visible={this.state.displayDeleteDialog} width="300px" header={this.props.t('common:delete-confirmation')}
                        modal={true}
                        onHide={() => this.setState({displayDeleteDialog: false})}>
                    {
                        this.state.selectedIrrigation &&
                        <Form>
                            <span>
                                {this.props.t('field:irrigation-delete-configuration')} '{Commons.dateFormat(this.state.selectedIrrigation.date)}'?
                           </span>
                            <div>
                                <Button color='primary' style={{marginRight: '5px'}} onClick={this.delete}>
                                    <FontAwesomeIcon icon='check'/> {this.props.t('common:yes')}
                                </Button>
                                <Button color='primary' onClick={() => this.setState({displayDeleteDialog: false})}
                                        className="p-button-secondary">
                                    <FontAwesomeIcon icon='times'/> {this.props.t('common:no')}
                                </Button>
                            </div>
                        </Form>
                    }
                </Dialog>

                <br/>
                <br/>
                <h2>{this.props.t('field:protection-ops')}</h2>
                <DataTable value={this.state.treatments}
                           loading={this.state.loadingDetails}
                           footer={treatmentsFooter}
                           responsive={true}
                           ref={(el) => this.dt = el}
                           onRowSelect={e => {
                               this.setState({
                                   formTreatment: {
                                       uuid: e.data.uuid,
                                       date: moment(e.data.date).toDate(),
                                       appliesToPotatoes: e.data.appliesToPotatoes,
                                       description: e.data.description,
                                       fieldId: this.props.match.params.fieldId
                                   },
                                   newTreatment: false,
                                   selectedTreatment: e.data,
                                   displayTreatmentEditDialog: true
                               });
                           }}
                           paginator={true} rows={20} rowsPerPageOptions={[10, 20, 30, 40, 50]}
                           selectionMode="single" selection={this.state.selectedTreatment}
                           onSelectionChange={e => this.setState({selectedTreatment: e.value})}>
                    <Column field="date" body={Commons.dateColumnTemplate} header="Data" sortable={true} filter={true}
                            filterMatchMode='contains'/>
                    <Column field="description" header={this.props.t('common:description')} sortable={true} filter={true} filterMatchMode='contains'/>
                    <Column field="appliesToPotatoes" header={this.props.t('field:applies-to-potatoes')} sortable={true}
                            body={this.appliesToPotatoesTemplate}
                            filter={true} filterElement={appliesToPotatoesFilter}/>
                    <Column body={this.treatmentActionTemplate} style={{width: '122px'}}/>
                </DataTable>

                <Dialog visible={this.state.displayTreatmentEditDialog} style={{width: '350px'}}
                        header={this.props.t('field:treatment-form')} modal={true}
                        onHide={() => this.setState({displayTreatmentEditDialog: false})}>
                    {
                        this.state.formTreatment &&

                        <Form>
                            <FormGroup row>
                                <Label for="date" sm={3}>{this.props.t('common:date')}*</Label>
                                <Col sm={9}>
                                    <Calendar id='date' value={this.state.formTreatment.date}
                                              readOnlyInput={true}
                                              maxDate={new Date()}
                                              onChange={(e) => this.setState(prevState => {
                                                  prevState.formTreatment.date = e.value;
                                                  return prevState;
                                              })}
                                              dateFormat='yy-mm-dd' showIcon={true}
                                              className={this.state.validateDate ? '' : 'is-invalid'}
                                              locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                                    <FormFeedback>{this.props.t('common:date-required')}</FormFeedback>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="desc" sm={3}>{this.props.t('common:description')}</Label>
                                <Col sm={9}>
                                    <InputText name='desc'
                                               value={this.state.formTreatment.description}
                                               onChange={e => {
                                                   let value = e.target.value;
                                                   this.setState((prevState) => {
                                                       prevState.formTreatment.description = value;
                                                       return (
                                                           prevState
                                                       )
                                                   })
                                               }}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="desc" sm={3}>{this.props.t('field:applies-to-potatoes')} (NegFry)</Label>
                                <Col sm={9}>
                                    <Checkbox
                                        onChange={e => {
                                            let value = e.checked;
                                            this.setState((prevState) => {
                                                prevState.formTreatment.appliesToPotatoes = value;
                                                return (
                                                    prevState
                                                )
                                            })
                                        }}
                                        checked={this.state.formTreatment.appliesToPotatoes}/>
                                </Col>
                            </FormGroup>
                            <Button color='primary' style={{marginRight: '5px'}} onClick={this.saveTreatment}>
                                <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
                            </Button>
                            <Button color='primary' onClick={() => this.setState({displayTreatmentEditDialog: false})}
                                    className="p-button-secondary">
                                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
                            </Button>
                        </Form>
                    }
                </Dialog>

                <Dialog visible={this.state.displayTreatmentDeleteDialog} width="300px" header={this.props.t('common:delete-confirmation')}
                        modal={true}
                        onHide={() => this.setState({displayTreatmentDeleteDialog: false})}>
                    {
                        this.state.selectedTreatment &&
                        <Form>
                            <span>
                                {this.props.t('field:treatment-delete-configuration')} '{Commons.dateFormat(this.state.selectedTreatment.date)}'?
                           </span>
                            <div>
                                <Button color='primary' style={{marginRight: '5px'}} onClick={this.deleteTreatment}>
                                    <FontAwesomeIcon icon='check'/> {this.props.t('common:yes')}
                                </Button>
                                <Button color='primary' onClick={() => this.setState({displayTreatmentDeleteDialog: false})}
                                        className="p-button-secondary">
                                    <FontAwesomeIcon icon='times'/> {this.props.t('common:no')}
                                </Button>
                            </div>
                        </Form>
                    }
                </Dialog>
            </Container>
        );
    }
}

export default withTranslation(['field', 'station', 'meteo'])(MyFieldDetails);