import React from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import './NegFryResult.css';

class NegFryResult extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    rowClassName(rowData) {
        return {'p-highlight': rowData.intervention};
    }

    numberRow(rowData, column) {
        const data = rowData[column.field];
        return data !== null && data !== undefined ? data.toFixed(2) : '';
    }

    yesNullRow(rowData, column) {
        const data = rowData[column.field];
        return data ? 'TAK' : '';
    }

    render() {
        const {firstInterventionDate, dailyRisks} = this.props.result;
        const riskValueHeader = <div className='tooltip-parent'><span className='full-header'>Dzienne wartość ryzyka</span><span className='short-header'>DWZ</span>
            <span className="tooltip-text">Dzienne wartość ryzyka obliczana na podtawie wilgotności powietrza, temperatur powietrza oraz opadu (wg Ullrich'a i Schrödtera, 1966)</span>
        </div>;
        const riskSumHeader = <div className='tooltip-parent'><span className='full-header'>Kumulowana wartość ryzyka</span><span className='short-header'>KWZ</span>
            <span className="tooltip-text">Suma dziennych wartości ryzyka liczona od terminu wschodów wymagana do wyznaczenia terminu pierwszego zabiegu.</span>
        </div>;
        const rainHeader = <div className='tooltip-parent'><span className='full-header'>Opad</span><span className='short-header'>O</span>
            <span className="tooltip-text">Używany wraz z nawadnianiem do obliczenia terminów kolejnych zabiegów.</span>
        </div>;
        const irrigationHeader = <div className='tooltip-parent'><span className='full-header'>Nawadnianie</span><span className='short-header'>N</span>
            <span className="tooltip-text">Używane wraz z opadem do obliczenia terminów kolejnych zabiegów.</span>
        </div>;
        const rainSumHeader = <div className='tooltip-parent'><span className='full-header'>Suma opadu</span><span className='short-header'>SO</span>
            <span className="tooltip-text">Suma opadów i zabiegów nawadniania od dnia ostatniego zabiegu, używana do obliczenia terminów kolejnych zabiegów.</span>
        </div>;
        const blightUnitHeader = <div className='tooltip-parent'><span className='full-header'>Jednostki zarazowe</span><span className='short-header'>JZ</span>
            <span className="tooltip-text">Jednostki zarazowe (wg Fry'a, 1983) obliczane na podstawie temperatury, wilgotności powietrza oraz stopnia podatności odmiany ziemniaka, używane do obliczenia terminów kolejnych zabiegów.</span>
        </div>;
        const blightUnitSumHeader = <div className='tooltip-parent'><span className='full-header'>Kumulowane jednostki zarazowe</span><span className='short-header'>KJZ</span>
            <span className="tooltip-text">Suma jednostek zarazowych od dnia ostatniego zabiegu, używana do obliczenia terminów kolejnych zabiegów.</span>
        </div>;
        const interventionHeader = <div className='tooltip-parent'><span className='full-header'>Zalecany zabieg</span><span className='short-header'>Z</span>
            <span className="tooltip-text">Informacja czy system zaleca wykonanie zabiegu w danych dniach.</span>
        </div>;
        const interventionDoneHeader = <div className='tooltip-parent'><span className='full-header'>Wykonany zabieg</span><span className='short-header'>WZ</span>
            <span className="tooltip-text">Informacja czy zabieg został wykonany w danym dniu (wg formularza danych Pola wprowadzonych w zakładce Moje Pole).</span>
        </div>;
        return (
            <article>
                <h3>Wyniki</h3>
                <div className='first-intervention'>
                    Termin pierwszego zabiegu: {firstInterventionDate}
                </div>
                <div style={{fontSize: '95%', marginBottom: '4px', textAlign: 'justify'}}>
                    Termin pierwszego zabiegu wyliczany jest na podstawie dziennej wartości ryzyka oraz kumulowanej wartości ryzyka.
                    Terminy kolejnych zabiegów wyliczane są na podstawie dziennej wartości ryzyka, ilości opadów i zabiegów nawadniania oraz jednostkach zarazowych i kumulowanych jednostach zarazowych.
                    Wartości te będą prezentowane w tabeli po wyznaczonym terminie pierwszego zabiegu.
                </div>
                <DataTable value={dailyRisks} rowClassName={this.rowClassName} responsive={true} className='negfry-result-table'>
                    <Column field="date" header={<div><span className='full-header'>Data</span><span className='short-header'>Data</span></div>}/>
                    <Column field="riskValue" header={riskValueHeader} body={this.numberRow}/>
                    <Column field="riskSum" header={riskSumHeader} body={this.numberRow}/>
                    <Column field="rain" header={rainHeader} body={this.numberRow}/>
                    <Column field="irrigation" header={irrigationHeader} body={this.numberRow}/>
                    <Column field="rainSum" header={rainSumHeader} body={this.numberRow}/>
                    <Column field="blightUnit" header={blightUnitHeader}/>
                    <Column field="blightUnitSum" header={blightUnitSumHeader}/>
                    <Column field="intervention" header={interventionHeader} body={this.yesNullRow}/>
                    <Column field="interventionDone" header={interventionDoneHeader} body={this.yesNullRow}/>
                </DataTable>
            </article>
        )
    }

}

export default withTranslation('ns')(NegFryResult);
