import React from 'react';
import ReactEcharts from "echarts-for-react";
import ChartBuilder from "./ChartBuilder";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {uniq} from "lodash";
import moment from "moment";

class Chart extends React.Component {

    constructor(props) {
        super(props);
        this.getNoDataPanel = this.getNoDataPanel.bind(this);
    }

    getNoDataPanel() {
        return <div></div>;
    }

    renameProperty = (object, prefix) => {
        const clone = {...object};
        for (let prop in clone) {
            if (clone.hasOwnProperty(prop)) {
                clone[prop + prefix] = clone[prop];
                delete clone[prop];
            }
        }
        return clone;
    };

    render() {
        if (this.props.data === null || this.props.data === undefined
            || Object.keys(this.props.data).length === 0 || Object.keys(this.props.data[0]).length === 0) {
            return this.getNoDataPanel();
        }

        let dateKeys = [];
        this.props.data.forEach((item) => {
            dateKeys = dateKeys.concat(Object.keys(item));
        });
        const settings = {
            measurements: Object.keys(this.props.data[0][dateKeys[0]].data)
        };
        dateKeys = uniq(dateKeys);
        const newChartData = [];
        dateKeys.forEach(dateItem => {
            let mergedData = {};
            this.props.data.forEach((item, index) => {
                const data = item[dateItem];
                if (data !== undefined) {
                    const clone = this.renameProperty(data.data, this.props.dataDesc[index]);
                    mergedData = {...mergedData, ...clone};
                }
            });
            newChartData.push(Object.assign({time: dateItem}, mergedData));
        });
        let firstDate = new Date(dateKeys[0]);
        let lastDate = new Date(dateKeys[dateKeys.length - 1]);
        let min = moment(firstDate).subtract(this.props.interval, 'm').format('YYYY-MM-DD[T]HH:mm:ssZ');
        let max = moment(lastDate).add(this.props.interval, 'm').format('YYYY-MM-DD[T]HH:mm:ssZ');
        let minItem = {time: min};
        let maxItem = {time: max};
        for (let i = 0; i < settings.measurements.length; i++) {
            this.props.data.forEach((item, index) => {
                let mt = settings.measurements[i] + this.props.dataDesc[index];
                minItem[mt] = null;
                maxItem[mt] = null;
            });
        }
        newChartData.push(minItem);
        newChartData.push(maxItem);

        newChartData.sort((a, b) => {
            return new Date(a.time) - new Date(b.time);
        });
        const chartBuilder = new ChartBuilder(
            settings,
            this.props.t,
            this.props.sunriseAndSunsets,
            firstDate,
            lastDate,
            this.props.data,
            this.props.dataDesc,
            min, max);
        let zoomXAxisIndex = 0;
        if (chartBuilder.grid.length === 2) {
            zoomXAxisIndex = [0, 1];
        }
        const option = {
            dataset: {
                source: newChartData,
            },
            dataZoom: [{type: 'inside', xAxisIndex: zoomXAxisIndex}],
            grid: chartBuilder.grid,
            xAxis: chartBuilder.xAxis,
            yAxis: chartBuilder.yAxis,
            series: chartBuilder.series,
            legend: {
                right: 80,
                data: chartBuilder.legendData
            },
            toolbox: {
                feature: {
                    restore: {show: true, title: this.props.t('data-charts-toolbox-restore')},
                    saveAsImage: {show: true, title: this.props.t('data-charts-toolbox-save')}
                }
            },
            tooltip: {
                trigger: 'axis',
                axisPointer: {
                    type: 'cross',
                },
                formatter: chartBuilder.tooltipFormatter
            },
            axisPointer: {
                link: {xAxisIndex: 'all'}
            },
            animation: false
        };
        if (settings.measurements.length === 0 || option.grid.length === 0) {
            return this.getNoDataPanel();
        }
        const height = option.grid.length === 3 ? 750 : 500;
        return (
            <div>
                <ReactEcharts notMerge={true} option={option} opts={{height: height}} style={{height: height + 'px'}}/>
                <span style={{fontSize: '75%'}}>{this.props.t('data-charts-zoom-help')}</span>
            </div>
        )
    }
}

Chart.propTypes = {
    data: PropTypes.object.isRequired,
    sunriseAndSunsets: PropTypes.array.isRequired
};

export default withTranslation('meteo')(Chart);