import React, {Component} from 'react';
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {AvFeedback, AvForm, AvGroup, AvInput, AvField} from "availity-reactstrap-validation";
import {Col, Container, Label, Row} from "reactstrap";
import Button from "reactstrap/es/Button";
import MeteoStationService from "./MeteoStationService";
import {withTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";

class AdminStations extends Component {

    constructor(props) {
        super(props);
        this.meteoStationService = new MeteoStationService();
        this.state = {
            station: null,
            selectedStation: null,
            stations: [],
            validBusinessId: true,
            settings: [],
            displayDeleteConfirmation: false
        };
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.onStationSelect = this.onStationSelect.bind(this);
        this.addNew = this.addNew.bind(this);
    }

    delete() {
        this.meteoStationService.delete(this.state.selectedStation.uuid)
            .then(data => {
                let index = this.findSelectedStationIndex();
                this.setState({
                    stations: this.state.stations.filter((val, i) => i !== index),
                    selectedStation: null,
                    station: null,
                    displayDialog: false,
                    displayDeleteConfirmation: false
                });
            });
    }

    componentDidMount() {
        Promise.all([this.meteoStationService.getAll(), this.meteoStationService.getSettingsFormsForAllStations()])
            .then(values => {
                let stations = values[0];
                let settings = values[1];
                for (let i = 0; i < stations.length; i++) {
                    let station = stations[i];
                    station.settings = settings.filter(setting => setting.stationUuid === station.uuid)[0];
                }
                this.setState({stations: stations, settings: settings})
            });
    }

    onStationSelect(e) {
        this.newStation = false;
        this.setState({
            displayDialog: true,
            station: Object.assign({}, e.data)
        });
    }

    onValidSubmit = (event, values) => {
        let validationErrors = false;
        let validBusinessId = true;
        let station = this.state.station;
        if (this.newStation) {
            let foundEqualBusinessIdSize = this.state.stations.filter(s => '' + s.businessId === '' + station.businessId)
                .length;
            if (foundEqualBusinessIdSize > 0) {
                validationErrors = true;
                validBusinessId = false;
            }
        }
        if (validationErrors) {
            this.setState({
                validBusinessId: validBusinessId,
                validationErrors: validationErrors
            })
        } else {
            this.save();
        }
    };

    save() {
        let stations = [...this.state.stations];
        if (this.newStation) {
            this.meteoStationService.create(this.state.station)
                .then(response => {
                    response.settings = {enabled: true, measurementSettings: []};
                    stations.push(response);
                    this.setState({stations: stations, selectedStation: null, station: null, displayDialog: false});
                });
        } else {
            this.meteoStationService.update(this.state.station.uuid, this.state.station);
            stations[this.findSelectedStationIndex()] = this.state.station;
            this.setState({stations: stations, selectedStation: null, station: null, displayDialog: false});
        }
    }

    addNew() {
        this.newStation = true;
        this.setState({
            station: {
                uuid: '',
                name: '',
                description: '',
                businessId: '',
                city: '',
                county: '',
                x: '',
                y: ''
            },
            displayDialog: true
        });
    }

    deleteDialog() {
        this.setState({
            displayDeleteConfirmation: true
        })
    }

    findSelectedStationIndex() {
        return this.state.stations.indexOf(this.state.selectedStation);
    }

    updateProperty(property, value) {
        let station = this.state.station;
        station[property] = value;
        this.setState({station: station});
    }

    yesNoColumnTemplate(rowData, column) {
        return <span>{rowData.settings.enabled ? 'Tak' : 'Nie'}</span>
    }

    measurementCountColumnTemplate(rowData, column) {
        return <span>{Object.values(rowData.settings.measurementSettings).filter(t => t).length}</span>
    }

    validateNameUnique = (value, ctx, input, cb) => {
        const {stations, station} = this.state;
        const found = stations.find(func => func.name === value);
        let uniqueTest;
        if (!this.newStation) {
            uniqueTest = ((found === undefined) || (station.uuid === found.uuid));
        } else {
            uniqueTest = (found === undefined);
        }
        cb((uniqueTest) ? true : this.props.t('station:name-not-unique'));
    };

    render() {
        const dataTableFooter = (
            <div>
                <Button color='primary' onClick={e => this.addNew()}>Dodaj nową stację</Button>
            </div>
        );
        return (
            <Container>
                <div>
                    <DataTable value={this.state.stations} paginator={true} rows={15}
                               footer={dataTableFooter}
                               selectionMode="single" selection={this.state.selectedStation}
                               onSelectionChange={e => this.setState({selectedStation: e.value})}
                               onRowSelect={this.onStationSelect}>
                        <Column field="city" header={this.props.t('common:city')}
                                sortable={true} filter={true}/>
                        <Column field="county" header={this.props.t('common:county')}
                                sortable={true} filter={true}/>
                        <Column field="description" header={this.props.t('common:description')}
                                sortable={true} filter={true}/>
                        <Column field="name" header={this.props.t('station:name')}
                                sortable={true} filter={true}/>
                        <Column field="businessId" header={this.props.t('station:businessId')}
                                sortable={true} filter={true}/>
                        <Column field="x" header={this.props.t('common:x')}
                                sortable={true}/>
                        <Column field="y" header={this.props.t('common:y')}
                                sortable={true}/>
                        <Column field="settings.enabled" header='Aktywna' body={this.yesNoColumnTemplate}
                                sortable={true}/>
                        <Column header='Liczba pomiarów' body={this.measurementCountColumnTemplate}/>
                    </DataTable>

                    <Dialog
                        header={this.props.t('station-data')}
                        visible={this.state.displayDeleteConfirmation}
                        width='350px'
                        modal={true}
                        onHide={() => this.setState({displayDeleteConfirmation: false})}
                        maximizable={true}>
                        Czy na pewno usunąć stację ? <br/>
                        <AvForm onValidSubmit={this.onValidSubmit}>
                            <Button color='primary' className='mr-2' type='button'
                                    onClick={e => this.delete()}>
                                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
                            </Button>

                            <Button color='primary' type='button'
                                    label={this.props.t('common:action-no')}
                                    icon='pi pi-times'
                                    onClick={e => this.setState({displayDeleteConfirmation: false})}>
                                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
                            </Button>
                        </AvForm>
                    </Dialog>

                    <Dialog
                        header={this.props.t('station-data')}
                        visible={this.state.displayDialog}
                        width='350px'
                        modal={true}
                        onHide={() => this.setState({displayDialog: false})}
                        maximizable={true}>
                        {this.state.station &&
                        <AvForm onValidSubmit={this.onValidSubmit}>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="city">{this.props.t('common:city')}</Label>
                                        <AvInput id="city" name="city"
                                                 value={this.state.station.city}
                                                 onChange={(e) => {
                                                     this.updateProperty('city', e.target.value)
                                                 }} required/>
                                        <AvFeedback>{this.props.t('common:city-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="county">{this.props.t('common:county')}</Label>
                                        <AvInput id="county" name="county"
                                                 value={this.state.station.county}
                                                 onChange={(e) => {
                                                     this.updateProperty('county', e.target.value)
                                                 }}/>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="description">{this.props.t('common:description')}</Label>
                                        <AvInput id="description" name="description"
                                                 value={this.state.station.description}
                                                 onChange={(e) => {
                                                     this.updateProperty('description', e.target.value)
                                                 }}/>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvField id="name" name="name"
                                             label={this.props.t('station:name')}
                                             value={this.state.station.name}
                                             helpMessage={this.props.t('station:name-dsc')}
                                             onChange={(e) => {
                                                 this.updateProperty('name', e.target.value)
                                             }}
                                             validate={{
                                                 required: {
                                                     value: true,
                                                     errorMessage: this.props.t('common:concrete-input-required', {inputName: this.props.t('station:name')})
                                                 },
                                                 unique: this.validateNameUnique
                                             }}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="businessId">{this.props.t('station:businessId')}</Label>
                                        <AvInput id="businessId" name="businessId"
                                                 className={this.state.validBusinessId ? '' : 'form-control is-invalid'}
                                                 value={this.state.station.businessId}
                                                 onChange={(e) => {
                                                     this.updateProperty('businessId', e.target.value)
                                                 }} required/>
                                        <div className="invalid-feedback">
                                            {!this.state.validBusinessId &&
                                            <span>{this.props.t('station:businessId-not-unique')}</span>}
                                        </div>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <AvGroup>
                                        <Label for="x">{this.props.t('common:x')}</Label>
                                        <AvInput id="x" name="x"
                                                 value={this.state.station.x}
                                                 onChange={(e) => {
                                                     this.updateProperty('x', e.target.value)
                                                 }} required/>
                                        <AvFeedback>{this.props.t('common:x-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                                <Col>
                                    <AvGroup>
                                        <Label for="y">{this.props.t('common:y')}</Label>
                                        <AvInput id="y" name="y"
                                                 value={this.state.station.y}
                                                 onChange={e => this.updateProperty('y', e.target.value)}
                                                 required/>
                                        <AvFeedback>{this.props.t('common:y-required')}</AvFeedback>
                                    </AvGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Link to={'/admin/stacje/ustawienia/' + this.state.station.businessId}>Ustawienia
                                        stacji</Link>
                                </Col>
                            </Row>
                            <div className='mt-2'>
                                <Button color='primary' className='mr-2'
                                        type='submit'>
                                    <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
                                </Button>
                                <Button color='primary' className='mr-2' type='button' disabled={this.newStation}
                                        onClick={e => this.deleteDialog()}>
                                    <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
                                </Button>

                                <Button color='primary' type='button'
                                        label={this.props.t('common:action-cancel')}
                                        icon='pi pi-times'
                                        onClick={e => this.setState({displayDialog: false})}>
                                    <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
                                </Button>
                            </div>
                        </AvForm>
                        }
                    </Dialog>

                </div>
            </Container>
        )
    }
}

export default withTranslation(['station', 'common'])(AdminStations);
