import axios from 'axios';
import config from './../../config/config';
import ApiService from "../../service/common/ApiService";


class AuthActions {

    annonymousInstance = axios.create({});

    login(login, password) {
        const dataForm = new URLSearchParams();
        dataForm.append('username', login);
        dataForm.append('password', password);
        dataForm.append('grant_type', 'password');
        return axios({
            method: 'post',
            url: config.apiServerUrl + '/oauth/token',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            auth: {
                username: config.clientId,
                password: config.clientSecret
            },
            data: dataForm,
        })
            .then((response) => {
                localStorage.setItem('api_access_token', response.data.access_token);
                localStorage.setItem('api_refresh_token', response.data.refresh_token);
                localStorage.setItem('anonymous_user', 'false');
                const dataForm = new URLSearchParams();
                dataForm.append('token', response.data.access_token);

                return axios({
                    method: 'post',
                    headers: {
                        'content-type': 'application/x-www-form-urlencoded'
                    },
                    url: config.apiServerUrl + '/oauth/check_token',
                    data: dataForm,
                    auth: {
                        username: config.clientId,
                        password: config.clientSecret
                    }
                })
            })
            .then((res) => {
                localStorage.setItem('api_access_info', JSON.stringify(res.data));
                ApiService.get('/userstations')
                    .then(res => {
                        window.location.reload();
                        localStorage.setItem('default_stations', JSON.stringify(res));
                    })
                    .catch(err => {
                        window.location.reload();
                        localStorage.setItem('default_stations', JSON.stringify([]));
                        console.error('Nie udany wczytane listy domyślnych stacji: ' + err)
                    });
                return res;
            })
            .catch((error) => {
                    //todo komunikat błędu
                    console.error(error);
                    throw error;
                }
            );
    }

    loginAnonymous() {
        const dataForm = new URLSearchParams();
        dataForm.append('username', config.anonymousUserLogin);
        dataForm.append('password', config.anonymousUserSecret);
        dataForm.append('grant_type', 'password');
        return this.annonymousInstance
            .request({
                method: 'post',
                url: config.apiServerUrl + '/oauth/token',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                auth: {
                    username: config.clientId,
                    password: config.clientSecret
                },
                data: dataForm
            })
            .then(response => {
                localStorage.setItem('api_access_token', response.data.access_token);
                localStorage.setItem('api_refresh_token', response.data.refresh_token);
                localStorage.setItem('anonymous_user', 'true');
                const dataForm = new URLSearchParams();
                dataForm.append('token', response.data.access_token);
                return this.annonymousInstance
                    .request({
                        method: 'post',
                        url: config.apiServerUrl + '/oauth/check_token',
                        headers: {
                            'content-type': 'application/x-www-form-urlencoded'
                        },
                        data: dataForm,
                        auth: {
                            username: config.clientId,
                            password: config.clientSecret
                        }
                    })
            })
            .then((response) => {
                localStorage.setItem('api_access_info', JSON.stringify(response.data));
                return response.data;
            })
    }

    logout() {
        const token = localStorage.getItem('api_access_token');
        if (token) {
            axios({
                method: 'delete',
                url: config.apiServerUrl + '/api/oauth/token',
                headers: {'Authorization': "bearer " + token}
            })
                .then(response => {
                    localStorage.removeItem('api_access_token');
                    localStorage.removeItem('api_refresh_token');
                    localStorage.removeItem('api_access_info');
                    localStorage.removeItem('anonymous_user');
                    window.location.replace("/?success=logout");
                })
                .catch(function (error) {
                        if (error.response.status === 401) {
                            localStorage.removeItem('api_access_token');
                            localStorage.removeItem('api_refresh_token');
                            localStorage.removeItem('api_access_info');
                            localStorage.removeItem('anonymous_user');
                            window.location.replace("/?success=logout");
                        } else {
                            console.error(error);
                        }
                    }
                );
        }
    }

    logoutForce() {
        const token = localStorage.getItem('api_access_token');
        localStorage.removeItem('api_access_token');
        localStorage.removeItem('api_refresh_token');
        localStorage.removeItem('anonymous_user');
        if (token) {
            axios({
                method: 'delete',
                url: config.apiServerUrl + '/api/oauth/token',
                headers: {'Authorization': "bearer " + token}
            }).then(response => {
                window.location.replace("/?error=endSession");
            }).catch(error => {
                window.location.replace("/?error=endSession");
            });
        } else {
            window.location.replace("/?error=endSession");
        }
    }

    refreshToken() {
        const refreshToken = localStorage.getItem('api_refresh_token');
        const dataForm = new URLSearchParams();
        dataForm.append('grant_type', 'refresh_token');
        dataForm.append('refresh_token', refreshToken);
        console.log('refresh');
        return axios({
            method: 'post',
            headers: {
                'content-type': 'application/x-www-form-urlencoded'
            },
            url: config.apiServerUrl + '/oauth/token',
            data: dataForm,
            auth: {
                username: config.clientId,
                password: config.clientSecret
            }
        }).then(res => {
            localStorage.setItem('api_access_token', res.data.access_token);
            localStorage.setItem('api_refresh_token', res.data.refresh_token);
            return res;
        });
    }

    isTokenValid() {
        return localStorage.getItem('api_access_token');
    }

    isExternalUserLogged() {
        let access_info = localStorage.getItem('anonymous_user');
        return access_info != null && access_info === "false";
    }

    isAdminLogged() {
        const info = localStorage.getItem('api_access_info');
        return info && info.includes('ROLE_ADMIN');
    }
}

export default new AuthActions();
