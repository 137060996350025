import React, {Component} from "react";
import MeteoService from "../../map/MeteoService";
import {withTranslation} from "react-i18next";
import {Panel} from "primereact/panel";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {Button, Col, Container, FormGroup, Label, Row} from 'reactstrap';
import {Calendar} from "primereact/calendar";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import PropTypes from "prop-types";
import "./DataForm.css";
import Cookies from "universal-cookie/lib";
import SettingsService from "../../admin/MenuSettings/SettingsService";
import AuthActions from "../../auth/AuthActions";
import {Growl} from "primereact/growl";
import {withRouter} from "react-router";
import {applyShortcutRange, getOnlyMap} from "../../../util/MeteoUtil";
import {Link} from "react-router-dom";


class DataForm extends Component {

    constructor(props) {
        super(props);
        this.defaultStations = JSON.parse(localStorage.getItem('default_stations'));
        let now = new Date();
        now.setSeconds(0);
        now.setMilliseconds(0);
        let from = new Date(now);
        from.setHours(now.getHours() - 24);
        this.state = {
            panelCollapsed: false,
            stations: [],
            selectedStations: [],
            measurementTypes: [],
            selectedMeasurementTypes: [],
            selectedPeriodicity: 'min15',
            allPeriodicity: [],
            allShortcuts: [],
            fromDate: from,
            toDate: now,
            commonChart: false,
            predefinedTimeDropdownOpen: false,
            selectedAll: false,
            validDateFrom: true,
            validDateTo: true,
            validStations: true,
            validMeasurement: true,
            validateRange: true
        };
        const cookies = new Cookies();
        const value = cookies.get(this.getCookieName());
        if (value) {
            this.state.selectedStations = value.stations;
            this.state.selectedMeasurementTypes = value.measurementTypes.filter(meas => !getOnlyMap(meas));
            this.state.selectedPeriodicity = value.periodicity;
        } else {
            this.state.selectedMeasurementTypes = ['airTemperature200', 'airHumidity200', 'precipitation1', 'soilTemperature15', 'soilHumidity10'];
        }
        if (this.props.preselectedStation) {
            this.state.selectedStations = [this.props.preselectedStation.uuid];
            if (this.props.location.state !== undefined && this.props.location.state.fromMap && this.props.location.state.selectedMeasurementType) {
                this.state.selectedMeasurementTypes = [this.props.location.state.selectedMeasurementType];
            }
        } else if (this.state.selectedStations.length === 0) {
            this.state.selectedStations = this.defaultStations && this.defaultStations.length > 0 ? this.defaultStations : [];
        }
        this.meteoService = new MeteoService();
        this.settingsService = new SettingsService();
        this.onMeasurementTypeChange = this.onMeasurementTypeChange.bind(this);
        this.togglePredefinedTimeDropdownOpen = this.togglePredefinedTimeDropdownOpen.bind(this);
        this.onPredefinedDateClick = this.onPredefinedDateClick.bind(this);
        this.submit = this.submit.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.saveInCookies = this.saveInCookies.bind(this);
        this.getCookieName = this.getCookieName.bind(this);
        this.selectAllMeasurement = this.selectAllMeasurement.bind(this);
        this.saveDefault = this.saveDefault.bind(this);
        this.loadDefault = this.loadDefault.bind(this);
        this.handleSelectDefaultStations = this.handleSelectDefaultStations.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        let loggedUser = AuthActions.isExternalUserLogged();
        if (!this.props.preselectedStation) {
            this.meteoService.getAllStations().then(data => this.setState({stations: data}));
        }
        let settingsPromise;
        if (this.props.preselectedStation) {
            settingsPromise = Promise.all([this.settingsService.getGlobalSettingsOnlyMeteo(), this.settingsService.getStationSettings(this.props.preselectedStation.uuid)])
        } else {
            settingsPromise = Promise.all([this.settingsService.getGlobalSettingsOnlyMeteo()]);
        }
        settingsPromise.then(response => {
            let res = response[0];
            let measurements = response.length === 2 ? response[1].measurements : res.measurements;
            measurements = measurements.filter(meas => !getOnlyMap(meas))
            if (!loggedUser) {
                measurements = measurements.filter(m => m !== 'leafWetting');
            }
            this.setState(state => {
                let selectedMeasurementTypes = state.selectedMeasurementTypes.filter(m => measurements.includes(m));
                return {
                    selectedMeasurementTypes: selectedMeasurementTypes,
                    measurementTypes: measurements,
                    allPeriodicity: res.periodicities,
                    allShortcuts: res.shortcuts,
                    selectedAll: res.measurements.length <= state.selectedMeasurementTypes.length
                }
            }, () => {
                if (this.props.stationId !== undefined && this.props.stationId !== null) {
                    this.submit(false);
                } else if (this.state.fromDate && this.state.toDate && this.state.selectedMeasurementTypes) {
                    this.submit(false);
                }
            })
        });
    }

    handleSelectDefaultStations() {
        if (this.defaultStations && this.defaultStations.length > 0) {
            this.setState({selectedStations: this.defaultStations})
        } else {
            this.setState({selectedStations: []})
        }
    }

    saveDefault() {
        this.settingsService.saveDataFormDefault(JSON.stringify({
            selectedStations: [],
            selectedMeasurementTypes: this.state.selectedMeasurementTypes,
            selectedPeriodicity: this.state.selectedPeriodicity
        }))
            .then(res => {
                this.growl.current.show({severity: 'info', summary: 'Zapisano ustawienia'});
            })
            .catch(err => {
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
                console.error(err);
            });
    }

    loadDefault() {
        this.settingsService.loadDataFormDefault()
            .then(res => {
                if (res !== "") {
                    this.setState({
                        selectedMeasurementTypes: res.selectedMeasurementTypes,
                        selectedPeriodicity: res.selectedPeriodicity
                    });
                } else {
                    this.growl.current.show({severity: 'warn', summary: 'Brak zapisanych domyślnych ustawień'});
                }
            })
            .catch(err => {
                this.growl.current.show({severity: 'error', summary: 'Wczytanie nieudane'});
                console.error(err);
            });
    }

    selectAllMeasurement(e) {
        let selectedMeasurementTypes = e.checked ? [...this.state.measurementTypes] : [];
        this.setState({
            selectedAll: e.checked,
            selectedMeasurementTypes: selectedMeasurementTypes
        });
    }

    saveInCookies() {
        const cookies = new Cookies();
        const value = {
            stations: this.state.selectedStations,
            measurementTypes: this.state.selectedMeasurementTypes,
            periodicity: this.state.selectedPeriodicity,
        };
        cookies.set(this.getCookieName(), value);
    }

    getCookieName() {
        let cookieName = 'mDataForm';
        if (this.props.preselectedStation) {
            cookieName += '_' + this.props.preselectedStation.businessId;
        }
        return cookieName;
    }

    onMeasurementTypeChange(e) {
        let selected = [...this.state.selectedMeasurementTypes];
        if (e.checked) {
            selected.push(e.value);
        } else {
            selected.splice(selected.indexOf(e.value), 1);
        }
        this.setState({selectedMeasurementTypes: selected});
    }

    togglePredefinedTimeDropdownOpen() {
        this.setState(state => {
            return {predefinedTimeDropdownOpen: !state.predefinedTimeDropdownOpen}
        })
    }

    onPredefinedDateClick(val) {
        this.setState(state => {
            return applyShortcutRange(state.toDate, val);
        });
    }

    handleSubmit() {
        this.submit(true);
    }

    submit(saveCookies) {
        let validStations = this.state.selectedStations.length > 0;
        let validMeasurements = this.state.selectedMeasurementTypes.length > 0;
        let validDateFrom = this.state.fromDate;
        let validDateTo = this.state.toDate;
        let validateRange = validDateFrom && validDateTo && this.state.fromDate < this.state.toDate;
        if (validStations && validMeasurements && validDateFrom && validDateTo && validateRange) {
            const dto = {
                stations: this.state.selectedStations,
                measurementTypes: this.state.selectedMeasurementTypes,
                periodicity: this.state.selectedPeriodicity,
                from: this.state.fromDate.toISOString(),
                to: this.state.toDate.toISOString(),
                commonChart: this.state.commonChart
            };
            if (saveCookies) {
                this.saveInCookies();
            }
            this.props.onSubmit(dto);
        }
        this.setState({
            validDateFrom: validDateFrom,
            validDateTo: validDateTo,
            validStations: validStations,
            validMeasurement: validMeasurements,
            validateRange: validateRange
        })
    }

    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();
        const countyShort = this.props.t('county-short');
        const stationOptions = this.state.stations.map(station => {
            return {label: station.city + ', ' + countyShort + ' ' + station.county, value: station.uuid}
        });

        const measurementTypeCheckboxes = [];
        this.state.measurementTypes.forEach(mt => {
            measurementTypeCheckboxes.push(
                <div className="p-col-12" key={mt}>
                    <Checkbox inputId={mt} value={mt} onChange={this.onMeasurementTypeChange}
                              className={this.state.validMeasurement ? '' : 'is-invalid'}
                              checked={this.state.selectedMeasurementTypes.includes(mt)}/>
                    <label htmlFor={mt} className="p-checkbox-label">{this.props.t(mt)}</label>
                </div>
            )
        });

        const periodicityList = [];
        this.state.allPeriodicity.forEach(p => {
            periodicityList.push(
                <div key={p}>
                    <RadioButton key={p} value={p} name='periodicity'
                                 onChange={(e) => this.setState({selectedPeriodicity: e.value})}
                                 checked={this.state.selectedPeriodicity === p}/>
                    <span> {this.props.t('data-filters-panel-periodicity-'.concat(p))}</span>
                </div>
            )
        });

        const predefinedDates = [];
        this.state.allShortcuts.forEach(p => {
            predefinedDates.push(
                <Button type='button' color='primary' key={p} className='m-1'
                        onClick={e => this.onPredefinedDateClick(p)}>
                    {this.props.t('data-filters-panel-date-predefined-'.concat(p))}
                </Button>
            )
        });
        return (
            <Panel className='data-filter-panel'
                   header={this.props.t('data-filters-panel-header')}
                   toggleable={true} collapsed={this.state.panelCollapsed}
                   onToggle={(e) => this.setState({panelCollapsed: e.value})}>
                <Container style={{border: 'none'}}>
                    <Growl ref={this.growl}/>
                    <Row>
                        {!this.props.preselectedStation && <Col style={{minWidth: '300px'}}>
                            <FormGroup>
                                <h4>{this.props.t('data-filters-panel-stations')}</h4>
                                <MultiSelect value={this.state.selectedStations}
                                             scrollHeight='320px'
                                             filter={true}
                                             className={this.state.validStations ? '' : 'form-control is-invalid'}
                                             maxSelectedLabels={3}
                                             selectedItemsLabel={this.props.t('stations-count')}
                                             options={stationOptions}
                                             onChange={(e) => this.setState({selectedStations: e.value})}/>
                                <div className="invalid-feedback">
                                    {this.props.t('data-filters-panel-stations-invalid')}
                                </div>
                                {externalUserLogged && <div>
                                    <Button type='button' color='primary' style={{width: '100%'}}
                                            onClick={this.handleSelectDefaultStations}>{this.props.t('data-filters-panel-stations-defaults')}</Button>
                                    <Link to="/konto#stacje">{this.props.t('data-filters-panel-stations-set-defaults')}</Link>
                                </div>}
                            </FormGroup>
                        </Col>}
                        <Col style={{minWidth: '300px'}}>
                            <FormGroup>
                                <h4>{this.props.t('data-filters-panel-measurement-types')}</h4>
                                <div className="p-col-12">
                                    <Checkbox inputId='measurement-all' onChange={this.selectAllMeasurement}
                                              checked={this.state.selectedAll}/>
                                    <label htmlFor='measurement-all'
                                           className="p-checkbox-label">{this.props.t('select-all')}</label>
                                </div>
                                <hr className='mt-1 mb-1'/>
                                {measurementTypeCheckboxes}
                                <div className="invalid-feedback"
                                     style={{display: this.state.validMeasurement ? 'none' : 'block'}}>
                                    {this.props.t('data-filters-panel-measurement-types-invalid')}
                                </div>
                                {externalUserLogged &&
                                <div>
                                    <Button color='primary' onClick={this.loadDefault}
                                            className='mt-2' style={{width: '100%'}}>
                                        {this.props.t('data-filters-panel-load-default')}
                                    </Button>
                                    <Button color='primary' onClick={this.saveDefault}
                                            className='mt-2' style={{width: '100%'}}>
                                        {this.props.t('data-filters-panel-save-default')}
                                    </Button>
                                </div>
                                }
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <h4>{this.props.t('data-filters-panel-periodicity')}</h4>
                                {periodicityList}
                            </FormGroup>
                        </Col>
                        <Col style={{minWidth: '294px'}}>
                            <h4>{this.props.t('data-filters-panel-date')}</h4>
                            <FormGroup>
                                <Label for="dateFrom">{this.props.t('data-filters-panel-date-from')}&nbsp;</Label>
                                <Calendar id='dateFrom'
                                          dateFormat='yy-mm-dd'
                                          className={!this.state.validDateFrom || (this.state.validDateFrom && this.state.validDateTo && !this.state.validateRange) ? 'is-invalid' : ''}
                                          showTime={true} hourFormat="24"
                                          value={this.state.fromDate}
                                          onChange={(e) => this.setState({fromDate: e.value})}
                                          locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                                <div className="invalid-feedback ml-4">
                                    {!this.state.validDateFrom &&
                                    <span>{this.props.t('data-filters-panel-date-from-invalid')}</span>}
                                    {this.state.validDateFrom && !this.state.validateRange &&
                                    <span>{this.props.t('data-filters-panel-date-from-range-invalid')}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for="dateTo">{this.props.t('data-filters-panel-date-to')}&nbsp;</Label>
                                <Calendar id='dateTo'
                                          className={!this.state.validDateTo || (this.state.validDateFrom && this.state.validDateTo && !this.state.validateRange) ? 'is-invalid' : ''}
                                          dateFormat='yy-mm-dd'
                                          showTime={true} hourFormat="24"
                                          value={this.state.toDate}
                                          onChange={(e) => this.setState({toDate: e.value})}
                                          locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                                <div className="invalid-feedback ml-4">
                                    {!this.state.validDateTo &&
                                    <span>{this.props.t('data-filters-panel-date-to-invalid')}</span>}
                                    {this.state.validDateTo && !this.state.validateRange &&
                                    <span>{this.props.t('data-filters-panel-date-from-range-invalid')}</span>}
                                </div>
                            </FormGroup>
                            <div>
                                <h6>{this.props.t('data-filters-panel-date-predefined')}</h6>
                                <span
                                    style={{fontSize: '85%'}}>{this.props.t('data-filters-panel-date-predefined-dsc')}</span><br/>
                                {predefinedDates}
                            </div>
                        </Col>
                    </Row>
                    {
                        !this.props.preselectedStation &&
                        <Row>
                            <Col className='text-right'>
                                <FormGroup>
                                    <Checkbox inputId="common-chart" checked={this.state.commonChart}
                                              onChange={e => this.setState({commonChart: e.checked})}/>
                                    <label htmlFor='common-chart'
                                           className="p-checkbox-label">{this.props.t('common-chart-form-option')}</label>
                                </FormGroup>
                            </Col>
                        </Row>
                    }
                    <Row>
                        {!this.props.preselectedStation && <Col></Col>}
                        <Col></Col>
                        <Col></Col>
                        <Col className='text-right'>
                            <Button color='primary' onClick={this.handleSubmit} style={{width: '100%'}}>
                                {this.props.t('data-filters-panel-submit')}
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Panel>
        );
    }
}

DataForm.propTypes = {
    onSubmit: PropTypes.func.isRequired,
    stationId: PropTypes.number,
    preselectedStation: PropTypes.object
};

export default withRouter(withTranslation('meteo')(DataForm));