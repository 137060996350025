import React from 'react';
import {Col, Container, Nav, NavItem, Row} from "reactstrap";
import {NavLink} from "react-router-dom";
import './Grains.css';
import {withTranslation} from "react-i18next";
import Septoria from "./Septoria/Septoria";
import Eyespot from "./Eyespot/Eyespot";
import GrainDiseaseService from "../admin/GrainDisease/GrainDiseaseService";
import AuthActions from "../auth/AuthActions";
import draftToHtml from "draftjs-to-html";

class Grains extends React.Component {

    constructor(props) {
        super(props);
        this.service = new GrainDiseaseService();
        this.state = {
            diseases: [],
            selected: null,
            content: {content: '', name: '', lang: '', version: ''}
        };
        this.selectDisease = this.selectDisease.bind(this);
    }

    componentDidMount() {
        this.service.getAll(this.props.i18n.language)
            .then(diseases => {
                let selected = diseases.filter(disease => this.props.location.pathname === '/zboza/' + disease.url)[0];
                if (!selected) {
                    selected = diseases[0];
                }
                this.selectDisease(selected);
                this.setState({diseases: diseases})
            })
    }

    selectDisease(disease) {
        if (disease) {
            this.service.getByUrl(disease.url, this.props.i18n.language)
                .then(res => {
                    this.setState({
                        content: res,
                        selected: disease
                    });
                })
        }
    }

    convert() {
        if (this.state.content && this.state.content.content) {
            try {
                return draftToHtml(JSON.parse(this.state.content.content));
            } catch (ignore) {
            }
        }
        return '';
    }

    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();
        const menuPart1 = this.state.diseases
            .filter(disease => disease.calcType)
            .map((disease, index) =>
                <NavItem key={disease.url}>
                    <NavLink to={'/zboza/' + disease.url} activeClassName='active-link'
                             onClick={() => this.selectDisease(disease)}
                             isActive={(match, location) => {
                                 return (index === 0 && location.pathname === '/zboza') || location.pathname === '/zboza/' + disease.url;
                             }}>
                        {disease.name}
                    </NavLink>
                </NavItem>
            );

        const menuPart2 = this.state.diseases
            .filter(disease => !disease.calcType)
            .map(disease =>
                <NavItem key={disease.url}>
                    <NavLink to={'/zboza/' + disease.url} activeClassName='active-link'
                             onClick={() => this.selectDisease(disease)}
                             isActive={(match, location) => {
                                 return location.pathname === '/zboza/' + disease.url;
                             }}>
                        {disease.name}
                    </NavLink>
                </NavItem>
            );
        return (
            <>
                <Container>
                    <Row id='grains-panel'>
                        <Col md="3" className='border px-0 py-3'>
                            <Nav vertical className='grains-menu'>
                                {menuPart1}
                            </Nav>
                            <hr className='grains-menu-separator'/>
                            <Nav vertical className='grains-menu'>
                                {menuPart2}
                            </Nav>
                        </Col>
                        <Col md="5" className='border p-2 grains-content'>
                            {this.state.content && <div dangerouslySetInnerHTML={{__html: this.convert()}}></div>}
                        </Col>
                        <Col md="4" className='border p-3'>
                            {externalUserLogged && this.state.selected && this.state.selected.calcType === 'EYESPOT' &&
                                <Eyespot/>
                            }
                            {externalUserLogged && this.state.selected && this.state.selected.calcType === 'SEPTORIA' &&
                                <Septoria/>
                            }
                            {!externalUserLogged &&
                                <p>Zaloguj się, aby móc wypełnić kwestionariusz i dowiedzieć się więcej o zalecanych
                                    zabiegach.</p>
                            }

                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default withTranslation('grains')(Grains);
