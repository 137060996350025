import React from 'react';
import {withTranslation} from 'react-i18next';
import UserFormService from "../admin/Users/FormEditor/UserFormService";
import {Col, Label, Row} from "reactstrap";
import {AvFeedback, AvField, AvGroup, AvInput, AvRadio, AvRadioGroup} from 'availity-reactstrap-validation';
import './UserCustomForm.css';
import {MultiSelect} from "primereact/multiselect";
import {detectMobile} from "../layout/MainLayoutWrapper";


class UserCustomForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            values: {}
        };
        this.userFormService = new UserFormService();
        this.getCustomDataDto = this.getCustomDataDto.bind(this);
        this.updateCustom = this.updateCustom.bind(this);
    }

    componentDidMount() {
        this.props.reg(this);
        this.userFormService.getAll()
            .then(res => {
                const newValues = {};
                for (let i = 0; i < res.length; i++) {
                    newValues[res[i].uuid] = this.getEmptyValue(res[i]);
                }
                this.setState({fields: res, values: newValues})
            })
    }

    updateCustom(custom) {
        this.setState(state => {
            let values = Object.assign({}, state.values, custom);
            return {values: values};
        })
    }

    getEmptyValue(def) {
        switch (def.type) {
            case 'CHECKBOX':
                return false;
            case 'RADIO':
                return '';
            case 'SELECT':
                return '';
            case 'MULTIPLE':
                return [];
            default:
                return '';
        }
    }

    getCustomDataDto() {
        return this.state.values;
    }

    buildInputGroup(def) {
        switch (def.type) {
            case 'NUMBER':
                return this.buildNumberInputGroup(def);
            case 'CHECKBOX':
                return this.buildCheckboxInputGroup(def);
            case 'RADIO':
                return this.buildRadioInputGroup(def);
            case 'SELECT':
                return this.buildSelectInputGroup(def);
            case 'MULTIPLE':
                return this.buildMultipleInputGroup(def);
            default:
                return this.buildTextInputGroup(def);
        }
    }

    buildLabel(def) {
        return (
            <Label for={def.uuid} className='tooltip-parent'>
                {def.name}{def.required ? ' *' : ''}
                {def.description && <span className="tooltip-text">{def.description}</span>}
            </Label>
        );
    }

    buildTextInputGroup(def) {
        return (<AvGroup>
            {this.buildLabel(def)}
            <AvInput name={def.uuid} id={def.uuid} required={def.required}
                     value={this.state.values[def.uuid]}
                     onChange={e => {
                         const val = e.target.value;
                         this.setState(state => {
                             let values = Object.assign({}, state.values);
                             values[def.uuid] = val;
                             return {values: values};
                         })
                     }}
            />
            {def.required && <AvFeedback>Pole wymagane!</AvFeedback>}
        </AvGroup>);
    }

    buildNumberInputGroup(def) {
        return (<AvGroup>
            {this.buildLabel(def)}
            <AvInput type='number' name={def.uuid} id={def.uuid} required={def.required}
                     value={this.state.values[def.uuid]}
                     onChange={e => {
                         const val = e.target.value;
                         this.setState(state => {
                             let values = Object.assign({}, state.values);
                             values[def.uuid] = val;
                             return {values: values};
                         })
                     }}/>
            {def.required && <AvFeedback>Pole wymagane!</AvFeedback>}
        </AvGroup>);
    }

    buildCheckboxInputGroup(def) {
        return (<AvGroup check>
                <AvInput type="checkbox" name={def.uuid}
                         checked={this.state.values[def.uuid]}
                         onChange={e => {
                             const val = e.target.checked;
                             this.setState(state => {
                                 let values = Object.assign({}, state.values);
                                 values[def.uuid] = val;
                                 return {values: values};
                             })
                         }}/>
                {this.buildLabel(def)}
            </AvGroup>
        )
    }

    buildRadioInputGroup(def) {
        const options = def.items.map(item => <AvRadio key={item} label={item} value={item}
                                                       onChange={e => {
                                                           this.setState(state => {
                                                               let values = Object.assign({}, state.values);
                                                               values[def.uuid] = item;
                                                               return {values: values};
                                                           })
                                                       }}
        />);
        return (<AvRadioGroup name={def.uuid} label={def.name + (def.required ? ' *' : '')}
                              value={this.state.values[def.uuid]}
                              required={def.required}
                              errorMessage='Pole wymagane!'>
            {options}
        </AvRadioGroup>);
    }

    buildSelectInputGroup(def) {
        const options = [];
        if(!def.required) {
            options.push(<option key={def.name + '_0'} value={''}>---</option>);
        }
        def.items.map(item => <option key={item}>{item}</option>).forEach(opt => options.push(opt));
        return (
            <AvGroup>
                {this.buildLabel(def)}
                <AvField type="select" name={def.uuid}
                         required={def.required}
                         value={this.state.values[def.uuid]}
                         onChange={e => {
                             const val = e.target.value;
                             this.setState(state => {
                                 let values = Object.assign({}, state.values);
                                 values[def.uuid] = val;
                                 return {values: values};
                             })
                         }}>
                    {options}
                </AvField>
            </AvGroup>
        )
    }

    buildMultipleInputGroup(def) {
        const options = def.items.map(item => {
            return {label: item, value: item}
        });
        return (
            <AvGroup>
                {this.buildLabel(def)}
                <AvField tag={MultiSelect}
                         required={def.required}
                         value={this.state.values[def.uuid]}
                         onChange={e => {
                             const val = e.value;
                             this.setState(state => {
                                 let values = Object.assign({}, state.values);
                                 values[def.uuid] = val;
                                 return {values: values};
                             })
                         }}
                         name={def.uuid}
                         options={options}>
                </AvField>
            </AvGroup>
        )
    }

    render() {
        let mobile = detectMobile();
        const rows = [];
        let cols = [];
        for (let i = 0; i < this.state.fields.length; i++) {
            const field = this.state.fields[i];
            cols.push(<Col key={i}>
                {this.buildInputGroup(field)}
            </Col>);
            if (mobile || i % 2 === 1 || i === this.state.fields.length - 1) {
                rows.push(<Row key={i}>{cols}</Row>);
                cols = [];
            }
        }
        return (
            <div className='custom-form'>
                {rows}
            </div>
        )
    }

}

export default withTranslation('ns')(UserCustomForm);
