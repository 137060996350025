import React from 'react';
import {Calendar} from "primereact/calendar";
import './Septoria.css';
import {RadioButton} from "primereact/radiobutton";
import {Button, Label} from "reactstrap";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import {withTranslation} from "react-i18next";
import Alert from "reactstrap/es/Alert";
import MeteoService from "../../map/MeteoService";
import DataService from "../../data/DataService";
import VarietiesService from "../Varieties/VarietiesService";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {Rating} from "primereact/rating";
import {SelectButton} from "primereact/selectbutton";
import DiseaseBaseForm from "../DiseaseBaseForm/DiseaseBaseForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {orderBy} from "lodash"
import {Link} from "react-router-dom";

const emptyVariety = {
    value: null
};

class Septoria extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            station: null,
            date: null,
            validateDate: true,
            touchedDate: false,
            question2: null,
            validate2: true,
            question3: null,
            validate3: true,
            question4: null,
            validate4: true,
            question5: null,
            validate5: true,
            question6: null,
            validate6: true,
            question7: null,
            validate7: true,
            question8: null,
            validate8: true,
            varieties: [],
            variety: emptyVariety,
            validateVariety: true,
            customVariety: '',
            customVarietyType: '',
            customVarietyPoint: null,
            result: null,
            errors: false,
            resultColor: 'success',
            daysWithRain: 0,
            selectedStation: '',
            sort: {
                order: 1,
                field: 'name'
            }
        };
        this.meteoService = new MeteoService();
        this.dataService = new DataService();
        this.varietiesService = new VarietiesService();
        this.handleCalc = this.handleCalc.bind(this);
        this.calculate = this.calculate.bind(this);
        this.handleStationChange = this.handleStationChange.bind(this);
        this.diseaseBaseForm = React.createRef();
    }

    componentDidMount() {
        this.varietiesService.getAllBaseAndUser()
            .then(res => {
                this.setState({
                    varietes: res
                })
            });
    }

    handleCalc() {
        this.calculate(this.state).then(result => {
            this.setState(result);
        })
    }

    async calculate(state) {
        const data = Object.assign({}, state);
        if (this.state.selectedStation !== '') {
            data.daysWithRain = await this.dataService.getRainDays(this.state.selectedStation, state.date);
            data.question4 = data.daysWithRain < 3 ? 1 : data.daysWithRain < 6 ? 3 : 5;
        }
        data.errors = false;
        if (data.date === null) {
            data.validateDate = false;
            data.errors = true;
        } else {
            data.validateDate = true;
        }
        if (data.question2 === null) {
            data.validate2 = false;
            data.errors = true;
        } else {
            data.validate2 = true;
        }
        if (data.question3 === null) {
            data.validate3 = false;
            data.errors = true;
        } else {
            data.validate3 = true;
        }
        if (data.question4 === null) {
            data.validate4 = false;
            data.errors = true;
        } else {
            data.validate4 = true;
        }
        if (data.question5 === null) {
            data.validate5 = false;
            data.errors = true;
        } else {
            data.validate5 = true;
        }
        if (data.question6 === null) {
            data.validate6 = false;
            data.errors = true;
        } else {
            data.validate6 = true;
        }
        if (data.question7 === null) {
            data.validate7 = false;
            data.errors = true;
        } else {
            data.validate7 = true;
        }
        if (data.question8 === null) {
            data.validate8 = false;
            data.errors = true;
        } else {
            data.validate8 = true;
        }
        if (data.variety.value === null
            || (data.variety.value === -1 && (data.customVariety === null || data.customVariety === '' || data.customVarietyType === null || data.customVarietyType === '' || (data.customVarietyPoint < 1 && data.customVarietyPoint > 5)))) {
            data.validateVariety = false;
            data.errors = true;
        } else {
            data.validateVariety = true;
        }
        let sum = 0;
        if (!data.errors) {
            const varietyPoints = data.variety.value !== -1 ? data.variety.value : data.customVarietyPoint;
            sum = data.question2 + data.question3 + data.question4 + data.question5 + data.question6 + data.question7 + data.question8 + varietyPoints;
        }
        let result = sum <= 17 ? 'septorioza-form-result-a' : sum <= 21 ? 'septorioza-form-result-b' : 'septorioza-form-result-c';
        let resultColor = sum <= 17 ? 'success' : sum <= 21 ? 'warning' : 'danger';
        return {
            errors: data.errors,
            validateDate: data.validateDate,
            validate2: data.validate2,
            validate3: data.validate3,
            validate4: data.validate4,
            validate5: data.validate5,
            validate6: data.validate6,
            validate7: data.validate7,
            validate8: data.validate8,
            validate9: data.validate9,
            validateVariety: data.validateVariety,
            daysWithRain: data.daysWithRain,
            resultColor: resultColor,
            result: result
        }
    }

    handleStationChange(station) {
        this.setState({selectedStation: station});
    }

    handleWheatVarietySortChange = (sortField) => {
        this.setState(state => {
            const newSort = Object.assign({}, state.sort);
            newSort.field = sortField;
            if (state.sort.field === sortField) {
                newSort.order = newSort.order * (-1);
            } else {
                newSort.order = 1;
            }
            return {
                sort: {
                    field: newSort.field,
                    order: newSort.order
                }
            };
        })
    };

    render() {
        const types = [
            {label: this.props.t('wheat-variety-type-spring'), value: 'SPRING'},
            {label: this.props.t('wheat-variety-type-winter'), value: 'WINTER'}
        ];
        const varietesOptions = [];
        varietesOptions.push({label: this.props.t('wheat-variety-own'), value: -1});
        const varietesOrdered = orderBy(this.state.varietes, [this.state.sort.field, 'name'], [this.state.sort.order === 1 ? 'asc' : 'desc', 'asc']);
        varietesOrdered
            .map(item => {
                const grains = this.props.t('meteo:grain-type-' + item.type.toLowerCase());
                item.value = item.septoria;
                item.label = item.name + ' (' + grains + ') - ' + item.septoria + ' ' + this.props.t('wheat-variety-points') + (item.userVariety ? ' - ' + this.props.t('own'): '');
                item.typeLabel = grains;
                return item;
            })
            .forEach(opt => varietesOptions.push(opt));
        return (
            <article className='calc'>
                <h3>{this.props.t('treatment-calculator')}</h3>
                <DiseaseBaseForm onStationChange={this.handleStationChange} ref={this.diseaseBaseForm}/>
                <section>
                    <h4>{this.props.t('septoria-question-1')}</h4>
                    <Calendar id='question1' value={this.state.date}
                              onChange={(e) => this.setState({date: e.value})}
                              dateFormat='dd-mm-yy' showIcon={true}
                              locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}
                              className={this.state.validateDate ? '' : 'is-invalid'}/>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-1-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-4')}</h4>
                    <div className='question-description'
                         style={{marginBottom: ".5rem"}}>{this.props.t('septoria-question-4-dsc')}
                    </div>
                    {this.state.selectedStation !== '' &&
                    <span>{this.props.t('septoria-question-4-auto')}</span>}
                    {this.state.selectedStation === '' &&
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question4" checked={this.state.question4 === 1}
                                     disabled={this.state.selectedStation !== ''}
                                     onChange={(e) => this.setState({question4: 1})}/>
                        <span>0-2</span>
                    </Label>}
                    {this.state.selectedStation === '' &&
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question4" checked={this.state.question4 === 3}
                                     disabled={this.state.selectedStation !== ''}
                                     onChange={(e) => this.setState({question4: 3})}/>
                        <span>3-5</span>
                    </Label>}
                    {this.state.selectedStation === '' &&
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="3" name="question4" checked={this.state.question4 === 5}
                                     disabled={this.state.selectedStation !== ''}
                                     onChange={(e) => this.setState({question4: 5})}/>
                        <span>&gt;=6</span>
                    </Label>}
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-4-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-2')}</h4>
                    <div className='question-description'>{this.props.t('septoria-question-2-dcs-1')}
                        <ul>
                            <li>{this.props.t('septoria-question-2-dcs-2')}</li>
                            <li>{this.props.t('septoria-question-2-dcs-3')}</li>
                            <li>{this.props.t('septoria-question-2-dcs-4')}
                            </li>
                        </ul>
                    </div>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question2" checked={this.state.question2 === 1}
                                     onChange={(e) => this.setState({question2: 1})}/>
                        <span>{this.props.t('septoria-question-2-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question2" checked={this.state.question2 === 2}
                                     onChange={(e) => this.setState({question2: 2})}/>
                        <span>{this.props.t('septoria-question-2-ans-2')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question2" checked={this.state.question2 === 4}
                                     onChange={(e) => this.setState({question2: 4})}/>
                        <span>{this.props.t('septoria-question-2-ans-3')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="5" name="question2" checked={this.state.question2 === 5}
                                     onChange={(e) => this.setState({question2: 5})}/>
                        <span>{this.props.t('septoria-question-2-ans-4')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="6" name="question2" checked={this.state.question2 === 6}
                                     onChange={(e) => this.setState({question2: 6})}/>
                        <span>{this.props.t('septoria-question-2-ans-5')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-2-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-3')}</h4>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question3" checked={this.state.question3 === 1}
                                     onChange={(e) => this.setState({question3: 1})}/>
                        <span>{this.props.t('septoria-question-3-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question3" checked={this.state.question3 === 2}
                                     onChange={(e) => this.setState({question3: 2})}/>
                        <span>{this.props.t('septoria-question-3-ans-2')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="3" name="question3" checked={this.state.question3 === 3}
                                     onChange={(e) => this.setState({question3: 3})}/>
                        <span>{this.props.t('septoria-question-3-ans-3')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question3" checked={this.state.question3 === 4}
                                     onChange={(e) => this.setState({question3: 4})}/>
                        <span>{this.props.t('septoria-question-3-ans-4')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="5" name="question3" checked={this.state.question3 === 5}
                                     onChange={(e) => this.setState({question3: 5})}/>
                        <span>{this.props.t('septoria-question-3-ans-5')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-3-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-5')}</h4>
                    <a href={process.env.PUBLIC_URL + '/fazyrozwoju'} target='_blank' rel="noopener noreferrer"
                       style={{color: 'var(--primary)'}}>{this.props.t('septoria-question-4-learn-more')}</a>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question5" checked={this.state.question5 === 1}
                                     onChange={(e) => this.setState({question5: 1})}/>
                        <span>{this.props.t('septoria-question-5-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question5" checked={this.state.question5 === 2}
                                     onChange={(e) => this.setState({question5: 2})}/>
                        <span>{this.props.t('septoria-question-5-ans-2')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="3" name="question5" checked={this.state.question5 === 3}
                                     onChange={(e) => this.setState({question5: 3})}/>
                        <span>{this.props.t('septoria-question-5-ans-3')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question5" checked={this.state.question5 === 4}
                                     onChange={(e) => this.setState({question5: 4})}/>
                        <span>{this.props.t('septoria-question-5-ans-4')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="6" name="question5" checked={this.state.question5 === 6}
                                     onChange={(e) => this.setState({question5: 6})}/>
                        <span>{this.props.t('septoria-question-5-ans-5')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-5-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-6')}</h4>
                    <Label className={this.state.validate6 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question6" checked={this.state.question6 === 1}
                                     onChange={(e) => this.setState({question6: 1})}/>
                        <span>{this.props.t('septoria-question-6-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate6 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="3" name="question6" checked={this.state.question6 === 3}
                                     onChange={(e) => this.setState({question6: 3})}/>
                        <span>{this.props.t('septoria-question-6-ans-2')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-6-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-7')}</h4>
                    <Label className={this.state.validate7 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="0" name="question7" checked={this.state.question7 === 0}
                                     onChange={(e) => this.setState({question7: 0})}/>
                        <span>{this.props.t('septoria-question-7-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate7 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="-1" name="question7" checked={this.state.question7 === -1}
                                     onChange={(e) => this.setState({question7: -1})}/>
                        <span>{this.props.t('septoria-question-7-ans-2')}</span>
                    </Label>
                    <Label className={this.state.validate7 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="-2" name="question7" checked={this.state.question7 === -2}
                                     onChange={(e) => this.setState({question7: -2})}/>
                        <span>{this.props.t('septoria-question-7-ans-3')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-7-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-8')}</h4>
                    <Label className={this.state.validate8 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="0" name="question8" checked={this.state.question8 === 0}
                                     onChange={(e) => this.setState({question8: 0})}/>
                        <span>{this.props.t('septoria-question-8-ans-1')}</span>
                    </Label>
                    <Label className={this.state.validate8 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="-1" name="question8" checked={this.state.question8 === -1}
                                     onChange={(e) => this.setState({question8: -1})}/>
                        <span>{this.props.t('septoria-question-8-ans-2')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-8-invalid')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('septoria-question-9')}</h4>
                    <p className='question-description'>{this.props.t('septoria-question-9-dsc')}</p>
                    <p className='question-sort'><span>{this.props.t('common:sort')}: </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('name');
                                 }}
                                 className={this.state.sort.field === 'name' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:name').toLowerCase()}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'name' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>, </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('type');
                                 }}
                                 className={this.state.sort.field === 'type' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:type')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'type' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>, </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('septoria');
                                 }}
                                 className={this.state.sort.field === 'septoria' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:susceptibility')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'septoria' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>,
                        </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('userVariety');
                                 }}
                                 className={this.state.sort.field === 'userVariety' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('user-variety')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'userVariety' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>
                        </span>
                    </p>
                    <Dropdown value={this.state.variety}
                              options={varietesOptions}
                              optionLabel="label"
                              onChange={(e) =>
                                  this.setState({variety: e.value === null ? emptyVariety : e.value})
                              }
                              filter={true}
                              filterPlaceholder={this.props.t('septoria-question-9-placeholder')}
                              filterBy="name"
                              required={true}
                              className={this.state.validateVariety ? '' : 'is-invalid'}
                              showClear={true}/>
                    <Link to="/odmianyzboza">{this.props.t('common:my-varieties')}</Link>
                    {this.state.variety.value === -1 &&
                    <Label for='customVariety'
                           className='mt-2'>{this.props.t('septoria-question-9-variety-name')}</Label>}
                    {this.state.variety.value === -1 &&
                    <InputText name='customVariety'
                               value={this.state.customVariety}
                               className={this.state.validateVariety ? '' : 'form-control  is-invalid'}
                               onChange={e => {
                                   this.setState({customVariety: e.target.value})
                               }}/>}
                    {this.state.variety.value === -1 &&
                    <Label for='customVarietyType'
                           className='mt-2'>{this.props.t('septoria-question-9-variety-type')}</Label>}
                    {this.state.variety.value === -1 &&
                    <SelectButton name='customVarietyType' value={this.state.customVarietyType}
                                  className={this.state.validateVariety ? '' : 'form-control is-invalid'}
                                  options={types}
                                  onChange={e => {
                                      this.setState({
                                          customVarietyType: e.value
                                      })
                                  }}
                    />}
                    {this.state.variety.value === -1 &&
                    <Label for='customVarietyPoint'
                           className='mt-2'>{this.props.t('septoria-question-9-points')}</Label>}
                    {this.state.variety.value === -1 &&
                    <Rating name='customVarietyPoint'
                            className={this.state.validateVariety ? '' : 'form-control  is-invalid'}
                            cancel={false}
                            stars={5}
                            value={this.state.customVarietyPoint}
                            onChange={e => {
                                this.setState({customVarietyPoint: e.value})
                            }}
                    />
                    }
                    <div className="invalid-feedback">
                        {this.props.t('septoria-question-9-invalid')}
                    </div>
                </section>
                <section>
                    <Button color='primary' onClick={this.handleCalc}>{this.props.t('septoria-calc')}</Button>
                </section>
                {!this.state.errors && this.state.result && <section>
                    <h4>{this.props.t('septoria-result')}</h4>
                    <Alert color={this.state.resultColor}>
                        {this.props.t(this.state.result)}
                    </Alert>
                    {this.state.selectedStation !== '' &&
                    <span>{this.props.t('septoria-rain-days')}: {this.state.daysWithRain}</span>}
                </section>}
                {this.state.errors && <section>
                    <h4>{this.props.t('septoria-invalid')}</h4>
                </section>}
            </article>
        )
    }

}

export default withTranslation('grains')(Septoria);
