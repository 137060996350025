import React from 'react';
import {withTranslation} from "react-i18next";
import SettingsService from "./SettingsService";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Growl} from "primereact/growl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AdminMenuSettings extends React.Component {

    constructor(props) {
        super(props);
        this.settingsService = new SettingsService();
        this.state = {
            globalForm: {
                measurementSettings: {
                    airTemperature200: false,
                    airHumidity200: false,
                    wind: false,
                    airTemperature5: false,
                    precipitation1: false,
                    precipitation2: false,
                    precipitationSum: false,
                    precipitationDiurnalSum: false,
                    leafWetting: false,
                    soilTemperature15: false,
                    soilTemperature45: false,
                    soilHumidity10: false,
                    soilHumidity20: false,
                    soilHumidity30: false,
                    soilHumidity40: false,
                    soilHumidity50: false,
                    soilHumidity60: false
                },
                periodicitySettings: {
                    min15: false,
                    min30: false,
                    h1: false,
                    h3: false,
                    h6: false,
                    h8: false,
                    h12: false,
                    h24: false
                },
                shortcutSettingsDTO: {
                    day: false,
                    week: false,
                    month: false,
                    quarter: false,
                    halfYear: false,
                    year: false
                }
            },
            enableGlobalForm: false
        };
        this.handleGlobalSettingsChange = this.handleGlobalSettingsChange.bind(this);
        this.handleGlobalMeasurementSettingsChange = this.handleGlobalMeasurementSettingsChange.bind(this);
        this.handleGlobalPeriodicitySettingsChange = this.handleGlobalPeriodicitySettingsChange.bind(this);
        this.handleGlobalShortcutSettingsChange = this.handleGlobalShortcutSettingsChange.bind(this);
        this.saveGlobal = this.saveGlobal.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.settingsService.getGlobalSettingsOnlyMeteoFormDTO()
            .then(res => {
                console.log(res);
                this.setState({
                    globalForm: res,
                    enableGlobalForm: true
                })
            })
    }

    saveGlobal() {
        this.settingsService.saveGlobalOnlyMeteo(this.state.globalForm)
            .then(res => {
                this.growl.current.show({severity: 'info', summary: 'Zapis udany'});
            })
            .catch(err => {
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
            })
    }

    handleGlobalMeasurementSettingsChange(event) {
        this.handleGlobalSettingsChange('measurementSettings', event);
    }

    handleGlobalPeriodicitySettingsChange(event) {
        this.handleGlobalSettingsChange('periodicitySettings', event);
    }

    handleGlobalShortcutSettingsChange(event) {
        this.handleGlobalSettingsChange('shortcutSettingsDTO', event);
    }

    handleGlobalSettingsChange(part, event) {
        const name = event.target.name;
        const checked = event.target.checked;
        this.setState(state => {
            let newForm = Object.assign({}, state.globalForm);
            newForm[part][name] = checked;
            return {globalForm: newForm};
        })
    }

    render() {
        return <Container className='p-4'>
            <div>
                <Growl ref={this.growl}/>
                <h2>{this.props.t('menu-settings-header')}</h2>
                <hr/>
                <article>
                    <h3>{this.props.t('menu-settings-header-global')}</h3>
                    <Form>
                        <h4>{this.props.t('menu-settings-header-measurements')}</h4>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='airTemperature200' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.airTemperature200}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:airTemperature200')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='airHumidity200' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.airHumidity200}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:airHumidity200')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='wind' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.wind}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:wind')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='airTemperature5' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.airTemperature5}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:airTemperature5')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='precipitation1' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.precipitation1}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:precipitation1')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='precipitation2' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.precipitation2}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:precipitation2')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='precipitationSum' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.precipitationSum}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:precipitationSum')}
                                    </Label>
                                </FormGroup>
                            </Col> <Col>
                            <FormGroup check>
                                <Label check>
                                    <Input name='precipitationDiurnalSum' type="checkbox"
                                           checked={this.state.globalForm.measurementSettings.precipitationDiurnalSum}
                                           onChange={this.handleGlobalMeasurementSettingsChange}/>
                                    {this.props.t('meteo:precipitationDiurnalSum')}
                                </Label>
                            </FormGroup>
                        </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='leafWetting' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.leafWetting}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:leafWetting')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilTemperature15' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilTemperature15}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilTemperature15')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilTemperature45' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilTemperature45}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilTemperature45')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity10' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity10}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity10')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity20' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity20}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity20')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity30' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity30}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity30')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity40' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity40}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity40')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity50' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity50}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity50')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='soilHumidity60' type="checkbox"
                                               checked={this.state.globalForm.measurementSettings.soilHumidity60}
                                               onChange={this.handleGlobalMeasurementSettingsChange}/>
                                        {this.props.t('meteo:soilHumidity60')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <h4>{this.props.t('menu-settings-header-periodicity')}</h4>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='min15' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.min15}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-min15')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='min30' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.min30}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-min30')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h1' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h1}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h1')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h3' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h3}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h3')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h6' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h6}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h6')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h8' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h8}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h8')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h12' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h12}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h12')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='h24' type="checkbox"
                                               checked={this.state.globalForm.periodicitySettings.h24}
                                               onChange={this.handleGlobalPeriodicitySettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-periodicity-h24')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <h4>{this.props.t('menu-settings-header-shortcuts')}</h4>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='day' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.day}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-day')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='week' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.week}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-week')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='month' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.month}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-month')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='quarter' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.quarter}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-quarter')}
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='halfYear' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.halfYear}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-6months')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='year' type="checkbox"
                                               checked={this.state.globalForm.shortcutSettingsDTO.year}
                                               onChange={this.handleGlobalShortcutSettingsChange}/>
                                        {this.props.t('meteo:data-filters-panel-date-predefined-year')}
                                    </Label>
                                </FormGroup>
                            </Col>
                            <Col></Col>
                            <Col></Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className='mt-4' onClick={this.saveGlobal} color='primary'><FontAwesomeIcon icon='check'/> Zapisz</Button>
                            </Col>
                        </Row>
                    </Form>
                </article>
            </div>
        </Container>
    }
}

export default withTranslation(['admin', 'meteo'])(AdminMenuSettings);