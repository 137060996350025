import React from 'react';
import {withTranslation} from 'react-i18next';
import GrainDiseaseService from "../GrainDiseaseService";
import Container from "reactstrap/es/Container";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button} from "reactstrap";
import draftToHtml from 'draftjs-to-html';


class GrainDiseaseChangesHistory extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            history: [],
            disease: {name: ''},
            selected: null,
            displayDialog: false
        };
        this.grainDiseaseService = new GrainDiseaseService();
        this.actionColumn = this.actionColumn.bind(this);
        this.convert = this.convert.bind(this);
    }

    componentDidMount() {
        let diseaseUuid = this.props.match.params.diseaseUuid;
        Promise.all([this.grainDiseaseService.getById(diseaseUuid), this.grainDiseaseService.getContentHistory(diseaseUuid)])
            .then(values => {
                this.setState({disease: values[0], history: values[1]})
            });
    }

    actionColumn(rowData, column) {
        return <Button color='primary'
                       onClick={() => this.setState({
                           displayDialog: true,
                           selected: rowData
                       })}>Podgląd</Button>
    }

    convert() {
        try {
            return draftToHtml(JSON.parse(this.state.selected.content));
        } catch (e) {
            return '';
        }
    }

    render() {
        const header = <span>Historia zmian podstrony {this.state.disease.name}</span>;
        return (
            <Container>
                <DataTable value={this.state.history} header={header}>
                    <Column field='lang' header='Język' sortable={true}/>
                    <Column field='version' header='Wersja' sortable={true}/>
                    <Column field='modificationTime' header='Data' sortable={true}/>
                    <Column field='modificationBy' header='Autor' sortable={true}/>
                    <Column body={this.actionColumn}/>
                </DataTable>

                <Dialog visible={this.state.displayDialog}
                        modal={false}
                        style={{width: '472px', height: '540px'}}
                        contentStyle={{padding: '0'}}
                        header="Podgląd wersji"
                        onHide={() => this.setState({displayDialog: false})}>
                    {this.state.selected && <div style={{ overflow: 'scroll',height: '500px', padding: '8px'}}>
                    <div dangerouslySetInnerHTML={{__html: this.convert()}}>
                    </div></div>
                    }
                </Dialog>
            </Container>
        )
    }

}


export default withTranslation('ns')(GrainDiseaseChangesHistory);
