import React from 'react';
import moment from "moment";

class Commons {

    CALENDAR_PL = {
        firstDayOfWeek: 1,
        dayNames: ['Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek', 'Piątek', 'Sobota'],
        dayNamesShort: ['Nie', 'Pon', 'Wt', 'Śr', 'Czw', 'Pt', 'So'],
        dayNamesMin: ['N', 'P', 'W', 'Ś', 'Cz', 'Pt', 'S'],
        monthNames: ['Styczeń', 'Luty', 'Marzec', 'Kwiecień', 'Maj', 'Czerwiec', 'Lipiec', 'Sierpień', 'Wrzesień', 'Październik', 'Listopad', 'Grudzień'],
        monthNamesShort: ['Sty', 'Lut', 'Mar', 'Kwi', 'Maj', 'Cze', 'Lip', 'Sie', 'Wrz', 'Paź', 'Lis', 'Gru'],
        today: 'Dziś',
        clear: 'Wyczyść',
        dateFormat: 'yy-mm-dd',
        weekHeader: 'Tydzień'
    };

    dateFormat(date) {
        return moment(date).format('YYYY-MM-DD');
    }

    dateColumnTemplate(rowData, column) {
        return rowData[column.field] ? <span>{moment(rowData[column.field]).format('YYYY-MM-DD')}</span> : '';
    }

    buildStationOptions(stations, defaultStations, translateFunction) {
        if (defaultStations && defaultStations.length > 0) {
            const defaults = stations
                .filter(station => defaultStations.includes(station.uuid))
                .sort((a,b) => a.city.localeCompare(b.city))
                .map(station => {
                    return this.buildStationOption(station, translateFunction)
                });
            const rest = stations
                .filter(station => !defaultStations.includes(station.uuid))
                .sort((a,b) => a.city.localeCompare(b.city))
                .map(station => {
                    return this.buildStationOption(station, translateFunction)
                });
            return [
                <optgroup key='defaults'>
                    {defaults}
                </optgroup>,
                <optgroup key='rest' label="_________">
                    {rest}
                </optgroup>
            ]
        }
        return stations.map(station => {
                return this.buildStationOption(station, translateFunction)
            });
    }

    buildStationOption(station, translateFunction) {
        return <option key={station.uuid} value={station.uuid}>
            {translateFunction('station-select-label', {
                stationName: station.city,
                countyName: station.county
            })}
        </option>;
    }

}

export default new Commons();