import React from 'react';
import PropTypes from 'prop-types';

import countyTextOffsets from './CountyTextOffsets'
import {groupBy, isNaN, isNull, meanBy} from "lodash";
import {ProgressSpinner} from 'primereact/progressspinner';
import {getOnlyMap, getOnlyMapStationClickMeas, measurements} from "../../util/MeteoUtil"
import {withTranslation} from "react-i18next";
import * as moment from "moment";
import AuthActions from "../auth/AuthActions";
import {withRouter} from "react-router";

class SVGCountyMap extends React.Component {

    render() {
        const measurementType = measurements.find(item => item.key === this.props.selectedMeasurementType);
        const unitsOptions = [];
        for (var propt in measurementType.units) {
            const unitObject = measurementType.units[propt];
            unitsOptions.push({
                label: unitObject.name,
                value: unitObject
            })
        }
        const grouped = groupBy(this.props.currentMeteoData, (item) => {
            return item.county;
        });
        const adminLogged = AuthActions.isAdminLogged();
        const stationMarkers = [];
        return (
            <div style={{position: "relative"}}>
                {
                    this.props.loadingData &&
                    <ProgressSpinner className="map-loading"/>
                }
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox={this.props.map.viewBox}
                    className={this.props.className}
                    role={this.props.role}
                    aria-label={this.props.map.label}
                >
                    {this.props.map.locations.map((location, index) => {
                        const cur = countyTextOffsets.find(s => s.id === location.id);
                        let lines = location.name.split(' ');
                        if (lines[0] === 'Powiat') {
                            lines[0] = this.props.t('data-county');
                        }
                        if (lines.length > 1 && lines[1].includes('-')) {
                            const tmp = lines[1].split('-');
                            lines.pop();
                            lines = lines.concat(tmp);
                        }
                        let filteredGroupe = [];
                        let countyStationsNotActualData = false;
                        let titleMsg = [];
                        if (grouped[location.id] !== undefined) {
                            filteredGroupe = grouped[location.id].filter(item => !isNull(item[this.props.selectedMeasurementType]) && this.props.currentMeteoDataIsValid[item.stationUuid]);
                            if (filteredGroupe.length === 0) {
                                titleMsg.push(this.props.t('data-map-county-no-data-msg'));
                            } else {
                                const stationsName = filteredGroupe.map(item => item.city).join(', ');
                                const maxDate = new Date(Math.max.apply(null, filteredGroupe.map(function (e) {
                                    return new Date(e.dateTime);
                                })));
                                titleMsg.push(this.props.t(filteredGroupe.length === 1 ? 'data-map-county-actual-msg' : 'data-map-county-actual-msg_plural', {
                                    stationsNames: stationsName,
                                    dateTime: moment(maxDate).format('YYYY-MM-DD HH:mm')
                                }));
                            }
                        }
                        const meanValue = meanBy(filteredGroupe, this.props.selectedMeasurementType);
                        const hasMeanValue = !isNaN(meanValue);
                        const valueClassNames = 'map-meas-label' + (countyStationsNotActualData === true && adminLogged ? ' old-datetime' : '');
                        if (grouped[location.id]) {
                            grouped[location.id].forEach((station) => {
                                    stationMarkers.push(
                                        <g
                                            id={location.id + '-station-' + station.meteoStation + '-group'}
                                            key={location.id + '-station-' + station.meteoStation + '-group'}
                                            onClick={() => {
                                                this.props.history.push('/dane/' + station.businessId, {
                                                    fromMap: true,
                                                    selectedMeasurementType: getOnlyMap(this.props.selectedMeasurementType) ? getOnlyMapStationClickMeas(this.props.selectedMeasurementType) : this.props.selectedMeasurementType
                                                })
                                            }}
                                            style={{"display": "inline", "cursor": "pointer"}}>
                                            <title>{
                                                isNull(station[this.props.selectedMeasurementType]) || !this.props.currentMeteoDataIsValid[station["stationUuid"]]
                                                    ?
                                                    this.props.t('data-map-station-no-data-msg', {stationName: station.city})
                                                    :
                                                    this.props.t('data-map-station-actual-msg', {
                                                        stationName: station.city,
                                                        dateTime: moment(station.dateTime).format('YYYY-MM-DD HH:mm'),
                                                        value: this.props.selectedUnit.formula(station[this.props.selectedMeasurementType]).toFixed(measurementType.precision) + this.props.selectedUnit.name
                                                    })
                                            }</title>
                                            <circle
                                                r="11"
                                                cy={station.y}
                                                cx={station.x}
                                                id={location.id + '-station-' + station.meteoStation + '-back'}
                                                className="map-station-circle"/>
                                        </g>);
                                }
                            );
                        }
                        return (
                            <g
                                id={location.id}
                                key={location.id}
                                style={{"display": "inline"}}>
                                <title>{lines.join(' ')}</title>
                                <path
                                    id={location.id + '-path'}
                                    name={location.name + '-path'}
                                    d={location.path}
                                    className={typeof this.props.locationClassName === 'function' ? this.props.locationClassName(location, index) : this.props.locationClassName}
                                    aria-label={location.name}
                                    key={location.id + '_path'}
                                />
                                {
                                    cur &&
                                    <g
                                        style={{"display": "inline"}}
                                        id={location.id + '-label-group'}>
                                        <text
                                            id={location.id + '-meas-label'}
                                            className={valueClassNames}
                                            transform={hasMeanValue ? 'translate(0,0)' : 'translate(10,0)'}
                                        >
                                            {
                                                titleMsg && titleMsg.length &&
                                                <title>{titleMsg.join('\n')}</title>
                                            }
                                            <tspan
                                                id={location.id + '-meas-label-tspan'}
                                                y={cur.measy}
                                                x={cur.measx}
                                            >{(hasMeanValue ? this.props.selectedUnit.formula(meanValue).toFixed(measurementType.precision) : this.props.emptyMeasLabel) + this.props.selectedUnit.name}
                                            </tspan>
                                        </text>
                                    </g>
                                }
                            </g>
                        );
                    })}
                    <g id="station-markers">{stationMarkers}</g>
                </svg>
            </div>
        );
    }
}

SVGCountyMap.propTypes = {
    map: PropTypes.shape({
        viewBox: PropTypes.string.isRequired,
        locations: PropTypes.arrayOf(
            PropTypes.shape({
                path: PropTypes.string.isRequired,
                id: PropTypes.string.isRequired,
                name: PropTypes.string
            })
        ).isRequired,
        label: PropTypes.string
    }).isRequired,
    className: PropTypes.string,
    role: PropTypes.string,
    emptyMeasLabel: PropTypes.string,
    locationClassName: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
};

SVGCountyMap.defaultProps = {
    className: 'svg-map',
    role: 'none', // No role for map
    locationClassName: 'svg-map__location',
    emptyMeasLabel: '--'
};

export default withRouter(withTranslation('meteo')(SVGCountyMap));
