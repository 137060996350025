import ApiService from '../../../service/common/ApiService'

export default class MeteoStationService {

    getAll() {
        return ApiService.get('/meteostation');
    }

    get(id) {
        return ApiService.get('/meteostation/'+id);
    }

    create(data) {
        return ApiService.post('/meteostation/', data);
    }

    delete(id) {
        return ApiService.delete('/meteostation/' + id);
    }

    update(id, data) {
        return ApiService.put('/meteostation/' + id, data);
    }

    getSettingsFormsForAllStations() {
        return ApiService.get('/settings/stations');
    }
}