import React, {Component} from 'react';
import DataForm from "./Form/DataForm";
import DataChart from "./Chart/Chart";
import DataService from "./DataService";
import './Data.css';
import DataTable from './Table/DataTable'
import MeteoService from "../map/MeteoService";
import {withTranslation} from "react-i18next";
import {Button} from "reactstrap";
import {Dropdown} from 'primereact/dropdown';
import {ProgressSpinner} from "primereact/progressspinner";
import DataStats from "./Stats/DataStats";

class Data extends Component {

    constructor(props) {
        super(props);
        this.stationId = this.props.match.params.stationId;
        this.state = {
            data: [],
            dto: null,
            interval: 15,
            reportType: 'PDF',
            measurementTypes: [],
            sunriseAndSunsets: [],
            preselectedStation: null,
            commonChart: false,
            loadingData: false,
            blockExport: false
        };
        this.dataService = new DataService();
        this.meteoService = new MeteoService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.downloadReport = this.downloadReport.bind(this);
    }

    componentDidMount() {
        if (this.stationId) {
            this.meteoService.getByBusinessId(this.stationId)
                .then(res => this.setState({
                    preselectedStation: res
                }))
                .catch(err => {
                    this.setState({preselectedStation: null});
                })
        }
    }

    periodicityToMinutes(periodicity) {
        switch (periodicity) {
            case 'min15':
                return 15;
            case 'min30':
                return 30;
            case 'h1':
                return 60;
            case 'h3':
                return 180;
            case 'h6':
                return 360;
            case 'h8':
                return 480;
            case 'h12':
                return 720;
            case 'h24':
                return 1440;
            default:
                return 15;
        }
    }

    handleSubmit(dto) {
        this.setState({loadingData: true}, () => {
            this.dataService.getData(dto)
                .then(result => {
                    this.setState({
                        data: result.data,
                        dto: dto,
                        commonChart: dto.commonChart,
                        interval: this.periodicityToMinutes(dto.periodicity),
                        measurementTypes: dto.measurementTypes,
                        sunriseAndSunsets: result.sunriseAndSunsets,
                        loadingData: false
                    })
                })
        });
    }

    downloadReport() {
        let dto = this.state.dto;
        this.setState({loadingData: true, blockExport: true});
        this.dataService.getExport(dto, this.state.reportType)
            .then(response => {
                const disposition = response.request.getResponseHeader('Content-Disposition').split(';');
                const fileName = disposition[1].split('=')[1];
                const data = response.data;
                const blob = new Blob([data]);
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    const link = document.createElement('a');
                    // Browsers that support HTML5 download attribute
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        link.setAttribute('href', url);
                        link.setAttribute('download', fileName);
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
                this.setState({loadingData: false, blockExport: false});
            })
            .catch(err => this.setState({loadingData: false, blockExport: false}));
    }

    render() {
        const reportTypeSelectItems = [
            {label: 'PDF', value: 'PDF'},
            {label: 'CSV', value: 'CSV'},
            {label: 'TXT', value: 'TXT'},
            {label: 'XLSX', value: 'XLSX'},
        ];

        const stationDataPanels = [];
        if (this.state.commonChart) {
            const stationData = [];
            const stationDataDesc = [];
            for (let i = 0; i < this.state.data.length; i++) {
                stationData.push(this.state.data[i].data);
                stationDataDesc.push(this.state.data[i].city);
            }
            stationDataPanels.push(
                <article key="common-chart">
                    <h2>{this.props.t('common-chart-header')}</h2>
                    <h6>{this.props.t('data-charts-header-help')}</h6>
                    <DataChart data={stationData}
                               interval={this.state.interval}
                               dataDesc={stationDataDesc}
                               sunriseAndSunsets={this.state.sunriseAndSunsets}/>
                </article>
            );
        }

        for (let i = 0; i < this.state.data.length; i++) {
            const data = this.state.data[i];
            stationDataPanels.push(
                <article key={data.id}>
                    {!this.state.preselectedStation &&
                    <h2>{data.city}, {this.props.t('data-county').toLowerCase()} {data.county}</h2>}
                    {
                        !this.state.commonChart &&
                        <h6>{this.props.t('data-charts-header-help')}</h6>
                    }
                    {
                        !this.state.commonChart &&
                        <DataChart data={[data.data]}
                                   dataDesc={[data.city]}
                                   interval={this.state.interval}
                                   sunriseAndSunsets={this.state.sunriseAndSunsets}/>
                    }
                    <DataStats data={data.minMaxList} rawData={data.data}
                               from={this.state.dto ? this.state.dto.from : null}
                               to={this.state.dto ? this.state.dto.to : null}/>
                    <DataTable data={data.data} dataMinMax={data.minMaxList}/>
                </article>
            )
        }

        return (<>
            <div id='data-panel' className='container'>
                {this.state.preselectedStation &&
                <h1 className='p-2'>{this.props.t('data-station') + ' ' + this.state.preselectedStation.city}</h1>
                }
                {this.state.preselectedStation &&
                <DataForm onSubmit={this.handleSubmit} stationId={this.state.stationId}
                          preselectedStation={this.state.preselectedStation}/>
                }
                {!this.state.preselectedStation &&
                <DataForm onSubmit={this.handleSubmit} stationId={this.state.stationId}/>
                }
                {this.state.loadingData && <ProgressSpinner/>}
                {stationDataPanels}
                <div className='p-2'>
                    <b>{this.props.t('download-as')}</b>
                    <Dropdown className='m-2'
                              value={this.state.reportType} options={reportTypeSelectItems} onChange={(e) => {
                        this.setState({reportType: e.value})
                    }}/>
                    <Button disabled={this.state.blockExport} color='primary'
                            onClick={this.downloadReport}>{this.props.t('download-report')}</Button>
                </div>
                <br/>
            </div>
            </>
        );
    }
}

export default withTranslation('meteo')(Data);