import ApiService from '../../service/common/ApiService'

class UserService {

    getAll() {
        return ApiService.get('/users');
    }

    create(dto) {
        return ApiService.post('/users', dto);
    }

    update(id, data) {
        return ApiService.put('/user/' + id, data);
    }

    confirmRegistration(token) {
        return ApiService.get('/users/registrationConfirm?token=' + token);
    }

    getAllUserRoles() {
        return ApiService.get('/user/roles');
    }

    getAllEmails() {
        return ApiService.get('/users/email');
    }

    emailExists(email) {
        return ApiService.get('/user/email/' + email + '/exist');
    }

    delete(id) {
        return ApiService.delete('/user/' + id);
    }

    getUserDetails() {
        return ApiService.get('/user/details');
    }
}

export default UserService;