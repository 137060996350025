import React from 'react';
import {withTranslation} from 'react-i18next';

import {Editor} from 'react-draft-wysiwyg';
import {convertFromRaw, convertToRaw, EditorState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {Button, Container} from "reactstrap";

import config from '../../../../config/config'
import GrainDiseaseService from "../GrainDiseaseService";
import {Growl} from "primereact/growl";
import './GrainDiseaseEditor.css';
import {RadioButton} from "primereact/radiobutton";
import {InputText} from "primereact/inputtext";

class GrainDiseaseEditor extends React.Component {

    constructor(props) {
        super(props);
        this.diseaseUuid = this.props.match.params.diseaseUuid;
        this.lang = this.props.match.params.lang;
        this.state = {
            name: '',
            editorState: EditorState.createEmpty(),
            orgName: '',
            mode: 'edit'
        };
        this.service = new GrainDiseaseService();
        this.onEditorStateChange = this.onEditorStateChange.bind(this);
        this.uploadImageCallBack = this.uploadImageCallBack.bind(this);
        this.save = this.save.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.service.getById(this.diseaseUuid).then(res => this.setState({orgName: res.name}));

        this.service.getContentForm(this.diseaseUuid, this.lang)
            .then(res => {
                let contentState = convertFromRaw(JSON.parse(res.content));
                this.setState({
                    name: res.name,
                    editorState: EditorState.createWithContent(contentState)
                })
            });
    }

    onEditorStateChange(editorState) {
        this.setState({
            editorState
        });
    };

    uploadImageCallBack(file) {
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest();
                xhr.open('POST', config.apiUrl + '/grains/diseases/images');
                xhr.setRequestHeader('Authorization', "bearer " + localStorage.getItem('api_access_token'));
                const formData = new FormData();
                formData.append('image', file);
                xhr.send(formData);
                xhr.addEventListener('load', () => {
                    resolve({data: {link: config.apiUrl + '/grains/diseases/images/' + xhr.responseText}});
                });
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText);
                    reject(error);
                });
            }
        );
    }

    save() {
        this.service.updateContent({
            diseaseUuid: this.diseaseUuid,
            lang: this.lang,
            content: JSON.stringify(convertToRaw(this.state.editorState.getCurrentContent())),
            name: this.state.name
        })
            .then(res => {
                this.growl.current.show({severity: 'success', summary: 'Zapisano zmiany.'});
            })
            .catch(err => {
                console.log(err);
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany.'});
            });
    }

    render() {
        return (
            <Container className='p-1'>
                <Growl ref={this.growl}/>
                <center><h2 className='m-2'>Edycja treści podstrony {this.state.orgName} w j. {this.lang === 'pl' ? 'polskim' : 'angielskim'}</h2></center>
                {this.lang === 'en' && <div className='m-2'>
                    Nazwa w j. angielskim: <InputText style={{width: '360px'}} value={this.state.name} onChange={e => this.setState({name: e.target.value})}/>
                </div>}
                <div className='m-2'>
                    <RadioButton value={this.state.mode} checked={this.state.mode === 'edit'} onChange={() => this.setState({mode: 'edit'})}/>
                    <span className='mr-4'> Szeroki formularz (edycja)</span>
                    <RadioButton value={this.state.mode} checked={this.state.mode === 'preview'} onChange={() => this.setState({mode: 'preview'})}/>
                    <span className='mr-4'> Wąski formularz (podgląd)</span>
                </div>
                <Editor
                    editorClassName={this.state.mode === 'edit' ? 'grains-editor grains-editor-edit' : 'grains-editor grains-editor-preview'}
                    wrapperClassName='grains-editor-wrapper'
                    toolbarClassName='grains-editor-toolbar'
                    editorState={this.state.editorState}
                    onEditorStateChange={this.onEditorStateChange}
                    localization={{
                        locale: 'pl',
                    }}
                    toolbar={{
                        inline: {inDropdown: false},
                        list: {inDropdown: true},
                        textAlign: {inDropdown: false},
                        link: {inDropdown: false},
                        history: {inDropdown: false},
                        image: {uploadCallback: this.uploadImageCallBack, alt: {present: true, mandatory: true}},
                    }}
                />
                <span style={{fontSize: '85%'}}>Szerokość kolumny z treścią podstrony choroby wynosi 456px.</span><br/>
                <Button color='primary' onClick={this.save}>Zapisz</Button>
            </Container>
        )
    }

}

export default withTranslation('ns')(GrainDiseaseEditor);
