import React from 'react';
import UserService from "./UserService";

class UserConfirmRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.userService = new UserService();
    }

    componentDidMount() {
        const token = this.props.match.params.token;
        if (token !== null) {
            this.userService.confirmRegistration(token)
                .then(() => {
                    this.props.history.push('/?success=registrationConfirmed');
                })
                .catch(e => {
                    this.props.history.push('/?error=invalidToken');
                });
        } else {
            this.props.history.push('/');
        }
    }

    render() {
        return null;
    }
}

export default UserConfirmRegistration;
