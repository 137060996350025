import React from "react";
import {Col, Container, Label, Row} from "reactstrap";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import Button from "reactstrap/es/Button";
import {withTranslation} from "react-i18next";
import UserService from "./UserService";
import {Growl} from "primereact/growl";
import MeteoService from "../map/MeteoService";
import {MultiSelect} from 'primereact/multiselect';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserCustomForm from "./UserCustomForm";

class UserAccount extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            user: null,
            repeatedPassword: '',
            stations: []
        };
        this.userService = new UserService();
        this.meteoService = new MeteoService();
        this.growl = React.createRef();
        this.stationsSelect = React.createRef();
        this.customForm = null;
    }

    componentDidMount() {
        Promise.all([this.userService.getUserDetails(), this.meteoService.getAllStations()])
            .then(values => {
                const user = values[0];
                if (user.password === null) {
                    user.password = '';
                }
                this.customForm.updateCustom(user.custom);
                this.setState({
                    user: Object.assign({}, user),
                    stations: values[1]
                });
            });
        const focus = this.props.location.hash;
        if(focus === '#stacje') {
            this.stationsSelect.current.onFocus();
        }
    }

    updateProperty(property, value) {
        let user = this.state.user;
        if (user) {
            user[property] = value;
            this.setState({user: user});
        }
    }

    onValidSubmit = (event, values) => {
        this.update();
    };


    update() {
        let user = Object.assign({}, this.state.user);
        user.custom = this.customForm.getCustomDataDto();
        this.userService.update(this.state.user.uuid, user)
            .then(response => {
                localStorage.setItem('default_stations', JSON.stringify(this.state.user.stationsIds));
                let savedMessage = this.props.t('common:action-saved');
                this.growl.current.show({severity: 'info', summary: savedMessage})
            });
    }

    updateRepeatedPassword(value) {
        this.setState({repeatedPassword: value});
    }

    render() {
        const stationOptions = this.state.stations.map(station => {
            return {
                label: this.props.t('meteo:station-select-label', {
                    stationName: station.city,
                    countyName: station.county
                }), value: station.uuid
            }
        });

        return (
            <Container>
                <div className="form-margin">
                    <Growl ref={this.growl}/>
                    <AvForm onValidSubmit={this.onValidSubmit}>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="firstName">{this.props.t('user-form-first-name')}</Label>
                                    <AvInput id="firstName" name="firstName"
                                             value={this.state.user ? this.state.user.firstName : ''}
                                             onChange={(e) => {
                                                 this.updateProperty('firstName', e.target.value)
                                             }} required/>
                                    <AvFeedback>{this.props.t('user-form-first-name-required')}</AvFeedback>
                                </AvGroup>
                            </Col>
                            <Col>
                                <AvGroup>
                                    <Label for="lastName">{this.props.t('user-form-last-name')}</Label>
                                    <AvInput id="lastName" name="lastName"
                                             value={this.state.user ? this.state.user.lastName : ''}
                                             onChange={(e) => {
                                                 this.updateProperty('lastName', e.target.value)
                                             }} required/>
                                    <AvFeedback>{this.props.t('user-form-last-name-required')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="email">{this.props.t('user-form-email')}</Label>
                                    <AvInput id="email" name="email"
                                             value={this.state.user ? this.state.user.email : ''}
                                             onChange={(e) => {
                                                 this.updateProperty('email', e.target.value)
                                             }} required
                                             validate={{email: true}}
                                    />
                                    <AvFeedback>{this.props.t('user-form-email-invalid')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div>
                                    <span>{this.props.t('user-form-stations')}</span>
                                    <br/>
                                    <MultiSelect value={this.state.user ? this.state.user.stationsIds : []}
                                                 ref={this.stationsSelect}
                                                 options={stationOptions}
                                                 style={{width: '100%'}} filter={true}
                                                 onChange={(e) => this.updateProperty('stationsIds', e.value)}/>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <AvGroup>
                                    <Label for="password">{this.props.t('user-form-password')}</Label>
                                    <AvInput id="password" name="password"
                                             value={this.state.user ? this.state.user.password : ''}
                                             type="password"
                                             onChange={(e) => {
                                                 this.updateProperty('password', e.target.value)
                                             }}
                                             autoComplete="off"
                                             validate={{
                                                 match: {
                                                     value: 'repeatedPassword',
                                                     errorMessage: ''
                                                 },
                                             }}/>
                                    <AvFeedback> {this.props.t('user-form-password-mismatch')}</AvFeedback>
                                </AvGroup>
                            </Col>
                            <Col>
                                <AvGroup>
                                    <Label
                                        for="repeatedPassword">{this.props.t('user-form-repeated-password')}</Label>
                                    <AvInput id="repeatedPassword" name="repeatedPassword"
                                             value={this.state.user ? this.state.repeatedPassword : ''}
                                             type="password"
                                             autoComplete="off"
                                             onChange={(e) => {
                                                 this.updateRepeatedPassword(e.target.value)
                                             }}
                                             validate={{
                                                 match: {
                                                     value: 'password',
                                                     errorMessage: ''
                                                 },
                                             }}/>
                                    <AvFeedback> {this.props.t('user-form-password-mismatch')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <UserCustomForm reg={ref => this.customForm = ref}/>
                        <div>
                            <Button color='primary' type='submit'><FontAwesomeIcon
                                icon='check'/> {this.props.t('common:action-save')}</Button>
                        </div>
                    </AvForm>
                </div>
            </Container>
        );
    }
}

export default withTranslation(['admin', 'common', 'meteo'])(UserAccount);