import ApiService from "../../../service/common/ApiService";

export default class GrainDiseaseService {

    getByUrl(url, lang) {
        return ApiService.get('/grains/diseases/url/' + url + '?lang=' + lang);
    }

    getById(uuid) {
        return ApiService.get('/grains/diseases/id/' + uuid);
    }

    getContentForm(uuid, lang) {
        return ApiService.get('/grains/diseases/contentform/' + lang + '/' + uuid);
    }

    getAll(lang) {
        return ApiService.get('/grains/diseases?lang=' + lang);
    }

    create(dto) {
        return ApiService.post('/grains/diseases', dto);
    }

    update(dto) {
        return ApiService.put('/grains/diseases/' + dto.uuid, dto);
    }

    delete(uuid) {
        return ApiService.delete('/grains/diseases/' + uuid);
    }

    updateContent(dto) {
        return ApiService.post('/grains/diseases/content', dto);
    }

    getContentHistory(diseaseUuid) {
        return ApiService.get('/grains/diseases/content/' + diseaseUuid);
    }
}