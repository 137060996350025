import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios'
import AuthActions from "./components/auth/AuthActions";

import login_pl from "./translations/pl/login.json";
import meteo_pl from "./translations/pl/meteo.json";
import admin_pl from "./translations/pl/admin.json";
import station_pl from "./translations/pl/station.json";
import grains_pl from "./translations/pl/grains.json";
import common_pl from "./translations/pl/common.json";
import login_en from "./translations/en/login.json";
import meteo_en from "./translations/en/meteo.json";
import admin_en from "./translations/en/admin.json";
import station_en from "./translations/en/station.json";
import grains_en from "./translations/en/grains.json";
import common_en from "./translations/en/common.json";
import field_en from "./translations/en/field.json";
import field_pl from "./translations/pl/field.json";

import './primereact-theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import './theme.css';
import './index.css';
import './fontawesome';

import {I18nextProvider} from "react-i18next";
import i18next from 'i18next';
import authInterceptor from './components/auth/AuthInterceptor';

axios.interceptors.request.use(async (config) => {
    if (localStorage.getItem('api_access_token') === null) {
        await AuthActions.loginAnonymous();
        config.headers.Authorization = "bearer " + localStorage.getItem('api_access_token');
    }
    return config
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(undefined, authInterceptor(axios));

i18next.init({
    interpolation: {escapeValue: false},
    lng: 'pl',
    resources: {
        pl: {
            login: login_pl,
            meteo: meteo_pl,
            admin: admin_pl,
            station: station_pl,
            grains: grains_pl,
            common: common_pl,
            field: field_pl
        },
        en: {
            login: login_en,
            meteo: meteo_en,
            admin: admin_en,
            station: station_en,
            grains: grains_en,
            common: common_en,
            field: field_en
        },
    },
});

if (process.env.NODE_ENV === 'production') {
    let temp = document.createElement('script');
    temp.async = true;
    temp.src = "https://www.googletagmanager.com/gtag/js?id=UA-155889254-1";
    document.getElementsByTagName('head')[0].appendChild(temp);
    temp = document.createElement('script');
    temp.innerHTML = 'window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'UA-155889254-1\');';
    document.getElementsByTagName('head')[0].appendChild(temp);
}


ReactDOM.render(<I18nextProvider i18n={i18next}><App/></I18nextProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
