import ApiService from '../../../service/common/ApiService'

class TreatmentService {

    create(data) {
        return ApiService.post('/treatments', data);
    }

    update(id, data) {
        return ApiService.put('/treatments/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/treatments/' + id);
    }

}

export default TreatmentService;
