import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button, Container, Form, FormGroup, Label} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Growl} from "primereact/growl";
import {withTranslation} from "react-i18next";
import {RadioButton} from "primereact/radiobutton";
import PotatoVarietyService from "./PotatoVarietyService";

class PotatoVarieties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            varieties: [],
            selected: null,
            displayDialog: false,
            name: '',
            uuid: null,
            susceptibility: 'MEDIUM',
            validName: true,
            validSusceptibility: true,
            validationErrors: false,
            validUniqueName: true
        };
        this.potatoVarietyService = new PotatoVarietyService();
        this.onSelect = this.onSelect.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.addNew = this.addNew.bind(this);
        this.susceptibilityTemplate = this.susceptibilityTemplate.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.handleSusceptibilityFilterChange = this.handleSusceptibilityFilterChange.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.potatoVarietyService.getAll()
            .then(res => this.setState({varieties: res}));
    }

    onSelect(e) {
        this.setState({
            displayDialog: true,
            variety: e.data,
            uuid: e.data.uuid,
            name: e.data.name,
            susceptibility: e.data.susceptibility
        });
    }

    save() {
        let validationErrors = false;
        let validUniqueName = true;
        let validName = true;
        if (this.state.name === null || this.state.name === '') {
            validName = false;
            validationErrors = true;
        } else if (this.state.varieties.filter(v => v.name.toLowerCase() === this.state.name.toLowerCase() && v.uuid !== this.state.uuid).length > 0) {
            validUniqueName = false;
            validationErrors = true;
        }
        let validSusceptibility = true;
        if (this.state.susceptibility === null || (this.state.susceptibility !== 'HIGH' && this.state.susceptibility !== 'MEDIUM' && this.state.susceptibility !== 'LOW')) {
            validSusceptibility = false;
            validationErrors = true;
        }
        if (validationErrors) {
            this.setState({
                validName: validName,
                validUniqueName: validUniqueName,
                validSusceptibility: validSusceptibility,
                validationErrors: validationErrors
            })
        } else {
            if (this.state.uuid) {
                this.update({uuid: this.state.uuid, name: this.state.name, susceptibility: this.state.susceptibility});
            } else {
                this.create({uuid: '', name: this.state.name, susceptibility: this.state.susceptibility});
            }
        }
    }

    create(variety) {
        this.potatoVarietyService.create(variety)
            .then(res => {
                this.setState(state => {
                    let varieties = [...state.varieties];
                    varieties.push(res);
                    return {
                        varieties: varieties,
                        selected: null,
                        variety: null,
                        name: '',
                        susceptibility: 'MEDIUM',
                        displayDialog: false,
                        validName: true,
                        validSusceptibility: true,
                        validationErrors: false,
                        validUniqueName: true
                    }
                });
                this.growl.current.show({severity: 'success', summary: 'Dodano nową odmianę.'});
            }).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się dodać odmiany.'});
        })
    }

    update(variety) {
        this.potatoVarietyService.update(variety.uuid, variety)
            .then(res => {
                    this.setState(state => {
                        let variety = this.state.variety;
                        variety.name = this.state.name;
                        variety.susceptibility = this.state.susceptibility;
                        let varieties = [...state.varieties];
                        let index = state.varieties.indexOf(state.selected);
                        varieties[index] = this.state.variety;
                        return {
                            varieties: varieties,
                            selected: null,
                            variety: null,
                            name: '',
                            susceptibility: 'MEDIUM',
                            displayDialog: false,
                            validName: true,
                            validSusceptibility: true,
                            validUniqueName: true,
                            validationErrors: false
                        }
                    });
                    this.growl.current.show({severity: 'success', summary: 'Zapisano odmianę.'});
                }
            ).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się zapisać odmiany.'});
        })
    }

    delete() {
        this.potatoVarietyService.delete(this.state.selected.uuid)
            .then(res => {
                this.setState(state => {
                    let index = state.varieties.indexOf(state.selected);
                    return {
                        varieties: state.varieties.filter((val, i) => i !== index),
                        selected: null,
                        variety: null,
                        displayDialog: false
                    }
                });
            })
    }

    addNew() {
        this.setState({
            variety: {name: '', susceptibility: 'MEDIUM'},
            displayDialog: true
        });
    }

    susceptibilityTemplate(rowData, column) {
        return <span>{rowData.susceptibility === 'HIGH' ? 'Podatna' : rowData.susceptibility === 'MEDIUM' ? 'Średnio podatna' : 'Średnio odporna'}</span>;
    }

    handleSusceptibilityFilterChange(event) {
        this.dt.filter(event.value, 'susceptibility', 'equals');
        this.setState({selectedSusceptibility: event.value});
    }

    render() {
        let footer = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={this.addNew}>
                <FontAwesomeIcon icon='plus'/> Dodaj
            </Button>
        </div>;


        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button color='primary' onClick={this.save}>
                <FontAwesomeIcon icon='check'/> Zapisz
            </Button>
            {this.state.variety && this.state.variety.uuid && <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> Usuń odmianę
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDialog: false})}>
                <FontAwesomeIcon icon='times'/> Anuluj
            </Button>
        </div>;
        const types = [
            {label: 'Podatna', value: 'HIGH'},
            {label: 'Średnio podatna', value: 'MEDIUM'},
            {label: 'Średnio odporna', value: 'LOW'}
        ];
        let typeFilter = <SelectButton style={{width: '100%'}}
                                       value={this.state.selectedSusceptibility} options={types}
                                       onChange={this.handleSusceptibilityFilterChange}/>;

        return (
            <Container>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.varieties} paginator={true} rows={20}
                           ref={(el) => this.dt = el}
                           footer={footer}
                           selectionMode="single"
                           selection={this.state.selected}
                           onSelectionChange={e => this.setState({selected: e.value})}
                           onRowSelect={this.onSelect}>
                    <Column field="name" header="Nazwa" sortable={true} filter={true}/>
                    <Column field="susceptibility" header="Podatność" sortable={true} body={this.susceptibilityTemplate}
                            filter={true} filterElement={typeFilter}/>
                </DataTable>

                <Dialog visible={this.state.displayDialog} width="600px" header="Odmiana zboża" modal={true}
                        footer={dialogFooter}
                        onHide={() => this.setState({displayDialog: false})}>
                    {
                        this.state.variety &&
                        <Form>
                            <FormGroup>
                                <Label for='name'>Nazwa odmiany</Label><br/>
                                <InputText name='name' value={this.state.name}
                                           className={this.state.validName && this.state.validUniqueName ? '' : 'form-control is-invalid'}
                                           onChange={(e) => this.setState({name: e.target.value})}
                                />
                                <div className="invalid-feedback">
                                    {!this.state.validName && <span>Nazwa jest wymagana</span>}
                                    {!this.state.validUniqueName && <span>Nazwa jest już użyta.</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>Rodzaj</Label><br/>
                                <Label>
                                    <RadioButton value="HIGH" name="high"
                                                 onChange={(e) => this.setState({susceptibility: 'HIGH'})}
                                                 checked={this.state.susceptibility === 'HIGH'}/>
                                </Label> Podatna<br/>
                                <Label>
                                    <RadioButton value="MEDIUM" name="medium"
                                                 onChange={(e) => this.setState({susceptibility: 'MEDIUM'})}
                                                 checked={this.state.susceptibility === 'MEDIUM'}/>
                                </Label> Średnio podatna<br/>
                                <Label>
                                    <RadioButton value="LOW" name="medium"
                                                 onChange={(e) => this.setState({susceptibility: 'LOW'})}
                                                 checked={this.state.susceptibility === 'LOW'}/>
                                </Label> Średnio odporna<br/>
                            </FormGroup>
                        </Form>
                    }
                </Dialog>
            </Container>
        )
    }

}

export default withTranslation('negfry')(PotatoVarieties);
