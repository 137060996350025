const config = {
    appName: 'KPODR',
    apiServerUrl: process.env.REACT_APP_API_SERVER_URL,
    apiUrl: process.env.REACT_APP_API_URL,
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    anonymousUserLogin: process.env.REACT_APP_ANONYMOUS_USER_LOGIN,
    anonymousUserSecret: process.env.REACT_APP_ANONYMOUS_USER_SECRET
};

export default config;