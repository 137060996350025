import ApiService from "../../../service/common/ApiService";

export default class StationsSettingsService {

    getStationSettingsFormDTO(id) {
        return ApiService.get('/settings/stations/form/'+id);
    }

    saveStation(id,dto) {
        return ApiService.post('/settings/stations/form/'+id, dto);
    }
}