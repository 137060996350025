export const calendarEn = {
    firstDayOfWeek: 1,
    dayNames: ["sunday", "monday", "tuesday", "wednesday", "thursday", "friday", "saturday"],
    dayNamesShort: ["sun", "mon", "tue", "wed", "thu", "fri", "sat"],
    dayNamesMin: ["S", "M", "Tu", "W", "Th", "F", "Sat"],
    monthNames: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthNamesShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    today: 'Today',
    clear: 'Clear',
    dateFormat: 'yyyy-mm-dd',
    weekHeader: 'week'
};