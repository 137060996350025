import React from 'react';
import {Container} from "reactstrap";
import './NegFry.css'
import AuthActions from "../auth/AuthActions";
import NegFryForm from "./Form/NegFryForm";

class NegFry extends React.Component {
    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();
        return (<>
            <Container className='negfry'>
                <article className='p-4' style={{textAlign: 'justify'}}>
                    <h2 className='mt-4 mb-4'>SWD NegFry</h2>
                    <section>
                        <p>
                            Zaraza ziemniaka zaliczana jest do grupy najgroźniejszych chorób ziemniaka, która
                            może
                            spowodować nawet całkowitą utratę plonu. Jedną z metod zwalczania zarazy jest
                            stosowanie
                            według zaleceń systemów decyzyjnych, które uwzględniają wpływ warunków
                            meteorologicznych na
                            rozwój zarazy. Przykładem takiego systemu wspomagającego podejmowanie decyzji jest
                            opracowany w Danii w latach 1992-1996 NegFry. Jest to połączenie dwóch modeli
                            prognostycznych: Ullrich`a i Schrödtera (prognoza negatywna) oraz modelu Frya.
                            NegFry
                            pozwala na określenie przewidywanego terminu wystąpienia zarazy ziemniaka, a tym
                            samym
                            wyznaczenia daty przeprowadzenia zabiegów ochronnych.
                        </p>
                    </section>
                    <section>
                        <p>
                            System ten przeprowadza kalkulację dziennej wartości ryzyka, które określane jest na
                            podstawie wyników pomiarów temperatury powietrza, wilgotności względnej powietrza
                            oraz
                            opadów deszczu. Pomiary rozpoczyna się od wyznaczenia daty wschodów ziemniaka, a
                            dane
                            meteorologiczne są pobierane z najbliższej stacji meteo.
                        </p>
                    </section>
                    <hr/>
                    <section>
                        {!externalUserLogged && <p>
                            W celu skorzystania z systemy wspomagania decyzji NegFry należy założyć indywidualne
                            konto,
                            a następnie zalogować się do portalu.
                        </p>}
                        {externalUserLogged && <NegFryForm/>}
                    </section>
                </article>
            </Container>
            </>
        );
    }
}

export default NegFry;
