import React from 'react';
import {Calendar} from "primereact/calendar";
import {RadioButton} from "primereact/radiobutton";
import {Button, Label} from "reactstrap";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import {withTranslation} from "react-i18next";
import './Eyespot.css';
import moment from "moment";
import Alert from "reactstrap/es/Alert";
import {Dropdown} from "primereact/dropdown";
import VarietiesService from "../Varieties/VarietiesService";
import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import DiseaseBaseForm from "../DiseaseBaseForm/DiseaseBaseForm";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {orderBy} from "lodash"
import {Link} from "react-router-dom";

const emptyVariety = {
    value: null
};

class Eyespot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            date: null,
            validateDate: true,
            question2: null,
            validate2: true,
            question3: null,
            validate3: true,
            question4: null,
            validate4: true,
            question5: null,
            validate5: true,
            customVariety: '',
            customVarietyType: 'SPRING',
            customVarietyPoint: null,
            result: null,
            errors: false,
            varietes: [],
            variety: emptyVariety,
            validateVariety: true,
            sort: {
                order: 1,
                field: 'name'
            }
        };
        this.handleCalc = this.handleCalc.bind(this);
        this.calculate = this.calculate.bind(this);
        this.varietiesService = new VarietiesService();
        this.diseaseBaseForm = React.createRef();
    }

    componentDidMount() {
        this.varietiesService.getAllBaseAndUser()
            .then(res => {
                this.setState({
                    varietes: res
                })
            });
    }

    handleCalc() {
        this.setState(state => this.calculate(state));
    }

    calculateDate(date) {
        const momDate = moment(date);
        let cond = moment(momDate.year() + "-10-05");
        if (momDate.isBefore(cond)) {
            return 4;
        }
        cond = moment(momDate.year() + "-10-15");
        if (momDate.isBefore(cond)) {
            return 3;
        }
        cond = moment(momDate.year() + "-10-25");
        if (momDate.isBefore(cond)) {
            return 2;
        }
        return 1;
    }

    calculate(state) {
        const data = Object.assign({}, state);
        data.errors = false;
        if (data.date === null || data.date === '') {
            data.validateDate = false;
            data.errors = true;
        } else {
            data.validateDate = true;
        }
        if (data.question2 === null) {
            data.validate2 = false;
            data.errors = true;
        } else {
            data.validate2 = true;
        }
        if (data.question3 === null) {
            data.validate3 = false;
            data.errors = true;
        } else {
            data.validate3 = true;
        }
        if (data.question4 === null) {
            data.validate4 = false;
            data.errors = true;
        } else {
            data.validate4 = true;
        }
        if (data.question5 === null) {
            data.validate5 = false;
            data.errors = true;
        } else {
            data.validate5 = true;
        }
        if (data.variety.value === null
            || (data.variety.value === -1 && (data.customVariety === null || data.customVariety === '' || data.customVarietyType === null || data.customVarietyType === '' || (data.customVarietyPoint < 1 && data.customVarietyPoint > 5)))) {
            data.validateVariety = false;
            data.errors = true;
        } else {
            data.validateVariety = true;
        }
        if (data.errors) {
            return {
                errors: data.errors,
                validateDate: data.validateDate,
                validate2: data.validate2,
                validate3: data.validate3,
                validate4: data.validate4,
                validate5: data.validate5,
                validate6: data.validate6,
                validateVariety: data.validateVariety,
                result: null
            }
        }
        const varietyPoints = data.variety.value !== -1 ? data.variety.value : data.customVarietyPoint;
        let sum = this.calculateDate(data.date) + varietyPoints + data.question2 + data.question3 + data.question4 + data.question5;
        let resultColor = sum <= 17 ? 'success' : sum <= 21 ? 'warning' : 'danger';
        return {result: sum, resultColor, errors: false, validateVariety: data.validateVariety}
    }

    describeResult() {
        const {result} = this.state;
        if (result < 18) {
            return this.props.t('grains:eyespot-result-not-necessary');
        } else if (result >= 18 && result <= 21) {
            return this.props.t('grains:eyespot-result-recomended');
        } else if (result > 21) {
            return this.props.t('grains:eyespot-result-necessary');
        }
    }

    handleWheatVarietySortChange = (sortField) => {
        this.setState(state => {
            const newSort = Object.assign({}, state.sort);
            newSort.field = sortField;
            if (state.sort.field === sortField) {
                newSort.order = newSort.order * (-1);
            } else {
                newSort.order = 1;
            }
            return {
                sort: {
                    field: newSort.field,
                    order: newSort.order
                }
            };
        })
    };

    render() {
        const types = [
            {label: this.props.t('grains:wheat-variety-type-spring'), value: 'SPRING'},
            {label: this.props.t('grains:wheat-variety-type-winter'), value: 'WINTER'}
        ];
        const eyespot34 = [
            {label: '3', value: 3},
            {label: '4', value: 4}
        ];
        const eyespot1 = [
            {label: '1', value: 1},
        ];
        const varietesOptions = [];
        varietesOptions.push({label: this.props.t('grains:wheat-variety-own'), value: -1});
        const varietesOrdered = orderBy(this.state.varietes, [this.state.sort.field, 'name'], [this.state.sort.order === 1 ? 'asc' : 'desc', 'asc']);
        varietesOrdered
            .map(item => {
                const grains = this.props.t('grain-type-' + item.type.toLowerCase());
                item.value = item.eyespot;
                item.label = item.name + ' (' + grains + ') - ' + item.eyespot + ' ' + this.props.t('grains:wheat-variety-points')  + (item.userVariety ? ' - ' + this.props.t('grains:own'): '');;
                item.typeLabel = grains;
                return item;
            })
            .forEach(opt => varietesOptions.push(opt));
        return (
            <article className='calc'>
                <h3>{this.props.t('grains:treatment-calculator')}</h3>
                <DiseaseBaseForm stationDisabled={true} ref={this.diseaseBaseForm}/>
                <section>
                    <h4>{this.props.t('grains:sowing-date')}</h4>
                    <Calendar id='question1' value={this.state.date} onChange={(e) => this.setState({date: e.value})}
                              dateFormat='dd-mm-yy' showIcon={true}
                              locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}
                              className={this.state.validateDate ? '' : 'is-invalid'}/>
                    <div className="invalid-feedback">
                        {this.props.t('grains:sowing-date-required')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('grains:crop-infestation')}</h4>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question2" checked={this.state.question2 === 1}
                                     onChange={() => this.setState({question2: 1})}/>
                        <span>{this.props.t('grains:light-to-medium-soil')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question2" checked={this.state.question2 === 2}
                                     onChange={() => this.setState({question2: 2})}/>
                        <span>{this.props.t('grains:medium-heavy-to-heavy-rarely-soil')}</span>
                    </Label>
                    <Label className={this.state.validate2 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question2" checked={this.state.question2 === 4}
                                     onChange={() => this.setState({question2: 4})}/>
                        <span>{this.props.t('grains:medium-heavy-to-heavy-often-soil')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('common:option-required')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('grains:sowing-density')}</h4>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question3" checked={this.state.question3 === 1}
                                     onChange={() => this.setState({question3: 1})}/>
                        <span>{this.props.t('grains:sowing-density-small')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question3" checked={this.state.question3 === 2}
                                     onChange={() => this.setState({question3: 2})}/>
                        <span>{this.props.t('grains:sowing-density-medium')}</span>
                    </Label>
                    <Label className={this.state.validate3 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question3" checked={this.state.question3 === 4}
                                     onChange={() => this.setState({question3: 4})}/>
                        <span>{this.props.t('grains:sowing-density-large')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('common:option-required')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('grains:determine-winter-weather')}</h4>
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question4" checked={this.state.question4 === 1}
                                     onChange={() => this.setState({question4: 1})}/>
                        <span>{this.props.t('grains:determine-winter-weather-heavy')}</span>
                    </Label>
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="2" name="question4" checked={this.state.question4 === 2}
                                     onChange={() => this.setState({question4: 2})}/>
                        <span>{this.props.t('grains:determine-winter-weather-average')}</span>
                    </Label>
                    <Label className={this.state.validate4 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="4" name="question4" checked={this.state.question4 === 4}
                                     onChange={() => this.setState({question4: 4})}/>
                        <span>{this.props.t('grains:determine-winter-weather-mild')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('common:option-required')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('grains:forecrop')}</h4>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="1" name="question5" checked={this.state.question5 === 1}
                                     onChange={() => this.setState({question5: 1})}/>
                        <span>{this.props.t('grains:forecrop-two-weeding')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="5" name="question5" checked={this.state.question5 === 5}
                                     onChange={() => this.setState({question5: 5})}/>
                        <span>{this.props.t('grains:forecrop-one-weeding-multiple')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="6" name="question5" checked={this.state.question5 === 6}
                                     onChange={() => this.setState({question5: 6})}/>
                        <span>{this.props.t('grains:forecrop-one-weeding-rye')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="7" name="question5" checked={this.state.question5 === 7}
                                     onChange={() => this.setState({question5: 7})}/>
                        <span>{this.props.t('grains:forecrop-one-selfseeding')}</span>
                    </Label>
                    <Label className={this.state.validate5 ? '' : 'form-control is-invalid'}>
                        <RadioButton value="8" name="question5" checked={this.state.question5 === 8}
                                     onChange={() => this.setState({question5: 8})}/>
                        <span>{this.props.t('grains:forecrop-one-wheat')}</span>
                    </Label>
                    <div className="invalid-feedback">
                        {this.props.t('common:option-required')}
                    </div>
                </section>
                <section>
                    <h4>{this.props.t('grains:wheat-variety')}</h4>
                    <p className='question-description'>{this.props.t('grains:wheat-variety-desc')}</p>
                    <p className='question-sort'><span>{this.props.t('common:sort')}: </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('name');
                                 }}
                                 className={this.state.sort.field === 'name' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:name').toLowerCase()}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'name' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>, </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('type');
                                 }}
                                 className={this.state.sort.field === 'type' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:type')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'type' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>, </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('eyespot');
                                 }}
                                 className={this.state.sort.field === 'eyespot' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('common:susceptibility')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'eyespot' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>,
                        </span>
                        <span><a href="#"
                                 onClick={e => {
                                     e.preventDefault();
                                     this.handleWheatVarietySortChange('userVariety');
                                 }}
                                 className={this.state.sort.field === 'userVariety' ? 'grains-wheat-variety-sort-active' : ''}>{this.props.t('grains:user-variety')}
                            <FontAwesomeIcon
                                icon={this.state.sort.field !== 'userVariety' ? 'sort' : (this.state.sort.order === 1 ? 'sort-up' : 'sort-down')}/></a>
                        </span>
                    </p>
                    <Dropdown value={this.state.variety}
                              options={varietesOptions}
                              optionLabel="label"
                              onChange={(e) => this.setState({variety: e.value === null ? emptyVariety : e.value})}
                              filter={true}
                              filterPlaceholder={this.props.t('grains:wheat-variety-filter')}
                              filterBy="name"
                              required={true}
                              className={this.state.validateVariety ? '' : 'is-invalid'}
                              showClear={true}/>
                    <Link to="/odmianyzboza">{this.props.t('common:my-varieties')}</Link>
                    {this.state.variety.value === -1 &&
                    <Label for='customVariety' className='mt-2'>{this.props.t('grains:wheat-variety-name')}</Label>}
                    {this.state.variety.value === -1 &&
                    <InputText name='customVariety'
                               value={this.state.customVariety}
                               className={this.state.validateVariety ? '' : 'form-control  is-invalid'}
                               onChange={e => {
                                   this.setState({customVariety: e.target.value})
                               }}/>}

                    {this.state.variety.value === -1 &&
                    <Label for='customVarietyType' className='mt-2'>{this.props.t('grains:wheat-variety-type')}</Label>}
                    {this.state.variety.value === -1 &&
                    <SelectButton name='customVarietyType' value={this.state.customVarietyType}
                                  className={this.state.validateVariety ? '' : 'form-control is-invalid'}
                                  options={types}
                                  onChange={e => {
                                      let customVarietyPoints = e.value === 'WINTER' ? 1 : 3;
                                      this.setState({
                                          customVarietyType: e.value,
                                          customVarietyPoints: customVarietyPoints
                                      })
                                  }}
                    />}

                    {this.state.variety.value === -1 &&
                    <Label for='customVarietyPoint' className='mt-2'>{this.props.t('grains:wheat-variety-number-of-points')}</Label>}
                    {this.state.variety.value === -1 && this.state.customVarietyType === 'WINTER' &&
                    <SelectButton name='customVarietyPoint' value={this.state.customVarietyPoints}
                                  className={this.state.validateVariety ? '' : 'form-control is-invalid'}
                                  options={eyespot34}
                                  onChange={(e) => {
                                      this.setState({customVarietyPoints: e.value})
                                  }}
                    />}
                    {
                        this.state.variety.value === -1 && this.state.customVarietyType === 'SPRING' &&
                        <SelectButton name='customVarietyPoint'
                                      value={this.state.customVarietyPoints}
                                      options={eyespot1}
                                      disabled={true}
                        />
                    }
                    <div className="invalid-feedback">
                        {this.props.t('common:option-required')}
                    </div>
                </section>
                <section>
                    <Button onClick={this.handleCalc} color='primary'>{this.props.t('common:calculate')}</Button>
                </section>
                {
                    this.state.result && <section>
                        <h4>{this.props.t('common:result')}</h4>
                        <Alert color={this.state.resultColor}>
                            {this.describeResult()}
                        </Alert>
                    </section>
                }
                {
                    this.state.errors && <section>
                        <h4>{this.props.t('common:all-answers-needed')}</h4>
                    </section>
                }
            </article>
        )
    }

}

export default withTranslation('meteo')(Eyespot);
