import React from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Col, Form, FormGroup, Input, Label, Row} from "reactstrap";
import MyFieldService from "../../myfield/MyFieldService";
import {InputText} from "primereact/inputtext";
import PotatoVarietyService from "../PotatoVariety/PotatoVarietyService";
import {Dropdown} from "primereact/dropdown";
import {Calendar} from "primereact/calendar";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import MeteoService from "../../map/MeteoService";
import {InputTextarea} from "primereact/inputtextarea";
import './NegFryForm.css';
import {isEmpty} from "lodash";
import NegFryFieldService from "./NegFryFieldService";
import {Growl} from "primereact/growl";
import moment from "moment";
import {Dialog} from "primereact/dialog";
import NegFryService from "./NegFryService";
import NegFryResult from "../Result/NegFryResult";
import Commons from "../../../common/Commons";
import ListGroup from "reactstrap/es/ListGroup";
import ListGroupItem from "reactstrap/es/ListGroupItem";

class NegFryForm extends React.Component {

    constructor(props) {
        super(props);
        this.defaultStations = JSON.parse(localStorage.getItem('default_stations'));
        this.state = {
            selectedMyField: null,
            fields: [],
            potatoVarieties: [],
            stations: [],
            uuid: null,
            selectedField: '',
            selectedVariety: null,
            startDate: null,
            endDate: null,
            selectedStation: this.defaultStations && this.defaultStations.length === 1 ? this.defaultStations[0] : '',
            comment: '',
            fieldName: '',
            fieldCity: '',
            fieldArea: '',
            validEndDate: true,
            validStartDate: true,
            validFieldName: true,
            validFieldCity: true,
            validFieldArea: true,
            validVariety: true,
            validStation: true,
            hasErrors: false,
            negFryFields: [],
            visible: false,
            calcResult: null
        };
        this.fieldService = new MyFieldService();
        this.potatoVarietyService = new PotatoVarietyService();
        this.meteoService = new MeteoService();
        this.negFryFieldService = new NegFryFieldService();
        this.negFryService = new NegFryService();
        this.calcToToday = this.calcToToday.bind(this);
        this.save = this.save.bind(this);
        this.validate = this.validate.bind(this);
        this.selectField = this.selectField.bind(this);
        this.getNegFryFieldForField = this.getNegFryFieldForField.bind(this);
        this.delete = this.delete.bind(this);

        this.growl = React.createRef();
    }

    componentDidMount() {
        this.fieldService.getAll()
            .then(res => this.setState({fields: res}));
        this.potatoVarietyService.getAll()
            .then(res => this.setState({potatoVarieties: res}));
        this.meteoService.getAllStations()
            .then(res => this.setState({stations: res}));
        this.negFryFieldService.getAll()
            .then(res => this.setState({negFryFields: res}));
    }

    delete() {
        if (this.state.uuid) {
            this.negFryFieldService.delete(this.state.uuid)
                .then(rest => {
                    this.setState(state => {
                        let negFryFields = state.negFryFields.filter(f => f.uuid !== this.state.uuid);
                        return {negFryFields: negFryFields, visible: false};
                    });
                    this.growl.current.show({severity: 'info', summary: 'Usunięcie udane'});
                })
                .catch(err => {
                    console.error(err);
                    this.growl.current.show({severity: 'error', summary: 'Usunięcie nieudane'});
                })
        }
    }

    selectField(field) {
        this.fieldService.getById(field)
            .then(data => {
                let newState = {
                    selectedMyField: data,
                    selectedField: field,
                    uuid: null,
                    startDate: null,
                    endDate: null,
                    comment: '',
                    selectedVariety: null,
                    selectedStation: this.defaultStations && this.defaultStations.length === 1 ? this.defaultStations[0] : ''
                };
                if (field) {
                    let negFryField = this.getNegFryFieldForField(field);
                    if (negFryField) {
                        newState = {
                            selectedMyField: data,
                            selectedField: field,
                            uuid: negFryField.uuid,
                            startDate: new Date(negFryField.startDate),
                            endDate: new Date(negFryField.endDate),
                            comment: negFryField.comment,
                            selectedVariety: negFryField.potatoVariety ? negFryField.potatoVariety.uuid : null,
                            selectedStation: negFryField.station ? negFryField.station.uuid : ''
                        };
                    } else {
                        newState.selectedStation = this.state.fields.find(item => item.uuid === field).meteoStationId;
                    }
                }
                this.setState(newState);
            })
            .catch(function (error) {
                console.log(error);
            })
            .finally(() => {

            })
    }

    getNegFryFieldForField(field) {
        let negFryFieldsForField = this.state.negFryFields
            .filter(negFryField => negFryField.field.uuid === field)
            .sort((a, b) => new Date(b.endDate) - new Date(a.endDate));
        return negFryFieldsForField[0];
    }

    calcToToday() {
        let validationResult = this.validate();
        if (validationResult.hasErrors) {
            this.setState({
                hasErrors: validationResult.hasErrors,
                validStartDate: validationResult.validStartDate,
                validEndDate: validationResult.validEndDate,
                validStation: validationResult.validStation,
                validVariety: validationResult.validVariety,
                validFieldArea: validationResult.validFieldArea,
                validFieldCity: validationResult.validFieldCity,
                validFieldName: validationResult.validFieldName,
                calcResult: null
            });
        } else {
            this.negFryService.calc(
                {
                    uuid: this.state.uuid,
                    fieldUuid: this.state.selectedField,
                    fieldName: this.state.fieldName,
                    fieldCity: this.state.fieldCity,
                    fieldArea: this.state.fieldArea,
                    potatoVarietyUuid: this.state.selectedVariety,
                    startDate: this.state.startDate,
                    endDate: this.state.endDate,
                    stationUuid: this.state.selectedStation,
                    comment: this.state.comment
                }
            ).then(res =>
                this.setState({
                    hasErrors: validationResult.hasErrors,
                    validStartDate: validationResult.validStartDate,
                    validEndDate: validationResult.validEndDate,
                    validStation: validationResult.validStation,
                    validVariety: validationResult.validVariety,
                    validFieldArea: validationResult.validFieldArea,
                    validFieldCity: validationResult.validFieldCity,
                    validFieldName: validationResult.validFieldName,
                    calcResult: res
                }));
        }
    }

    save() {
        let validationResult = this.validate();
        if (validationResult.hasErrors) {
            this.setState({
                hasErrors: validationResult.hasErrors,
                validStartDate: validationResult.validStartDate,
                validEndDate: validationResult.validEndDate,
                validStation: validationResult.validStation,
                validVariety: validationResult.validVariety,
                validFieldArea: validationResult.validFieldArea,
                validFieldCity: validationResult.validFieldCity,
                validFieldName: validationResult.validFieldName
            });
        } else if (this.state.uuid) {
            this.negFryFieldService.update({
                uuid: this.state.uuid,
                fieldUuid: this.state.selectedField,
                fieldName: this.state.fieldName,
                fieldCity: this.state.fieldCity,
                fieldArea: this.state.fieldArea,
                potatoVarietyUuid: this.state.selectedVariety,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                stationUuid: this.state.selectedStation,
                comment: this.state.comment
            }).then(res => {
                this.setState(state => {
                    let negFryFields = state.negFryFields.filter(f => f.uuid !== res.uuid);
                    negFryFields.push(res);
                    return {negFryFields: negFryFields}
                });
                this.growl.current.show({severity: 'info', summary: 'Zapisano formularz'});
            }).catch(err => {
                console.error(err);
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
            })
        } else {
            this.negFryFieldService.create({
                fieldUuid: this.state.selectedField,
                fieldName: this.state.fieldName,
                fieldCity: this.state.fieldCity,
                fieldArea: this.state.fieldArea,
                potatoVarietyUuid: this.state.selectedVariety,
                startDate: this.state.startDate,
                endDate: this.state.endDate,
                stationUuid: this.state.selectedStation,
                comment: this.state.comment
            }).then(res => {
                this.setState(state => {
                    let negFryFields = [...state.negFryFields];
                    negFryFields.push(res);
                    return {negFryFields: negFryFields}
                });
                this.growl.current.show({severity: 'info', summary: 'Zapisano formularz'});
            }).catch(err => {
                console.error(err);
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
            })
        }

    }

    validate() {
        let validationResult = {
            hasErrors: false,
            validFieldName: true,
            validFieldCity: true,
            validFieldArea: true,
            validVariety: true,
            validStation: true,
            validStartDate: true,
            validEndDate: true
        };
        if (!this.state.selectedField) {
            if (isEmpty(this.state.fieldName)) {
                validationResult.hasErrors = true;
                validationResult.validFieldName = false;
            }
            if (isEmpty(this.state.fieldCity)) {
                validationResult.hasErrors = true;
                validationResult.validFieldCity = false;
            }
            if (isEmpty(this.state.fieldArea)) {
                validationResult.hasErrors = true;
                validationResult.validFieldArea = false;
            }
        }
        if (!this.state.selectedVariety) {
            validationResult.hasErrors = true;
            validationResult.validVariety = false;
        }
        if (!this.state.selectedStation) {
            validationResult.hasErrors = true;
            validationResult.validStation = false;
        }
        if (!this.state.startDate) {
            validationResult.hasErrors = true;
            validationResult.validStartDate = false;
        }
        if (!this.state.endDate) {
            validationResult.hasErrors = true;
            validationResult.validEndDate = false;
        }
        return validationResult;
    }

    irrigation(irrigations) {

    }

    render() {
        const stationOptions2 = Commons.buildStationOptions(this.state.stations, this.defaultStations, (a, b) => this.props.t('meteo:' + a, b));
        const fieldOptions = [{value: '', label: 'Nowe pole'}];
        this.state.fields
            .sort(this.compareField)
            .map(field => {
                let negFryField = this.getNegFryFieldForField(field.uuid);
                let label = field.name + ', ' + field.city;
                if (negFryField) {
                    label += ' - ' + negFryField.potatoVariety.name + ', termin śledzenia ' + moment(negFryField.endDate).format('DD-MM-YYYY');
                }
                return {value: field.uuid, label: label}
            })
            .forEach(opt => fieldOptions.push(opt));
        const varietyOptions = this.state.potatoVarieties.map(variety => {
            return {
                value: variety.uuid,
                label: variety.name + (variety.susceptibility === 'HIGH' ? ' - podatna' : variety.susceptibility === 'MEDIUM' ? ' - średnio podatna' : ' - srednio odporna')
            }
        });

        const dialogFooter = (
            <div>
                <Button color='primary' type='button' onClick={this.delete}>
                    Tak
                </Button>
                <Button color='primary' type='button' onClick={() => this.setState({visible: false})}>
                    Nie
                </Button>
            </div>
        );

        return (
            <div>
                <Growl ref={this.growl}/>
                <Form className='neg-fry-form'>
                    <Row>
                        <Col sm='6'>
                            <FormGroup>
                                <h4><Label for="field">{this.props.t('grains:field')}</Label></h4>
                                <Dropdown id="field" value={this.state.selectedField} options={fieldOptions}
                                          onChange={e => this.selectField(e.value)}/>
                            </FormGroup>
                            {!this.state.selectedField && <FormGroup>
                                <Label for='fieldName' className='mt-2'>Nazwa pola</Label><br/>
                                <InputText name='fieldName' value={this.state.fieldName}
                                           onChange={e => this.setState({fieldName: e.target.value})}
                                           className={this.state.validFieldName ? '' : 'form-control is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>}
                            {!this.state.selectedField && <FormGroup>
                                <Label for='fieldCity' className='mt-2'>{this.props.t('grains:field-city')}</Label><br/>
                                <InputText name='fieldCity' value={this.state.fieldCity}
                                           onChange={e => this.setState({fieldCity: e.target.value})}
                                           className={this.state.validFieldCity ? '' : 'form-control is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>}
                            {!this.state.selectedField && <FormGroup>
                                <Label for='fieldArea' className='mt-2'>{this.props.t('grains:field-area')}</Label><br/>
                                <InputText name='fieldArea' value={this.state.fieldArea} keyfilter='pnum'
                                           onChange={e => this.setState({fieldArea: e.target.value})}
                                           className={this.state.validFieldArea ? '' : 'form-control is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>}

                            <FormGroup>
                                <h4><Label for="variety">Odmiana ziemniaka</Label></h4>
                                <Dropdown id="variety" value={this.state.selectedVariety}
                                          filter={true}
                                          options={varietyOptions}
                                          onChange={e => this.setState({selectedVariety: e.value})}
                                          className={this.state.validVariety ? '' : 'is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h4><Label for="startDate">Termin wschodów</Label></h4>
                                <Calendar id='startDate' value={this.state.startDate}
                                          onChange={(e) => this.setState({startDate: e.value})}
                                          dateFormat='dd-mm-yy' showIcon={true}
                                          locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}
                                          className={this.state.validStartDate ? '' : 'is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h4><Label for="endDate">Ostatni dzień śledzenia modelu</Label></h4>
                                <Calendar id='endDate' value={this.state.endDate}
                                          onChange={(e) => this.setState({endDate: e.value})}
                                          dateFormat='dd-mm-yy' showIcon={true}
                                          locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}
                                          className={this.state.validEndDate ? '' : 'is-invalid'}/>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h4><Label for='station'>Stacja meteorologiczna</Label></h4>
                                <Input id="station" type="select" name="station"
                                       value={this.state.selectedStation}
                                       className={this.state.validStation ? 'p-component p-dropdown' : 'p-component p-dropdown is-invalid'}
                                       onChange={e => this.setState({selectedStation: e.target.value})}>
                                    <option value="">---</option>
                                    {stationOptions2}
                                </Input>
                                <div className="invalid-feedback">
                                    Pole wymagane
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <h4><Label for='station'>Obserwacje/komentarz</Label></h4>
                                <InputTextarea value={this.state.comment}
                                               onChange={e => this.setState({comment: e.target.value})}/>
                            </FormGroup>
                            <FormGroup>
                                <Button type='button' color='primary' onClick={this.calcToToday}
                                        className='mr-3 mt-2'>Oblicz</Button>
                                <Button type='button' color='primary' onClick={this.save} className='mr-3 mt-2'>Zapisz
                                    formularz</Button>
                                <Button type='button' color='primary' onClick={() => this.setState({visible: true})}
                                        disabled={!this.state.uuid} className='mr-3 mt-2'>Usuń formularz</Button>
                            </FormGroup>
                        </Col>
                        <Col sm='6'>
                            {this.state.selectedField &&
                            <div>
                                <h4><b>{this.props.t('field:my-field-details')}</b></h4> <br/>
                                {this.props.t('common:name')}: <b>{this.state.selectedMyField.name}</b><br/>
                                {this.props.t('common:description')}: <b>{this.state.selectedMyField.name}</b><br/>
                                {this.props.t('field:city')}: <b>{this.state.selectedMyField.city}</b><br/>
                                {this.props.t('field:field-size-descr')}: <b>{this.state.selectedMyField.fieldSize} ha</b><br/>
                                {this.props.t('station:station')}: <b>{this.state.selectedMyField.meteoStation}</b><br/><br/>
                                <h4><b>{this.props.t('field:irrigation-ops')}</b></h4>
                                <ListGroup>
                                    <ListGroupItem>
                                        {this.props.t('common:date')} | {this.props.t('field:amount-of-water')}
                                    </ListGroupItem>
                                    {this.state.selectedMyField.irrigations.map(
                                        (irrigation) => (
                                            <ListGroupItem>
                                                {irrigation.date} | {irrigation.amount} mm
                                            </ListGroupItem>
                                        ))
                                    }
                                </ListGroup>
                                <br/>
                                <h4><b>{this.props.t('field:protection-ops')}</b></h4>
                                <ListGroup>
                                    <ListGroupItem>
                                        {this.props.t('common:date')}
                                    </ListGroupItem>
                                    {this.state.selectedMyField.treatments
                                        .filter(value => {
                                            return value.appliesToPotatoes;
                                        })
                                        .map(
                                            (treatment) => (
                                                <ListGroupItem>
                                                    {treatment.date}
                                                </ListGroupItem>
                                            ))
                                    }
                                </ListGroup>
                            </div>
                            }
                        </Col>
                    </Row>
                </Form>
                <Dialog header="{this.props.t('common:delete-confirmation')}" visible={this.state.visible}
                        footer={dialogFooter}
                        style={{width: '560px'}} modal={true} onHide={() => this.setState({visible: false})}>
                    Czy na pewno chcesz usunąć zapisany formularz?
                </Dialog>
                {this.state.calcResult && <NegFryResult result={this.state.calcResult}/>}
            </div>
        )
    }

}

export default withTranslation(['negfry', 'grains', 'meteo', 'common', 'station'])(NegFryForm);
