import React from 'react';
import {withTranslation} from "react-i18next";
import {Container, Form, FormGroup, Label} from "reactstrap";
import MessageService from "./MessageService";
import {DataTable} from "primereact/datatable";
import Button from "reactstrap/es/Button";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Calendar} from "primereact/calendar";
import Commons from "../../../common/Commons";
import {Dropdown} from "primereact/dropdown";
import {InputText} from "primereact/inputtext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Growl} from "primereact/growl";
import moment from "moment";
import "./AdminNews.css"
import {SelectButton} from "primereact/selectbutton";
import {InputTextarea} from "primereact/inputtextarea";

class AdminNews extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            selected: null,
            levels: [],
            validateType: true,
            validateMsgPl: true,
            validateMsgEn: true,
            validationErrors: false,
            selectedType: null
        };
        this.service = new MessageService();
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.service.getAll()
            .then(data => {
                data.forEach(item => {
                    if (item.activeTo !== undefined && item.activeTo !== null) {
                        item.activeTo = new Date(item.activeTo);
                    }
                });
                this.setState({messages: data});
            });
        this.service.getMessageTypes()
            .then(data => {
                this.setState({levels: data});
            })
    }

    addNew() {
        this.setState({
            message: {
                description: '',
                type: '',
                activeTo: '',
                msgPl: '',
                msgEn: '',
            },
            displayDialog: true
        });
    }

    updateProperty(property, value) {
        let message = this.state.message;
        message[property] = value;
        this.setState({message});
    }

    save = () => {
        let message = this.state.message;
        let validationErrors = false;
        let validateType = true;
        if (message.type === null || message.type === '') {
            validateType = false;
            validationErrors = true;
        }
        let validateMsgPl = true;
        if (message.msgPl === null || message.msgPl === '') {
            validateMsgPl = false;
            validationErrors = true;
        }
        let validateMsgEn = true;
        if (message.msgEn === null || message.msgEn === '') {
            validateMsgEn = false;
            validationErrors = true;
        }
        if (validationErrors) {
            this.setState({
                validationErrors,
                validateType,
                validateMsgPl,
                validateMsgEn
            })
        } else {
            message.activeTo = new Date(Date.UTC(message.activeTo.getFullYear(), message.activeTo.getMonth(), message.activeTo.getDate()));
            if (message.uuid) {
                this.update(message);
            } else {
                this.create(message);
            }
        }
    };

    create(message) {
        this.service.create(message)
            .then(res => {
                this.setState(state => {
                    let messages = [...state.messages];
                    if (res.activeTo !== undefined && res.activeTo !== null) {
                        res.activeTo = new Date(res.activeTo);
                    }
                    messages.push(res);
                    return {
                        messages,
                        selected: null,
                        message: null,
                        displayDialog: false,
                        validateType: true,
                        validateMsgPl: true,
                        validateMsgEn: true,
                        validationErrors: false
                    }
                });
                this.growl.current.show({severity: 'success', summary: 'Dodano nowy komunikat.'});
            }).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się dodać komunikat.'});
        })
    }

    update(message) {
        this.service.update(message.uuid, message)
            .then((data) => {
                    this.setState(state => {
                        let messages = [...state.messages];
                        let index = state.messages.indexOf(state.selected);
                        if (data.activeTo !== undefined && data.activeTo !== null) {
                            data.activeTo = new Date(data.activeTo);
                        }
                        messages[index] = data;
                        return {
                            messages,
                            selected: null,
                            message: null,
                            displayDialog: false,
                            validateType: true,
                            validateMsgPl: true,
                            validateMsgEn: true,
                            validationErrors: false
                        }
                    });
                    this.growl.current.show({severity: 'success', summary: 'Zmodyfikowano komunikat.'});
                }
            ).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się zmodyfikować komunikatu.'});
        })
    }

    onSelect = (e) => {
        this.setState(() => {
            return {
                displayDialog: true,
                message: Object.assign({}, e.data)
            }
        });
    };

    delete = () => {
        this.service.delete(this.state.selected.uuid)
            .then(() => {
                this.setState(state => {
                    let index = state.messages.indexOf(state.selected);
                    return {
                        messages: state.messages.filter((val, i) => i !== index),
                        selected: null,
                        message: null,
                        displayDialog: false,
                        displayDeleteDialog: false
                    }
                });
            })
    };

    typeTemplate = (rowData, column) => {
        return rowData[column.field] ? <span>{this.props.t('message-level-' + rowData[column.field])}</span> : '';
    };

    activeToTemplate = (rowData, column) => {
        return rowData[column.field] ?
            <span className={!rowData.active ? 'message-not-active' : ''}
                  title={!rowData.active ? 'Komunikat nieaktywny' : ''}>{moment(rowData[column.field]).format('YYYY-MM-DD')}</span> : '';
    };

    handleTypeFilterChange = (event) => {
        this.dt.filter(event.value, 'type', 'equals');
        this.setState({selectedType: event.value});
    };

    render() {
        const dataTableFooter = (
            <div>
                <Button color='primary' onClick={() => this.addNew()}>Dodaj nową wiadomość</Button>
            </div>
        );

        const levelItems = this.state.levels.map(level => {
            return {
                label: this.props.t('message-level-' + level),
                value: level
            }
        });

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button color='primary' onClick={this.save}>
                <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
            </Button>
            {this.state.message && this.state.message.uuid && <Button color='primary' onClick={
                () => this.setState({displayDialog: false, displayDeleteDialog: true})
            }>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({
                validateType: true,
                validateMsgPl: true,
                validateMsgEn: true,
                validationErrors: false,
                displayDialog: false
            })}>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;

        const deleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.state.message && this.state.message.uuid &&
            <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: false})}>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;

        let typeFilter = <SelectButton style={{width: '100%'}}
                                       value={this.state.selectedType} options={levelItems}
                                       onChange={this.handleTypeFilterChange}/>;

        let columns = [];
        columns.push(<Column key="description" sortable={true} field="description"
                             header={this.props.t('common:description')}/>);
        columns.push(<Column key="type" sortable={true} filter={true} filterElement={typeFilter} field="type"
                             body={this.typeTemplate}
                             header="Typ"/>);
        columns.push(<Column key="activeTo" sortable={true} field="activeTo" body={this.activeToTemplate}
                             header="Aktywny do"/>);
        columns.push(<Column key="msgPl" sortable={true} field="msgPl" header="Treść PL"/>);
        columns.push(<Column key="msgEn" sortable={true} field="msgEn" header="Treść EN"/>);

        return <Container>
            <div>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.messages} paginator={true} rows={15}
                           ref={(el) => this.dt = el}
                           footer={dataTableFooter}
                           selectionMode="single" selection={this.state.selected}
                           onSelectionChange={e => this.setState({selected: e.value})}
                           onRowSelect={this.onSelect}>
                    {columns}
                </DataTable>
            </div>

            <Dialog visible={this.state.displayDialog} width="600px" header="Komunikat" modal={true}
                    footer={dialogFooter}
                    onHide={() => this.setState({displayDialog: false})}>
                {
                    this.state.message &&
                    <Form autocomplete="off">
                        <FormGroup>
                            <Label for='description'>{this.props.t('common:description')}</Label><br/>
                            <InputText name='description' type="text"
                                       style={{width: "20em"}}
                                       value={this.state.message.description}
                                       onChange={(e) => {
                                           this.updateProperty("description", e.target.value);
                                       }}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='level'>Typ*</Label><br/>
                            <Dropdown name='level' key="levelDropdown"
                                      value={this.state.message.type}
                                      style={{width: "20em"}}
                                      className={this.state.validateType ? '' : 'form-control is-invalid'}
                                      options={levelItems}
                                      onChange={(e) => {
                                          this.updateProperty("type", e.value);
                                      }} placeholder=" Wybierz typ"/>
                            <div className=" invalid-feedback">
                                {!this.state.validateType &&
                                <span>{this.props.t('common:concrete-input-required', {inputName: "Typ"})}</span>}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for='activeTo'>Aktywny do</Label><br/>
                            <Calendar id=" activeTo"
                                      value={this.state.message.activeTo}
                                      locale={Commons.CALENDAR_PL}
                                      dateFormat={Commons.CALENDAR_PL.dateFormat}
                                      onChange={(e) => {
                                          this.updateProperty("activeTo", e.target.value);
                                      }}
                                      showIcon={true}/>
                        </FormGroup>
                        <FormGroup>
                            <Label for='msgPl'>Komunikat PL*</Label><br/>
                            <InputTextarea rows={5} cols={30}
                                           name='msgPl' type=" text"
                                           style={{width: "20em"}}
                                           className={this.state.validateMsgPl ? '' : 'form-control is-invalid'}
                                           value={this.state.message.msgPl}
                                           onChange={(e) => {
                                               this.updateProperty("msgPl", e.target.value);
                                           }}/>
                            <div className=" invalid-feedback">
                                {!this.state.validateMsgPl &&
                                <span>{this.props.t('common:concrete-input-required', {inputName: " Komunikat PL"})}</span>}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for='msgEn'>Komunikat EN*</Label><br/>
                            <InputTextarea rows={5} cols={30}
                                           name='msgEn' type=" text"
                                           style={{width: "20em"}}
                                           className={this.state.validateMsgEn ? '' : 'form-control is-invalid'}
                                           value={this.state.message.msgEn}
                                           onChange={(e) => {
                                               this.updateProperty("msgEn", e.target.value);
                                           }}/>
                            <div className="invalid-feedback">
                                {!this.state.validateMsgEn &&
                                <span>{this.props.t('common:concrete-input-required', {inputName: " Komunikat EN"})}</span>}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label>{this.props.t('common:legend-required')}</Label>
                        </FormGroup>
                    </Form>
                }
            </Dialog>

            <Dialog visible={this.state.displayDeleteDialog}
                    header="Potwierdzenie usunięcia" footer={deleteFooter} modal={true}
                    onHide={() => this.setState({displayDeleteDialog: false})}>
                {
                    this.state.message && this.state.message.uuid &&
                    <span>Czy na pewno chcesz usunąć komunikat o wiadomości: '{this.state.message.msgPl}'?</span>
                }
            </Dialog>
        </Container>
    }
}

export default withTranslation('admin')(AdminNews);