import React, {Component} from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {applyPrecision, getUnit} from "../../util/MeteoUtil";
import {withTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import * as moment from "moment";
import {isNaN, isNull} from "lodash";

class MapMeteoTable extends Component {

    constructor(props) {
        super(props);
        this.table = React.createRef();
    }

    getTitleMsg(rowData, measurementType, currentMeteoDataIsValid) {
        const notActualMeas = !currentMeteoDataIsValid[rowData['stationUuid']];
        const hasValue = !isNull(rowData[measurementType]) && !isNaN(rowData[measurementType]);
        let titleMsg;
        if (!hasValue || notActualMeas) {
            titleMsg = this.props.t('data-table-no-data-msg')
        } else {
            titleMsg = this.props.t('data-table-actual-msg', {"dateTime": moment(rowData.dateTime).format('YYYY-MM-DD HH:mm ')})
        }
        return titleMsg;
    }

    airTemperatureTemplate = (rowData, column, currentMeteoDataIsValid) => {
        const notActualMeas = !currentMeteoDataIsValid[rowData['stationUuid']];
        const hasValue = !isNull(rowData['airTemperature200']) && !isNaN(rowData['airTemperature200']);
        const titleMsg = this.getTitleMsg(rowData, 'airTemperature200', currentMeteoDataIsValid);
        return <span
            title={titleMsg}>{
            !hasValue || notActualMeas ? '--' :
                applyPrecision(rowData['airTemperature200'], 'airTemperature200')
        }</span>;
    };

    precipitationSumTemplate(rowData, column, currentMeteoDataIsValid) {
        const notActualMeas = !currentMeteoDataIsValid[rowData['stationUuid']];
        const hasValue = !isNull(rowData['precipitationSum']) && !isNaN(rowData['precipitationSum']);
        const titleMsg = this.getTitleMsg(rowData, 'precipitationSum', currentMeteoDataIsValid);
        return <span
            title={titleMsg}>{
            !hasValue || notActualMeas ? '--' :
                applyPrecision(rowData['precipitationSum'], 'precipitationSum')
        }</span>;
    }

    windTemplate(rowData, column, currentMeteoDataIsValid) {
        const notActualMeas = !currentMeteoDataIsValid[rowData['stationUuid']];
        const hasValue = !isNull(rowData['wind']) && !isNaN(rowData['wind']);
        const titleMsg = this.getTitleMsg(rowData, 'wind', currentMeteoDataIsValid);
        return <span
            title={titleMsg}>{
            !hasValue || notActualMeas ? '--' :
                applyPrecision(rowData['wind'], 'wind')
        }</span>;
    }

    airHumidity200Template(rowData, column, currentMeteoDataIsValid) {
        const notActualMeas = !currentMeteoDataIsValid[rowData['stationUuid']];
        const hasValue = !isNull(rowData['airHumidity200']) && !isNaN(rowData['airHumidity200']);
        const titleMsg = this.getTitleMsg(rowData, 'airHumidity200', currentMeteoDataIsValid);
        return <span
            title={titleMsg}>{
            !hasValue || notActualMeas ? '--' :
                applyPrecision(rowData['airHumidity200'], 'airHumidity200')
        }</span>;
    }

    cityTemplate = (rowData, column) => {
        return (
            <Link to={{
                pathname: "/dane/" + rowData.businessId,
                state: {
                    fromMap: true,
                    selectedMeasurementType: this.props.selectedMeasurementType
                }
            }}>
                <span>{rowData[column.field]}</span>
            </Link>
        );
    };

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.table.current.reset();
    }

    render() {
        const {currentMeteoData, currentMeteoDataIsValid, t} = this.props;
        const airTempHeader = t('airTemperature200') + ' [' + getUnit('airTemperature200').name.trim() + ']';
        const precipitationSumHeader = t('precipitationSum') + ' [' + getUnit('precipitationSum').name.trim() + ']';
        const wintHeader = t('wind') + ' [' + getUnit('wind').name.trim() + ']';
        const airHumidity200Header = t('airHumidity200') + ' [' + getUnit('airHumidity200').name.trim() + ']';
        return (
            <DataTable value={currentMeteoData}
                       ref={this.table}
                       responsive={true}
                       sortField="businessId"
                       sortOrder={1}
                       paginator={true}
                       loading={this.props.loadingData}
                       className={"map-current-data-table " + (this.props.i18n.language === "en" ? 'en' : '')}
                       paginatorTemplate="PrevPageLink PageLinks NextPageLink"
                       rows={this.props.rows}>
                <Column field="city" body={this.cityTemplate} header={t('data-table-city')}
                        sortable={true}/>
                <Column field="county" header={t('data-table-county')} sortable={true}/>
                <Column
                    body={(rowData, column) => this.airTemperatureTemplate(rowData, column, currentMeteoDataIsValid)}
                    header={airTempHeader}/>
                <Column
                    body={(rowData, column) => this.precipitationSumTemplate(rowData, column, currentMeteoDataIsValid)}
                    header={precipitationSumHeader}/>
                <Column body={(rowData, column) => this.windTemplate(rowData, column, currentMeteoDataIsValid)}
                        header={wintHeader}/>
                <Column key="airHumidity200"
                        body={(rowData, column) => this.airHumidity200Template(rowData, column, currentMeteoDataIsValid)}
                        header={airHumidity200Header}/>
            </DataTable>
        )
    }
}

export default withTranslation('meteo')(MapMeteoTable);
