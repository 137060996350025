import ApiService from '../../../service/common/ApiService'

class VarietiesService {

    getAll() {
        return ApiService.get('/grains/varieties');
    }

    create(data) {
        return ApiService.post('/grains/varieties', data);
    }

    update(id, data) {
        return ApiService.put('/grains/varieties/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/grains/varieties/' + id);
    }

    getAllBaseAndUser() {
        return ApiService.get('/grains/baseanduservarieties');
    }

    getAllUserVarieties() {
        return ApiService.get('/grains/uservarieties');
    }

    createVariety(data) {
        return ApiService.post('/grains/uservarieties', data);
    }

    updateVariety(id, data) {
        return ApiService.put('/grains/uservarieties/' + id, data);
    }

    deleteVariety(id) {
        return ApiService.delete('/grains/uservarieties/' + id);
    }

}

export default VarietiesService;
