import React from 'react';
import './MainLayoutWrapper.css'
import {Growl} from "primereact/growl";
import {isEmpty} from "lodash";

export default class MessagePanel extends React.Component {

    constructor(props) {
        super(props);
        this.growl = React.createRef();
    }

    componentDidMount() {
        const {search} = this.props.location;
        if (isEmpty(search)) {
            this.props.history.push('/mapa');
        } else {
            const entries = search.substring(1, search.length).split('=');
            const params = new Map();
            for (let i = 0; i < entries.length; i = i + 2) {
                params.set(entries[i], entries[i + 1]);
            }
            const error = params.get('error');
            const success = params.get('success');
            if (!isEmpty(error)) {
                if (error === "invalidToken") {
                    this.growl.current.show({severity: 'error', summary: 'Błędny token'});
                } else if (error === "endSession") {
                    this.growl.current.show({severity: 'error', summary: 'Sesja wygasła. Wylogowano użytkownika.'});
                }
            } else if (!isEmpty(success)) {
                if (success === "registrationConfirmed") {
                    this.growl.current.show({severity: 'info', summary: 'Aktywowano użytkownika'});
                } else if (success === "registrationComplete") {
                    this.growl.current.show({
                        severity: 'info',
                        summary: 'Użytkownik zarejestrowany. Wiadomość z linkiem potwierdzającym została przesłana.'
                    });
                } else if (success === "logout") {
                    this.growl.current.show({severity: 'info', summary: 'Poprawnie wylogowano użytkownika.'});
                }
            }
        }
    }

    render() {
        return (
            <Growl ref={this.growl}/>
        )
    }
};

