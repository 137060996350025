import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Nav, NavItem} from 'reactstrap'
import Navbar from "reactstrap/es/Navbar";
import PropTypes from "prop-types";
import './Menu.css';
import iconData from './icon-data.png';
import iconReports from './icon-reports.png';
import iconFields from './icon-fields.png';
import iconMap from './icon-map.png';
import iconGrails from './icon-grails.png';
import iconNegFry from './icon-neg-fry.png';
import {withTranslation} from "react-i18next";

class Menu extends Component {

    render() {
        let className = '';
        className += this.props.externalUserLogged ? 'items-6' : 'items-4';
        className += this.props.mobile ? ' mobile' : ' standard';
        return (
            <Navbar id='menu' className={className}>
                <Nav>
                    <NavItem>
                        <NavLink to="/mapa" activeClassName='active-link'>
                            <img alt='' width={28} src={iconMap}/><span>{this.props.t('map')}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/dane" activeClassName='active-link'>
                            <img alt='' width={28} src={iconData}/><span>{this.props.t('data')}</span>
                        </NavLink>
                    </NavItem>
                    {this.props.externalUserLogged &&
                    <NavItem>
                        <NavLink to="/pole" activeClassName='active-link'>
                            <img alt='' width={28} src={iconFields}/><span>{this.props.t('my-fields')}</span>
                        </NavLink>
                    </NavItem>
                    }
                    <NavItem>
                        <NavLink to="/negfry" activeClassName='active-link'>
                            <img alt='' width={28} src={iconNegFry}/><span>{this.props.t('negfry')}</span>
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink to="/zboza" activeClassName='active-link'>
                            <img alt='' width={28} src={iconGrails}/><span>{this.props.t('grains')}</span>
                        </NavLink>
                    </NavItem>
                    {this.props.externalUserLogged &&
                    <NavItem>
                        <NavLink to="/raporty" activeClassName='active-link'>
                            <img alt='' width={28} src={iconReports}/><span>{this.props.t('reports')}</span>
                        </NavLink>
                    </NavItem>
                    }
                </Nav>
            </Navbar>
        )
    }
}

Menu.propTypes = {
    externalUserLogged: PropTypes.bool.isRequired,
    mobile: PropTypes.bool.isRequired
};

export default withTranslation('login')(Menu);