import React from 'react';
import {withTranslation} from "react-i18next";
import UserService from "../../../user/UserService";
import {Column} from "primereact/column";
import {DataTable} from "primereact/datatable";
import {Dialog} from "primereact/dialog";
import {AvFeedback, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Col, FormGroup, Label, Row} from "reactstrap";
import Button from "reactstrap/es/Button";
import {MultiSelect} from "primereact/multiselect";
import {Checkbox} from "primereact/checkbox";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import UserFormService from "../FormEditor/UserFormService";

class AdminUsers extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            user: null,
            roles: [],
            repeatedPassword: '',
            showDetailsDialog: false,
            selectedUser: null,
            fields: [],
            userRoles: []
        };
        this.newUser = false;
        this.userService = new UserService();
        this.userFormService = new UserFormService();
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.onUserSelect = this.onUserSelect.bind(this);
        this.addNew = this.addNew.bind(this);
        this.actionsColumn = this.actionsColumn.bind(this);
    }

    componentDidMount() {
        Promise.all([this.userService.getAll(), this.userService.getAllUserRoles(), this.userFormService.getAll()])
            .then(values => {
                const userRoles = values[1].filter(role => role.name !== 'ROLE_GUEST');
                const users = values[0].filter(user => user.email !== 'anonymous@kpodr.pl');
                this.setState({users: users, userRoles: userRoles, fields: values[2]});
            })
    }

    onUserSelect(user) {
        this.newUser = false;
        user.enabled = user.enabled === 'TAK';
        this.setState({
            displayDialog: true,
            user: Object.assign({}, user),
            roles: user.roles
        });
    }

    addNew() {
        this.newUser = true;
        this.setState({
            user: {
                email: '',
                firstName: '',
                secondName: '',
                lastName: '',
                password: '',
                roles: [],
                enabled: false
            },
            displayDialog: true
        });
    }

    updateProperty(property, value) {
        let user = this.state.user;
        user[property] = value;
        this.setState({user: user});
    }

    updateRepeatedPassword(value) {
        this.setState({repeatedPassword: value});
    }

    updateRoles(value) {
        this.setState({roles: value});
    }

    onValidSubmit = (event, values) => {
        this.save();
    };

    findSelectedUserIndex() {
        return this.state.users.indexOf(this.state.selectedUser);
    }

    save() {
        let users = [...this.state.users];
        const user = this.state.user;
        user.roles = this.state.roles;
        this.setState({
            user: user
        });
        if (this.newUser) {
            this.userService.create(this.state.user)
                .then(response => {
                    response.enabled = response.enabled ? 'TAK' : 'NIE';
                    users.push(response);
                    this.setState({
                        users: users,
                        selectedUser: null,
                        user: null,
                        displayDialog: false,
                        repeatedPassword: '',
                        roles: []
                    });
                });
        } else {
            this.userService.update(this.state.user.uuid, this.state.user)
                .then(response => {
                    let user = this.state.user;
                    user.enabled = user.enabled ? 'TAK' : 'NIE';
                    user.password = '';
                    users[this.findSelectedUserIndex()] = user;
                    this.setState({
                        users: users,
                        selectedUser: null,
                        user: null,
                        displayDialog: false,
                        repeatedPassword: '',
                        roles: []
                    });
                });
        }
    }

    delete() {
        this.userService.delete(this.state.selectedUser.uuid)
            .then(data => {
                let index = this.findSelectedUserIndex();
                this.setState({
                    users: this.state.users.filter((val, i) => i !== index),
                    selectedUser: null,
                    user: null,
                    displayDialog: false
                });
            });

    }

    roleColumn(rowData, column) {
        let result = '';
        for (let i = 0; i < rowData.roles.length; i++) {
            result += rowData.roles[i].substring(5);
            if (i < rowData.roles.length - 1) {
                result += ', ';
            }
        }
        return result;
    }

    actionsColumn(rowData, column) {
        return <div>
            <Button color='primary' type='button' className='mr-2'
                    onClick={() => this.onUserSelect(rowData)}><FontAwesomeIcon icon='edit'/></Button>
            <Button color='primary' type='button'
                    onClick={() => this.setState({selectedUser: rowData, showDetailsDialog: true})}><FontAwesomeIcon
                icon='search'/></Button>
        </div>
    }

    getFieldValue(field, custom) {
        let raw = custom[field.uuid];
        switch (field.type) {
            case 'CHECKBOX':
                return raw !== null && raw !== undefined ? (raw ? 'Tak' : 'Nie') : '';
            case 'MULTIPLE':
                if (raw instanceof Array) {
                    let result = '';
                    for (let i = 0; i < raw.length; i++) {
                        result = result + raw[i];
                        if (i < raw.length - 1) {
                            result = result + ', ';
                        }
                    }
                    return result;
                }
                return raw;
            default:
                return raw;
        }
    }

    render() {
        const dataTableFooter = (
            <div>
                <Button color='primary' onClick={e => this.addNew()}>Dodaj nowego użytkownika</Button>
            </div>
        );
        let userRolesOptions = this.state.userRoles.map(userRole => {
            let userRoleName = userRole.name;
            return {value: userRoleName, label: userRoleName.substring(5)};
        });
        const userDetails = [];
        if (this.state.selectedUser) {
            for (let i = 0; i < this.state.fields.length; i++) {
                let field = this.state.fields[i];
                let value = this.getFieldValue(field, this.state.selectedUser.custom);
                userDetails.push(<Row key={i}>
                    <Col md={3}>{field.name}:</Col>
                    <Col md={9}>{value}</Col>
                </Row>)
            }
        }

        return (
            <div className='m-4'>
                <DataTable value={this.state.users}
                           className='user-table'
                           responsive={true}
                           footer={dataTableFooter}
                           paginator={true} rows={20}>
                    <Column field='firstName' header='Imię' sortable={true}/>
                    <Column field='lastName' header='Nazwisko' sortable={true}/>
                    <Column field='email' header='Adres e-mail' sortable={true}/>
                    <Column field='enabled' header='Aktywny' sortable={true} style={{width: '108px'}}/>
                    <Column field='roles' header='Role' body={this.roleColumn} sortable={true}
                            style={{width: '160px'}}/>
                    <Column body={this.actionsColumn} style={{width: '120px'}}/>
                </DataTable>

                <Dialog
                    header={this.state.selectedUser ? (this.state.selectedUser.lastName && this.state.selectedUser.firstName) ? (this.state.selectedUser.lastName + ' ' + this.state.selectedUser.firstName) : this.state.selectedUser.email : 'Szczegóły'}
                    visible={this.state.showDetailsDialog}
                    onHide={() => this.setState({showDetailsDialog: false})}
                    maximizable={true}
                    width='860px'>
                    {this.state.selectedUser && <div style={{width: '860px'}}>
                        {userDetails}
                    </div>}
                </Dialog>

                <Dialog
                    header={this.props.t('user-form-details')}
                    visible={this.state.displayDialog}
                    width='860px'
                    modal={true}
                    onHide={() => this.setState({displayDialog: false})}
                    maximizable={true}>
                    {this.state.user &&
                    <AvForm onValidSubmit={this.onValidSubmit}>
                        <Row form>
                            <Col md={6}>
                                <AvGroup>
                                    <Label for="firstName">{this.props.t('user-form-first-name')}</Label>
                                    <AvInput id="firstName" name="firstName" value={this.state.user.firstName}
                                             onChange={(e) => {
                                                 this.updateProperty('firstName', e.target.value)
                                             }} required/>
                                    <AvFeedback>{this.props.t('user-form-first-name-required')}</AvFeedback>
                                </AvGroup>
                            </Col>
                            <Col md={6}>
                                <AvGroup>
                                    <Label for="lastName">{this.props.t('user-form-last-name')}</Label>
                                    <AvInput id="lastName" name="lastName" value={this.state.user.lastName}
                                             onChange={(e) => {
                                                 this.updateProperty('lastName', e.target.value)
                                             }} required/>
                                    <AvFeedback>{this.props.t('user-form-last-name-required')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col>
                                <AvGroup>
                                    <Label for="email">{this.props.t('user-form-email')}</Label>
                                    <AvInput id="email" name="email" value={this.state.user.email}
                                             onChange={(e) => {
                                                 this.updateProperty('email', e.target.value)
                                             }} required
                                             validate={{email: true}}
                                    />
                                    <AvFeedback>{this.props.t('user-form-email-invalid')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={6}>
                                <AvGroup>
                                    <Label for="password">{this.props.t('user-form-password')}</Label>
                                    <AvInput id="password" name="password" value={this.state.user.password}
                                             type="password"
                                             autoComplete="off"
                                             onChange={(e) => {
                                                 this.updateProperty('password', e.target.value)
                                             }}
                                             validate={{
                                                 match: {
                                                     value: 'repeatedPassword',
                                                     errorMessage: ''
                                                 },
                                             }}/>
                                    <AvFeedback>{this.props.t('user-form-password-mismatch')}</AvFeedback>
                                </AvGroup>
                            </Col>
                            <Col md={6}>
                                <AvGroup>
                                    <Label
                                        for="repeatedPassword">{this.props.t('user-form-repeated-password')}</Label>
                                    <AvInput id="repeatedPassword" name="repeatedPassword"
                                             value={this.state.repeatedPassword}
                                             type="password"
                                             autoComplete="off"
                                             onChange={(e) => {
                                                 this.updateRepeatedPassword(e.target.value)
                                             }}
                                             validate={{
                                                 match: {
                                                     value: 'password',
                                                     errorMessage: ''
                                                 },
                                             }}/>
                                    <AvFeedback> {this.props.t('user-form-password-mismatch')}</AvFeedback>
                                </AvGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <FormGroup>
                                    <Label for="roles">{this.props.t('user-form-roles')}</Label><br/>
                                    <MultiSelect id="roles" value={this.state.roles}
                                                 style={{width: '100%'}}
                                                 options={userRolesOptions}
                                                 onChange={(e) => {
                                                     this.updateRoles(e.target.value)
                                                 }}/>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row form>
                            <Col md={12}>
                                <Checkbox id="active" onChange={(e) => {
                                    this.updateProperty('enabled', e.checked)
                                }} checked={this.state.user.enabled}/>
                                <Label for='active' className='ml-1'> {this.props.t('user-form-active')}</Label>
                            </Col>
                        </Row>
                        <div className="ui-dialog-buttonpane p-clearfix">
                            <Button color='primary' type='submit' className='mr-2'>
                                <FontAwesomeIcon icon='check'/>{this.props.t('common:action-save')}
                            </Button>
                            <Button color='primary' type='button' className='mr-2' onClick={e => this.delete()}>
                                <FontAwesomeIcon icon='trash-alt'/>
                                {this.props.t('common:action-delete')}
                            </Button>
                            <Button color='primary' type='button'
                                    label={this.props.t('common:action-cancel')}
                                    icon='pi pi-times'
                                    onClick={e => this.setState({displayDialog: false})}>
                                <FontAwesomeIcon icon='times'/>{this.props.t('common:action-cancel')}
                            </Button>
                        </div>
                    </AvForm>
                    }
                </Dialog>
            </div>
        )
    }
}

export default withTranslation(['admin', 'common'])(AdminUsers);