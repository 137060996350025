import React, {Component} from 'react';

import CountyPolygons from "./CountyPolygons";
import './Map.css'
import MapMeteoTable from "./MapMeteoTable";
import "react-svg-map/lib/index.css";
import SVGCountyMap from "./SVGCountyMap";
import MeteoService from "./MeteoService";
import {Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";
import {Menu} from "primereact/menu";
import {Dropdown} from "primereact/dropdown";
import Cookies from "universal-cookie/lib";
import AuthActions from "../auth/AuthActions";
import {getUnit, getUnitByKey} from "../../util/MeteoUtil"
import moment from "moment";
import SettingsService from "../admin/MenuSettings/SettingsService";

const DIFF_IN_MINUTES_FOR_NOT_ACTUAL_MEAS_RELATIVE_TO_NOW = 240;

class Map extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentMeteoData: [],
            selectedMeasurementType: 'airTemperature200',
            selectedUnit: getUnit('airTemperature200'),
            selectedMeasurementTypeOption: {meas: 'airTemperature200'},
            loadingData: true,
            measurements: [],
            currentMeteoDataIsValid: {}
        };
        this.meteoService = new MeteoService();
        this.settingsService = new SettingsService();
        this.refreshTimer = null;
        const cookies = new Cookies();
        const value = cookies.get('meteoMapForm');
        if (value && value.measurementType && value.measurementTypeOption) {
            this.state.selectedMeasurementType = value.measurementType;
            this.state.selectedUnit = value.measurementTypeOption.unit === undefined ? getUnit(value.measurementType) : getUnitByKey(value.measurementType, value.measurementTypeOption.unit);
            this.state.selectedMeasurementTypeOption = value.measurementTypeOption
        }
        if (this.state.selectedMeasurementType === 'leafWetting' && !AuthActions.isExternalUserLogged()) {
            this.state.selectedMeasurementType = 'airTemperature200';
            this.state.selectedUnit = getUnit('airTemperature200');
            this.state.selectedMeasurementTypeOption = {meas: 'airTemperature200'};
        }
    }

    componentDidMount() {
        this.settingsService.getAvailableMeasurement()
            .then(res => this.setState({measurements: res}));
        this.loadAll();
        this.refreshTimer = setInterval(this.loadAll, 960 * 1000);
    }

    componentWillUnmount() {
        if (this.refreshTimer) {
            clearInterval(this.refreshTimer);
        }
        this.saveInCookies();
    }

    saveInCookies() {
        const cookies = new Cookies();
        const value = {
            measurementType: this.state.selectedMeasurementType,
            measurementTypeOption: this.state.selectedMeasurementTypeOption
        };
        cookies.set('meteoMapForm', value);
    }

    loadAll = () => {
        this.setState({loadingData: true});
        this.meteoService.getCurrentData()
            .then(data => {
                const currentMeteoDataIsValid = {};
                const now = moment();
                data.forEach(item => {
                    let measValid = true;
                    if (item['dateTime'] !== undefined && item['dateTime'] !== null) {
                        let date = moment(new Date(item['dateTime']));
                        if (moment.duration(now.diff(date)).asMinutes() > DIFF_IN_MINUTES_FOR_NOT_ACTUAL_MEAS_RELATIVE_TO_NOW) {
                            measValid = false;
                        }
                    }
                    currentMeteoDataIsValid[item['stationUuid']] = measValid;
                });
                this.setState({
                    currentMeteoData: data,
                    currentMeteoDataIsValid,
                    loadingData: false
                });
            });
    };

    handleChangeMeasurementType = (measTypeKey, unitKey = null) => {
        const selectedMeasurementTypeOption = {meas: measTypeKey};
        if (unitKey !== null) {
            selectedMeasurementTypeOption.unit = unitKey;
        }
        this.setState({
            selectedMeasurementTypeOption,
            selectedMeasurementType: measTypeKey,
            selectedUnit: unitKey === null ? getUnit(measTypeKey) : getUnitByKey(measTypeKey, unitKey)
        });
    };

    handleChangeUnit = (unit) => {
        this.setState({selectedUnit: unit});
    };

    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();

        let measurementItems = [];
        if (this.state.measurements.includes('airTemperature200')) {
            measurementItems.push({
                label: this.props.t('airTemperature200'),
                command: () => {
                    this.handleChangeMeasurementType('airTemperature200')
                },
                value: {meas: 'airTemperature200'},
                className: this.state.selectedMeasurementType === 'airTemperature200' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('airHumidity200')) {
            measurementItems.push({
                label: this.props.t('airHumidity200'),
                command: () => {
                    this.handleChangeMeasurementType('airHumidity200')
                },
                value: {meas: 'airHumidity200'},
                className: this.state.selectedMeasurementType === 'airHumidity200' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('wind')) {
            measurementItems.push({
                label: this.props.t('windMsMap'),
                command: () => {
                    this.handleChangeMeasurementType('wind', 'ms')
                },
                value: {meas: 'wind', unit: 'ms'},
                className: this.state.selectedMeasurementType === 'wind' && this.state.selectedUnit.name.trim() === 'm/s' ? 'measurement-type-selected' : ''
            });
            measurementItems.push({
                label: this.props.t('windKmhMap'),
                command: () => {
                    this.handleChangeMeasurementType('wind', 'kmh')
                },
                value: {meas: 'wind', unit: 'kmh'},
                className: this.state.selectedMeasurementType === 'wind' && this.state.selectedUnit.name.trim() === 'km/h' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('airTemperature5')) {
            measurementItems.push({
                label: this.props.t('airTemperature5'),
                command: () => {
                    this.handleChangeMeasurementType('airTemperature5')
                },
                value: {meas: 'airTemperature5'},
                className: this.state.selectedMeasurementType === 'airTemperature5' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('precipitationSum')) {
            measurementItems.push({
                label: this.props.t('precipitationSumMap'),
                command: () => {
                    this.handleChangeMeasurementType('precipitationSum')
                },
                value: {meas: 'precipitationSum'},
                className: this.state.selectedMeasurementType === 'precipitationSum' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('precipitationDiurnalSum')) {
            measurementItems.push({
                label: this.props.t('precipitationDiurnalSumMap'),
                command: () => {
                    this.handleChangeMeasurementType('precipitationDiurnalSum')
                },
                value: {meas: 'precipitationDiurnalSum'},
                className: this.state.selectedMeasurementType === 'precipitationDiurnalSum' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('leafWetting')) {
            measurementItems.push({
                label: this.props.t('leafWettingMap'),
                command: () => {
                    this.handleChangeMeasurementType('leafWetting')
                },
                secured: true,
                value: {meas: 'leafWetting'},
                className: this.state.selectedMeasurementType === 'leafWetting' ? 'measurement-type-selected' : ''
            });
        }
        if (this.state.measurements.includes('soilTemperature15')) {
            measurementItems.push({
                label: this.props.t('soilTemperature15'),
                command: () => {
                    this.handleChangeMeasurementType('soilTemperature15')
                },
                value: {meas: 'soilTemperature15'},
                className: this.state.selectedMeasurementType === 'soilTemperature15' ? 'measurement-type-selected' : ''
            });
        }

        if (!AuthActions.isExternalUserLogged()) {
            measurementItems = measurementItems.filter(item => {
                return item.secured === undefined || item.secured === false
            })
        }
        return (<>
            <Container>
                <Row noGutters={true}>
                    <Col id="map-mobile-measurement-dropdown">
                        <Dropdown style={{"width": "100%"}} value={this.state.selectedMeasurementTypeOption}
                                  options={measurementItems}
                                  onChange={(e) => {
                                      console.log(e.value.unit);
                                      this.handleChangeMeasurementType(e.value.meas, e.value.unit !== undefined ? e.value.unit : null)
                                  }} placeholder="Wybierz pomiar"/>
                    </Col>
                </Row>
                <Row noGutters={true}>
                    <Col xs="auto" id="map-menu-container">
                        <Menu className="map-measurements-menu" model={measurementItems}/>
                    </Col>
                    <Col id="map-svg-container">
                        <SVGCountyMap map={CountyPolygons}
                                      currentMeteoData={this.state.currentMeteoData}
                                      currentMeteoDataIsValid={this.state.currentMeteoDataIsValid}
                                      selectedMeasurementType={this.state.selectedMeasurementType}
                                      loadingData={this.state.loadingData}
                                      locationClassName="map-location"
                                      selectedUnit={this.state.selectedUnit}
                                      onChangeUnit={this.handleChangeUnit}
                        />
                    </Col>
                </Row>
                <Row noGutters={true}>
                    <Col id="map-meas-desc-container">
                        <span style={{fontSize: ".8em"}}>
                            {this.props.t('precipitationSumDescMap')}
                        </span>
                        <br/>
                        <span style={{fontSize: ".8em"}}>
                            {this.props.t('precipitationDiurnalSumDescMap')}
                        </span>
                        <br/>
                        {externalUserLogged &&
                        <span style={{fontSize: ".8em"}}>
                            {this.props.t('meteo:leafWetting-description')}: <br/>
                            {this.props.t('meteo:dry_surface')} <br/>
                            {this.props.t('meteo:wet_surface')}
                        </span>
                        }
                    </Col>
                </Row>
                <div className="w-100"></div>
                <Row noGutters={true}>
                    <Col id="map-table-container">
                        <MapMeteoTable
                            currentMeteoData={this.state.currentMeteoData}
                            currentMeteoDataIsValid={this.state.currentMeteoDataIsValid}
                            loadingData={this.state.loadingData}
                            selectedMeasurementType={this.state.selectedMeasurementType}
                            rows={this.calcSize(this.state.currentMeteoData.length)}/>
                    </Col>
                </Row>
            </Container>
            </>
        );
    }

    calcSize(total) {
        let lastPage10 = total % 10;
        if (lastPage10 === 0 || lastPage10 > 5) {
            return 10;
        }
        let lastPage9 = total % 9;
        if (lastPage9 === 0 || lastPage9 > 4) {
            return 9;
        }
        let lastPage8 = total % 8;
        if (lastPage8 === 0 || lastPage8 > 3) {
            return 8;
        }
        let lastPage7 = total % 7;
        if (lastPage7 === 0 || lastPage7 > 3) {
            return 7;
        }
        return 10;
    }
}

export default withTranslation('meteo')(Map);
