import config from "../../config/config";
import AuthActions from "./AuthActions"
import {history} from "../../routing"

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });
    failedQueue = [];
};

const interceptor = (axiosInstance) => (error) => {
    const _axios = axiosInstance;
    const originalRequest = error.config;
    if (error.response && error.response.status === 401 && !originalRequest._retry) {
        if (isRefreshing) {
            return new Promise(function (resolve, reject) {
                failedQueue.push({resolve, reject})
            }).then(token => {
                originalRequest.headers['Authorization'] = 'Bearer ' + token;
                return _axios.request(originalRequest);
            }).catch(err => {
                return Promise.reject(err);
            })
        }
        originalRequest._retry = true;
        isRefreshing = true;
        return new Promise((resolve, reject) => {
            const refreshToken = localStorage.getItem('api_refresh_token');
            const dataForm = new URLSearchParams();
            dataForm.append('grant_type', 'refresh_token');
            dataForm.append('refresh_token', refreshToken);
            return AuthActions.annonymousInstance.request({
                method: 'post',
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                },
                url: config.apiServerUrl + '/oauth/token',
                data: dataForm,
                auth: {
                    username: config.clientId,
                    password: config.clientSecret
                }
            })
                .then(({data}) => {
                    localStorage.setItem('api_access_token', data.access_token);
                    localStorage.setItem('api_refresh_token', data.refresh_token);
                    originalRequest.headers['Authorization'] = 'Bearer ' + data.access_token;
                    processQueue(null, data.access_token);
                    resolve(_axios(originalRequest));
                }).catch(err => {
                    const anonymous = localStorage.getItem('anonymous_user');
                    localStorage.removeItem('api_access_token');
                    localStorage.removeItem('api_refresh_token');
                    localStorage.removeItem('anonymous_user');
                    if (anonymous === 'false') {
                        history.replace("/?error=endSession");
                    } else {
                        history.replace("/");
                    }
                    processQueue(err, null);
                    reject(err);
                })
                .then(() => {
                    isRefreshing = false
                });
        })
    }
    return Promise.reject(error);
};

export default interceptor;