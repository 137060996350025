import ApiService from '../../service/common/ApiService'

class MyFieldService {

    getById(id) {
        return ApiService.get('/fields/' + id);
    }

    getAll() {
        return ApiService.get('/fields');
    }

    create(data) {
        return ApiService.post('/field', data);
    }

    update(id, data) {
        return ApiService.put('/field/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/field/' + id);
    }

}

export default MyFieldService;
