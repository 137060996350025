import React from 'react';
import {Container} from "reactstrap";
import {Messages} from "primereact/messages";
import MessageService from "../admin/News/MessageService";
import {withTranslation} from "react-i18next";

class CustomMessages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            messages: [],
            empty: false
        };
        this.messageService = new MessageService();
        this.props.i18n.on('languageChanged', lng => {
            const capitalizeLng = lng.charAt(0).toUpperCase() + lng.slice(1);
            const messages = [];
            this.messages.state.messages.forEach(item => {
                messages.push({
                    severity: item.source.type.toLowerCase(), sticky: true, detail: item.source["msg" + capitalizeLng], source: item.source
                });
            });
            this.messages.replace(messages);
        });
    }

    componentDidMount() {
        this.messageService.getAllActive()
            .then(data => {
                const lng = this.props.i18n.language;
                const capitalizeLng = lng.charAt(0).toUpperCase() + lng.slice(1);
                const messages = data.map(item => {
                    return {
                        severity: item.type.toLowerCase(), sticky: true, detail: item["msg" + capitalizeLng], source: item
                    }
                });
                this.setState({
                    messages: data,
                    empty: messages.length === 0
                });
                this.messages.show(messages);
            });
    }

    onRemove = () => {
        if (this.messages.state.messages.length === 1) {
            this.setState({
                empty: true
            })
        }
    };

    render() {
        return (
            <Container style={{display: this.state.empty ? 'none' : 'block'}}>
                <Messages ref={(el) => this.messages = el} onRemove={this.onRemove}/>
            </Container>
        )
    }
}

export default withTranslation('ns')(CustomMessages);