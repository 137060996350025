import React from 'react';
import Container from "reactstrap/es/Container";
import {Button, Col, Form, Row} from "reactstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import FieldDefinition from "./FieldDefinition";
import {Dialog} from "primereact/dialog";
import './UserFormEditor.css';
import {Growl} from 'primereact/growl';
import UserFormService from "./UserFormService";

export default class UserFormEditor extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fields: [],
            lastId: 0,
            position: 0,
            deleteDialog: false,
            selected: null
        };
        this.service = new UserFormService();
        this.addField = this.addField.bind(this);
        this.deleteSelectedField = this.deleteSelectedField.bind(this);
        this.handleDeleteField = this.handleDeleteField.bind(this);
        this.hideDeleteDialog = this.hideDeleteDialog.bind(this);
        this.validateName = this.validateName.bind(this);
        this.save = this.save.bind(this);
        this.reset = this.reset.bind(this);
        this.initData = this.initData.bind(this);
        this.init = this.init.bind(this);
        this.handleMoveUp = this.handleMoveUp.bind(this);
        this.handleMoveDown = this.handleMoveDown.bind(this);
        this.growl = React.createRef();
        this.fieldRefs = [];
    }

    handleMoveUp(id) {
        const fields = this.fieldRefs.filter(ref => ref).map(ref => ref.buildFieldDTO());
        const toMove = fields.filter(field => field.id === id)[0];
        if (toMove.position > 0) {
            const toMove2 = fields.filter(field => field.position === toMove.position - 1)[0];
            toMove.position = toMove2.position;
            toMove2.position = toMove2.position + 1;
        }
        fields.sort((a, b) => a.position - b.position);
        this.setState({fields: fields});
    }

    handleMoveDown(id) {
        const fields = this.fieldRefs.filter(ref => ref).map(ref => ref.buildFieldDTO());
        const toMove = fields.filter(field => field.id === id)[0];
        if (toMove.position < this.state.fields.length - 1) {
            const toMove2 = fields.filter(field => field.position === toMove.position + 1)[0];
            toMove.position = toMove2.position;
            toMove2.position = toMove2.position - 1;
        }
        fields.sort((a, b) => a.position - b.position);
        this.setState({fields: fields});
    }

    componentDidMount() {
        this.initData();
    }

    initData() {
        this.service.getAll().then(res => this.init(res));
    }

    init(res) {
        let fields = [...res];
        for (let i = 0; i < fields.length; i++) {
            fields[i].id = i;
        }
        fields.sort((a, b) => a.position - b.position);
        this.setState({
            fields: fields,
            lastId: fields.length,
            position: fields.length,
            deleteDialog: false,
            selected: null
        });
    }

    save() {
        const fields = this.fieldRefs.filter(ref => ref !== null).map(ref => ref.buildFieldDTO());
        fields.sort((a,b)=>a.position - b.position);
        for (let i = 0; i < fields.length; i++) {
            fields[i].position = i;
        }
        this.service.update(fields)
            .then(res => {
                this.init(res);
                //this.growl.current.show({severity: 'success', summary: 'Zapisano zmiany.'});
                window.location.reload();
            })
            .catch(err => {
                console.error(err);
                this.growl.current.show({severity: 'error', summary: 'Błąd zapisu.'});
            })
    }

    reset() {
        this.initData();
    }

    addField() {
        this.setState(state => {
            let fields = [...state.fields];
            let nextId = state.lastId + 1;
            fields.push({
                id: nextId,
                name: '',
                description: '',
                type: 'text',
                required: false,
                items: [],
                position: state.position
            });
            return {
                fields: fields,
                lastId: nextId,
                position: state.position + 1
            }
        })
    }

    validateName(name) {
        if (name === '') {
            return false;
        }
        return this.state.fields.filter(field => field.name === name).length === 0;
    }

    handleDeleteField(field) {
        this.setState({
            deleteDialog: true,
            selected: field
        })
    }

    deleteSelectedField() {
        this.setState(state => {
            let fields = state.fields
                .filter(field => field.id !== state.selected.id);
            for (let i = 0; i < fields.length; i++) {
                if (fields[i].position > state.selected.position) {
                    fields[i].position = fields[i].position - 1;
                }
            }
            return {
                fields: fields,
                selected: null,
                deleteDialog: false,
                position: state.position - 1
            }
        })
    }

    hideDeleteDialog() {
        this.setState({deleteDialog: false});
    }


    render() {
        this.fieldRefs = [];
        const fields = this.state.fields
            .map((field, index) => <FieldDefinition key={field.id}
                                                    ref={(ref) => this.fieldRefs[index] = ref}
                                                    field={field}
                                                    onMoveUp={this.handleMoveUp}
                                                    onMoveDown={this.handleMoveDown}
                                                    onDelete={this.handleDeleteField}
                                                    validateName={this.validateName}/>);

        const deleteDialogFooter = (
            <div>
                <Button color='primary' onClick={this.deleteSelectedField}>
                    <FontAwesomeIcon icon='trash-alt'/> Usuń
                </Button>
                <Button color='primary' onClick={this.hideDeleteDialog}>
                    <FontAwesomeIcon icon='times'/> Anuluj
                </Button>
            </div>
        );

        return (
            <Container className='p-2'>
                <Growl ref={this.growl}/>
                <h1 className='p-2'>Edytor fomularza użytkownika</h1>
                <Form>
                    {fields}
                    <Row form>
                        <Col>
                            <Button type='button' color='primary' onClick={this.addField} className='ml-2'>
                                <FontAwesomeIcon icon='plus'/> Dodaj pole formularza
                            </Button>
                        </Col>
                    </Row>
                    <Row form className='form-buttons'>
                        <Col>
                            <Button color='primary' onClick={this.save}>
                                <FontAwesomeIcon icon='check'/> Zapisz
                            </Button>
                            <Button color='primary' onClick={this.reset}>
                                <FontAwesomeIcon icon='times'/> Anuluj
                            </Button>
                        </Col>

                    </Row>
                </Form>
                <Dialog header='Usunięcie pola formularza'
                        footer={deleteDialogFooter}
                        visible={this.state.deleteDialog} style={{width: '360px'}} modal={true}
                        onHide={this.hideDeleteDialog}>
                    Czy na pewno chcesz usunąć pole
                    formularza<br/>"{this.state.selected ? this.state.selected.name : ''}"?
                </Dialog>
            </Container>
        )
    }

}
