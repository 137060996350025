import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button, Container, Form, FormGroup, Label} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {Rating} from "primereact/rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Growl} from "primereact/growl";
import {withTranslation} from "react-i18next";
import {RadioButton} from "primereact/radiobutton";
import VarietiesService from "../Varieties/VarietiesService";

class UserVarieties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            varieties: [],
            baseVarieties: [],
            selected: null,
            displayDialog: false,
            variety: null,
            validName: true,
            validType: true,
            validSeptoria: true,
            validEyespot: true,
            validationErrors: false,
            validUniqueName: true,
            validUniqueBaseName: true,
            selectedType: null,
            displayDeleteDialog: false
        };
        this.varietiesService = new VarietiesService();
        this.onSelect = this.onSelect.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.addNew = this.addNew.bind(this);
        this.typeTemplate = this.typeTemplate.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.handleTypeFilterChange = this.handleTypeFilterChange.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        Promise.all([this.varietiesService.getAllUserVarieties(), this.varietiesService.getAll()])
            .then(values => {
                this.setState({varieties: values[0], baseVarieties: values[1]});
            });
    }

    onSelect(e) {
        this.setState({
            displayDialog: true,
            variety: Object.assign({}, e.data)
        });
    }

    save() {
        let variety = this.state.variety;
        let validationErrors = false;
        let validUniqueName = true;
        let validUniqueBaseName = true;
        let validName = true;
        if (variety.name === null || variety.name === '') {
            validName = false;
            validationErrors = true;
        } else if (this.state.varieties.filter(v => v.name.toLowerCase() === variety.name.toLowerCase() && v.uuid !== variety.uuid).length > 0) {
            validUniqueName = false;
            validationErrors = true;
        } else if (this.state.baseVarieties.filter(v => v.name.toLowerCase() === variety.name.toLowerCase()).length > 0) {
            validUniqueBaseName = false;
            validationErrors = true;
        }
        let validType = true;
        if (variety.type === null || (variety.type !== 'WINTER' && variety.type !== 'SPRING')) {
            validType = false;
            validationErrors = true;
        }
        let validSeptoria = true;
        if (variety.septoria === null || (variety.septoria !== 1 && variety.septoria !== 2 && variety.septoria !== 3 && variety.septoria !== 4 && variety.septoria !== 5)) {
            validSeptoria = false;
            validationErrors = true;
        }
        let validEyespot = true;
        if (variety.eyespot === null || (variety.eyespot !== 1 && variety.eyespot !== 3 && variety.eyespot !== 4)) {
            validEyespot = false;
            validationErrors = true;
        }
        if (validationErrors) {
            this.setState({
                validName: validName,
                validUniqueName: validUniqueName,
                validUniqueBaseName: validUniqueBaseName,
                validType: validType,
                validSeptoria: validSeptoria,
                validEyespot: validEyespot,
                validationErrors: validationErrors
            })
        } else {
            if (variety.uuid) {
                this.update(variety);
            } else {
                this.create(variety);
            }
        }
    }

    create(variety) {
        this.varietiesService.createVariety(variety)
            .then(res => {
                this.setState(state => {
                    let varieties = [...state.varieties];
                    varieties.push(res);
                    return {
                        varieties: varieties,
                        selected: null,
                        variety: null,
                        displayDialog: false,
                        validName: true,
                        validType: true,
                        validSeptoria: true,
                        validEyespot: true,
                        validationErrors: false,
                        validUniqueName: true,
                        validUniqueBaseName: true
                    }
                });
                this.growl.current.show({severity: 'success', summary: this.props.t('variety-added-new')});
            }).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: this.props.t('variety-added-new-fail')});
        })
    }

    update(variety) {
        this.varietiesService.updateVariety(variety.uuid, variety)
            .then(res => {
                    this.setState(state => {
                        let varieties = [...state.varieties];
                        let index = state.varieties.indexOf(state.selected);
                        varieties[index] = this.state.variety;
                        return {
                            varieties: varieties,
                            selected: null,
                            variety: null,
                            displayDialog: false,
                            validName: true,
                            validType: true,
                            validSeptoria: true,
                            validEyespot: true,
                            validUniqueName: true,
                            validUniqueBaseName: true,
                            validationErrors: false
                        }
                    });
                    this.growl.current.show({severity: 'success', summary: this.props.t('variety-saved')});
                }
            ).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: this.props.t('variety-saved-fail')});
        })
    }

    delete() {
        this.varietiesService.deleteVariety(this.state.selected.uuid)
            .then(res => {
                this.setState(state => {
                    let index = state.varieties.indexOf(state.selected);
                    this.growl.current.show({severity: 'success', summary: this.props.t('variety-deleted')});
                    return {
                        varieties: state.varieties.filter((val, i) => i !== index),
                        selected: null,
                        variety: null,
                        displayDialog: false,
                        displayDeleteDialog: false
                    }
                });
            }).catch(err => {
            this.growl.current.show({severity: 'error', summary: this.props.t('variety-deleted-fail')});
        });
    }

    addNew() {
        this.setState({
            variety: {name: '', type: 'SPRING', septoria: 1, eyespot: 3},
            displayDialog: true
        });
    }

    typeTemplate(rowData, column) {
        return <span>{rowData.type === 'WINTER' ? this.props.t('wheat-variety-type-winter') : this.props.t('wheat-variety-type-spring')}</span>;
    }

    handleTypeFilterChange(event) {
        this.dt.filter(event.value, 'type', 'equals');
        this.setState({selectedType: event.value});
    }

    render() {
        let footer = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={this.addNew}>
                <FontAwesomeIcon icon='plus'/> {this.props.t('variety-add')}
            </Button>
        </div>;


        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button color='primary' onClick={this.save} className='mt-2'>
                <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
            </Button>
            {this.state.variety && this.state.variety.uuid &&
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: true})} className='mt-2'>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDialog: false})} className='mt-2'>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;
        const types = [
            {label: this.props.t('wheat-variety-type-spring'), value: 'SPRING'},
            {label: this.props.t('wheat-variety-type-winter'), value: 'WINTER'}
        ];
        const eyespot34 = [
            {label: '3', value: 3},
            {label: '4', value: 4}
        ];
        const eyespot1 = [
            {label: '1', value: 1},
        ];

        let typeFilter = <SelectButton style={{width: '100%'}}
                                       value={this.state.selectedType} options={types}
                                       onChange={this.handleTypeFilterChange}/>;

        const deleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.state.selected && this.state.selected.uuid &&
            <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: false})}>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;

        return (
            <Container>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.varieties} paginator={true} rows={20}
                           ref={(el) => this.dt = el}
                           responsive={true}
                           footer={footer}
                           selectionMode="single"
                           selection={this.state.selected}
                           onSelectionChange={e => this.setState({selected: e.value})}
                           onRowSelect={this.onSelect}>
                    <Column field="name" header={this.props.t('variety-name')} sortable={true} filter={true}/>
                    <Column field="type" header={this.props.t('variety-type')} sortable={true} body={this.typeTemplate}
                            filter={true} filterElement={typeFilter}/>
                    <Column field="septoria" header={this.props.t('variety-septoria')} sortable={true} filter={true}/>
                    <Column field="eyespot" header={this.props.t('variety-eyespot')} sortable={true}
                            filter={true}/>
                </DataTable>

                <Dialog visible={this.state.displayDialog} width="600px" header={this.props.t('variety')} modal={true}
                        footer={dialogFooter}
                        onHide={() => this.setState({displayDialog: false})}>
                    {
                        this.state.variety &&
                        <Form>
                            <FormGroup>
                                <Label for='name'>{this.props.t('variety-name')}</Label><br/>
                                <InputText name='name' value={this.state.variety.name}
                                           className={this.state.validName && this.state.validUniqueName && this.state.validUniqueBaseName ? '' : 'form-control is-invalid'}
                                           onChange={(e) => {
                                               let variety = Object.assign({}, this.state.variety);
                                               variety.name = e.target.value;
                                               this.setState({variety: variety})
                                           }}
                                />
                                <div className="invalid-feedback">
                                    {!this.state.validName && <span>{this.props.t('variety-name-req')}</span>}
                                    {!this.state.validUniqueName && <span>{this.props.t('variety-name-used')}</span>}
                                    {!this.state.validUniqueBaseName &&
                                    <span>{this.props.t('variety-name-exists')}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='type'>{this.props.t('variety-type')}</Label><br/>
                                <Label for='type'>
                                    <RadioButton value="WINTER" name="winter"
                                                 onChange={(e) => {
                                                     let variety = Object.assign({}, this.state.variety);
                                                     variety.type = 'WINTER';
                                                     variety.eyespot = 1;
                                                     this.setState({variety: variety})
                                                 }}
                                                 checked={this.state.variety.type === 'WINTER'}/>
                                </Label> {this.props.t('wheat-variety-type-winter')}<br/>
                                <Label for='type'>
                                    <RadioButton value="SPRING" name="Jare"
                                                 onChange={(e) => {
                                                     let variety = Object.assign({}, this.state.variety);
                                                     variety.type = 'SPRING';
                                                     variety.eyespot = 3;
                                                     this.setState({variety: variety})
                                                 }}
                                                 checked={this.state.variety.type === 'SPRING'}/> {this.props.t('wheat-variety-type-spring')}
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for='septoria'>{this.props.t('variety-septoria')}</Label>
                                <Rating name='septoria'
                                        className={this.state.validSeptoria ? '' : 'form-control is-invalid'}
                                        cancel={false}
                                        stars={5}
                                        value={this.state.variety.septoria}
                                        onChange={(e) => {
                                            let variety = Object.assign({}, this.state.variety);
                                            variety.septoria = e.value;
                                            this.setState({variety: variety})
                                        }}
                                />
                                <div className="invalid-feedback">
                                    {this.props.t('commons:option-required')}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='eyespot'>{this.props.t('variety-eyespot')}</Label>
                                {this.state.variety.type === 'WINTER' &&
                                <SelectButton name='eyespot' value={this.state.variety.eyespot}
                                              className={this.state.validEyespot ? '' : 'form-control is-invalid'}
                                              options={eyespot34}
                                              onChange={(e) => {
                                                  let variety = Object.assign({}, this.state.variety);
                                                  variety.eyespot = e.value;
                                                  this.setState({variety: variety})
                                              }}
                                />}
                                {
                                    this.state.variety.type === 'SPRING' &&
                                    <SelectButton name='eyespot'
                                                  value={this.state.variety.eyespot}
                                                  options={eyespot1}
                                                  disabled={true}
                                    />
                                }
                                <div className="invalid-feedback">
                                    {this.props.t('commons:option-required')}
                                </div>
                            </FormGroup>
                        </Form>
                    }
                </Dialog>
                <Dialog visible={this.state.displayDeleteDialog}
                        header={this.props.t('commons:delete-confirmation')} footer={deleteFooter} modal={true}
                        onHide={() => this.setState({displayDeleteDialog: false})}>
                    {this.state.selected &&
                    <span>{this.props.t('variety-delete-confirmation-details')} {this.state.selected.name}?</span>}
                </Dialog>
            </Container>
        )
    }

}

export default withTranslation('grains')(UserVarieties);
