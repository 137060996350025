import axios from 'axios'
import config from '../../config/config'
import {isEmpty} from "lodash";

class ApiService {

    get(url) {
        return axios({
            method: 'get',
            url: config.apiUrl + url,
            headers: {'Authorization': "bearer " + localStorage.getItem('api_access_token')}
        }).then(res => res.data);
    }

    getFile(url, data) {
        return axios({
            responseType: 'arraybuffer',
            method: 'post',
            url: config.apiUrl + url,
            data: data,
            headers: {'Authorization': "bearer " + localStorage.getItem('api_access_token')},
            exposedHeaders: ['Content-Disposition']
        }).then(res => res);
    }

    put(url, data) {
        return axios({
                method: 'put',
                url: config.apiUrl + url,
                headers: {'Authorization': "bearer " + localStorage.getItem('api_access_token')},
                data: data

            }
        ).then(res => res.data);
    }

    delete(url) {
        return axios({
                method: 'delete',
                url: config.apiUrl + url,
                headers: {'Authorization': "bearer " + localStorage.getItem('api_access_token')},
            }
        ).then(res => res.data);
    }

    post(url, data) {
        return axios({
                method: 'post',
                url: config.apiUrl + url,
                headers: {'Authorization': "bearer " + localStorage.getItem('api_access_token')},
                data: data
            }
        ).then(res => res.data);
    }

    postJson(url, data) {
        return axios({
                method: 'post',
                url: config.apiUrl + url,
                headers: {
                    'Authorization': "bearer " + localStorage.getItem('api_access_token'),
                    'content-type': 'application/json'
                },
                data: data
            }
        ).then(res => res.data);
    }

    count(baseUrl, filters) {
        let url = baseUrl;
        if (filters) {
            url += '?filter=' + this.buildFilterParam(filters);
        }
        return this.get(url);
    }

    getPage(baseUrl, first, rows, sortField, sortOrder, filters) {
        let url = baseUrl + '/' + first + '/' + rows;
        if (sortField && sortOrder) {
            url = url + '?sortField=' + sortField + '&sortOrder=' + sortOrder;
        }
        if (filters && !isEmpty(filters)) {
            if (!(sortField && sortOrder)) {
                url += '?filter=';
            } else {
                url += '&filter='
            }
            url += this.buildFilterParam(filters);
        }
        return this.get(url);
    }

    buildFilterParam(filters) {
        let result = '';
        const keys = Object.keys(filters);
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = filters[key].value;
            result = result + key + '==' + value + ';';
        }
        return result;
    }

}

export default new

ApiService();