import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    Nav,
    Navbar,
    NavbarBrand,
    NavItem,
    UncontrolledDropdown
} from "reactstrap";
import {Link} from "react-router-dom";
import logo from '../../img/logo.png'
import iconLogout from './icon-logout.png'
import iconDb from './icon-db.png'
import iconAdmin from './icon-admin.png'
import iconNews from './icon-news.png'
import iconUsers from './icon-users.png'
import iconMenu from './icon-menu.png'
import iconDocuments from './icon-documents.png'
import iconGrains from './icon-grails.png'
import iconPotato from './icon-potato.png'
import iconPl from './icon-poland.png'
import iconEng from './icon-gb.png'
import iconStation from './icon-cloud.png'
import iconGrainDisease from './icon-grain-disease.png'
import iconAccountEdit from './icon-account-edit.png'
import iconAlarm from './icon-alarm.png'
import iconUserFormEditor from './icon-user-form-editor.png'
import AuthActions from "../auth/AuthActions";
import {AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Growl} from "primereact/growl";
import {withTranslation} from "react-i18next";
import './TopMenu.css';
import NavLink from "reactstrap/es/NavLink";
import Button from "reactstrap/es/Button";

class TopMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userName: '',
            userPassword: '',
            loginPopUp: false
        };
        this.growl = React.createRef();
        this.onValidSubmit = this.onValidSubmit.bind(this);
        this.onInvalidSubmit = this.onInvalidSubmit.bind(this);
        this.changeLanguage = this.changeLanguage.bind(this);
        this.toggleLoginPopUp = this.toggleLoginPopUp.bind(this);
    }

    toggleLoginPopUp() {
        this.setState(state => {
            return {loginPopUp: !state.loginPopUp}
        })
    }

    onValidSubmit() {
        AuthActions.login(this.state.userName.trim(), this.state.userPassword)
            .catch(() => {
                    this.growl.current.show({severity: 'error', summary: this.props.t('login-invalid')});
                }
            );
    };

    onInvalidSubmit() {
        this.growl.current.show({severity: 'error', summary: 'Błędy walidacji'});
    };

    changeLanguage(lng) {
        this.props.i18n.changeLanguage(lng);
    };

    render() {

        const {i18n, externalUserLogged, adminLogged} = this.props;
        return (<div>
                <Navbar id='top-menu' light expand="xs"
                        className={externalUserLogged ? 'navbar-nav top-menu-user' : 'navbar-nav top-menu-guest'}>
                    <NavbarBrand href="https://www.kpodr.pl/" target="_blank">
                        <img alt='' src={logo} width={25} className="margin-class"/>
                        <span>{this.props.t('common:kpodr-fullname')}</span>
                    </NavbarBrand>

                    {externalUserLogged && <Nav className='ml-auto'>
                        {adminLogged &&
                        <UncontrolledDropdown>
                            <DropdownToggle nav caret>
                                <img alt='' src={iconAdmin} width={25} className="margin-class"
                                     title={this.props.t('administration')}/>
                                <span>{this.props.t('administration')}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag={Link} to="/admin/uzytkownicy">
                                    <img alt='' src={iconUsers} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-users')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/dane">
                                    <img alt='' src={iconDb} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-data')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/komunikaty">
                                    <img alt='' src={iconNews} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-news')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/dokumenty">
                                    <img alt='' src={iconDocuments} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-documents-settings')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/menu">
                                    <img alt='' src={iconMenu} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-menu-settings')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/zboza">
                                    <img alt='' src={iconGrains} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-menu-grains-variety')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/ziemniaki">
                                    <img alt='' src={iconPotato} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-menu-potato-variety')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/stacje">
                                    <img alt='' src={iconStation} width={25} className="margin-class"/>
                                    <span>{this.props.t('admin-menu-stations')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/chorobyzboz">
                                    <img alt='' src={iconGrainDisease} width={25} className="margin-class"/>
                                    <span>Choroby zbóż</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/admin/formularzuzytkownika">
                                    <img alt='' src={iconUserFormEditor} width={25} className="margin-class"/>
                                    <span>Formularz użytkownika</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>}
                        <UncontrolledDropdown>
                            <DropdownToggle nav caret>
                                <img alt='' src={iconStation} width={25} className="margin-class"/>
                                <span>{this.props.t('account')}</span>
                            </DropdownToggle>
                            <DropdownMenu>
                                <DropdownItem tag={Link} to="/alarmy">
                                    <img alt='' src={iconAlarm} width={25} className="margin-class"/>
                                    <span>Alarmy</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/konto">
                                    <img alt='' src={iconAccountEdit} width={25} className="margin-class"/>
                                    <span>{this.props.t('common:action-edit')}</span>
                                </DropdownItem>
                                <DropdownItem tag={Link} to="/odmianyzboza">
                                    <img alt='' src={iconGrains} width={25} className="margin-class"/>
                                    <span>{this.props.t('common:my-varieties')}</span>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <NavItem>
                            <Link to="/" onClick={AuthActions.logout}>
                                <img alt='' src={iconLogout} width={25} className="margin-class"
                                     title={this.props.t('logout')}/>
                                <span>{this.props.t('logout')}</span>
                            </Link>
                        </NavItem>
                    </Nav>}

                    {!externalUserLogged &&
                    <Nav className='ml-auto'>
                        <NavItem className='login-popup-btn'>
                            <Button type="button" color='primary' onClick={this.toggleLoginPopUp}>
                                {this.props.t('login')}
                            </Button>
                        </NavItem>
                        <NavItem className='login-form'>
                            <AvForm onValidSubmit={this.onValidSubmit}
                                    onInvalidSubmit={this.onInvalidSubmit}>
                                <AvGroup>
                                    <AvInput name="email" id="email"
                                             autoComplete="off"
                                             required
                                             value={this.state.userName} placeholder={this.props.t('email')}
                                             onChange={(e) => this.setState({userName: e.target.value.trim()})}/>
                                </AvGroup>
                                <AvGroup>
                                    <AvInput name="password" id="password"
                                             type="password"
                                             autoComplete="off"
                                             required
                                             value={this.state.userPassword} placeholder={this.props.t('password')}
                                             onChange={(e) => this.setState({userPassword: e.target.value})}/>
                                </AvGroup>
                                <Button type="submit" color='primary'>
                                    {this.props.t('login')}
                                </Button>
                            </AvForm>
                        </NavItem>
                        <NavItem className='login-form'>
                            <Link to="/rejestracja">
                                {this.props.t('register')}
                            </Link>
                        </NavItem>
                    </Nav>}
                    {
                        i18n.language === "en" &&
                        <NavItem>
                            <Button className='lang-button'
                                    onClick={() => this.changeLanguage('pl')}
                                    type="button">
                                <img alt='' src={iconPl} width={48}
                                     title='Zmień język na polski'/>
                            </Button>
                        </NavItem>
                    }
                    {
                        i18n.language === "pl" &&
                        <NavItem>
                            <Button className='lang-button'
                                    onClick={() => this.changeLanguage('en')}
                                    type="button">
                                <img alt='' src={iconEng} width={48}
                                     title='Change language to english'/>
                            </Button>
                        </NavItem>
                    }
                </Navbar>
                < Growl ref={this.growl}/>
                <Modal isOpen={this.state.loginPopUp} toggle={this.toggleLoginPopUp} className='login-popup'>
                    <ModalBody>
                        <h4 className='mb-5'>
                            <img alt='' src={logo} width={48} className="mb-3"/>
                            <br/>
                            <span>Kujawsko-Pomorski Ośrodek Doradztwa Rolniczego</span>
                        </h4>
                        <AvForm onValidSubmit={this.onValidSubmit}
                                onInvalidSubmit={this.onInvalidSubmit}>
                            <AvGroup>
                                <AvInput name="email2" id="email2"
                                         autoComplete="off"
                                         required
                                         value={this.state.userName} placeholder={this.props.t('email')}
                                         onChange={(e) => this.setState({userName: e.target.value.trim()})}/>
                            </AvGroup>
                            <AvGroup>
                                <AvInput name="password2" id="password2"
                                         type="password"
                                         autoComplete="off"
                                         required
                                         value={this.state.userPassword} placeholder={this.props.t('password')}
                                         onChange={(e) => this.setState({userPassword: e.target.value})}/>
                            </AvGroup>
                            <Button type="submit" color='primary'>
                                {this.props.t('login')}
                            </Button>
                        </AvForm>
                        <br/>
                        <span>
                        {this.props.t('or')}
                        </span>
                        <br/>
                        <NavLink href="/rejestracja">
                            {this.props.t('register')}
                        </NavLink>
                    </ModalBody>
                </Modal>
            </div>
        )
    }
}

TopMenu.propTypes = {
    externalUserLogged: PropTypes.bool.isRequired
};


export default withTranslation('login')(TopMenu);