import ApiService from "../../../service/common/ApiService";

export default class NegFryFieldService {
    getAll() {
        return ApiService.get('/negfry/fields');
    }

    create(data) {
        return ApiService.post('/negfry/fields', data);
    }

    update(id, data) {
        return ApiService.put('/negfry/fields/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/negfry/fields/' + id);
    }

}