import React from 'react';
import {withTranslation} from 'react-i18next';
import StationsSettingsService from "./StationsSettingsService";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Growl} from "primereact/growl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import MeteoStationService from "../Stations/MeteoStationService";

class AdminStationsSettings extends React.Component {

    constructor(props) {
        super(props);
        this.businessId = this.props.match.params.businessId;
        this.state = {
            station: null,
            enabled: true,
            airTemperature200: false,
            airHumidity200: false,
            wind: false,
            airTemperature5: false,
            precipitation1: false,
            precipitation2: false,
            precipitationSum: false,
            precipitationDiurnalSum: false,
            leafWetting: false,
            soilTemperature15: false,
            soilTemperature45: false,
            soilHumidity10: false,
            soilHumidity20: false,
            soilHumidity30: false,
            soilHumidity40: false,
            soilHumidity50: false,
            soilHumidity60: false
        };
        this.service = new StationsSettingsService();
        this.meteoStationService = new MeteoStationService();
        this.save = this.save.bind(this);
        this.growl = React.createRef();

    }

    componentDidMount() {
        this.meteoStationService.get(this.businessId)
            .then(res => {
                const station = res;
                this.service.getStationSettingsFormDTO(station.uuid)
                    .then(res2 => {
                        this.setState({
                            station: station,
                            enabled: res2.enabled,
                            airTemperature200: res2.measurementSettings.airTemperature200,
                            airHumidity200: res2.measurementSettings.airHumidity200,
                            wind: res2.measurementSettings.wind,
                            airTemperature5: res2.measurementSettings.airTemperature5,
                            precipitation1: res2.measurementSettings.precipitation1,
                            precipitation2: res2.measurementSettings.precipitation2,
                            precipitationSum: res2.measurementSettings.precipitationSum,
                            precipitationDiurnalSum: res2.measurementSettings.precipitationDiurnalSum,
                            leafWetting: res2.measurementSettings.leafWetting,
                            soilTemperature15: res2.measurementSettings.soilTemperature15,
                            soilTemperature45: res2.measurementSettings.soilTemperature45,
                            soilHumidity10: res2.measurementSettings.soilHumidity10,
                            soilHumidity20: res2.measurementSettings.soilHumidity20,
                            soilHumidity30: res2.measurementSettings.soilHumidity30,
                            soilHumidity40: res2.measurementSettings.soilHumidity40,
                            soilHumidity50: res2.measurementSettings.soilHumidity50,
                            soilHumidity60: res2.measurementSettings.soilHumidity60,
                        })
                    })
            })
    }

    save() {
        this.service.saveStation(this.state.station.uuid, {
            stationUuid: this.state.station.uuid,
            enabled: this.state.enabled,
            measurementSettings: {
                airTemperature200: this.state.airTemperature200,
                airHumidity200: this.state.airHumidity200,
                wind: this.state.wind,
                airTemperature5: this.state.airTemperature5,
                precipitation1: this.state.precipitation1,
                precipitation2: this.state.precipitation2,
                precipitationSum: this.state.precipitationSum,
                precipitationDiurnalSum: this.state.precipitationDiurnalSum,
                leafWetting: this.state.leafWetting,
                soilTemperature15: this.state.soilTemperature15,
                soilTemperature45: this.state.soilTemperature45,
                soilHumidity10: this.state.soilHumidity10,
                soilHumidity20: this.state.soilHumidity20,
                soilHumidity30: this.state.soilHumidity30,
                soilHumidity40: this.state.soilHumidity40,
                soilHumidity50: this.state.soilHumidity50,
                soilHumidity60: this.state.soilHumidity60,
            }
        })
            .then(res => {
                this.growl.current.show({severity: 'info', summary: 'Zapis udany'});
            })
            .catch(err => {
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
            });
    }

    render() {
        return (
            <Container className='p-4'>
                <div>
                    <Growl ref={this.growl}/>
                    <h2>Administracja - konfiguracja
                        stacji {this.state.station ? (this.state.station.city + ' p. ' + this.state.station.county) : ''}</h2>
                    <hr/>
                    <article>
                        <h3>{this.props.t('menu-settings-header-global')}</h3>
                        <Form>
                            <h4></h4>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='enabled' type="checkbox"
                                                   checked={this.state.enabled}
                                                   onChange={e => this.setState({enabled: e.target.checked})}/>
                                            Stacja aktywna
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <h4>{this.props.t('menu-settings-header-measurements')}</h4>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='airTemperature200' type="checkbox"
                                                   checked={this.state.airTemperature200}
                                                   onChange={e => this.setState({airTemperature200: e.target.checked})}/>
                                            {this.props.t('meteo:airTemperature200')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='airHumidity200' type="checkbox"
                                                   checked={this.state.airHumidity200}
                                                   onChange={e => this.setState({airHumidity200: e.target.checked})}/>
                                            {this.props.t('meteo:airHumidity200')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='wind' type="checkbox"
                                                   checked={this.state.wind}
                                                   onChange={e => this.setState({wind: e.target.checked})}/>
                                            {this.props.t('meteo:wind')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='airTemperature5' type="checkbox"
                                                   checked={this.state.airTemperature5}
                                                   onChange={e => this.setState({airTemperature5: e.target.checked})}/>
                                            {this.props.t('meteo:airTemperature5')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='precipitation1' type="checkbox"
                                                   checked={this.state.precipitation1}
                                                   onChange={e => this.setState({precipitation1: e.target.checked})}/>
                                            {this.props.t('meteo:precipitation1')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='precipitation2' type="checkbox"
                                                   checked={this.state.precipitation2}
                                                   onChange={e => this.setState({precipitation2: e.target.checked})}/>
                                            {this.props.t('meteo:precipitation2')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='precipitationSum' type="checkbox"
                                                   checked={this.state.precipitationSum}
                                                   onChange={e => this.setState({precipitationSum: e.target.checked})}/>
                                            {this.props.t('meteo:precipitationSum')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='precipitationDiurnalSum' type="checkbox"
                                                   checked={this.state.precipitationDiurnalSum}
                                                   onChange={e => this.setState({precipitationDiurnalSum: e.target.checked})}/>
                                            {this.props.t('meteo:precipitationDiurnalSum')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='leafWetting' type="checkbox"
                                                   checked={this.state.leafWetting}
                                                   onChange={e => this.setState({leafWetting: e.target.checked})}/>
                                            {this.props.t('meteo:leafWetting')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilTemperature15' type="checkbox"
                                                   checked={this.state.soilTemperature15}
                                                   onChange={e => this.setState({soilTemperature15: e.target.checked})}/>
                                            {this.props.t('meteo:soilTemperature15')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilTemperature45' type="checkbox"
                                                   checked={this.state.soilTemperature45}
                                                   onChange={e => this.setState({soilTemperature45: e.target.checked})}/>
                                            {this.props.t('meteo:soilTemperature45')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity10' type="checkbox"
                                                   checked={this.state.soilHumidity10}
                                                   onChange={e => this.setState({soilHumidity10: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity10')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity20' type="checkbox"
                                                   checked={this.state.soilHumidity20}
                                                   onChange={e => this.setState({soilHumidity20: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity20')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity30' type="checkbox"
                                                   checked={this.state.soilHumidity30}
                                                   onChange={e => this.setState({soilHumidity30: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity30')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity40' type="checkbox"
                                                   checked={this.state.soilHumidity40}
                                                   onChange={e => this.setState({soilHumidity40: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity40')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity50' type="checkbox"
                                                   checked={this.state.soilHumidity50}
                                                   onChange={e => this.setState({soilHumidity50: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity50')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                                <Col>
                                    <FormGroup check>
                                        <Label check>
                                            <Input name='soilHumidity60' type="checkbox"
                                                   checked={this.state.soilHumidity60}
                                                   onChange={e => this.setState({soilHumidity60: e.target.checked})}/>
                                            {this.props.t('meteo:soilHumidity60')}
                                        </Label>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className='mt-4' onClick={this.save} color='primary'><FontAwesomeIcon
                                        icon='check'/> Zapisz</Button>
                                </Col>
                            </Row>
                        </Form>
                    </article>
                </div>
            </Container>
        )
    }

}

export default withTranslation('admin', 'meteo')(AdminStationsSettings);
