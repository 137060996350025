import React from 'react';
import {Alert, Col, Container, Row} from "reactstrap";
import {withTranslation} from "react-i18next";

function AccessForbidden(props) {
    return (
        <Container className="p-4">
            <Row>
                <Col>
                    <Alert color="danger">
                        {props.t('access-forbidden')}
                    </Alert>
                </Col>
            </Row>
        </Container>
    );
}

export default withTranslation('common')(AccessForbidden);
