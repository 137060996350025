import React from 'react';
import {Button, Col, Container, FormGroup, Label, Row} from "reactstrap";
import {Calendar} from "primereact/calendar";
import {calendarPl} from "../../translations/pl/primereact";
import {calendarEn} from "../../translations/en/primereact";
import {withTranslation} from "react-i18next";
import {Dropdown} from "primereact/dropdown";
import {MultiSelect} from "primereact/multiselect";
import MeteoService from "../map/MeteoService";
import DataService from "../data/DataService";
import {applyShortcutRange} from "../../util/MeteoUtil";
import SettingsService from "../admin/MenuSettings/SettingsService";

class Reports extends React.Component {

    constructor(props) {
        super(props);
        let now = new Date();
        now.setMinutes(0);
        now.setHours(0);
        now.setSeconds(0);
        now.setMilliseconds(0);
        let from = new Date(now);
        from.setHours(now.getHours() - 24);
        this.defaultStations = JSON.parse(localStorage.getItem('default_stations'));
        this.state = {
            stations: [],
            selectedStations: [],
            allShortcuts: [],
            fromDate: from,
            toDate: now,
            kind: 'RAINFALL_DISTRIBUTION',
            validStations: true,
            validDateFrom: true,
            validDateTo: true,
            validateRange: true,
            downloadDisabled: false
        };
        this.meteoService = new MeteoService();
        this.dataService = new DataService();
        this.settingsService = new SettingsService();
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleSelectDefaultStations = this.handleSelectDefaultStations.bind(this);
    }

    componentDidMount() {
        this.meteoService.getAllStations()
            .then(data => {
                data.sort((a, b) => a.name.localeCompare(b.name));
                this.setState({stations: data})
            });
        this.settingsService.getGlobalSettingsOnlyMeteo()
            .then(data => {
                this.setState({
                    allShortcuts: data.shortcuts
                })
            })
    }

    handleSelectDefaultStations() {
        if (this.defaultStations && this.defaultStations.length > 0) {
            this.setState({selectedStations: this.defaultStations})
        } else {
            this.setState({selectedStations: []})
        }
    }

    handleSubmit() {
        this.setState({
            downloadDisabled: true
        });

        let validStations = this.state.selectedStations.length > 0;
        let validDateFrom = this.state.fromDate;
        let validDateTo = this.state.toDate;
        let validateRange = validDateFrom && validDateTo && this.state.fromDate < this.state.toDate;
        if (validStations && validDateFrom && validDateTo && validateRange) {
            const dto = {
                stations: this.state.selectedStations,
                from: new Date(Date.UTC(this.state.fromDate.getFullYear(), this.state.fromDate.getMonth(), this.state.fromDate.getDate())),
                to: new Date(Date.UTC(this.state.toDate.getFullYear(), this.state.toDate.getMonth(), this.state.toDate.getDate()))
            };
            this.dataService.getReport(this.state.kind, dto)
                .then(response => {
                    const disposition = response.request.getResponseHeader('Content-Disposition').split(';');
                    const fileName = disposition[1].split('=')[1];
                    const data = response.data;
                    const blob = new Blob([data]);
                    if (navigator.msSaveBlob) {
                        // IE 10+
                        navigator.msSaveBlob(blob, fileName);
                    } else {
                        const link = document.createElement('a');
                        // Browsers that support HTML5 download attribute
                        if (link.download !== undefined) {
                            const url = URL.createObjectURL(blob);
                            link.setAttribute('href', url);
                            link.setAttribute('download', fileName);
                            link.style.visibility = 'hidden';
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                        }
                    }
                    this.setState({
                        validDateFrom: validDateFrom,
                        validDateTo: validDateTo,
                        validStations: validStations,
                        validateRange: validateRange,
                        downloadDisabled: false
                    })
                }).catch(reason => {
                this.setState({
                    validDateFrom: validDateFrom,
                    validDateTo: validDateTo,
                    validStations: validStations,
                    validateRange: validateRange,
                    downloadDisabled: false
                })
            });
        }

    };

    onPredefinedDateClick(val) {
        this.setState(state => {
            return applyShortcutRange(state.toDate, val);
        });
    }

    render() {
        const stationOptions = this.state.stations.map(station => {
            return {
                label: this.props.t('station-select-label', {
                    stationName: station.city,
                    countyName: station.county
                }), value: station.uuid
            }
        });
        const kindOptions = [
            {
                label: this.props.t('report-kind-rainfall-distribution'),
                key: 'rainfall_distribution',
                value: 'RAINFALL_DISTRIBUTION'
            },
            {
                label: this.props.t('report-kind-temperature-course'),
                key: 'temperature_course',
                value: 'TEMPERATURE_COURSE'
            },
            {label: this.props.t('report-kind-spring-frosts'), key: 'spring_frosts', value: 'SPRING_FROSTS'},
            {
                label: this.props.t('report-kind-heavy-torrential-rain'),
                key: 'heavy_torrential_rain',
                value: 'HEAVY_TORRENTIAL_RAIN'
            },
            {label: this.props.t('report-kind-heat'), key: 'heat', value: 'HEAT'},
            {label: this.props.t('report-kind-strong-wind'), key: 'heat', value: 'STRONG_WIND'}
        ];
        const predefinedDates = [];
        this.state.allShortcuts.forEach(p => {
            predefinedDates.push(
                <Button type='button' color='primary' key={p} className='m-1'
                        onClick={e => this.onPredefinedDateClick(p)}>
                    {this.props.t('data-filters-panel-date-predefined-'.concat(p))}
                </Button>
            )
        });
        return (
            <Container className='p-4'>
                <Row>
                    <Col>
                        <FormGroup>
                            <h4>{this.props.t('data-filters-panel-stations')}</h4>
                            <MultiSelect value={this.state.selectedStations}
                                         style={{minWidth: '230px'}}
                                         scrollHeight='320px'
                                         filter={true}
                                         className={this.state.validStations ? '' : 'form-control is-invalid'}
                                         maxSelectedLabels={3}
                                         selectedItemsLabel={this.props.t('stations-count')}
                                         options={stationOptions}
                                         onChange={(e) => this.setState({selectedStations: e.value})}/>
                            <div className="invalid-feedback">
                                {this.props.t('data-filters-panel-stations-invalid')}
                            </div>
                            <div>
                                <Button type='button' color='primary' style={{width: '230px'}}
                                        onClick={this.handleSelectDefaultStations}>{this.props.t('data-filters-panel-stations-defaults')}</Button>
                            </div>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <h4>{this.props.t('report-kind')}</h4>
                            <Dropdown key="kind" value={this.state.kind} options={kindOptions}
                                      onChange={(e) => {
                                          this.setState({kind: e.value})
                                      }} placeholder="Wybierz rodzaj raportu"/>
                        </FormGroup>
                    </Col>
                    <Col style={{minWidth: '294px'}}>
                        <h4>{this.props.t('data-filters-panel-date-only')}</h4>
                        <FormGroup>
                            <Label for="dateFrom">{this.props.t('data-filters-panel-date-from')}&nbsp;</Label>
                            <Calendar id='dateFrom'
                                      dateFormat='yy-mm-dd'
                                      className={!this.state.validDateFrom || (this.state.validDateFrom && this.state.validDateTo && !this.state.validateRange) ? 'is-invalid' : ''}
                                      value={this.state.fromDate}
                                      onChange={(e) => this.setState({fromDate: e.value})}
                                      locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                            <div className="invalid-feedback ml-4">
                                {!this.state.validDateFrom &&
                                <span>{this.props.t('data-filters-panel-date-from-invalid')}</span>}
                                {this.state.validDateFrom && !this.state.validateRange &&
                                <span>{this.props.t('data-filters-panel-date-from-range-invalid')}</span>}
                            </div>
                        </FormGroup>
                        <FormGroup>
                            <Label for="dateTo">{this.props.t('data-filters-panel-date-to')}&nbsp;</Label>
                            <Calendar id='dateTo'
                                      className={!this.state.validDateTo || (this.state.validDateFrom && this.state.validDateTo && !this.state.validateRange) ? 'is-invalid' : ''}
                                      dateFormat='yy-mm-dd'
                                      value={this.state.toDate}
                                      onChange={(e) => this.setState({toDate: e.value})}
                                      locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}/>
                            <div className="invalid-feedback ml-4">
                                {!this.state.validDateTo &&
                                <span>{this.props.t('data-filters-panel-date-to-invalid')}</span>}
                                {this.state.validDateTo && !this.state.validateRange &&
                                <span>{this.props.t('data-filters-panel-date-from-range-invalid')}</span>}
                            </div>
                        </FormGroup>
                        <div>
                            <h6>{this.props.t('data-filters-panel-date-predefined')}</h6>
                            <span
                                style={{fontSize: '85%'}}>{this.props.t('data-filters-panel-date-predefined-dsc')}</span><br/>
                            {predefinedDates}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className='text-right'>
                        <br/>
                        <Button color='primary' disabled={this.state.downloadDisabled || this.state.selectedStations.length === 0}
                                onClick={this.handleSubmit}>{this.props.t('common:download')}</Button>
                    </Col>
                </Row>
            </Container>
        );
    }
}

export default withTranslation('meteo')(Reports);
