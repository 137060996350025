import React, {Component} from 'react';
import MainLayoutWrapper from './components/layout/MainLayoutWrapper'
import {Router} from "react-router";
import {history} from "./routing"

window.dataLayer = window.dataLayer || [];

function gtag2() {
    window.dataLayer.push(arguments);
}

class App extends Component {

    constructor(props) {
        super(props);
        this.router = React.createRef();
    }

    componentDidMount() {
        if (process.env.NODE_ENV === 'production') {
            history.listen((location) => {
                gtag2('config', 'UA-155889254-1');
            });
        }
    }

    render() {
        window.addEventListener('storage', function (e) {
            if (e.key === 'anonymous_user' && e.newValue !== e.oldValue) {
                window.location.reload();
            }
        });
        return (
            <Router ref={this.router} history={history}>
                <MainLayoutWrapper/>
            </Router>
        );
    }
}

export default App;
