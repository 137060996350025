import React from 'react';
import {withTranslation} from "react-i18next";
import {Button, Col, Container, Form, FormGroup, Row} from "reactstrap";
import {Dropdown} from "primereact/dropdown";
import MeteoService from "../../map/MeteoService";
import {Calendar} from "primereact/calendar";
import {calendarPl} from "../../../translations/pl/primereact";
import {calendarEn} from "../../../translations/en/primereact";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {measurements} from "../../../util/MeteoUtil"
import moment from "moment";
import {InputText} from "primereact/inputtext";
import MeasurementService from "./MeasurementService";
import {Growl} from "primereact/growl";
import {isNull} from "lodash"
import "./AdminData.css"
import {AvField, AvForm, AvGroup} from "availity-reactstrap-validation";
import ApiService from "../../../service/common/ApiService";

class AdminData extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            stations: [],
            selectedStation: null,
            toDate: new Date(),
            measurements: [],
            totalRecords: 0,
            first: 0,
            rows: 20,
            loading: false
        };
        this.clonedMeasurements = {};
        this.meteoService = new MeteoService();
        this.measurementService = new MeasurementService();
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.meteoService.getAllStations().then(data => this.setState({
            selectedStation: data[0] === undefined ? null : data[0].uuid,
            stations: data
        }));
    }

    handleSubmit = () => {
        this.loadData(this.state.first, this.state.rows);
    };

    loadData = (first, rows) => {
        this.setState({
            loading: true
        });
        const startIndex = first;
        const {selectedStation, toDate} = this.state;
        this.meteoService.getDataPage(selectedStation, toDate.toISOString(), startIndex, rows)
            .then(data =>
                this.setState({
                    measurements: data.list,
                    totalRecords: data.count,
                    rows: rows,
                    first: startIndex,
                    loading: false,
                }))
            .catch(res => {
                this.growl.current.show({severity: 'error', summary: this.props.t('data-lazy-loading-error')});
                console.log(res);
            });
    };

    onPage = (event) => {
        this.loadData(event.first, event.rows);
        window.scrollTo(0, 0);
    };

    datetimeTemplate(rowData, column) {
        return moment(rowData['dateTime']).format("YYYY-MM-DD HH:mm");
    }

    onEditorValueChange(props, value) {
        let updatedMeasurements = [...props.value];
        updatedMeasurements[props.rowIndex][props.field] = value;
        this.setState({measurements: updatedMeasurements});
    }

    inputTextEditor = (props, field) => {
        const value = props.rowData[field] != null ? props.rowData[field] : '';
        return <InputText type="text" keyfilter="num" value={value} required={false}
                          onChange={(e) => this.onEditorValueChange(props, e.target.value)}/>;
    };

    editor = (props, fieldName) => {
        return this.inputTextEditor(props, fieldName);
    };

    onRowEditSave = (event) => {
        const dto = {};
        measurements.filter(item => item.raw).forEach(item => {
            dto[item.key] = !isNull(event.data[item.key]) && event.data[item.key] !== '' ? Number(event.data[item.key]) : null;
        });
        this.measurementService.update(event.data.uuid, dto)
            .then(() => {
                this.growl.current.show({severity: 'info', summary: this.props.t('common:successful-modification')});
                delete this.clonedMeasurements[event.data.uuid];
            })
            .catch(e => {
                this.growl.current.show({severity: 'error', summary: this.props.t('common:unsuccessful-modification')});
                this.onRowEditCancel(event);
                console.log(e);
            });
    };

    onRowEditInit = (event) => {
        this.clonedMeasurements[event.data.uuid] = {...event.data};
    };

    onRowEditCancel = (event) => {
        let measurements = [...this.state.measurements];
        measurements[event.index] = this.clonedMeasurements[event.data.uuid];
        delete this.clonedMeasurements[event.data.uuid];
        this.setState({
            measurements
        })
    };

    downloadFtpFilesLogFile = () => {
        ApiService.getFile('/logs/ftpfiles')
            .then(response => {
                const disposition = response.request.getResponseHeader('Content-Disposition').split(';');
                const fileName = disposition[1].split('=')[1];
                const data = response.data;
                const blob = new Blob([data]);
                if (navigator.msSaveBlob) {
                    // IE 10+
                    navigator.msSaveBlob(blob, fileName);
                } else {
                    const link = document.createElement('a');
                    // Browsers that support HTML5 download attribute
                    if (link.download !== undefined) {
                        const url = URL.createObjectURL(blob);
                        link.href = url;
                        link.download = fileName;
                        link.style.visibility = 'hidden';
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }
            })
            .catch(e => {
                this.growl.current && this.growl.current.show({severity: 'error', summary: this.props.t('ftpfiles-logs-download-ERROR')})
            })
    }

    measColumnBody = (rowData, column) => {
        const value = rowData[column.field];
        const rawValue = rowData[column.field + 'Raw'];
        let diffRaw = false;
        if (column.field !== 'leafWetting') {
            if (value !== null) {
                if (isNaN(parseFloat(rawValue)) || parseFloat(rawValue) !== value) {
                    diffRaw = true;
                }
            } else {
                if (rawValue != null) {
                    diffRaw = true;
                }
            }
        }
        return <span
            title={diffRaw ? this.props.t('data-changed') + (rawValue == null ? this.props.t('common:none') : rawValue) : ''}
            className={diffRaw ? 'diff-raw' : ''}>{rowData[column.field] != null && rowData[column.field] !== '' ? rowData[column.field] : '---'}</span>;
    };

    render() {
        let columns = [];
        columns.push(<Column style={{width: "14%"}} body={this.datetimeTemplate} key="dateTimeColumn" header="Data"/>);
        measurements.filter(item => item.raw).forEach(item => {
            columns.push(<Column key={item}
                                 body={this.measColumnBody}
                                 field={item.key}
                                 editor={props => this.editor(props, item.key)}
                                 header={this.props.t('meteo:' + item.key + 'Short')}/>);
        });
        columns.push(<Column key="actionColumn" rowEditor={true} style={{'width': '32px', 'textAlign': 'center'}}/>);
        const countyShort = this.props.t('meteo:county-short');
        const stationItems = this.state.stations.map(item => {
            return {label: item.city + ', ' + countyShort + ' ' + item.county, key: item.uuid, value: item.uuid}
        });
        return <Container>
            <article>
                <div className='m-2'>
                    <h2>{this.props.t('data-header')}</h2>
                    <Growl ref={this.growl}/>

                    <Button color='primary'
                            className="my-2"
                            onClick={this.downloadFtpFilesLogFile}>{this.props.t('ftpfiles-logs-btn')}</Button>

                    <AvForm onValidSubmit={this.handleSubmit}>
                        <Row form>
                            <Col>
                                <AvGroup>
                                    <AvField name="stationDropdown" id="stationDropdown" label={this.props.t('meteo:data-station') + ': '}
                                             tag={Dropdown}
                                             value={this.state.selectedStation}
                                             options={stationItems}
                                             style={{width: "20em"}}
                                             onChange={(e) => {
                                                 this.setState({selectedStation: e.value})
                                             }} placeholder={this.props.t('data-choose-station')}
                                             errorMessage={this.props.t('common:input-required')}
                                             required
                                    />
                                </AvGroup>
                            </Col>
                            <Col>
                                <AvGroup>
                                    <AvField name="toDate" id="toDate" label="Data do: "
                                             tag={Calendar}
                                             value={this.state.toDate}
                                             dateFormat='yy-mm-dd'
                                             showTime={true} hourFormat="24"
                                             monthNavigator={true} yearNavigator={true}
                                             yearRange="2015:2030"
                                             errorMessage={this.props.t('common:input-required') + ' ' + this.props.t('common:format-required', {format: "YYYY-MM-DD HH:MM"})}
                                             onChange={(e) => this.setState({toDate: e.value})}
                                             locale={this.props.i18n.language === 'pl' ? calendarPl : calendarEn}
                                             required
                                    />
                                </AvGroup>
                            </Col>
                            <Col>
                                <Col className='text-right'>
                                    <Button color='primary'>{this.props.t('meteo:data-filters-panel-submit')}</Button>
                                </Col>
                            </Col>
                        </Row>
                    </AvForm>
                </div>
                <DataTable value={this.state.measurements}
                           paginator={true}
                           className="admin-data-table"
                           rows={this.state.rows}
                           rowsPerPageOptions={[10, 20, 30, 40, 50]}
                           totalRecords={this.state.totalRecords}
                           lazy={true}
                           first={this.state.first}
                           onPage={this.onPage}
                           loading={this.state.loading}
                           editable={true}
                           editMode="row"
                           onRowEditInit={this.onRowEditInit}
                           onRowEditSave={this.onRowEditSave}
                           onRowEditCancel={this.onRowEditCancel}
                >
                    {columns}
                </DataTable>
            </article>
        </Container>
    }
}

export default withTranslation('admin')(AdminData);