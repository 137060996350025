import ApiService from "../../service/common/ApiService";
import moment from "moment";

export default class DataService {

    getData(dto) {
        return ApiService.post('/data/custom', dto);
    }

    getExport(dto, reportType) {
        switch (reportType) {
            case 'PDF':
                return this.getPdfExport(dto);
            case 'TXT':
                return this.getTxtExport(dto);
            case 'XLSX':
                return this.getXlsxExport(dto);
            case 'CSV':
            default:
                return this.getCsvExport(dto);
        }
    }

    getPdfExport(dto) {
        return ApiService.getFile('/data/download/report/pdf', dto);
    }

    getCsvExport(dto) {
        return ApiService.getFile('/data/download/report/csv', dto);
    }

    getTxtExport(dto) {
        return ApiService.getFile('/data/download/report/txt', dto);
    }

    getXlsxExport(dto) {
        return ApiService.getFile('/data/download/report/xlsx', dto);
    }

    getReport(kind, dto) {
        return ApiService.getFile('/data/report/' + kind, dto);
    }

    getRainDays(uuid, dateTo) {
        let date = moment(dateTo).format("YYYY-MM-DD");
        return ApiService.get('/data/raindays/' + uuid + '/' + date);
    }

}
