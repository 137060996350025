import React from 'react';
import VarietiesService from "./VarietiesService";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button, Container, Form, FormGroup, Label} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {SelectButton} from "primereact/selectbutton";
import {Rating} from "primereact/rating";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Growl} from "primereact/growl";
import {withTranslation} from "react-i18next";
import {RadioButton} from "primereact/radiobutton";

class Varieties extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            varieties: [],
            selected: null,
            displayDialog: false,
            variety: null,
            validName: true,
            validType: true,
            validSeptoria: true,
            validEyespot: true,
            validationErrors: false,
            validUniqueName: true,
            selectedType: null
        };
        this.varietiesService = new VarietiesService();
        this.onSelect = this.onSelect.bind(this);
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
        this.addNew = this.addNew.bind(this);
        this.typeTemplate = this.typeTemplate.bind(this);
        this.create = this.create.bind(this);
        this.update = this.update.bind(this);
        this.handleTypeFilterChange = this.handleTypeFilterChange.bind(this);
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.varietiesService.getAll()
            .then(res => this.setState({varieties: res}));
    }

    onSelect(e) {
        this.setState({
            displayDialog: true,
            variety: Object.assign({}, e.data)
        });
    }

    save() {
        let variety = this.state.variety;
        let validationErrors = false;
        let validUniqueName = true;
        let validName = true;
        if (variety.name === null || variety.name === '') {
            validName = false;
            validationErrors = true;
        } else if (this.state.varieties.filter(v => v.name.toLowerCase() === variety.name.toLowerCase() && v.uuid !== variety.uuid).length > 0) {
            validUniqueName = false;
            validationErrors = true;
        }
        let validType = true;
        if (variety.type === null || (variety.type !== 'WINTER' && variety.type !== 'SPRING')) {
            validType = false;
            validationErrors = true;
        }
        let validSeptoria = true;
        if (variety.septoria === null || (variety.septoria !== 1 && variety.septoria !== 2 && variety.septoria !== 3 && variety.septoria !== 4 && variety.septoria !== 5)) {
            validSeptoria = false;
            validationErrors = true;
        }
        let validEyespot = true;
        if (variety.eyespot === null || (variety.eyespot !== 1 && variety.eyespot !== 3 && variety.eyespot !== 4)) {
            validEyespot = false;
            validationErrors = true;
        }
        if (validationErrors) {
            this.setState({
                validName: validName,
                validUniqueName: validUniqueName,
                validType: validType,
                validSeptoria: validSeptoria,
                validEyespot: validEyespot,
                validationErrors: validationErrors
            })
        } else {
            if (variety.uuid) {
                this.update(variety);
            } else {
                this.create(variety);
            }
        }
    }

    create(variety) {
        this.varietiesService.create(variety)
            .then(res => {
                this.setState(state => {
                    let varieties = [...state.varieties];
                    varieties.push(res);
                    return {
                        varieties: varieties,
                        selected: null,
                        variety: null,
                        displayDialog: false,
                        validName: true,
                        validType: true,
                        validSeptoria: true,
                        validEyespot: true,
                        validationErrors: false,
                        validUniqueName: true
                    }
                });
                this.growl.current.show({severity: 'success', summary: 'Dodano nową odmianę.'});
            }).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się dodać odmiany.'});
        })
    }

    update(variety) {
        this.varietiesService.update(variety.uuid, variety)
            .then(res => {
                    this.setState(state => {
                        let varieties = [...state.varieties];
                        let index = state.varieties.indexOf(state.selected);
                        varieties[index] = this.state.variety;
                        return {
                            varieties: varieties,
                            selected: null,
                            variety: null,
                            displayDialog: false,
                            validName: true,
                            validType: true,
                            validSeptoria: true,
                            validEyespot: true,
                            validUniqueName: true,
                            validationErrors: false
                        }
                    });
                    this.growl.current.show({severity: 'success', summary: 'Zapisano odmianę.'});
                }
            ).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: 'Nie udało się zapisać odmiany.'});
        })
    }

    delete() {
        this.varietiesService.delete(this.state.selected.uuid)
            .then(res => {
                this.setState(state => {
                    let index = state.varieties.indexOf(state.selected);
                    return {
                        varieties: state.varieties.filter((val, i) => i !== index),
                        selected: null,
                        variety: null,
                        displayDialog: false
                    }
                });
            })
    }

    addNew() {
        this.setState({
            variety: {name: '', type: 'SPRING', septoria: 1, eyespot: 3},
            displayDialog: true
        });
    }

    typeTemplate(rowData, column) {
        return <span>{rowData.type === 'WINTER' ? 'Ozime' : 'Jare'}</span>;
    }

    handleTypeFilterChange(event) {
        this.dt.filter(event.value, 'type', 'equals');
        this.setState({selectedType: event.value});
    }

    render() {
        let footer = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={this.addNew}>
                <FontAwesomeIcon icon='plus'/> Dodaj
            </Button>
        </div>;


        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button color='primary' onClick={this.save}>
                <FontAwesomeIcon icon='check'/> Zapisz
            </Button>
            {this.state.variety && this.state.variety.uuid && <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> Usuń odmianę
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDialog: false})}>
                <FontAwesomeIcon icon='times'/> Anuluj
            </Button>
        </div>;
        const types = [
            {label: 'Jare', value: 'SPRING'},
            {label: 'Ozime', value: 'WINTER'}
        ];
        const eyespot34 = [
            {label: '3', value: 3},
            {label: '4', value: 4}
        ];
        const eyespot1 = [
            {label: '1', value: 1},
        ];

        let typeFilter = <SelectButton style={{width: '100%'}}
                                       value={this.state.selectedType} options={types}
                                       onChange={this.handleTypeFilterChange}/>;

        return (
            <Container>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.varieties} paginator={true} rows={20}
                           ref={(el) => this.dt = el}
                           footer={footer}
                           selectionMode="single"
                           selection={this.state.selected}
                           onSelectionChange={e => this.setState({selected: e.value})}
                           onRowSelect={this.onSelect}>
                    <Column field="name" header="Nazwa" sortable={true} filter={true}/>
                    <Column field="type" header="Rodzaj" sortable={true} body={this.typeTemplate}
                            filter={true} filterElement={typeFilter}/>
                    <Column field="septoria" header="Podatność septorioza" sortable={true} filter={true}/>
                    <Column field="eyespot" header="Podatność łamliwość kłosa" sortable={true}
                            filter={true}/>
                </DataTable>

                <Dialog visible={this.state.displayDialog} width="600px" header="Odmiana zboża" modal={true}
                        footer={dialogFooter}
                        onHide={() => this.setState({displayDialog: false})}>
                    {
                        this.state.variety &&
                        <Form>
                            <FormGroup>
                                <Label for='name'>Nazwa odmiany</Label><br/>
                                <InputText name='name' value={this.state.variety.name}
                                           className={this.state.validName && this.state.validUniqueName ? '' : 'form-control is-invalid'}
                                           onChange={(e) => {
                                               let variety = Object.assign({}, this.state.variety);
                                               variety.name = e.target.value;
                                               this.setState({variety: variety})
                                           }}
                                />
                                <div className="invalid-feedback">
                                    {!this.state.validName && <span>Nazwa jest wymagana</span>}
                                    {!this.state.validUniqueName && <span>Nazwa jest już użyta.</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='type'>Rodzaj</Label><br/>
                                <Label for='type'>
                                    <RadioButton value="WINTER" name="winter"
                                                 onChange={(e) => {
                                                     let variety = Object.assign({}, this.state.variety);
                                                     variety.type = 'WINTER';
                                                     variety.eyespot = 1;
                                                     this.setState({variety: variety})
                                                 }}
                                                 checked={this.state.variety.type === 'WINTER'}/>
                                </Label> Ozime<br/>
                                <Label for='type'>
                                    <RadioButton value="SPRING" name="Jare"
                                                 onChange={(e) => {
                                                     let variety = Object.assign({}, this.state.variety);
                                                     variety.type = 'SPRING';
                                                     variety.eyespot = 3;
                                                     this.setState({variety: variety})
                                                 }}
                                                 checked={this.state.variety.type === 'SPRING'}/> Jare
                                </Label>
                            </FormGroup>
                            <FormGroup>
                                <Label for='septoria'>Podatność septorioza</Label>
                                <Rating name='septoria'
                                        className={this.state.validSeptoria ? '' : 'form-control is-invalid'}
                                        cancel={false}
                                        stars={5}
                                        value={this.state.variety.septoria}
                                        onChange={(e) => {
                                            let variety = Object.assign({}, this.state.variety);
                                            variety.septoria = e.value;
                                            this.setState({variety: variety})
                                        }}
                                />
                                <div className="invalid-feedback">
                                    Należy wybrać jedną opcję.
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='eyespot'>Podatność łamliwość kłosa</Label>
                                {this.state.variety.type === 'WINTER' &&
                                <SelectButton name='eyespot' value={this.state.variety.eyespot}
                                              className={this.state.validEyespot ? '' : 'form-control is-invalid'}
                                              options={eyespot34}
                                              onChange={(e) => {
                                                  let variety = Object.assign({}, this.state.variety);
                                                  variety.eyespot = e.value;
                                                  this.setState({variety: variety})
                                              }}
                                />}
                                {
                                    this.state.variety.type === 'SPRING' &&
                                    <SelectButton name='eyespot'
                                                  value={this.state.variety.eyespot}
                                                  options={eyespot1}
                                                  disabled={true}
                                    />
                                }
                                <div className="invalid-feedback">
                                    Należy wybrać jedną opcję.
                                </div>
                            </FormGroup>
                        </Form>
                    }
                </Dialog>
            </Container>
        )
    }

}

export default withTranslation('ns')(Varieties);
