import React from 'react';
import {Container} from "reactstrap";

export default function Cookie() {
    return <Container>
        <div style={{padding: "24px"}}>
            <h2 style={{textAlign: "center"}}>Polityka Cookies</h2>
            <p>Poniższa Polityka Cookies określa zasady zapisywania i uzyskiwania dostępu do danych na Urządzeniach
                Użytkowników korzystających z Serwisu do celów świadczenia usług drogą elektroniczną przez Administratora
                Serwisu.</p>

            <h3 style={{textAlign: "center"}}>§ 1 Definicje</h3>
            <ul>
                <li><p><strong>Serwis</strong> - serwis internetowy działający pod adresem <span id="serwis">
                <span className="colored"></span></span></p></li>
                <li><p><strong>Serwis zewnętrzny</strong> - serwis internetowe partnerów, usługodawców lub usługobiorców
                    Administratora</p></li>
                <li><p><strong>Administrator</strong> - firma <span id="firma"><span className="colored">Przemysław Tomecki PTM Soft</span>, prowadząca działalność pod adresem: <span
                    className="colored">ul. Kasztanowa 78 Więckowice 32-082 Bolechowice</span>, o nadanym numerze identyfikacji podatkowej (NIP): <span
                    className="colored">6792846140</span></span>, świadcząca usługi drogą elektroniczną za pośrednictwem
                    Serwisu oraz przechowująca i uzyskująca dostęp do informacji w urządzeniach Użytkownika</p></li>
                <li><p><strong>Użytkownik</strong> - osba fizyczna, dla której Administrator świadczy usługi drogą
                    elektroniczna za pośrednictwem Serwisu.</p></li>
                <li><p><strong>Urządzenie</strong> - elektroniczne urządzenie wraz z oprogramowaniem, za pośrednictwem,
                    którego Użytkownik uzyskuje dostęp do Serwisu</p></li>
                <li><p><strong>Cookies (ciasteczka)</strong> - dane tekstowe gromadzone w formie plików zamieszczanych na
                    Urządzeniu Użytkownika</p></li>
            </ul>
            <h3 style={{textAlign: "center"}}>§ 2 Rodzaje Cookies</h3>
            <ul>
                <li><p><strong>Cookies wewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przes
                    system teleinformatyczny Serwisu</p></li>
                <li><p><strong>Cookies zewnętrzne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez
                    systemy teleinformatyczne Serwisów zewnętrznych</p></li>
                <li><p><strong>Cookies sesyjne</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez
                    Serwis <span id="sz1" style={{display: "inline"}}>lub Serwisy zewnętrzne</span> podczas jednej sesji danego
                    Urządzenia. Po zakończeniu sesji pliki są usuwane z Urządzenia Użytkownika.</p></li>
                <li><p><strong>Cookies trwałe</strong> - pliki zamieszczane i odczytywane z Urządzenia Użytkownika przez
                    Serwis <span id="sz2" style={{display: "inline"}}>lub Serwisy zewnętrzne</span> do momentu ich ręcznego
                    usunięcia. Pliki nie są usuwane automatycznie po zakończeniu sesji Urządzenia chyba że konfiguracja
                    Urządzenia Użytkownika jest ustawiona na tryb usuwanie plików Cookie po zakończeniu sesji Urządzenia.
                </p></li>
            </ul>

            <h3 style={{textAlign: "center"}}>§ 3 Bezpieczeństwo</h3>
            <ul>
                <li><p><strong>Mechanizmy składowania i odczytu</strong> - Mechanizmy składowania i odczytu Cookies nie
                    pozwalają na pobierania jakichkolwiek danych osobowych ani żadnych informacji poufnych z Urządzenia
                    Użytkownika. Przeniesienie na Urządzenie Użytkownika wirusów, koni trojańskich oraz innych robaków jest
                    praktynie niemożliwe.</p></li>
                <li><p><strong>Cookie wewnętrzne</strong> - zastosowane przez Administratora Cookie wewnętrzne są bezpieczne
                    dla Urządzeń Użytkowników</p></li>
                <li><p><strong>Cookie zewnętrzne</strong> - za bezpieczeństwo plików Cookie pochodzących od partnerów
                    Serwisu Administrator nie ponosi odpowiedzialności. Lista partnerów zamieszczona jest w dalszej części
                    Polityki Cookie.</p></li>
            </ul>

            <h3 style={{textAlign: "center"}}>§ 4 Cele do których wykorzystywane są pliki Cookie</h3>
            <ul id="cele">
                <li><p><strong><span className="colored">Usprawnienie i ułatwienie dostępu do Serwisu</span></strong> -
                    Administrator może przechowywać w plikach Cookie informacje o prefernecjach i ustawieniach użytkownika
                    dotyczących Serwisu aby usprawnić, polepszyć i przyśpieszyć świadczenie usług w ramach Serwisu.</p></li>
                <li><p><strong><span className="colored">Logowanie</span></strong> - Administrator wykorzystuje pliki Cookie
                    do celów logowania Użytkowników w Serwisie</p></li>
                <li><p><strong><span className="colored">Dane statystyczne</span></strong> - Administrator <span id="sz4"
                                                                                                                 style={{display: "inline"}}>oraz Serwisy zewnętrzne</span> wykorzystuje
                    pliki Cookie do zbirania i przetwarzania danych statystycznych takich jak np. statystyki odwiedzin,
                    statystyki Urządzeń Użytkowników czy statystyki zachowań użytkowników. Dane te zbierane są w celu
                    analizy i ulepszania Serwisu.</p></li>
            </ul>
            <h3 style={{textAlign: "center"}}>§ 5 Serwisy zewnętrzne</h3>
            <p id="zewinfo"><span className="colored">Administrator współpracuje z następującymi serwisami zewnętrznymi, które mogą zamieszczać pliki Cookie na Urządzeniach Użytkownika:</span>
            </p>
            <ul id="zewnetrzne">
                <li><p><strong><span className="colored">Google Analytics</span></strong></p></li>
            </ul>
            <h3 style={{textAlign: "center"}}>§ 6 Możliwości określania warunków przechowywania i uzyskiwania dostępu na
                Urządzeniach Użytkownika przez Serwis<span id="sz6" style={{display: "inline"}}> i Serwisy zewnętrzne</span>
            </h3>
            <ul>
                <li><p>Użytkownik może w dowolnym momencie, samodzielnie zmienić ustawienia dotyczące zapisywania, usuwania
                    oraz dostępu do danych zapisanych plików Cookies</p></li>
                <li><p>Informacje o sposobie wyłączenia plików Cookie w najpopularniejszych przeglądarkach komputerowych i
                    urządzeń mobilnych dostępna są na stronie: <a href="http://jakwylaczyccookie.pl">jak wyłączyć cookie</a>.
                </p></li>
                <li><p>Użytkownik może w dowolnym momencie usunąć wszelkie zapisane do tej pory pliki Cookie korzystając z
                    narzędzi Urządzenia Użytkownika za pośrednictwem którego Użytkowanik korzysta z usług Serwisu.</p></li>
            </ul>
            <h3 style={{textAlign: "center"}}>§ 7 Wymagania Serwisu</h3>
            <ul>
                <li><p>Ograniczenie zapisu i dostępu do plików Cookie na Urządzeniu Użytkownika może spowodować
                    nieprawidłowe działanie niektórych funkcji Serwisu.</p></li>
                <li><p>Administrator nie ponosi żadnej odpowiedzialności za nieprawidłowo działające funkcje Serwisu w
                    przypadku gdy Użytkownik ograniczy w jakikolwiek sposób możliwość zapisywania i odczytu plików
                    Cookie.</p></li>
            </ul>
            <h3 style={{textAlign: "center"}}>§ 8 Zmiany w Polityce Cookie</h3>
            <ul>
                <li><p>Administrator zastrzega sobie prawo do dowolnej zmiany niniejszej Polityki Cookie bez konieczności
                    informowania o tym użytkowników.</p></li>
                <li><p>Wprowadzone zmiany w Polityce Cookie zawsze będą publikowane na tej stronie.</p></li>
                <li><p>Wprowadzone zmiany wchodzą w życie w dniu publikacji Polityki Cookie.</p></li>
            </ul>

        </div>
    </Container>

}