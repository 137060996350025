import React from 'react';
import {Route} from 'react-router-dom';
import Data from "../data/Data";
import Map from "../map/Map";
import Menu from "../menu/Menu";
import Reports from "../reports/Reports";
import Grains from "../grains/Grains";
import NegFry from "../negfry/NegFry";
import './MainLayoutWrapper.css'
import TopMenu from "../top-menu/TopMenu";
import AuthActions from "../auth/AuthActions";
import UserConfirmRegistration from "../user/UserConfirmRegistration";
import MessagePanel from "./MessagePanel";
import UserRegistration from "../user/UserRegistration";
import UserAccount from "../user/UserAccount";
import MyFieldList from "../myfield/MyFieldList";
import AdminData from "../admin/Data/AdminData";
import AdminUsers from "../admin/Users/Admin/AdminUsers";
import AdminNews from "../admin/News/AdminNews";
import AdminMenuSettings from "../admin/MenuSettings/AdminMenuSettings";
import Footer from "../footer/Footer";
import Cookie from "../cookie/Cookie";
import Rodo from "../rodo/Rodo";
import Growth from "../grains/Growth/Growth";
import Varieties from "../grains/Varieties/Varieties";
import MyFieldDetails from "../myfield/details/MyFieldDetails";
import PotatoVarieties from "../negfry/PotatoVariety/PotatoVarieties";
import AdminStations from "../admin/Stations/AdminStations";
import Alarms from "../alarms/Alarms";
import GrainDiseaseEditor from "../admin/GrainDisease/Editor/GrainDiseaseEditor";
import GrainDiseaseList from "../admin/GrainDisease/List/GrainDiseaseList";
import GrainDiseaseChangesHistory from "../admin/GrainDisease/ChangesHistory/GrainDiseaseChangesHistory";
import UserFormEditor from "../admin/Users/FormEditor/UserFormEditor";
import AdminDocumentSettings from "../admin/DocumentSettings/AdminDocumentSettings";
import AdminStationsSettings from "../admin/StationsSettings/AdminStationsSettings";
import UserVarieties from "../grains/UserVarieties/UserVarieties";
import SecuredComponent from "../../common/SecuredComponent";
import AccessForbidden from "../../common/AccessForbidden";
import CustomMessages from "../messages/CustomMessages";

export default class MainLayoutWrapper extends React.Component {

    render() {
        const externalUserLogged = AuthActions.isExternalUserLogged();
        const adminLogged = AuthActions.isAdminLogged();
        let mobile = detectMobile();
        return (
            <div>
                <header>
                    <TopMenu externalUserLogged={externalUserLogged} adminLogged={adminLogged}/>
                    <Menu externalUserLogged={externalUserLogged} mobile={mobile}/>
                    <CustomMessages />
                </header>
                <div>
                    <Route path="/" exact component={MessagePanel}/>
                    <Route path="/dostepzabroniony" exact component={AccessForbidden}/>
                    <Route path="/mapa" exact component={Map}/>
                    <Route path="/dane" exact component={Data}/>
                    <Route path="/dane/:stationId" component={Data}/>
                    <Route path="/pole" exact render={props => <SecuredComponent><MyFieldList {...props}/></SecuredComponent>}/>
                    <Route path="/pole/:fieldId" render={props => <SecuredComponent><MyFieldDetails {...props}/></SecuredComponent>}/>
                    <Route path="/negfry" component={NegFry}/>
                    <Route path="/zboza" exact component={Grains}/>
                    <Route path="/zboza/:disease" exact component={Grains}/>
                    <Route path="/raporty" render={props => <SecuredComponent><Reports {...props}/></SecuredComponent>}/>
                    <Route path="/rejestracja" component={UserRegistration}/>
                    <Route path="/uzytkownik/:token" component={UserConfirmRegistration}/>
                    <Route path="/konto" render={props => <SecuredComponent><UserAccount {...props}/></SecuredComponent>}/>
                    <Route path="/odmianyzboza" render={props => <SecuredComponent><UserVarieties {...props}/></SecuredComponent>}/>
                    <Route path="/alarmy" exact render={props => <SecuredComponent><Alarms {...props}/></SecuredComponent>}/>
                    <Route path="/alarmy/:alarmUuid" render={props => <SecuredComponent><Alarms {...props}/></SecuredComponent>}/>
                    <Route path="/admin/uzytkownicy" render={props => <SecuredComponent onlyAdmin={true}><AdminUsers {...props}/></SecuredComponent>}/>
                    <Route path="/admin/formularzuzytkownika" render={props => <SecuredComponent onlyAdmin={true}><UserFormEditor {...props}/></SecuredComponent>}/>
                    <Route path="/admin/dane" render={props => <SecuredComponent onlyAdmin={true}><AdminData {...props}/></SecuredComponent>}/>
                    <Route path="/admin/komunikaty" render={props => <SecuredComponent onlyAdmin={true}><AdminNews {...props}/></SecuredComponent>}/>
                    <Route path="/admin/menu" render={props => <SecuredComponent onlyAdmin={true}><AdminMenuSettings {...props}/></SecuredComponent>}/>
                    <Route path="/admin/dokumenty" render={props => <SecuredComponent onlyAdmin={true}><AdminDocumentSettings {...props}/></SecuredComponent>}/>
                    <Route path="/admin/chorobyzboz" render={props => <SecuredComponent onlyAdmin={true}><GrainDiseaseList {...props}/></SecuredComponent>} exact/>
                    <Route path="/admin/chorobyzboz/historia/:diseaseUuid" render={props => <SecuredComponent onlyAdmin={true}><GrainDiseaseChangesHistory {...props}/></SecuredComponent>} exact/>

                    <Route path="/admin/chorobyzboz/edytor/:lang/:diseaseUuid" render={props => <SecuredComponent onlyAdmin={true}><GrainDiseaseEditor {...props}/></SecuredComponent>} exact/>
                    <Route path="/cookie" component={Cookie}/>
                    <Route path="/rodo" component={Rodo}/>
                    <Route path="/fazyrozwoju" component={Growth}/>
                    <Route path="/admin/zboza" render={props => <SecuredComponent onlyAdmin={true}><Varieties {...props}/></SecuredComponent>}/>
                    <Route path="/admin/ziemniaki" render={props => <SecuredComponent onlyAdmin={true}><PotatoVarieties {...props}/></SecuredComponent>}/>
                    <Route path="/admin/stacje" render={props => <SecuredComponent onlyAdmin={true}><AdminStations {...props}/></SecuredComponent>} exact/>
                    <Route path="/admin/stacje/ustawienia/:businessId" exact render={props => <SecuredComponent onlyAdmin={true}><AdminStationsSettings {...props}/></SecuredComponent>}/>

                </div>
                <Footer mobile={mobile}/>
                {mobile && <div style={{height: '54px'}}></div>}
            </div>
        )
    }
};

export function detectMobile() {
    return !!(navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i));
}

