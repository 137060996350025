import {getUnit} from '../../../util/MeteoUtil';
import moment from "moment";

export default class ChartBuilder {

    constructor(settings, translate, sunriseAndSunsets, firstDate, lastDate, data, dataDesc, min, max) {
        this.renderCustomBar = this.renderCustomBar.bind(this);
        this.settings = settings;
        this.translate = translate;
        this.sunriseAndSunsets = sunriseAndSunsets;
        this.dataDesc = dataDesc;
        this.data = data;
        this.firstDate = firstDate;
        this.minX = min;
        this.maxX = max;
        this.lastDate = lastDate;
        this.gridPlan = [];
        this.grid = [];
        this.xAxis = [];
        this.yAxis = [];
        this.series = [];
        this.legendData = [];
        this.tooltipFormatter = null;
        this.initContainsFields();
        this.planGrid();
        let dataSize = this.data && this.data.length > 0 ? Object.keys(this.data[0]).length : 0;
        this.rainWidth = dataSize <= (24 * 4) ? 4 : (dataSize <= (7 * 24 * 4) ? 3 : 2);
        if (this.grid.length > 0) {
            this.initXAxis();
            this.initYAxis();
            this.initSeries();
            this.initTooltipFormatter();
            if (sunriseAndSunsets && sunriseAndSunsets.length > 1) {
                this.initBackground();
            }
        }
    }

    initContainsFields() {
        this.containsTemperature = this.settings.measurements.includes('airTemperature200') || this.settings.measurements.includes('airTemperature5')
            || this.settings.measurements.includes('soilTemperature15') || this.settings.measurements.includes('soilTemperature45');
        this.containsHumidity = this.settings.measurements.includes('airHumidity200');
        this.containsWind = this.settings.measurements.includes('wind');
        this.containsLeaf = this.settings.measurements.includes('leafWetting');
        this.containsPrecipitation = this.settings.measurements.includes('precipitation1') || this.settings.measurements.includes('precipitation2') || this.settings.measurements.includes('precipitationSum') || this.settings.measurements.includes('precipitationDiurnalSum');
        this.containsSoilHumidity = this.settings.measurements.includes('soilHumidity10') || this.settings.measurements.includes('soilHumidity20')
            || this.settings.measurements.includes('soilHumidity30') || this.settings.measurements.includes('soilHumidity40')
            || this.settings.measurements.includes('soilHumidity50') || this.settings.measurements.includes('soilHumidity60');
    }

    planGrid() {
        const allTypes = this.containsTemperature && this.containsHumidity && this.containsWind && this.containsLeaf
            && this.containsPrecipitation && this.containsSoilHumidity;
        let leftOffset = this.containsPrecipitation ? 10 : 0;
        let position = 0;
        let before = 0;
        let gridsNumber = 0;
        if (this.containsTemperature) {
            this.gridPlan['temperature'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }
        if (this.containsHumidity) {
            this.gridPlan['humidity'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }
        if (this.containsWind) {
            if (before >= 2) {
                gridsNumber = 1;
                position = 0;
            }
            this.gridPlan['wind'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }
        if (this.containsLeaf) {
            if (before >= 2) {
                gridsNumber = 1;
                if (position === 2) {
                    position = 0;
                }
            }
            this.gridPlan['leafWetting'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }
        if (this.containsPrecipitation) {
            if (allTypes) {
                position = 0;
                gridsNumber = 2;
            } else if (before >= 2 && gridsNumber === 0) {
                position = 0;
                gridsNumber = 1;
            }
            this.gridPlan['precipitation'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }
        if (this.containsSoilHumidity) {
            if (before >= 2) {
                if (before >= 5) {
                    gridsNumber = 2;
                } else {
                    gridsNumber = 1;
                }
                if (before === 2) {
                    position = 0;
                }
            }
            this.gridPlan['soilHumidity'] = {grid: gridsNumber, position: position};
            ++position;
            ++before;
        }

        if (gridsNumber === 0) {
            this.grid.push({});
        } else if (gridsNumber === 1) {
            this.grid.push({bottom: 260, top: 90, right: 130, left: 50 + leftOffset}, {
                bottom: 20,
                top: 340,
                right: 130,
                left: 50 + leftOffset
            });
        }
        if (gridsNumber === 2) {
            this.grid.push(
                {bottom: 500, top: 90, right: 130, left: 50 + leftOffset},
                {bottom: 260, top: 330, right: 130, left: 50 + leftOffset},
                {bottom: 20, top: 570, right: 130, left: 50 + leftOffset});
        }
    }

    dateLabelFormatter = function (value, index) {
        const date = value.value ? moment(new Date(value.value)) : moment(new Date(value));
        return date.format("HH:mm") + '\n' + date.format("DD.MM");
    };

    initXAxis() {
        if (this.grid.length === 1) {
            this.xAxis.push({
                type: 'time', axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            });
        } else if (this.grid.length === 2) {
            this.xAxis.push({
                type: 'time', gridIndex: 0, axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            }, {
                type: 'time', gridIndex: 1, axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            });
        } else {
            this.xAxis.push({
                type: 'time', gridIndex: 0, axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            }, {
                type: 'time', gridIndex: 1, axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            }, {
                type: 'time', gridIndex: 2, axisLabel: {
                    formatter: this.dateLabelFormatter
                }, axisPointer: {label: {formatter: this.dateLabelFormatter}}
            });
        }
    }

    initYAxis() {
        let currentIndex = 0;
        if (this.containsTemperature) {
            const tmp = [];
            for (let i = 0; i < this.data.length; i++) {
                for (let [key, value] of Object.entries(this.data[i])) {
                    if (value.data['airTemperature200']) {
                        tmp.push(value.data['airTemperature200']);
                    }
                    if (value.data['airTemperature5']) {
                        tmp.push(value.data['airTemperature5']);
                    }
                    if (value.data['soilTemperature15']) {
                        tmp.push(value.data['soilTemperature15']);
                    }
                    if (value.data['soilTemperature45']) {
                        tmp.push(value.data['soilTemperature45']);
                    }
                }
            }
            const min = getMin(tmp, 5);
            const max = getMax(tmp, 5);
            this.yAxis.push({
                type: 'value',
                name: this.translate('temperature'),
                splitNumber: 5, min: min, max: max,
                interval: Math.abs(max - min) / 5,
                gridIndex: 0,
                axisLabel: {
                    formatter: '{value}°C'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(1) + '°C';
                        }
                    }
                }
            });
            this.soilTemperatureAxisIndex = currentIndex;
            ++currentIndex;
        }
        if (this.containsHumidity) {
            const tmp = [];
            for (let i = 0; i < this.data.length; i++) {
                for (let [key, value] of Object.entries(this.data[i])) {
                    if (value.data['airHumidity200']) {
                        tmp.push(value.data['airHumidity200']);
                    }
                }
            }
            let min = getMin(tmp, 5);
            if (min < 0) {
                min = 100;
            }
            let max = getMax(tmp, 5);
            if (max > 100) {
                max = 100;
            }
            this.yAxis.push({
                type: 'value',
                name: this.translate('humidity'),
                min: min, max: max,
                interval: Math.abs(max - min) / 5,
                gridIndex: 0,
                position: this.gridPlan.humidity.position === 0 ? 'left' : 'right',
                axisLabel: {
                    formatter: '{value}%'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(0) + '%';
                        }
                    }
                }
            });
            this.humidityAxisIndex = currentIndex;
            ++currentIndex;
        }
        if (this.containsWind) {
            const tmp = [];
            for (let i = 0; i < this.data.length; i++) {
                for (let [key, value] of Object.entries(this.data[i])) {
                    if (value.data['wind']) {
                        tmp.push(value.data['wind']);
                    }
                }
            }
            const max = getMax(tmp, 5);
            this.yAxis.push({
                type: 'value',
                name: this.translate('wind'),
                offset: this.gridPlan.wind.position === 2 ? 80 : 0,
                min: 0, splitNumber: 5, max: max,
                interval: Math.abs(max) / 5,
                gridIndex: this.gridPlan.wind.grid,
                position: this.gridPlan.wind.position === 0 ? 'left' : 'right',
                axisLabel: {
                    formatter: '{value} m/s'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(1) + ' m/s';
                        }
                    }
                }
            });
            this.windAxisIndex = currentIndex;
            ++currentIndex;
        }
        if (this.containsLeaf) {
            this.yAxis.push({
                type: 'value',
                name: this.translate('leafWetting'),
                min: 0, max: 15, splitNumber: 5,
                gridIndex: this.gridPlan.leafWetting.grid,
                position: this.gridPlan.leafWetting.position === 0 ? 'left' : 'right',
                offset: this.gridPlan.leafWetting.position === 2 ? 80 : 0,
                axisLabel: {
                    formatter: '{value}'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(1);
                        }
                    }
                }
            });
            this.leafAxisIndex = currentIndex;
            ++currentIndex;
        }

        if (this.containsSoilHumidity) {
            const tmp = [];
            for (let i = 0; i < this.data.length; i++) {
                for (let [key, value] of Object.entries(this.data[i])) {
                    if (value.data['soilHumidity10']) {
                        tmp.push(value.data['soilHumidity10']);
                    }
                    if (value.data['soilHumidity20']) {
                        tmp.push(value.data['soilHumidity20']);
                    }
                    if (value.data['soilHumidity30']) {
                        tmp.push(value.data['soilHumidity30']);
                    }
                    if (value.data['soilHumidity40']) {
                        tmp.push(value.data['soilHumidity40']);
                    }
                    if (value.data['soilHumidity50']) {
                        tmp.push(value.data['soilHumidity50']);
                    }
                    if (value.data['soilHumidity50']) {
                        tmp.push(value.data['soilHumidity50']);
                    }
                }
            }
            let min = getMin(tmp, 5);
            if (min < 0) {
                min = 100;
            }
            let max = getMax(tmp, 5);
            if (max > 100) {
                max = 100;
            }
            this.yAxis.push({
                type: 'value',
                name: this.translate('soilHumidity'),
                min: min, max: max,
                interval: Math.abs(max - min) / 5,
                gridIndex: this.gridPlan.soilHumidity.grid,
                position: this.gridPlan.soilHumidity.position === 0 ? 'left' : 'right',
                offset: this.gridPlan.soilHumidity.position === 2 ? 80 : 0,
                axisLabel: {
                    formatter: '{value}%'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(0) + '%';
                        }
                    }
                }
            });
            this.soilHumidityAxisIndex = currentIndex;
            ++currentIndex;
        }
        if (this.containsPrecipitation) {
            const tmp = [];
            for (let i = 0; i < this.data.length; i++) {
                for (let [key, value] of Object.entries(this.data[i])) {
                    if (this.settings.measurements.includes('precipitation1')) {
                        tmp.push(value.data['precipitation1']);
                    }
                    if (this.settings.measurements.includes('precipitation2')) {
                        tmp.push(value.data['precipitation2']);
                    }
                    if (this.settings.measurements.includes('precipitationSum')) {
                        tmp.push(value.data['precipitationSum']);
                    }
                    if (this.settings.measurements.includes('precipitationDiurnalSum')) {
                        tmp.push(value.data['precipitationDiurnalSum']);
                    }
                }
            }
            const max = getMax(tmp, 1);
            this.yAxis.push({
                type: 'value',
                name: this.translate('precipitation'),
                min: 0, max: max, splitNumber: 5,
                interval: Math.abs(max) / 5,
                gridIndex: this.gridPlan.precipitation.grid,
                position: this.gridPlan.precipitation.position === 0 ? 'left' : 'right',
                offset: this.gridPlan.precipitation.position === 2 ? 80 : 0,
                axisLabel: {
                    formatter: '{value} mm'
                },
                axisPointer: {
                    label: {
                        formatter: function (params) {
                            return params.value.toFixed(1) + ' mm';
                        }
                    }
                }
            });
            this.precipitationAxisIndex = currentIndex;
        }

    }

    initSeries() {
        const renderStationLabel = this.data.length > 1;
        if (this.settings.measurements.includes('airTemperature200')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'airTemperature200' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('airTemperature200') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'airTemperature200' + this.dataDesc[i]},
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                });
                this.legendData.push({name: this.translate('airTemperature200') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('airTemperature5')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'airTemperature5' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('airTemperature5') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'airTemperature5' + this.dataDesc[i]},
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                });
                this.legendData.push({name: this.translate('airTemperature5') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('airHumidity200')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'airHumidity200' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('airHumidity200') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'airHumidity200' + this.dataDesc[i]},
                    xAxisIndex: 0,
                    yAxisIndex: this.humidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('airHumidity200') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('leafWetting')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'leafWetting' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('leafWetting') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'leafWetting' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.leafWetting.grid,
                    yAxisIndex: this.leafAxisIndex,
                    lineStyle: {
                        type: 'dashed'
                    }
                });
                this.legendData.push({name: this.translate('leafWetting') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('precipitation1')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'precipitation1' + this.dataDesc[i],
                    type: 'custom',
                    name: this.translate('precipitation1') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    barCategoryGap: '0%',
                    encode: {y: 'precipitation1' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.precipitation.grid,
                    yAxisIndex: this.precipitationAxisIndex,
                    renderItem: this.renderCustomBar,
                    color: 'rgba(0, 127, 255, 0.32)'
                });
                this.legendData.push({name: this.translate('precipitation1') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('precipitation2')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'precipitation2' + this.dataDesc[i],
                    type: 'custom',
                    name: this.translate('precipitation2') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    barCategoryGap: '0%',
                    encode: {y: 'precipitation2' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.precipitation.grid,
                    yAxisIndex: this.precipitationAxisIndex,
                    renderItem: this.renderCustomBar,
                    color: 'rgba(255, 127, 0, 0.32)'
                });
                this.legendData.push({name: this.translate('precipitation2') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('precipitationSum')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'precipitationSum' + this.dataDesc[i],
                    type: 'custom',
                    renderItem: this.renderCustomBar,
                    color: 'rgba(0, 255, 0, 0.07)',
                    symbol: 'none',
                    name: this.translate('precipitationSum') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    barCategoryGap: '0%',
                    encode: {y: 'precipitationSum' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.precipitation.grid,
                    yAxisIndex: this.precipitationAxisIndex,
                });
                this.legendData.push({name: this.translate('precipitationSum') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('precipitationDiurnalSum')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'precipitationDiurnalSum' + this.dataDesc[i],
                    type: 'custom',
                    renderItem: this.renderCustomBar,
                    color: 'rgba(255,45,179,0.07)',
                    symbol: 'none',
                    name: this.translate('precipitationDiurnalSum') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    barCategoryGap: '0%',
                    encode: {y: 'precipitationDiurnalSum' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.precipitation.grid,
                    yAxisIndex: this.precipitationAxisIndex,
                });
                this.legendData.push({name: this.translate('precipitationDiurnalSum') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('wind')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'wind' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('wind') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'wind' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.wind.grid,
                    yAxisIndex: this.windAxisIndex,
                });
                this.legendData.push({name: this.translate('wind') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilTemperature15')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilTemperature15' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilTemperature15') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilTemperature15' + this.dataDesc[i]},
                    xAxisIndex: 0,
                    yAxisIndex: this.soilTemperatureAxisIndex,
                });
                this.legendData.push({name: this.translate('soilTemperature15') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilTemperature45')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilTemperature45' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilTemperature45') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilTemperature45' + this.dataDesc[i]},
                    xAxisIndex: 0,
                    yAxisIndex: this.soilTemperatureAxisIndex,
                });
                this.legendData.push({name: this.translate('soilTemperature45') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity10')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity10' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity10') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity10' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity10') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity20')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity20' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity20') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity20' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity20') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity30')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity30' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity30') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity30' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity30') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity40')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity40' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity40') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity40' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity40') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity50')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity50' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity50') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity50' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity50') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
        if (this.settings.measurements.includes('soilHumidity60')) {
            for (let i = 0; i < this.data.length; i++) {
                this.series.push({
                    id: 'soilHumidity60' + this.dataDesc[i],
                    type: 'line',
                    symbol: 'none',
                    name: this.translate('soilHumidity60') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : ''),
                    encode: {y: 'soilHumidity60' + this.dataDesc[i]},
                    xAxisIndex: this.gridPlan.soilHumidity.grid,
                    yAxisIndex: this.soilHumidityAxisIndex,
                    lineStyle: {
                        type: 'dotted'
                    }
                });
                this.legendData.push({name: this.translate('soilHumidity60') + (renderStationLabel ? ' [' + this.dataDesc[i] + ']' : '')});
            }
        }
    }

    initTooltipFormatter() {
        const settings = this.settings;
        const minX = this.minX;
        const maxX = this.maxX;
        this.tooltipFormatter = function (params) {
            if (!params[0].data || params[0].data.time === minX || params[0].data.time === maxX) {
                return '';
            }
            const lines = [];
            lines.push(new Date(params[0].axisValue).toLocaleString() + '<br/>');
            params.forEach(param => {
                const meas = settings.measurements.find(m => param.seriesId.startsWith(m));
                if (meas !== undefined) {
                    const unit = getUnit(meas).name;
                    lines.push(param.seriesName + ": " + (param.data[param.seriesId] !== null && param.data[param.seriesId] !== undefined ? param.data[param.seriesId].toFixed('%' === unit ? 0 : 1) + unit : '') + "<br/>");
                }
            });
            return lines.join('');
        }
    }

    initBackground() {
        const right = this.grid[0].right;
        const left = this.grid[0].left;
        if (!right || !left) {
            return;
        }
        const gridWidth = document.getElementById("data-panel").clientWidth - 17 - right - left;
        const background = document.createElement("canvas");
        background.width = gridWidth + 200;
        background.height = 100;
        let ctx = background.getContext("2d");
        ctx.fillStyle = "#ffffff";
        ctx.fillRect(0, 0, background.width, 100);

        ctx = background.getContext("2d");
        ctx.fillStyle = "#e0e0e0";

        const range = this.lastDate - this.firstDate;
        for (let i = 0; i < this.sunriseAndSunsets.length; i++) {
            let begin = new Date(this.sunriseAndSunsets[i].s);
            let end = new Date(this.sunriseAndSunsets[i].r);
            if (begin < this.firstDate) {
                begin = this.firstDate;
            }
            if (end > this.lastDate) {
                end = this.lastDate;
            }
            if (begin < end) {
                const x = (gridWidth * (begin - this.firstDate) / range) + left;
                const w = gridWidth * (end - begin) / range;
                ctx.fillRect(x, 0, w, 100);
            }
        }
        let gridElement;
        for (gridElement of this.grid) {
            gridElement.show = true;
            gridElement.backgroundColor = {
                image: background,
                repeat: 'repeat-y'
            }
        }
    }

    renderCustomBar(param, api) {
        const xValue = api.value(param.encode.x);
        const highPoint = api.coord([xValue, api.value(param.encode.y[0])]);
        const lowPoint = api.coord([xValue, 0]);
        const style = api.style({
            stroke: api.visual('color'),
            lineWidth: this.rainWidth,
            fill: null
        });
        return {
            type: 'line',
            shape: {
                x1: highPoint[0], y1: highPoint[1],
                x2: lowPoint[0], y2: lowPoint[1]
            },
            style: style
        };
    }
}

function getMin(array, prec) {
    let min = Math.min(...array);
    if (min % prec === 0) {
        min -= 1;
    }
    let roundedMin = min - (min % prec);
    if (min && min < 0) {
        roundedMin = roundedMin - prec;
    }
    return roundedMin;
}

function getMax(array, prec) {
    let max = Math.max(...array) + 1;

    let roundedMax = max + (prec - max % prec);
    if (max && max < 0) {
        roundedMax = roundedMax - prec;
    }
    return roundedMax;
}
