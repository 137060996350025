import {library} from '@fortawesome/fontawesome-svg-core';
import {faDatabase, faHome, faQuestion} from "@fortawesome/free-solid-svg-icons";
import {faFolder} from "@fortawesome/free-solid-svg-icons/faFolder";
import {faCogs} from "@fortawesome/free-solid-svg-icons/faCogs";
import {faUsersCog} from "@fortawesome/free-solid-svg-icons/faUsersCog";
import {faFileAlt} from "@fortawesome/free-solid-svg-icons/faFileAlt";
import {faUserCog} from "@fortawesome/free-solid-svg-icons/faUserCog";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons/faEnvelope";
import {faUser} from "@fortawesome/free-solid-svg-icons/faUser";
import {faSignOutAlt} from "@fortawesome/free-solid-svg-icons/faSignOutAlt";
import {faMapMarkedAlt} from "@fortawesome/free-solid-svg-icons/faMapMarkedAlt";
import {faClipboardList} from "@fortawesome/free-solid-svg-icons/faClipboardList";
import {faClipboard} from "@fortawesome/free-solid-svg-icons/faClipboard";
import {faBoxOpen} from "@fortawesome/free-solid-svg-icons/faBoxOpen";
import {faMonument} from "@fortawesome/free-solid-svg-icons/faMonument";
import {faBook} from "@fortawesome/free-solid-svg-icons/faBook";
import {faTag} from "@fortawesome/free-solid-svg-icons/faTag";
import {faStream} from "@fortawesome/free-solid-svg-icons/faStream";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import {faEdit} from "@fortawesome/free-solid-svg-icons/faEdit";
import {faTrashAlt} from "@fortawesome/free-solid-svg-icons/faTrashAlt";
import {faPlus} from "@fortawesome/free-solid-svg-icons/faPlus";
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {faBan} from "@fortawesome/free-solid-svg-icons/faBan";
import {faSave} from "@fortawesome/free-solid-svg-icons/faSave";
import {faList} from "@fortawesome/free-solid-svg-icons/faList";
import {faTh} from "@fortawesome/free-solid-svg-icons/faTh";
import {faFilePdf} from "@fortawesome/free-solid-svg-icons/faFilePdf";
import {faFileImage} from "@fortawesome/free-solid-svg-icons/faFileImage";
import {faFileWord} from "@fortawesome/free-solid-svg-icons/faFileWord";
import {faFile} from "@fortawesome/free-solid-svg-icons/faFile";
import {faAngleLeft} from "@fortawesome/free-solid-svg-icons/faAngleLeft";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons/faAngleRight";
import {faBroom} from "@fortawesome/free-solid-svg-icons/faBroom";
import {faExclamationCircle} from "@fortawesome/free-solid-svg-icons/faExclamationCircle";
import {faBell} from "@fortawesome/free-solid-svg-icons/faBell";
import {faHistory} from "@fortawesome/free-solid-svg-icons/faHistory";
import {faFileCsv} from "@fortawesome/free-solid-svg-icons/faFileCsv";
import {faChevronUp} from "@fortawesome/free-solid-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/free-solid-svg-icons/faChevronDown";
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import {faSort} from "@fortawesome/free-solid-svg-icons/faSort";
import {faSortUp} from "@fortawesome/free-solid-svg-icons/faSortUp";
import {faSortDown} from "@fortawesome/free-solid-svg-icons/faSortDown";

library.add(
    faHome,
    faDatabase,
    faQuestion,
    faFolder,
    faCogs,
    faUsersCog,
    faFileAlt,
    faUserCog,
    faEnvelope,
    faUser,
    faSignOutAlt,
    faMapMarkedAlt,
    faClipboardList,
    faClipboard,
    faBoxOpen,
    faMonument,
    faBook,
    faTag,
    faStream,
    faInfoCircle,
    faEdit,
    faTrashAlt,
    faPlus,
    faCheck,
    faTimes,
    faSave,
    faBan,
    faList,
    faTh,
    faFilePdf,
    faFileImage,
    faFileWord,
    faFile,
    faAngleLeft,
    faAngleRight,
    faBroom,
    faExclamationCircle,
    faBell,
    faHistory,
    faFileCsv,
    faChevronUp,
    faChevronDown,
    faSearch,
    faSort,
    faSortUp,
    faSortDown
);