export const calendarPl = {
    firstDayOfWeek: 1,
    dayNames: ["niedziela", "poniedziałek", "wtorek", "środa", "czwartek", "piątek", "sobota"],
    dayNamesShort: ["nie", "pon", "wto", "śr", "czw", "pią", "sob"],
    dayNamesMin: ["N", "Pon", "W", "Ś", "Cz", "Pią", 'S'],
    monthNames: ["styczeń", "luty", "marzec", "kwiecień", "maj", "czerwiec", "lipiec", "sierpień", "wrzesień", "październik", "listopad", "grudzień"],
    monthNamesShort: ["sty", "lut", "mar", "kwi", "maj", "cze", "lip", "sie", "wrz", "paź", "lis", "gru"],
    today: 'Dziś',
    clear: 'Wyczyść',
    dateFormat: 'yyyy-mm-dd',
    weekHeader: 'Tydzień'
};