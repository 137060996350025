import React from 'react';
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Dialog} from "primereact/dialog";
import {Button, Container, Form, FormGroup, Label, UncontrolledAlert} from "reactstrap";
import {InputText} from "primereact/inputtext";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Growl} from "primereact/growl";
import {withTranslation} from "react-i18next";
import MeteoStationService from "../admin/Stations/MeteoStationService";
import SettingsService from "../admin/MenuSettings/SettingsService";
import {Dropdown} from "primereact/dropdown";

class Alarms extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alarms: [],
            stations: [],
            measurements: [],
            operators: ["GREATER_THAN", "LESS_THAN", "GREATER_EQUALS", "LESS_EQUALS"],
            selected: null,
            displayDialog: false,
            alarm: null,
            validValue: true,
            validStation: true,
            validMeasurementType: true,
            validOperator: true,
            validUniqueStationMeasTypeAndOper: true,
            validationErrors: false,
            selectedTypeFilter: null,
            selectedMeasurementTypeFilter: null,
            selectedStationFilter: null,
            sendingRequest: false
        };

        this.meteoStationService = new MeteoStationService();
        this.settingsService = new SettingsService();
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.meteoStationService.getAll()
            .then(res => this.setState({stations: res}));
        this.settingsService.getAvailableMeasurement()
            .then(res => this.setState({measurements: res}));
        this.settingsService.getAlarms()
            .then(res => {
                let selected = null;
                const alarmUuid = this.props.match.params.alarmUuid;
                if (alarmUuid !== undefined) {
                    let found = res.find(alarm => alarm.uuid === alarmUuid);
                    if (found !== undefined) {
                        selected = found;
                    }
                    if (selected === null) {
                        this.growl.current && this.growl.current.show({
                            severity: 'error',
                            summary: 'Nie znaleziono żądnego alarmu'
                        });
                    }
                }
                this.setState({
                    alarms: res,
                    selected,
                    displayDialog: selected !== null,
                    alarm: selected !== null ? Object.assign({}, selected) : null
                })
            });
    }

    onSelect = (e) => {
        this.setState({
            displayDialog: true,
            alarm: Object.assign({}, e.data)
        });
    };

    save = () => {
        let alarm = this.state.alarm;
        let validationErrors = false;
        let validValue = true;
        if (alarm.value === null || alarm.value === '') {
            validValue = false;
            validationErrors = true;
        }
        let validStation = true;
        if (alarm.stationUuid === null || this.state.stations.filter(st => st.uuid === alarm.stationUuid).length === 0) {
            validStation = false;
            validationErrors = true;
        }
        let validMeasurementType = true;
        if (alarm.measurementType === null || this.state.measurements.filter(ms => ms === alarm.measurementType).length === 0) {
            validMeasurementType = false;
            validationErrors = true;
        }
        let validOperator = true;
        if (alarm.operator === null || this.state.operators.filter(op => op === alarm.operator).length === 0) {
            validOperator = false;
            validationErrors = true;
        }
        let validUniqueStationMeasTypeAndOper = true;
        if (alarm.measurementType !== null && alarm.station !== null &&
            this.state.alarms.filter(v => v.stationUuid === alarm.stationUuid && v.measurementType === alarm.measurementType && v.operator === alarm.operator && v.uuid !== alarm.uuid).length > 0) {
            validUniqueStationMeasTypeAndOper = false;
            validationErrors = true;
        }
        if (validationErrors) {
            this.setState({
                validationErrors,
                validValue,
                validStation,
                validMeasurementType,
                validOperator,
                validUniqueStationMeasTypeAndOper
            })
        } else {
            this.setState({
                sendingRequest: true
            });
            if (alarm.uuid) {
                this.update(alarm);
            } else {
                this.create(alarm);
            }
        }
    };

    create(alarm) {
        this.settingsService.createAlarm(alarm)
            .then(res => {
                this.setState(state => {
                    let alarms = [...state.alarms];
                    alarms.push(res);
                    return {
                        alarms,
                        selected: null,
                        alarm: null,
                        displayDialog: false,
                        validValue: true,
                        validStation: true,
                        validMeasurementType: true,
                        validOperator: true,
                        validUniqueStationMeasTypeAndOper: true,
                        validationErrors: false
                    }
                });
                this.growl.current.show({severity: 'success', summary: this.props.t('alarm-msg-add-alarm-success')});
            }).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: this.props.t('alarm-msg-add-alarm-failure')});
        })
            .finally(() => {
                this.setState({
                    sendingRequest: false
                });
            });
    }

    update(alarm) {
        this.settingsService.updateAlarm(alarm.uuid, alarm)
            .then(() => {
                    this.setState(state => {
                        let alarms = [...state.alarms];
                        let index = state.alarms.indexOf(state.selected);
                        alarms[index] = this.state.alarm;
                        return {
                            alarms: alarms,
                            selected: null,
                            alarm: null,
                            displayDialog: false,
                            validValue: true,
                            validStation: true,
                            validMeasurementType: true,
                            validOperator: true,
                            validUniqueStationMeasTypeAndOper: true,
                            validationErrors: false
                        }
                    });
                    this.growl.current.show({severity: 'success', summary: this.props.t('alarm-msg-edit-alarm-success')});
                }
            ).catch(res => {
            console.error(res);
            this.growl.current.show({severity: 'error', summary: this.props.t('alarm-msg-edit-alarm-failure')});
        })
            .finally(() => {
                this.setState({
                    sendingRequest: false
                });
            });
    }

    delete = () => {
        this.settingsService.deleteAlarm(this.state.selected.uuid)
            .then(() => {
                this.setState(state => {
                    let index = state.alarms.indexOf(state.selected);
                    return {
                        alarms: state.alarms.filter((val, i) => i !== index),
                        selected: null,
                        alarm: null,
                        displayDialog: false,
                        displayDeleteDialog: false
                    }
                });
                this.growl.current.show({severity: 'success', summary: this.props.t('alarm-msg-delete-alarm-success')});
            })
            .catch(res => {
                console.error(res);
                this.growl.current.show({severity: 'error', summary: this.props.t('alarm-msg-delete-alarm-failure')});
            })
            .finally(() => {
                this.setState({
                    sendingRequest: false
                });
            });
    };

    addNew = () => {
        this.setState({
            alarm: {stationUuid: null, measurementType: null, operator: null, value: ""},
            displayDialog: true
        });
    };

    stationTemplate = (rowData) => {
        const station = this.state.stations.find(station => station.uuid === rowData.stationUuid);
        if (station === undefined) {
            return ''
        }
        return <span>{this.props.t('station-select-label', {
            stationName: station.city,
            countyName: station.county
        })}</span>;
    };

    measurementTypeTemplate = (rowData) => {
        return <span>{this.props.t(rowData.measurementType)}</span>;
    };

    operatorTemplate = (rowData) => {
        return <span>{this.props.t('alarm-operator-' + rowData.operator.replace('_', '-').toLowerCase())}</span>;
    };

    handleStationFilterChange = (event) => {
        this.dt.filter(event.value, 'stationUuid', 'equals');
        this.setState({selectedStationFilter: event.value});
    };

    handleOperatorFilterChange = (event) => {
        this.dt.filter(event.value, 'operator', 'equals');
        this.setState({selectedTypeFilter: event.value});
    };

    handleMeasurementTypeFilterChange = (event) => {
        this.dt.filter(event.value, 'measurementType', 'equals');
        this.setState({selectedMeasurementTypeFilter: event.value});
    };

    render() {
        let footer = <div className='p-clearfix' style={{width: '100%'}}>
            <Button color='primary' style={{float: 'left'}} onClick={this.addNew}>
                <FontAwesomeIcon icon='plus'/> {this.props.t('common:action-add')}
            </Button>
        </div>;

        const stationItems = this.state.stations.map(item => {
            return {
                label: this.props.t('station-select-label', {stationName: item.city, countyName: item.county}),
                key: item.uuid,
                value: item.uuid
            }
        });
        const filterStationItems = [
            {
                label: this.props.t('alarm-station-all-filter'),
                key: 'all',
                value: null
            }, ...stationItems];
        const stationFilter = <Dropdown key="stationFilterDropdown"
                                        className="p-column-filter"
                                        value={this.state.selectedStationFilter}
                                        options={filterStationItems}
                                        onChange={this.handleStationFilterChange}/>;

        const measurementTypeItems = this.state.measurements.map(item => {
            return {
                label: this.props.t(item),
                key: item,
                value: item
            }
        });
        const filterMeasurementTypeItems = [
            {
                label: this.props.t('alarm-measurement-type-all-filter'),
                key: 'all',
                value: null
            }, ...measurementTypeItems];
        const measurementTypeFilter = <Dropdown key="measurementTypeFilterDropdown"
                                                className="p-column-filter"
                                                value={this.state.selectedMeasurementTypeFilter}
                                                options={filterMeasurementTypeItems}
                                                onChange={this.handleMeasurementTypeFilterChange}/>;

        const operatorItems = [];
        this.state.operators.map(op => {
            operatorItems.push({
                label: this.props.t('alarm-operator-' + op.replace('_', '-').toLowerCase()),
                key: op,
                value: op
            })
        });
        const filterOperatorItems = [
            {
                label: this.props.t('alarm-operator-all-filter'),
                key: 'all',
                value: null
            }, ...operatorItems];
        const operatorFilter = <Dropdown key="operatorFilterDropdown"
                                         className="p-column-filter"
                                         value={this.state.selectedTypeFilter}
                                         options={filterOperatorItems}
                                         onChange={this.handleOperatorFilterChange}/>;


        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button color='primary' onClick={this.save} disabled={this.state.sendingRequest}>
                <FontAwesomeIcon icon='check'/> {this.props.t('common:action-save')}
            </Button>
            {this.state.alarm && this.state.alarm.uuid && <Button color='primary' onClick={
                () => this.setState({displayDialog: false, displayDeleteDialog: true})
            }>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({
                validValue: true,
                validStation: true,
                validMeasurementType: true,
                validOperator: true,
                validUniqueStationMeasTypeAndOper: true,
                validationErrors: false,
                displayDialog: false
            })}>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;

        const deleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            {this.state.alarm && this.state.alarm.uuid &&
            <Button color='primary' onClick={this.delete}>
                <FontAwesomeIcon icon='trash-alt'/> {this.props.t('common:action-delete')}
            </Button>}
            <Button color='primary' onClick={() => this.setState({displayDeleteDialog: false})}>
                <FontAwesomeIcon icon='times'/> {this.props.t('common:action-cancel')}
            </Button>
        </div>;

        return (
            <Container>
                <Growl ref={this.growl}/>
                <DataTable value={this.state.alarms} paginator={true} rows={20}
                           ref={(el) => this.dt = el}
                           responsive={true}
                           footer={footer}
                           selectionMode="single"
                           selection={this.state.selected}
                           onRowSelect={this.onSelect}
                           onSelectionChange={e => this.setState({selected: e.value})}
                >
                    <Column field="stationUuid" header={this.props.t('data-station')}
                            body={this.stationTemplate}
                            filterElement={stationFilter}
                            sortable={true} filter={true}/>
                    <Column field="measurementType" header={this.props.t('data-table-measurement-type')}
                            body={this.measurementTypeTemplate}
                            filterElement={measurementTypeFilter}
                            filter={true}/>
                    <Column field="operator" header={this.props.t('alarm-operator')} body={this.operatorTemplate}
                            filter={true}
                            filterElement={operatorFilter}/>
                    <Column field="value" header={this.props.t('common:value')} filter={true}/>
                </DataTable>

                <Dialog visible={this.state.displayDialog} width="600px" header={this.props.t('alarm-meteorological')}
                        modal={true}
                        footer={dialogFooter}
                        onHide={() => this.setState({displayDialog: false})}>
                    {
                        this.state.alarm &&
                        <Form autocomplete="off">
                            <FormGroup>
                                <Label for='station'>{this.props.t('data-station')}*</Label><br/>
                                <Dropdown name='station' key="stationDropdown"
                                          style={{width: "20em"}}
                                          filter={true} filterPlaceholder={this.props.t('alarm-station-new-filter')}
                                          filterBy="label,value"
                                          value={this.state.alarm.stationUuid} options={stationItems}
                                          className={this.state.validStation && this.state.validUniqueStationMeasTypeAndOper ? '' : 'form-control is-invalid'}
                                          onChange={(e) => {
                                              let alarm = Object.assign({}, this.state.alarm);
                                              alarm.stationUuid = e.value;
                                              this.setState({alarm});
                                          }} placeholder={this.props.t('admin:data-choose-station')}/>
                                <div className="invalid-feedback">
                                    {!this.state.validStation &&
                                    <span>{this.props.t('common:concrete-input-required', {inputName: this.props.t('data-station')})}</span>}
                                    {!this.state.validUniqueStationMeasTypeAndOper &&
                                    <span>{this.props.t('alarm-station-measurement-type-operator-unique')}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='measurementType'>{this.props.t('data-table-measurement-type')}*</Label><br/>
                                <Dropdown name='measurementType' key="measurementType"
                                          value={this.state.alarm.measurementType}
                                          style={{width: "20em"}}
                                          filter={true}
                                          filterPlaceholder={this.props.t('alarm-measurement-type-new-filter')}
                                          filterBy="label,value"
                                          className={this.state.validMeasurementType && this.state.validUniqueStationMeasTypeAndOper ? '' : 'form-control is-invalid'}
                                          options={measurementTypeItems}
                                          onChange={(e) => {
                                              let alarm = Object.assign({}, this.state.alarm);
                                              alarm.measurementType = e.value;
                                              this.setState({alarm});
                                          }} placeholder={this.props.t('admin:data-choose-measurement-type')}/>
                                <div className="invalid-feedback">
                                    {!this.state.validMeasurementType &&
                                    <span>{this.props.t('common:concrete-input-required', {inputName: this.props.t('data-table-measurement-type')})}</span>}
                                    {!this.state.validUniqueStationMeasTypeAndOper &&
                                    <span>{this.props.t('alarm-station-measurement-type-operator-unique')}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='operator'>{this.props.t('alarm-operator')}*</Label><br/>
                                <Dropdown name='operator' key="operatorDropdown"
                                          style={{width: "20em"}}
                                          value={this.state.alarm.operator}
                                          className={this.state.validOperator && this.state.validUniqueStationMeasTypeAndOper ? '' : 'form-control is-invalid'}
                                          options={operatorItems}
                                          onChange={(e) => {
                                              let alarm = Object.assign({}, this.state.alarm);
                                              alarm.operator = e.value;
                                              this.setState({alarm});
                                          }} placeholder={this.props.t('alarm-choose-operator')}/>
                                <div className="invalid-feedback">
                                    {!this.state.validOperator &&
                                    <span>{this.props.t('common:concrete-input-required', {inputName: this.props.t('alarm-operator')})}</span>}
                                    {!this.state.validUniqueStationMeasTypeAndOper &&
                                    <span>{this.props.t('alarm-station-measurement-type-operator-unique')}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label for='value'>{this.props.t('common:value')}*</Label><br/>
                                <InputText name='value' type="text" keyfilter="num"
                                           style={{width: "20em"}}
                                           className={this.state.validValue ? '' : 'form-control is-invalid'}
                                           value={this.state.alarm.value}
                                           onChange={(e) => {
                                               let alarm = Object.assign({}, this.state.alarm);
                                               alarm.value = e.target.value;
                                               this.setState({alarm});
                                           }}/>
                                <div className="invalid-feedback">
                                    {!this.state.validValue &&
                                    <span>{this.props.t('common:concrete-input-required', {inputName: this.props.t('common:value')})}</span>}
                                </div>
                            </FormGroup>
                            <FormGroup>
                                <Label>{this.props.t('common:legend-required')}</Label>
                            </FormGroup>
                        </Form>
                    }
                </Dialog>

                <Dialog visible={this.state.displayDeleteDialog}
                        header={this.props.t('common:delete-confirmation')} footer={deleteFooter} modal={true}
                        onHide={() => this.setState({displayDeleteDialog: false})}>
                    {
                        this.state.displayDeleteDialog && this.state.alarm && this.state.alarm.uuid &&
                        <span>{this.props.t('alarm-delete-confirm-msg', {
                            "alarmDsc": this.props.t('alarm-desc', {
                                "stationName": this.state.stations.find(item => item.uuid === this.state.alarm.stationUuid).city,
                                "operator": this.props.t('alarm-operator-' + this.state.alarm.operator.replace('_', '-').toLowerCase()),
                                "sensorName": this.props.t(this.state.alarm.measurementType),
                                "value": this.state.alarm.value
                            })
                        })}</span>
                    }
                </Dialog>

            </Container>
        )
    }

}

export default withTranslation('meteo')(Alarms);
