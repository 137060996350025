import ApiService from '../../service/common/ApiService'

export default class MeteoService {

    getCurrentData() {
        return ApiService.get('/meteostation/measurement/current');
    }

    getData(id, to) {
        return ApiService.get('/meteostation/' + id + '/measurement?to=' + to);
    }

    getByBusinessId(id) {
        return ApiService.get('/meteostation/' + id);
    }

    getAllStations() {
        return ApiService.get('/meteostation');
    }

    getDataPage(id, to, first, rows) {
        return ApiService.get('/meteostation/' + id + '/measurement/' + first + '/' + rows + '?to=' + to);
    }

    getAllMeasurementTypes() {
        return Promise.resolve(['airTemperature200', 'airHumidity200', 'wind', 'airTemperature5',
            'precipitation1', 'precipitation2', 'leafWetting', 'soilTemperature15', 'soilTemperature45',
            'soilHumidity10', 'soilHumidity20', 'soilHumidity30', 'soilHumidity40', 'soilHumidity50', 'soilHumidity60']);
    }

    getAllPeriodicity() {
        return Promise.resolve(['min15', 'min30', 'h1', 'h3', 'h6', 'h8', 'h12', 'h24']);
    }
}