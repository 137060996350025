import ApiService from '../../../service/common/ApiService'

class IrrigationService {

    create(data) {
        return ApiService.post('/irrigations', data);
    }

    update(id, data) {
        return ApiService.put('/irrigations/' + id, data);
    }

    delete(id) {
        return ApiService.delete('/irrigations/' + id);
    }

}

export default IrrigationService;
