import ApiService from '../../../service/common/ApiService'

export default class MeteoStationService {

    getAllActive() {
        return ApiService.get('/messages?active=true');
    }

    getAll() {
        return ApiService.get('/messages');
    }

    getMessageTypes() {
        return Promise.resolve(['INFO', 'WARN', 'ERROR']);
    }

    create(data) {
        return ApiService.post('/messages/', data);
    }

    delete(id) {
        return ApiService.delete('/messages/' + id);
    }

    update(id, data) {
        return ApiService.put('/messages/' + id, data);
    }

}