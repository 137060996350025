import ApiService from "../../../service/common/ApiService";

export default class SettingsService {

    getGlobalSettingsFormDTO() {
        return ApiService.get('/settings/global/form');
    }

    getGlobalSettingsOnlyMeteoFormDTO() {
        return ApiService.get('/settings/global/form/meteo');
    }

    getGlobalSettingsOnlyDocumentFormDTO() {
        return ApiService.get('/settings/global/form/document');
    }

    getGlobalSettings() {
        return ApiService.get('/settings/global');
    }

    getGlobalSettingsOnlyMeteo() {
        return ApiService.get('/settings/global/meteo');
    }

    getGlobalSettingsOnlyDocument() {
        return ApiService.get('/settings/global/document');
    }

    getAvailableMeasurement() {
        return ApiService.get('/settings/measurements');
    }

    saveGlobal(dto) {
        return ApiService.post('/settings/global/form', dto);
    }

    saveGlobalOnlyMeteo(dto) {
        return ApiService.post('/settings/global/form/meteo', dto);
    }

    saveGlobalOnlyDocument(dto) {
        return ApiService.post('/settings/global/form/document', dto);
    }

    saveDataFormDefault(dto) {
        return ApiService.postJson('/settings/default/data', dto);
    }

    loadDataFormDefault() {
        return ApiService.get('/settings/default/data');
    }

    getAlarms() {
        return ApiService.get('/settings/alarms');
    }

    createAlarm(data) {
        return ApiService.post('/settings/alarms', data);
    }

    updateAlarm(id, data) {
        return ApiService.put('/settings/alarms/' + id, data);
    }

    deleteAlarm(id) {
        return ApiService.delete('/settings/alarms/' + id);
    }

    getStationSettings(id) {
        return ApiService.get('/settings/stations/simple/'+id)
    }
}