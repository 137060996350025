import React from 'react';
import {withTranslation} from 'react-i18next';
import {Container} from "reactstrap";
import './Growth.css';

class Growth extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <Container className='p-4 growth-panel'>
                <h2 className='mb-4 mt-4'>Fazy rozwoju zboża</h2>
                <article className='mt-4'>
                    <h3>Skala BBCH zboża</h3>
                    <section>
                        <p>
                            Skale BBCH – skale wykorzystywane w państwach UE do identyfikacji fitofenologicznych faz
                            roślin uprawnych. Seria skał BBCH została opracowana dla wielu gatunków roślin jedno- i
                            dwuliściennych, i dzięki możliwości szczegółowego określenia etapu rozwoju rośliny, jest
                            używana w wielu dyscyplinach naukowych (fizjologii, fitopatologii, entomologii, hodowli
                            roślin) oraz w rolnictwie (czas stosowania pestycydów, zapylanie, ubezpieczenia rolne).
                            Skale BBCH wykorzystują system kodu dziesiętnego, który dzieli się na zasadnicze i
                            drugorzędne fazy rozwoju. W przypadku skali służącej do oceny faz rozwoju zbóż oparty jest
                            na systemie kodowym opracowanym przez Zadoks (skala Zadoks).
                        </p>
                    </section>
                    <section>
                        <h3>Fazy rozwojowe</h3>
                        <p>Wyróżnia się 10 faz głównych ponumerowanych cyframi od 0 do 9, jednak żeby jeszcze dokładniej
                            określić daną fazę dodano do każdej z nich dodatkową drugą cyfrę, która bardziej
                            charakteryzuje daną fazę.</p>
                        <div className='growth-img'>
                            <img src={process.env.PUBLIC_URL + '/fazy_pszenica_jara.png'} alt='' style={{width: '90%'}}
                                 title='Fazy rozwoju pszenicy jarej'/>
                            <a href={process.env.PUBLIC_URL + '/fazy_pszenica_jara.png'} target='_blank'
                               rel="noopener noreferrer" >Fazy rozwoju
                                pszenicy jarej</a>
                        </div>
                        <div className='growth-img'>
                            <img src={process.env.PUBLIC_URL + '/fazy_pszenica_ozima.png'} alt='' style={{width: '90%'}}
                                 title='Fazy rozwoju pszenicy ozimej'/>
                            <a href={process.env.PUBLIC_URL + '/fazy_pszenica_ozima.png'} target='_blank'
                               rel="noopener noreferrer" >Fazy rozwoju
                                pszenicy ozimej</a>
                        </div>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 0: Kiełkowanie</h3>
                        <ul>
                            <li>
                                00 Suchy ziarniak
                            </li>
                            <li>01 Początek pęcznienia, ziarniak miękki typowej wielkości</li>
                            <li>03 Koniec pęcznienia, ziarniak napęczniały</li>
                            <li>05 Korzeń zarodkowy wydostaje się z ziarniaka</li>
                            <li>06 Korzeń zarodkowy wzrasta, widoczne włośniki i korzenie boczne</li>
                            <li>07 Pochewka liściowa (koleoptyl) wydostaje się z ziarniaka</li>
                            <li>09 Pochewka liściowa przebija się na powierzchnię gleby</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 1: Rozwój liści</h3>
                        <ul>
                            <li>10 Z pochewki liściowej (koleoptyla) wydobywa się pierwszy liść (szpilkowanie)</li>
                            <li>11 Faza 1. liścia</li>
                            <li>12 Faza 2. liścia</li>
                            <li>13 Faza 3. liścia (zwykle zaczyna się krzewienie)</li>
                            <li>Fazy kolejnych liści oznaczone następnymi cyframi (przeważnie od fazy 3. liścia zaczyna
                                się krzewienie)
                            </li>
                            <li>19 Faza 9. liścia lub więcej</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 2: Krzewienie</h3>
                        <ul>
                            <li>20 Brak rozkrzewień</li>
                            <li>21 Początek fazy krzewienia: widoczne 1. rozkrzewienie (zwykle faza 3. liścia)</li>
                            <li>22 Widoczne 2 rozkrzewienia</li>
                            <li>23 Widoczne 3 rozkrzewienia</li>
                            <li>… fazy kolejnych rozkrzewień oznaczone następnymi cyframi</li>
                            <li>29 Koniec fazy krzewienia – widoczna maksymalna liczba rozkrzewień</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 3: Strzelanie w źdźbło, wzrost pędu na długość</h3>
                        <ul>
                            <li>30 Początek wzrostu źdźbła: węzeł krzewienia podnosi się, pierwsze międzywęźle krótsze
                                niż 1 cm
                            </li>
                            <li>31 Pierwsze (liczone od dołu) kolanko co najmniej 1 cm nad węzłem krzewienia</li>
                            <li>32 Drugie kolanko co najmniej 2 cm nad kolankiem pierwszym</li>
                            <li>33 Trzecie kolanko co najmniej 2 cm nad kolankiem drugim</li>
                            <li>37 Widoczny liść flagowy, ale jeszcze nierozwinięty, kłos zaczyna pęcznieć</li>
                            <li>39 Faza liścia flagowego – liść flagowy całkowicie rozwinięty, widoczny języczek
                                ostatniego liścia
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 4: Grubienie pochwy liściowej liścia flagowego</h3>
                        <ul>
                            <li>41 Początek grubienia (nabrzmiewania) pochwy liściowej liścia flagowego (wczesna faza
                                rozwoju kłosa lub wiechy)
                            </li>
                            <li>47 Otwiera się pochwa liściowa liścia flagowego</li>
                            <li>49 Widoczne pierwsze ości</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 5: Kłoszenie (wierzchowanie)</h3>
                        <ul>
                            <li>51 Początek kłoszenia: szczyt kwiatostanu wyłania się z pochwy, widoczny pierwszy
                                kłosek
                            </li>
                            <li>52 Odsłania się 20% kwiatostanu</li>
                            <li>53 Odsłania się 30% kwiatostanu</li>
                            <li>… kolejną cyfrą oznacza się następną dziesiątkę procent wyłonienia kwiatostanu</li>
                            <li>59 Zakończenie fazy kłoszenia, kłos (wiecha) całkowicie widoczny</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 6: Kwitnienie</h3>
                        <ul>
                            <li>61 Początek fazy kwitnienia: widoczne pierwsze pylniki</li>
                            <li>65 Pełnia fazy kwitnienia, wykształconych 50% pylników</li>
                            <li>69 Koniec fazy kwitnienia, wszystkie kłoski zakończyły kwitnienie, widoczne zaschnięte
                                pylniki
                            </li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 7: Rozwój ziarniaków</h3>
                        <ul>
                            <li>71 Dojrzałość wodna: pierwsze ziarniaki wodniste, osiągnęły połowę typowej wielkości
                            </li>
                            <li>73 Początek dojrzałości mlecznej</li>
                            <li>75 Pełna dojrzałość mleczna ziarniaków, ziarniaki osiągnęły typową wielkość, źdźbło
                                zielone
                            </li>
                            <li>77 Dojrzałość późnomleczna ziarniaków</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 8: Dojrzewanie</h3>
                        <ul>
                            <li>83 Początek dojrzałości woskowej ziarniaków (ziarniaki tracą mleczny sok, stają się
                                mączyste)
                            </li>
                            <li>85 Dojrzałość woskowa miękka, ziarniaki łatwo rozcierają się między palcami</li>
                            <li>87 Dojrzałość woskowa twarda, ziarniaki łatwo złamać paznokciem</li>
                            <li>89 Dojrzałość pełna, ziarniaki twarde, trudne do podzielenia paznokciem</li>
                        </ul>
                    </section>
                    <section>
                        <h3>Główna faza rozwojowa 9: Zamieranie</h3>
                        <ul>
                            <li>92 Dojrzałość martwa, ziarniaki bardzo twarde, nie można w nie wbić paznokcia</li>
                            <li>93 Ziarniaki luźno ułożone w kłosie, mogą się osypać</li>
                            <li>97 Roślina więdnie i zamiera</li>
                            <li>99 Zebrane ziarno, okres spoczynku</li>
                        </ul>
                    </section>
                </article>
            </Container>
        )
    }

}

export default withTranslation('grains')(Growth);
