import React from 'react';
import {AvFeedback, AvField, AvForm, AvGroup, AvInput} from "availity-reactstrap-validation";
import {Button, ButtonToolbar, Col, Container, Label, Row} from "reactstrap";
import UserService from "./UserService";
import {withTranslation} from "react-i18next";
import rodo from '../rodo/rodo.json';
import UserCustomForm from "./UserCustomForm";
import {Growl} from "primereact/growl";

class UserRegistration extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            firstName: '',
            lastName: '',
            confirmPassword: '',
            validationErrors: false
        };
        this.userService = new UserService();
        this.customForm = null;
        this.growl = React.createRef();
    }

    componentDidMount() {
    }

    onValidSubmit = () => {
        const dto = {
            email: this.state.email,
            password: this.state.password,
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            roles: [],
            custom: this.customForm.getCustomDataDto()
        };
        this.userService.create(dto)
            .then(() => {
                this.props.history.push('/?success=registrationComplete');
            })
            .catch((e) => {
                console.error(e);
                this.growl.current.show({severity: 'error', summary: 'Błąd podczas rejestracji konta.'});
            });
    };

    validateEmail = (value, ctx, input, cb) => {
        if (value) {
            this.userService.emailExists(value)
                .then(exists => {
                    if (exists) {
                        cb(this.props.t('registration-email-used'));
                    } else {
                        cb(true);
                    }
                });
        }
    };

    render() {
        return (
            <Container className='p-3'>
                <Growl ref={this.growl}/>
                <AvForm onValidSubmit={this.onValidSubmit} className='registration-form'>
                    <h1>
                        {this.props.t('register_header')}
                    </h1>
                    <Row>
                        <Col>
                            <AvGroup>
                                <Label for="firstName">{this.props.t('admin:user-form-first-name') + ' *'}</Label>
                                <AvInput id="firstName" name="firstName"
                                         value={this.state.firstName}
                                         onChange={e => this.setState({firstName: e.target.value})}
                                         required/>
                                <AvFeedback>{this.props.t('admin:user-form-first-name-required')}</AvFeedback>
                            </AvGroup>
                        </Col>
                        <Col>
                            <AvGroup>
                                <Label for="lastName">{this.props.t('admin:user-form-last-name') + ' *'}</Label>
                                <AvInput id="lastName" name="lastName"
                                         value={this.state.lastName}
                                         onChange={e => this.setState({lastName: e.target.value})}
                                         required/>
                                <AvFeedback>{this.props.t('admin:user-form-last-name-required')}</AvFeedback>
                            </AvGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField name="email" id="email" label="E-mail*"
                                     type="text"
                                     onChange={e => this.setState({email: e.target.value})}
                                     value={this.state.email}
                                     validate={{
                                         email: {
                                             value: true,
                                             errorMessage: this.props.t('registration-email-invalid-format')
                                         },
                                         required: {value: true, errorMessage: this.props.t('common:input-required')},
                                         unique: this.validateEmail
                                     }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AvField name="password" id="password" label={this.props.t('password') + ' *'}
                                     autoComplete="off"
                                     type="password"
                                     onChange={e => this.setState({password: e.target.value})}
                                     value={this.state.password}
                                     validate={{
                                         required: {value: true, errorMessage: this.props.t('common:input-required')},
                                     }}/>
                        </Col>
                        <Col>
                            <AvField name="confirmPassword" id="confirmPassword"
                                     label={this.props.t('password_confirm') + ' *'}
                                     autoComplete="off"
                                     type="password"
                                     onChange={(e) => {
                                         this.setState({confirmPassword: e.target.value});
                                     }}
                                     value={this.state.confirmPassword}
                                     validate={{
                                         required: {value: true, errorMessage: this.props.t('common:input-required')},
                                         match: {
                                             value: 'password',
                                             errorMessage: this.props.t('registration-password-not-match')
                                         },
                                     }}/>
                        </Col>
                    </Row>

                    <UserCustomForm reg={ref => this.customForm = ref}/>

                    <div><i>* {this.props.t('common:inputs-required')}</i></div>

                    <div>
                        <span>
                            {this.props.t('register_dsc')}
                        </span>
                    </div>
                    <ButtonToolbar style={{"marginTop": "15px"}}>
                        <Button type="submit" color='primary'>
                            Utwórz
                        </Button>
                    </ButtonToolbar>
                    <hr/>
                    <article style={{fontSize: '90%'}}>
                        <p>{rodo.registrationTemplate}</p>
                    </article>
                </AvForm>
            </Container>
        );
    }
}

export default withTranslation(['login', 'admin', 'common'])(UserRegistration);
