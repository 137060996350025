import React from 'react';
import rodo from './rodo.json';
import {Container} from "reactstrap";

export default function Rodo() {
    return <Container>
        <div style={{padding: "24px"}}>
            <h2 style={{textAlign: "center"}}>Administracja danymi osobowymi</h2>
            <p>
                {rodo.registrationTemplate}
            </p>
        </div>
    </Container>
}