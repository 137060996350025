import {isNull, isNaN} from "lodash"

export const measurements = [
    {key: "airTemperature200", raw: true, units: {"c": {name: "°C", formula: (value) => value}}, precision: 1, order: 1},
    {key: "airHumidity200", raw: true, units: {"%": {name: "%", formula: (value) => value}}, precision: 0, order: 2},
    {
        key: "wind", raw: true, units: {
            "ms": {name: " m/s", formula: (value) => value},
            "kmh": {name: " km/h", formula: (value) => value * 3.6}
        }, precision: 1, order: 3
    },
    {key: "airTemperature5", raw: true, units: {"c": {name: "°C", formula: (value) => value}}, precision: 1, order: 4},
    {key: "precipitation1", raw: true, units: {"mm": {name: " mm", formula: (value) => value}}, precision: 1, order: 5},
    {key: "precipitation2", raw: true, units: {"mm": {name: " mm", formula: (value) => value}}, precision: 1, order: 6},
    {
        key: "precipitationSum",
        raw: false,
        units: {"mm": {name: " mm", formula: (value) => value}},
        onlyMap: true,
        onlyMapStationClickMeas: "precipitation1",
        precision: 1,
        order: 7
    },
    {key: "precipitationDiurnalSum", raw: false, units: {"mm": {name: " mm", formula: (value) => value}}, precision: 1, order: 8},
    {key: "leafWetting", raw: true, units: {"": {name: "", formula: (value) => value}}, precision: 1, order: 9},
    {key: "soilTemperature15", raw: true, units: {"c": {name: "°C", formula: (value) => value}}, precision: 1, order: 10},
    {key: "soilTemperature45", raw: true, units: {"c": {name: "°C", formula: (value) => value}}, precision: 1, order: 11},
    {key: "soilHumidity10", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 12},
    {key: "soilHumidity20", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 13},
    {key: "soilHumidity30", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 14},
    {key: "soilHumidity40", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 15},
    {key: "soilHumidity50", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 16},
    {key: "soilHumidity60", raw: true, units: {"%": {name: "%"}, formula: (value) => value}, precision: 0, order: 17}
];

export function getOrder(m) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.order);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return {name: '', formula: (value) => value};
}

export function getOnlyMapStationClickMeas(m) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.onlyMapStationClickMeas);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return false;
}

export function getOnlyMap(m) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.onlyMap);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return false;
}

export function getUnit(m) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.units[Object.keys(meas.units)[0]]);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return {name: '', formula: (value) => value};
}

export function getUnitByKey(m, u) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.units[u]);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return {name: '', formula: (value) => value};
}

export function getPrecision(m) {
    const found = measurements.filter(meas => meas.key === m).map(meas => meas.precision);
    if (found && found.length && found.length > 0) {
        return found[0];
    }
    return 0;
}

export function applyPrecision(value, key) {
    if (!isNull(value) && !isNaN(value)) {
        const precision = getPrecision(key);
        return value.toFixed(precision);
    }
    return '';
}

export function applyShortcutRange(toDate, val) {
    let newDateFrom = toDate ? new Date(toDate) : new Date();
    let newDateTo = toDate ? toDate : new Date(newDateFrom);
    switch (val) {
        case 'day':
            newDateFrom.setDate(newDateTo.getDate() - 1);
            break;
        case 'week':
            newDateFrom.setDate(newDateTo.getDate() - 7);
            break;
        case 'month':
            newDateFrom.setMonth(newDateTo.getMonth() - 1);
            break;
        case 'quarter':
            newDateFrom.setMonth(newDateTo.getMonth() - 3);
            break;
        case 'halfYear':
            newDateFrom.setMonth(newDateTo.getMonth() - 6);
            break;
        case 'year':
            newDateFrom.setMonth(newDateTo.getMonth() - 12);
            break;
        default:
    }
    return {fromDate: newDateFrom, toDate: newDateTo}
}

export const measurementsData = [
    {
        key: 'meteoStation',
        label: 'Stacja'
    },
    {
        key: 'city',
        label: 'Miasto'
    },
    {
        key: "county",
        label: 'Województwo'
    },
    {
        key: 'airTemperature200',
        label: 'Temperatura powietrza 2m'
    },
    {
        key: 'wind',
        label: 'Siła wiatru (km/h)'
    },
    {
        key: 'airHumidity200',
        label: 'Wilgotność powietrza (%)'
    }
];

export function getFieldLabel(key) {
    /*FIXME: z tłumaczeń!*/
    return measurementsData.filter(e => e.key === key).map(e => e.label).find(e => true);
}

export function prepareExportData(data) {
    data.map(function (obj) {
        let key;
        for (key of Array.from(Object.keys(obj))) {
            const newKey = getFieldLabel(key);
            obj[newKey] = obj[key];
            delete obj[key];
        }
        return obj;
    })
}