import React from 'react';
import {withTranslation} from 'react-i18next';
import {Input, Label} from "reactstrap";
import MeteoService from "../../map/MeteoService";
import MyFieldService from "../../myfield/MyFieldService";
import {InputText} from "primereact/inputtext";
import VarietiesService from "../Varieties/VarietiesService";
import {AutoComplete} from "primereact/autocomplete";
import Commons from "../../../common/Commons";

class DiseaseBaseForm extends React.Component {

    constructor(props) {
        super(props);
        this.defaultStations = JSON.parse(localStorage.getItem('default_stations'));
        this.state = {
            stations: [],
            selectedStation: '',
            fields: [],
            selectedField: null,
            fieldName: '',
            fieldCity: '',
            plant: '',
            plantsHelp: [],
            plantsSuggestion: []
        };
        this.meteoService = new MeteoService();
        this.fieldService = new MyFieldService();
        this.varietiesService = new VarietiesService();
        this.handleStationChange = this.handleStationChange.bind(this);
    }

    componentDidMount() {
        if (!this.props.stationDisabled) {
            this.meteoService.getAllStations()
                .then(res => {
                    this.setState({stations: res});
                });
        }
        this.fieldService.getAll()
            .then(res => {
                this.setState({fields: res});
            });
        this.varietiesService.getAllBaseAndUser()
            .then(res => {
                const plants = res.map(variety => variety.name);
                this.setState({plantsHelp: plants});
            })

    }

    handleStationChange(e) {
        this.setState({selectedStation: e.target.value});
        this.props.onStationChange(e.target.value);
    }

    compareStation(a, b) {
        return a.city.localeCompare(b.city);
    }

    suggestPlant(event) {
        let results = this.state.plantsHelp.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({plantsSuggestion: results});
    }

    compareField(a, b) {
        return a.name.localeCompare(b.name);
    }

    render() {
        let stationOptions2;
        if (!this.props.stationDisabled) {
            stationOptions2 = Commons.buildStationOptions(this.state.stations, this.defaultStations, (a, b) => this.props.t('meteo:' + a, b));
        }
        const fieldOptions = [
            <option key={0} value={''}>
                {this.props.t('grains:field-other')}
            </option>];
        this.state.fields
            .sort(this.compareField)
            .map(field =>
                <option key={field.uuid} value={field.uuid}>
                    {field.name}, {field.city}
                </option>)
            .forEach(opt => fieldOptions.push(opt));

        return (
            <div>
                <section>
                    <h4><Label for="field">{this.props.t('grains:field')}</Label></h4>
                    <Input type="select" name="field" id="field" value={this.state.selectedField}
                           onChange={e => this.setState({selectedField: e.target.value})}>
                        {fieldOptions}
                    </Input>
                    {!this.state.selectedField
                    && <Label for='fieldName' className='mt-2'>{this.props.t('grains:field-other')}</Label>}
                    {!this.state.selectedField &&
                    <InputText name='fieldName' value={this.state.fieldName}
                               onChange={e => this.setState({fieldName: e.target.value})}/>}
                    {!this.state.selectedField
                    && <Label for='fieldCity' className='mt-2'>{this.props.t('grains:field-city')}</Label>}
                    {!this.state.selectedField &&
                    <InputText name='fieldCity' value={this.state.fieldCity}
                               onChange={e => this.setState({fieldCity: e.target.value})}/>}
                </section>

                <section>
                    <h4><Label for="plant">{this.props.t('grains:plant-name')}</Label></h4>
                    <AutoComplete name="plant" value={this.state.plant}
                                  suggestions={this.state.plantsSuggestion}
                                  completeMethod={this.suggestPlant.bind(this)}
                                  onChange={e => this.setState({plant: e.target.value})}/>
                </section>

                {
                    !this.props.stationDisabled &&
                    <section>
                        <h4><Label for="station">{this.props.t('grains:meteo-station')}</Label></h4>
                        <Input type="select" name="station" id="station" value={this.state.selectedStation}
                               onChange={this.handleStationChange}>
                            <option value={''}>
                                {this.props.t('grains:measurement-own')}
                            </option>
                            {stationOptions2}
                        </Input>
                    </section>
                }
            </div>
        )
    }

}

export default withTranslation('ns')(DiseaseBaseForm);
