import React from 'react';
import {withTranslation} from "react-i18next";
import SettingsService from "../MenuSettings/SettingsService";
import {Button, Col, Container, Form, FormGroup, Input, Label, Row} from "reactstrap";
import {Growl} from "primereact/growl";
import {Editor} from 'react-draft-wysiwyg';
import {convertToRaw, EditorState, ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

class AdminDocumentSettings extends React.Component {

    constructor(props) {
        super(props);
        this.settingsService = new SettingsService();
        this.state = {
            exportEditorStateBelowHeader: EditorState.createEmpty(),
            exportEditorStateDocumentEnd: EditorState.createEmpty(),
            reportEditorStateBelowHeader: EditorState.createEmpty(),
            reportEditorStateDocumentEnd: EditorState.createEmpty(),
            globalForm: {
                documentSettings: {
                    exportHtmlDocumentEnd: '',
                    exportPlainTextDocumentEnd: '',
                    exportHtmlBelowHeader: '',
                    exportPlainTextBelowHeader: '',
                    exportIncludeGeneratedOnDateTime: false,
                    reportHtmlDocumentEnd: '',
                    reportPlainTextDocumentEnd: '',
                    reportHtmlBelowHeader: '',
                    reportPlainTextBelowHeader: '',
                    reportIncludeGeneratedOnDateTime: false
                }
            },
            enableGlobalForm: false
        };
        this.growl = React.createRef();
    }

    componentDidMount() {
        this.settingsService.getGlobalSettingsOnlyDocument()
            .then(res => {
                let exportDocumentEndContentState = null;
                if (res.documentSettings.exportHtmlDocumentEnd !== null) {
                    const exportDocumentEndBlocksFromHTML = htmlToDraft(res.documentSettings.exportHtmlDocumentEnd);
                    exportDocumentEndContentState = ContentState.createFromBlockArray(exportDocumentEndBlocksFromHTML.contentBlocks, exportDocumentEndBlocksFromHTML.entityMap);
                }
                let exportBelowHeaderContentState = null;
                if (res.documentSettings.exportHtmlBelowHeader !== null) {
                    const exportBelowHeaderBlocksFromHTML = htmlToDraft(res.documentSettings.exportHtmlBelowHeader);
                    exportBelowHeaderContentState = ContentState.createFromBlockArray(exportBelowHeaderBlocksFromHTML.contentBlocks, exportBelowHeaderBlocksFromHTML.entityMap);
                }
                let reportDocumentEndContentState = null;
                if (res.documentSettings.reportHtmlDocumentEnd !== null) {
                    const reportDocumentEndBlocksFromHTML = htmlToDraft(res.documentSettings.reportHtmlDocumentEnd);
                    reportDocumentEndContentState = ContentState.createFromBlockArray(reportDocumentEndBlocksFromHTML.contentBlocks, reportDocumentEndBlocksFromHTML.entityMap);
                }
                let reportBelowHeaderContentState = null;
                if (res.documentSettings.reportHtmlBelowHeader !== null) {
                    const reportBelowHeaderBlocksFromHTML = htmlToDraft(res.documentSettings.reportHtmlBelowHeader);
                    reportBelowHeaderContentState = ContentState.createFromBlockArray(reportBelowHeaderBlocksFromHTML.contentBlocks, reportBelowHeaderBlocksFromHTML.entityMap);
                }
                this.setState({
                    exportEditorStateDocumentEnd: exportDocumentEndContentState ? EditorState.createWithContent(exportDocumentEndContentState) : EditorState.createEmpty(),
                    exportEditorStateBelowHeader: exportBelowHeaderContentState ? EditorState.createWithContent(exportBelowHeaderContentState) : EditorState.createEmpty(),
                    reportEditorStateDocumentEnd: reportDocumentEndContentState ? EditorState.createWithContent(reportDocumentEndContentState) : EditorState.createEmpty(),
                    reportEditorStateBelowHeader: reportBelowHeaderContentState ? EditorState.createWithContent(reportBelowHeaderContentState) : EditorState.createEmpty(),
                    globalForm: res,
                    enableGlobalForm: true
                })
            })
    }

    saveGlobal = () => {
        const {exportEditorStateDocumentEnd, exportEditorStateBelowHeader, reportEditorStateDocumentEnd, reportEditorStateBelowHeader, globalForm} = this.state;
        const newGlobalForm = Object.assign({}, globalForm);
        newGlobalForm.documentSettings.exportHtmlBelowHeader = draftToHtml(convertToRaw(exportEditorStateBelowHeader.getCurrentContent()));
        newGlobalForm.documentSettings.exportPlainTextBelowHeader = exportEditorStateBelowHeader.getCurrentContent().getPlainText();
        newGlobalForm.documentSettings.exportHtmlDocumentEnd = draftToHtml(convertToRaw(exportEditorStateDocumentEnd.getCurrentContent()));
        newGlobalForm.documentSettings.exportPlainTextDocumentEnd = exportEditorStateDocumentEnd.getCurrentContent().getPlainText();
        newGlobalForm.documentSettings.reportHtmlBelowHeader = draftToHtml(convertToRaw(reportEditorStateBelowHeader.getCurrentContent()));
        newGlobalForm.documentSettings.reportPlainTextBelowHeader = reportEditorStateBelowHeader.getCurrentContent().getPlainText();
        newGlobalForm.documentSettings.reportHtmlDocumentEnd = draftToHtml(convertToRaw(reportEditorStateDocumentEnd.getCurrentContent()));
        newGlobalForm.documentSettings.reportPlainTextDocumentEnd = reportEditorStateDocumentEnd.getCurrentContent().getPlainText();
        this.setState({
            globalForm: newGlobalForm
        });
        this.settingsService.saveGlobalOnlyDocument(newGlobalForm)
            .then(() => {
                this.growl.current.show({severity: 'info', summary: 'Zapis udany'});
            })
            .catch(err => {
                console.log(err);
                this.growl.current.show({severity: 'error', summary: 'Zapis nieudany'});
            });
    };

    onExportStateChangeDocumentEnd = (editorState) => {
        this.setState({
            exportEditorStateDocumentEnd: editorState
        });
    };

    onExportStateChangeBelowHeader = (editorState) => {
        this.setState({
            exportEditorStateBelowHeader: editorState
        });
    };

    onReportStateChangeDocumentEnd = (editorState) => {
        this.setState({
            reportEditorStateDocumentEnd: editorState
        });
    };

    onReportStateChangeBelowHeader = (editorState) => {
        this.setState({
            reportEditorStateBelowHeader: editorState
        });
    };

    handleExportIncludeGeneratedOnDateTimeChange = (event) => {
        const checked = event.target.checked;
        this.setState(state => {
            let newForm = Object.assign({}, state.globalForm);
            newForm.documentSettings.exportIncludeGeneratedOnDateTime = checked;
            return {globalForm: newForm};
        })
    };

    handleReportIncludeGeneratedOnDateTimeChange = (event) => {
        const checked = event.target.checked;
        this.setState(state => {
            let newForm = Object.assign({}, state.globalForm);
            newForm.documentSettings.reportIncludeGeneratedOnDateTime = checked;
            return {globalForm: newForm};
        })
    };

    render() {
        const styles = {
            editor: {
                border: '1px solid gray',
                minHeight: '6em'
            }
        };

        return <Container className='p-4'>
            <div>
                <Growl ref={this.growl}/>
                <h2>Administracja - konfiguracja dokumentów (dotyczy formatów PDF i XLSX)</h2>
                <hr/>
                <article>
                    <Form>
                        <h4>Eksporty danych</h4>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='exportIncludeGeneratedOnDateTime' type="checkbox"
                                               checked={this.state.globalForm.documentSettings.exportIncludeGeneratedOnDateTime}
                                               onChange={this.handleExportIncludeGeneratedOnDateTimeChange}/>
                                        dołącz datę wygenerowania dokumentu
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{width: "100%"}}>
                                        Tekst poniżej nagłówka
                                        <div style={styles.editor}>
                                            <Editor
                                                editorState={this.state.exportEditorStateBelowHeader}
                                                onEditorStateChange={this.onExportStateChangeBelowHeader}
                                                localization={{
                                                    locale: 'pl',
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'fontSize', 'textAlign', 'history'],
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
                                                    },
                                                    inline: {
                                                        options: ["bold", "italic"]
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{width: "100%"}}>
                                        Tekst na koniec dokumentu
                                        <div style={styles.editor}>
                                            <Editor
                                                editorState={this.state.exportEditorStateDocumentEnd}
                                                onEditorStateChange={this.onExportStateChangeDocumentEnd}
                                                localization={{
                                                    locale: 'pl',
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'fontSize', 'textAlign', 'history'],
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
                                                    },
                                                    inline: {
                                                        options: ["bold", "italic"]
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <hr/>
                        <h4>Raporty</h4>
                        <Row>
                            <Col>
                                <FormGroup check>
                                    <Label check>
                                        <Input name='reportIncludeGeneratedOnDateTime' type="checkbox"
                                               checked={this.state.globalForm.documentSettings.reportIncludeGeneratedOnDateTime}
                                               onChange={this.handleReportIncludeGeneratedOnDateTimeChange}/>
                                        dołącz datę wygenerowania dokumentu
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <br/>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{width: "100%"}}>
                                        Tekst poniżej nagłówka
                                        <div style={styles.editor}>
                                            <Editor
                                                editorState={this.state.reportEditorStateBelowHeader}
                                                onEditorStateChange={this.onReportStateChangeBelowHeader}
                                                localization={{
                                                    locale: 'pl',
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'fontSize', 'textAlign', 'history'],
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
                                                    },
                                                    inline: {
                                                        options: ["bold", "italic"]
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label style={{width: "100%"}}>
                                        Tekst na koniec dokumentu
                                        <div style={styles.editor}>
                                            <Editor
                                                editorState={this.state.reportEditorStateDocumentEnd}
                                                onEditorStateChange={this.onReportStateChangeDocumentEnd}
                                                localization={{
                                                    locale: 'pl',
                                                }}
                                                toolbar={{
                                                    options: ['inline', 'fontSize', 'textAlign', 'history'],
                                                    fontSize: {
                                                        options: [8, 9, 10, 11, 12, 14, 16, 18, 24],
                                                    },
                                                    inline: {
                                                        options: ["bold", "italic"]
                                                    }
                                                }}
                                            />
                                        </div>
                                    </Label>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Button className='mt-4' onClick={this.saveGlobal} color='primary'><FontAwesomeIcon
                                    icon='check'/> Zapisz</Button>
                            </Col>
                        </Row>
                    </Form>
                </article>
            </div>
        </Container>
    }
}

export default withTranslation(['admin', 'meteo'])(AdminDocumentSettings);