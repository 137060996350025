export default {
    "label": "Powiaty",
    "viewBox": "0 0 1276 1193",
    "locations": [{
        "name": "Powiat świecki",
        "id": "świecki",
        "path": "M495.053 67.2204V53.2384H469.631V84.3801L458.191 100.269L440.396 107.895L429.591 146.028H447.387L458.191 189.881L424.507 207.676L414.974 252.164L386.859 273.915L399.721 291.568L391.459 308.092L399.721 335.421V364.656L429.591 371.012L440.396 398.34H469.631V412.958L513.483 419.949V430.753L528.101 434.566L552.252 364.656C558.184 361.69 571.445 354.996 577.038 351.945C582.631 348.894 583.182 335.421 582.758 329.065C587.207 332.667 597.121 337.709 601.189 329.065C605.256 320.422 614.747 298.347 618.984 288.39L659.659 291.568L665.379 277.586H709.232L751.178 207.676L758.804 128.868C753.72 124.631 743.551 112.98 743.551 100.269C743.551 84.3801 751.178 78.0247 758.804 67.2204C766.431 56.4161 753.085 41.7985 751.178 53.2384C749.271 64.6782 730.84 60.2294 723.214 60.2294C715.587 60.2294 708.596 53.2384 696.521 53.2384C686.861 53.2384 683.598 69.7626 683.174 78.0247L650.762 72.9403L659.659 91.3711H641.864V84.3801H632.331V78.0247H622.797V53.2384H596.105V67.2204C571.742 67.2204 521.873 65.8222 517.297 60.2294C512.721 54.6366 500.561 62.5597 495.053 67.2204Z"
    }, {
        "name": "Powiat żniński",
        "id": "żniński",
        "path": "M137.875 899.151V941.732L147.408 935.377L162.661 952.536V929.657H185.541L205.243 911.861L220.496 920.124L213.505 964.612L239.563 959.527V993.211L250.367 1002.11L282.144 970.332L289.771 952.536L306.295 935.377V899.151L346.97 870.551H362.859V853.391L331.081 803.819H362.859V789.201L401.627 818.436L439.124 809.538V778.397L427.684 770.135V740.899L419.422 727.553L380.018 721.833V701.496H401.627V680.523L380.018 657.007V639.848L368.578 624.594L336.801 629.679L317.099 594.724L311.379 629.679L317.099 657.007L311.379 680.523L282.144 708.487V727.553H213.505L181.092 751.068L147.408 756.153V853.391H132.155L121.987 834.325L100.378 853.391V877.542L137.875 899.151Z"
    }, {
        "name": "Powiat sępoleński",
        "id": "sępoleński",
        "path": "M81.3941 407.238L94.7405 434.566C102.155 430.753 119.018 421.855 127.153 416.771C135.288 411.686 144.525 418.889 148.126 423.126L159.566 412.958L165.286 423.126H200.241V412.958C203.843 414.229 214.223 416.008 226.934 412.958C239.645 409.907 244.941 384.993 246.001 372.918L226.934 367.198C226.934 367.198 251.297 325.676 264.431 313.177V296.017L246.001 289.026L241.552 229.92L218.037 236.911L195.157 206.405H172.277V191.152L195.157 177.805L172.277 161.281L165.286 177.805H142.407L130.331 142.215H116.985C109.57 150.901 93.3423 169.162 87.7495 172.721C80.7585 177.17 87.7495 200.685 81.3941 223.565C75.0386 246.444 89.6562 271.231 66.141 276.951C47.3288 281.527 29.0674 317.837 22.2883 335.421L0.679688 355.123L14.0262 383.722L28.6437 372.918L62.9632 383.722V403.424L81.3941 407.238Z"
    }, {
        "name": "Powiat nakielski",
        "id": "nakielski",
        "path": "M193.562 754.922H159.36V719.685L151.069 703.102H131.378L102.358 676.156L81.6304 687.556H54.6835V670.974L43.2832 660.609L59.8657 635.736V593.243L74.3754 585.988L66.0837 555.933L81.6304 548.678V536.241L66.0837 526.913L59.8657 505.149L66.0837 492.712L54.6835 480.275L66.0837 466.802L81.6304 474.056L102.358 460.583L91.483 427.821C96.3195 425.403 112.722 414.152 125.159 406.69C137.596 399.228 147.615 409.799 151.069 416.018L159.36 406.69L167.652 416.018H187.343L200.817 406.69H237.091V433.637L223.618 451.256L230.872 474.056L263.001 492.712V505.149L253.673 526.913H279.583L274.401 567.333H311.712L332.44 599.462V627.445V687.556L287.875 741.449H230.872L193.562 754.922Z"
    }, {
        "name": "Powiat tucholski",
        "id": "tucholski",
        "path": "M196.981 164.459L183.635 183.525L158.849 195.601V209.583H183.635L207.786 241.36L228.759 233.733L233.843 294.11H273.883L284.051 308.092L307.566 304.279C307.566 300.466 309.473 293.093 317.1 294.11C326.633 295.381 341.25 332.879 352.055 325.888C360.698 320.295 387.01 319.744 399.085 320.168V304.279C399.085 298.178 405.864 290.721 409.254 287.755C407.135 287.967 400.992 286.611 393.365 279.493C383.832 270.595 410.525 262.969 418.152 259.791C425.778 256.613 428.32 236.911 428.32 227.378V209.583H444.844L451.835 195.601H470.902L480.435 183.525C477.257 183.525 468.741 182 460.098 175.899C451.454 169.798 456.496 147.087 460.098 136.495C455.013 137.342 443.573 138.529 438.489 136.495C433.405 134.461 440.608 119.123 444.844 111.709H470.902V104.082C473.02 97.7266 477.893 85.0156 480.435 85.0156C483.613 85.0156 470.902 72.9403 470.902 65.9492V53.8739H499.501L496.324 22.0965H460.098C458.827 25.2743 455.395 31.6297 451.835 31.6297C448.276 31.6297 441.455 15.5292 438.489 7.47898C433.405 10.0212 422.219 15.1055 418.152 15.1055C414.084 15.1055 403.746 5.78419 399.085 1.12352C397.179 1.97092 393.365 4.42836 393.365 7.47898V31.6297H386.374V37.9852C388.705 44.3407 388.916 56.9245 371.121 56.416C353.326 55.9076 338.708 33.3245 333.624 22.0965H290.407L297.398 44.9762H307.566V65.9492C307.566 74.8469 270.705 85.0156 266.892 85.0156H233.843V117.428L243.376 128.233H266.892L251.003 164.459H196.981Z"
    }, {
        "name": "Powiat toruński",
        "id": "toruński",
        "path": "M439.642 797.599L449.785 529.335L596.872 413.983L977.566 379.186V797.599H439.642Z"
    }, {
        "name": "Powiat inowrocławski",
        "id": "inowrocławski",
        "path": "M432.768 806.996L402.898 813.352V859.111L391.458 893.431L402.898 923.301L432.768 929.657L481.07 916.31L510.941 893.431L521.109 976.687L578.308 1007.83L585.299 1032.62H603.73L624.703 1007.83V997.025H645.041V954.443L635.508 947.452V929.657L645.041 908.048H656.481V859.111L674.911 852.756L680.631 845.765H709.231V806.996H698.427L667.285 786.659L685.716 748.526L650.125 716.113L645.041 691.962H611.992H578.308L599.281 662.727L575.131 657.643L570.682 646.839L510.941 657.643L500.772 672.896H457.555L448.657 688.149L432.768 677.98H402.898L395.271 696.411L374.934 688.149V724.375L420.057 735.18V774.583L432.768 786.659V806.996Z"
    }, {
        "name": "Powiat bydgoski",
        "id": "bydgoski",
        "path": "M223.04 439.65L218.591 418.042C230.285 412.449 235.751 389.866 237.022 379.274L218.591 372.918C218.591 372.918 245.072 327.582 255.453 318.261V302.372L234.507 293.866L274.519 292.204L284.688 306.821L305.242 304.23C305.242 298.933 308.457 291.187 317.101 292.204C327.905 293.475 338.709 324.617 352.056 323.981C365.402 323.346 394.638 308.092 400.993 318.261C406.077 326.396 403.111 337.328 400.993 341.776V361.478C406.501 362.326 419.932 364.529 429.593 366.563C439.253 368.596 441.244 386.053 441.032 394.527L469.632 397.247V412.322L515.391 418.042V432.024H527.467C531.916 437.956 542.974 452.361 551.617 462.53C560.261 472.699 558.609 500.239 556.702 512.738H537L540.813 525.449L527.467 544.516L509.036 533.076L469.632 562.946L527.467 573.751L593.564 616.332L581.488 627.137L577.675 658.914L543.355 664.634V658.914L515.391 664.634C517.722 670.778 520.984 684.717 515.391 691.327C508.4 699.589 494.418 683.065 486.156 683.065C479.547 683.065 478.742 692.81 479.165 697.682L463.277 683.065V697.682H441.032L424.508 683.065H400.993L376.614 658.914V640.128L368.538 627.137L336.234 632.052L317.101 595.359L306.297 573.751H263.715L270.07 533.076H244.649V521.636L251.004 516.552V503.841L237.022 496.214V490.494L223.04 481.597H212.871L203.974 469.521L212.871 462.53V449.819L223.04 439.65Z"
    }, {
        "name": "Powiat grudziądzki",
        "id": "grudziądzki",
        "path": "M822.357 127.597H756.896L749.269 206.405L709.865 277.586H683.172V300.466L715.585 306.821L741.007 331.608L753.082 306.821C761.768 308.092 780.411 310.762 785.495 311.27C790.58 311.779 807.951 335.633 816.002 347.496L827.441 351.31L830.619 343.683H857.312L873.201 331.608V318.261H885.276L894.809 331.608L912.605 328.43V300.466C919.596 303.008 935.103 307.838 941.204 306.821C948.831 305.55 952.644 261.062 958.364 255.978C962.94 251.91 974.676 257.672 979.973 261.062L1006.03 238.182L979.973 195.601L987.599 168.272H970.439V144.757L933.578 127.597H839.517V151.748H822.357V127.597Z"
    }, {
        "name": "Powiat mogileński",
        "id": "mogileński",
        "path": "M318.371 955.714H288.5L305.66 935.377V897.244L346.97 871.186H362.223V853.391L329.175 801.912H362.223V786.659L413.067 826.698V845.129C416.033 848.095 421.965 854.789 421.965 857.84C421.965 860.891 407.135 879.872 399.721 888.982L409.254 897.244V919.488H442.302L445.48 909.955H479.799V893.431C487.426 886.44 505.221 872.839 515.39 874.364C525.559 875.889 523.017 887.711 520.474 893.431C524.711 892.795 533.44 892.668 534.456 897.244C535.473 901.82 527.677 912.285 523.652 916.946L536.999 919.488L523.652 928.386V949.359L536.999 955.714L529.372 977.958L541.447 984.314H577.038L590.385 997.025C589.961 1008.89 587.842 1032.62 582.758 1032.62C577.674 1032.62 575.131 1047.02 574.496 1054.22L536.999 1088.54L529.372 1079.65C527.465 1082.61 523.017 1088.54 520.474 1088.54C517.932 1088.54 509.67 1082.61 505.857 1079.65V1066.93C494.841 1066.72 470.902 1066.43 463.275 1066.93C453.742 1067.57 442.302 1052.32 453.107 1047.87C461.75 1044.31 460.098 1036.22 458.191 1032.62H399.721L376.841 1001.47C375.994 1003.8 372.646 1008.85 366.037 1010.37C359.427 1011.9 358.622 995.33 359.046 986.856L339.979 965.247L334.259 969.061H318.371V955.714Z"
    }, {
        "name": "Powiat aleksandrowski",
        "id": "aleksandrowski",
        "path": "M697.79 760.601L685.715 748.526L663.471 787.93L705.417 812.08V842.587H676.182L669.191 850.213L651.396 859.746L657.751 889.617L712.408 864.831L742.914 880.719L755.625 872.457L819.18 932.834H833.797L829.984 906.777V880.719L838.246 876.271L829.984 869.28V842.587H814.731V823.52L829.984 818.436H857.948L853.499 764.415L814.731 714.206L781.683 709.758L755.625 686.878L742.914 700.86V722.468L705.417 736.45L697.79 760.601Z"
    }, {
        "name": "Powiat włocławski",
        "id": "włocławski",
        "path": "M891.632 857.84L856.677 815.894L838.881 810.174L808.375 822.249V845.129L826.171 848.942V907.412L812.824 936.648L767.065 995.753L776.598 1086L760.709 1082.19L756.896 1131.12L760.709 1145.11H776.598L797.571 1166.08V1188.96L808.375 1192.14L812.824 1184.51H842.695L868.117 1162.27C875.955 1155.91 894.174 1145.87 904.343 1156.55C914.511 1167.22 939.086 1184.72 950.102 1192.14L962.177 1180.06H1010.48L1021.92 1169.89L1033.99 1175.61C1045.43 1156.97 1068.06 1117.14 1067.04 1106.97C1066.03 1096.81 1053.91 1098.92 1047.98 1101.25V1082.19H1082.3L1086.11 1054.22C1092.68 1047.02 1106.07 1030.33 1107.08 1021.18C1108.35 1009.74 1085.47 1020.54 1086.11 1014.18C1086.62 1009.1 1083.78 990.457 1082.3 981.771L1112.8 974.78V958.256C1117.04 957.197 1125.51 952.79 1125.51 943.639C1125.51 934.487 1103.48 928.809 1092.46 927.114L1052.43 931.563L1033.99 907.412L1006.03 911.861V888.346L997.768 883.897V863.56L978.702 840.045H962.177L950.102 831.147L915.147 863.56L891.632 857.84Z"
    }, {
        "name": "Powiat radziejowski",
        "id": "radziejowski",
        "path": "m 598.645,1049.14 5.084,-16.52 17.796,-36.867 20.337,-5.084 -3.178,-35.591 -10.804,-5.084 6.991,-38.768 16.524,-10.169 6.356,-11.44 c 16.736,-7.626 48.43623,-24.49174 54.02823,-24.49174 5.593,0 24.14377,11.99174 31.13477,15.59374 3.178,-1.695 9.65277,-6.84232 12.70377,-7.35032 21.88198,14.27955 61.83489,57.06272 61.83489,57.06272 L 767.065,995.753 c 0,0 5.69216,53.9664 9.533,90.247 l -15.889,-3.81 -3.813,48.93 -12.075,-1.9 v -24.79 h -26.693 l -5.085,-13.98 h -22.879 l -5.085,-16.52 c -2.33,-6.15 -9.406,-18.56 -19.066,-19.07 -9.66,-0.51 -24.786,19.7 -31.142,29.87 l -10.169,-4.45 -12.075,10.17 c 0,0 -14.406,-29.45 -13.982,-41.31 z"
    }, {
        "name": "Powiat lipnowski",
        "id": "lipnowski",
        "path": "M880.192 763.143L854.771 773.312V820.343L880.192 862.924L925.316 869.28L954.551 845.129L974.253 852.12L999.04 915.039H1030.82L1058.15 937.283L1089.92 929.657L1120.43 902.328L1132.5 897.244L1126.78 877.542V852.12L1162.37 862.924C1164.92 860.594 1170 851.103 1170 831.782C1170 812.462 1136.95 784.752 1120.43 773.312V756.788L1145.22 747.255L1142.04 733.273H1162.37L1132.5 703.402L1142.04 688.149L1132.5 683.7V663.998H1105.18L1089.92 674.167H1073.4V663.998H1058.15L1038.44 643.025L1030.82 622.052H1003.49L979.973 631.585C981.244 637.941 981.88 651.796 974.253 656.372C966.627 660.948 946.925 649.381 938.027 643.025L900.53 656.372V683.7L925.316 709.122V739.628L913.241 756.788L880.192 751.068V763.143Z"
    }, {
        "name": "Powiat rypiński",
        "id": "rypiński",
        "path": "m 978.7,489.859 41.31,-41.311 31.78,-17.16 20.97,70.546 h 24.15 l 86.44,8.262 h 21.6 l 16.53,24.786 v 24.787 l 20.97,24.786 3.82,57.199 -68.01,-19.702 14.62,19.702 -19.07,22.244 -28.6,3.178 -54.65,24.151 -38.77,-24.151 -22.25,-40.675 -35.587,-4.449 -6.991,-22.879 24.788,-68.639 z"
    }, {
        "name": "Powiat golubsko-dobrzyński",
        "id": "golubsko-dobrzyński",
        "path": "m 761.345,494.307 v -33.683 c 7.118,6.609 38.133,15.464 52.751,19.066 l 11.439,-19.066 23.516,9.533 h 23.515 c 3.601,-7.203 11.567,-20.846 14.617,-17.796 3.051,3.051 2.966,19.491 2.543,27.329 h 24.786 l 15.889,-19.066 h 9.533 v -15.889 l 14.11687,-1.56384 9.39813,-12.41816 17.795,-4.449 26.056,4.449 12.71,17.795 -41.31,41.311 33.05,40.675 -24.788,68.639 6.991,22.879 12.9887,1.25189 -39.0437,40.69411 -27.964,-15.253 v -13.346 h -50.208 l -6.356,13.346 h -15.253 l -12.075,-13.346 5.084,-27.964 H 840.153 L 796.936,559.769 V 532.44 c -5.508,-6.779 -18.05,-20.337 -24.151,-20.337 -6.101,0 -10.169,-11.864 -11.44,-17.796 z"
    }, {
        "name": "Powiat brodnicki",
        "id": "brodnicki",
        "path": "m 1013.02,253.435 -8.26,-13.346 -47.668,9.533 -12.71,19.066 -21.609,55.293 24.786,21.609 -3.177,55.928 12.71,34.955 h 15.254 l 48.934,12.711 30.51,-17.796 20.97,70.546 133.7797,11.42866 4.7703,5.09534 17.16,3.178 13.98,-19.066 c 4.24,-0.636 15.76,-5.339 27.97,-19.067 12.2,-13.728 -2.55,-37.073 -11.44,-47.03 2.54,0.635 8.39,-1.271 11.44,-13.982 3.05,-12.711 -20.76,-12.499 -33.05,-10.805 v -17.159 c 3.39,-0.848 11.69,-5.212 17.79,-15.889 6.1,-10.677 -12.28,-19.278 -22.24,-22.244 l -4.45,-32.413 h -42.58 l -18.43,10.804 -19.07,-4.449 c 0,0 -11.44,-26.692 -20.34,-26.057 -8.89,0.636 -17.16,-5.084 -20.97,-12.711 -3.05,-6.101 -19.49,-2.542 -27.33,0 l -4.45,-22.88 -17.16,13.983 -18.43,-13.983 v 26.693 l -34.95,-12.71 v -13.983 z"
    }, {
        "name": "Powiat wąbrzeski",
        "id": "wąbrzeski",
        "path": "m 774.692,433.295 c -0.509,-10.677 -16.736,-16.736 -24.787,-18.431 l -20.973,-52.115 -10.804,-20.973 3.813,-2.542 14.618,-19.702 13.346,-17.16 39.404,8.898 26.058,27.964 47.03,-9.533 4.449,-18.431 h 12.075 l 25.422,12.711 5.72,-27.328 h 22.244 l -9.534,27.328 24.786,21.609 -3.177,55.928 12.71,34.955 10.19892,-0.31594 4.18429,16.26306 -61.41221,34.26088 -43.217,-6.991 -38.133,-8.898 -13.346,15.889 -40.675,-15.889 -12.711,-9.533 c 4.448,-4.872 13.219,-17.287 12.711,-27.964 z"
    }, {
        "name": "Powiat chełmiński",
        "id": "chełmiński",
        "path": "m 733.67254,323.09011 -12.41687,19.1327 c 7.13647,26.31996 27.06814,57.77096 28.64933,72.64119 l -10.937,9.033 h -10.036 -0.19 l -0.141,0.126 -22,19.485 -7.966,-3.676 -0.175,-0.081 -0.184,0.058 -22.244,6.991 -0.051,0.016 -0.046,0.026 -18.952,10.739 h -24.654 -0.263 l -0.149,0.216 -12.561,18.215 h -8.636 -0.216 l -0.149,0.159 -18.282,19.543 h -35.579 l -4.385,-29.444 -0.018,-0.12 -0.07,-0.098 -24.04,-33.529 6.321,-60.048 60.85,-79.232 67.013,-30.345 22.39,13.683 -1.881,22.572 -0.041,0.493 0.493,0.047 32.459,7.128 z"
    }]
}